@use 'tailwindcss/tailwind';
@use 'libs/design-system/styles' as design-system;
@use '@angular/cdk/overlay-prebuilt';

body {
  @apply m-0;
}

*,
::before,
::after {
  @apply box-border;
}

:root {
  @include design-system.setup-color();
  @include design-system.setup-work-sans();
}

@include design-system.table-component();
@include design-system.button-component();
@include design-system.avatar-component();
@include design-system.badge-component();
@include design-system.button-group-component();
@include design-system.field-component();
@include design-system.input-component();
@include design-system.checkbox-component();
@include design-system.radio-component();
@include design-system.select-component();
@include design-system.toggle-component();
@include design-system.pagination-component();
@include design-system.modal-component();
@include design-system.tags-component();
@include design-system.tabs-component();
@include design-system.tooltip-component();
@include design-system.alert-component();
@include design-system.date-picker-component();
@include design-system.validation-component();
