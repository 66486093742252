@use '../../functions';

@mixin font-value-render-variables(
  $font-family,
  $font-family-key,
  $font-key,
  $font-weight
) {
  font-family: #{$font-family};
  font-size: var(
    #{functions.to-typography-variable($font-family-key, $font-key, font-size)}
  );
  line-height: var(
    #{functions.to-typography-variable($font-family-key, $font-key, line-height)}
  );
  letter-spacing: var(
    #{functions.to-typography-variable(
        $font-family-key,
        $font-key,
        letter-spacing
      )}
  );
  font-weight: #{$font-weight};
}
