@use '../../../palette';
@use '../../../typography';

@mixin tooltip-component() {
  .ds-tooltip {
    --ds-tooltip-bg: var(#{palette.to-color-variable(branding, base, white)});
    --ds-tooltip-title-color: var(
      #{palette.to-color-variable(branding, neutral, 800)}
    );
    --ds-tooltip-description-color: var(
      #{palette.to-color-variable(branding, neutral, 600)}
    );
    box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03),
      0 12px 16px -4px rgba(16, 24, 40, 0.08);
    background: var(--ds-tooltip-bg);
    @apply p-3 rounded-lg flex flex-col gap-1;

    &--dark {
      --ds-tooltip-bg: var(
        #{palette.to-color-variable(branding, neutral, 900)}
      );
      --ds-tooltip-title-color: var(
        #{palette.to-color-variable(branding, base, white)}
      );
      --ds-tooltip-description-color: var(
        #{palette.to-color-variable(branding, base, white)}
      );
    }

    &--white {
      --ds-tooltip-bg: var(#{palette.to-color-variable(branding, base, white)});
      --ds-tooltip-title-color: var(
        #{palette.to-color-variable(branding, neutral, 800)}
      );
      --ds-tooltip-description-color: var(
        #{palette.to-color-variable(branding, neutral, 600)}
      );
    }

    &:has(p) {
      @apply w-[320px];
    }

    &:not(:has(p)) {
      @apply w-fit min-w-[146px];
    }

    > h5 {
      @include typography.use-work-sans(md, 500);
      color: var(--ds-tooltip-title-color);
      @apply m-0 p-0;
    }

    > p {
      @include typography.use-work-sans(md, 400);
      color: var(--ds-tooltip-description-color);
      @apply m-0 p-0;
    }
  }
}
