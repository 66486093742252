@use '../../../palette';
@use '../../../typography';

@mixin field-component() {
  .ds-field {
    --ds-field-height: 40px;
    --ds-field-width: 320px;
    --ds-field-gap: 6px;
    --ds-field-control-padding-x: 7px;
    --ds-field-control-padding-y: 11px;
    --ds-field-control-gap: 8px;
    --ds-field-bg-color: var(
      #{palette.to-color-variable(branding, base, white)}
    );
    --ds-field-footer-color: var(
      #{palette.to-color-variable(branding, neutral, 600)}
    );
    --ds-field-header-color: var(
      #{palette.to-color-variable(branding, neutral, 700)}
    );
    --ds-field-header-required-color: var(
      #{palette.to-color-variable(branding, error, 600)}
    );
    --ds-field-border-color: var(
      #{palette.to-color-variable(branding, neutral, 300)}
    );
    --ds-field-box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    @apply flex flex-col;
    gap: var(--ds-field-gap);
    width: var(--ds-field-width);

    &--sm {
      --ds-field-height: 40px;
      --ds-field-control-padding-x: 11px;
      --ds-field-control-padding-y: 7px;
    }

    &--md {
      --ds-field-height: 44px;
      --ds-field-control-padding-x: 13px;
      --ds-field-control-padding-y: 9px;
    }

    &:has(.ds-input) {
      &:has(.ds-input.has-error) {
        > footer {
          --ds-field-footer-color: var(
            #{palette.to-color-variable(branding, error, 500)}
          );
        }
      }

      &:not(:has(.ds-input.has-error)) {
        > footer {
          --ds-field-footer-color: var(
            #{palette.to-color-variable(branding, neutral, 600)}
          );
        }
      }

      &:has(.ds-input:disabled) {
        --ds-field-bg-color: var(
          #{palette.to-color-variable(branding, neutral, 50)}
        );
      }

      &:not(:has(.ds-input:disabled)) {
        &:has(.ds-input.has-error) {
          --ds-field-border-color: var(
            #{palette.to-color-variable(branding, error, 300)}
          );

          &:has(.ds-input:active),
          &:has(.ds-input:focus),
          &:has(.ds-input:focus-visible) {
            --ds-field-box-shadow: 0px 0px 0px 4px #fee4e2,
              0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }
        }

        &:not(:has(.ds-input.has-error)) {
          &:has(.ds-input:active),
          &:has(.ds-input:focus),
          &:has(.ds-input:focus-visible) {
            --ds-field-border-color: var(
              #{palette.to-color-variable(branding, primary, 300)}
            );
            --ds-field-box-shadow: 0px 0px 0px 4px #f4ebff,
              0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }
        }
      }
    }

    &:has(.ds-select) {
      &:has(.ds-select.has-error) {
        > footer {
          --ds-field-footer-color: var(
            #{palette.to-color-variable(branding, error, 500)}
          );
        }
      }

      &:not(:has(.ds-select.has-error)) {
        > footer {
          --ds-field-footer-color: var(
            #{palette.to-color-variable(branding, neutral, 600)}
          );
        }
      }

      &:has(.ds-select.disabled),
      &:has(.ds-select:disabled) {
        --ds-field-bg-color: var(
          #{palette.to-color-variable(branding, neutral, 50)}
        );
      }

      &:not(:has(.ds-select.disabled)):not(:has(.ds-select:disabled)) {
        &:has(.ds-select.has-error) {
          --ds-field-border-color: var(
            #{palette.to-color-variable(branding, error, 300)}
          );

          &:has(.ds-select.focused),
          &:has(.ds-select:active),
          &:has(.ds-select:focus),
          &:has(.ds-select:focus-visible) {
            --ds-field-box-shadow: 0px 0px 0px 4px #fee4e2,
              0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }
        }

        &:not(:has(.ds-select.has-error)) {
          &:has(.ds-select.focused),
          &:has(.ds-select:active),
          &:has(.ds-select:focus),
          &:has(.ds-select:focus-visible) {
            --ds-field-border-color: var(
              #{palette.to-color-variable(branding, primary, 300)}
            );
            --ds-field-box-shadow: 0px 0px 0px 4px #f4ebff,
              0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }
        }
      }
    }

    &:has(.ds-date-picker) {
      &:has(.ds-date-picker.has-error) {
        > footer {
          --ds-field-footer-color: var(
            #{palette.to-color-variable(branding, error, 500)}
          );
        }
      }

      &:not(:has(.ds-date-picker.has-error)) {
        > footer {
          --ds-field-footer-color: var(
            #{palette.to-color-variable(branding, neutral, 600)}
          );
        }
      }

      &:has(.ds-date-picker.disabled),
      &:has(.ds-date-picker:disabled) {
        --ds-field-bg-color: var(
          #{palette.to-color-variable(branding, neutral, 50)}
        );
      }

      &:not(:has(.ds-date-picker.disabled)):not(:has(.ds-date-picker:disabled)) {
        &:has(.ds-date-picker.has-error) {
          --ds-field-border-color: var(
            #{palette.to-color-variable(branding, error, 300)}
          );

          &:has(.ds-date-picker.focused),
          &:has(.ds-date-picker:active),
          &:has(.ds-date-picker:focus),
          &:has(.ds-date-picker:focus-visible) {
            --ds-field-box-shadow: 0px 0px 0px 4px #fee4e2,
              0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }
        }

        &:not(:has(.ds-date-picker.has-error)) {
          &:has(.ds-date-picker.focused),
          &:has(.ds-date-picker:active),
          &:has(.ds-date-picker:focus),
          &:has(.ds-date-picker:focus-visible) {
            --ds-field-border-color: var(
              #{palette.to-color-variable(branding, primary, 300)}
            );
            --ds-field-box-shadow: 0px 0px 0px 4px #f4ebff,
              0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }
        }
      }
    }

    > header {
      > label {
        @include typography.use-work-sans(sm, 500);
        color: var(--ds-field-header-color);
      }

      > span {
        @include typography.use-work-sans(sm, 400);
        color: var(--ds-field-header-required-color);
      }
    }

    > main {
      @apply w-full;
      &:has(.ds-input) {
        @apply flex items-center justify-between rounded-xl;
        background-color: var(--ds-field-bg-color);
        box-shadow: var(--ds-field-box-shadow);
        border: 1px solid var(--ds-field-border-color);
        gap: var(--ds-field-control-gap);
        padding: var(--ds-field-control-padding-y)
          var(--ds-field-control-padding-x);

        &:has(input.ds-input) {
          height: var(--ds-field-height);
        }
      }
    }

    > footer {
      span {
        @include typography.use-work-sans(sm, 400);
        color: var(--ds-field-footer-color);
      }
    }
  }
}
