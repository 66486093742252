@use '../../../palette';
@use '../../../typography';
@use '../../../../assets/fonts/ocloud-icon' as icon;

@mixin radio-component() {
  .ds-radio {
    &-group {
      @apply flex flex-col gap-3;
    }

    --ds-radio-box-bg: var(#{palette.to-color-variable(branding, base, white)});
    --ds-radio-box-border: var(
      #{palette.to-color-variable(branding, neutral, 300)}
    );
    --ds-radio-box-shadow: none;
    --ds-radio-circle-size: 6px;
    --ds-radio-circle-opacity: 1;
    --ds-radio-size: 16px;
    --ds-radio-x: 0px;
    --ds-radio-y: 2px;
    --ds-radio-circle-distance: -3px;
    --ds-radio-padding-left: 24px;
    --ds-radio-gap: 0px;
    --ds-radio-content: '';

    @apply relative;

    &--sm {
      --ds-radio-circle-size: 6px;
      --ds-radio-size: 16px;
      --ds-radio-padding-left: 24px;
      --ds-radio-gap: 0px;
      --ds-radio-circle-distance: -3px;
    }

    &--md {
      --ds-radio-circle-size: 8px;
      --ds-radio-size: 20px;
      --ds-radio-padding-left: 32px;
      --ds-radio-gap: 2px;
      --ds-radio-circle-distance: -4px;
    }

    &--circle {
      --ds-radio-content: '';
      &::before {
        width: var(--ds-radio-circle-size);
        height: var(--ds-radio-circle-size);
        top: calc(var(--ds-radio-y) + calc(var(--ds-radio-size) / 2));
        left: calc(var(--ds-radio-x) + calc(var(--ds-radio-size) / 2));
        background-color: var(--ds-radio-box-border);
        transform: translate(
          var(--ds-radio-circle-distance),
          var(--ds-radio-circle-distance)
        );
      }
    }

    &--tick {
      --ds-radio-content: '#{icon.get-icon-content(bold-tick-circle)}';
      &::before {
        --ds-icon-font-size: calc(var(--ds-radio-size) + 2px) !important;
        @include icon.icon-component-default-config();
        top: 1px;
        left: -1px;
        color: var(--ds-radio-box-circle);
      }
    }

    &:has(label:empty) {
      --ds-radio-x: 0px;
      --ds-radio-y: 0px;
      --ds-radio-padding-left: 0px;

      > label {
        width: var(--ds-radio-size);
        height: var(--ds-radio-size);
      }
    }

    > label {
      @apply flex flex-col w-full z-[1] relative;
      padding-left: var(--ds-radio-padding-left);
      gap: var(--ds-radio-gap);

      > strong {
        @include typography.use-work-sans(md, 500);
        @apply text-branding-neutral-800;
      }

      > small {
        @include typography.use-work-sans(md, 400);
        @apply text-branding-neutral-600;
      }
    }

    > input[type='radio'] {
      @apply opacity-0 w-0 h-0 hidden;
    }

    &::before {
      @apply absolute z-[1] rounded-full;
      content: var(--ds-radio-content);
      opacity: var(--ds-radio-circle-opacity);
    }

    &::after {
      @apply absolute rounded-full content-[''];
      background-color: var(--ds-radio-box-bg);
      box-shadow: var(--ds-radio-box-shadow);
      border: 1px solid var(--ds-radio-box-border);
      width: var(--ds-radio-size);
      height: var(--ds-radio-size);
      top: var(--ds-radio-y);
      left: var(--ds-radio-x);
    }

    &:has(input[type='radio']:disabled) {
      @apply cursor-not-allowed;
      --ds-radio-box-border: var(
        #{palette.to-color-variable(branding, neutral, 200)}
      );
      --ds-radio-box-bg: var(
        #{palette.to-color-variable(branding, neutral, 100)}
      );

      &:has(input[type='radio']:checked) {
        --ds-radio-box-circle: var(
          #{palette.to-color-variable(branding, neutral, 200)}
        );
      }
      &:not(:has(input[type='radio']:checked)) {
        --ds-radio-box-circle: transparent;
        --ds-radio-circle-opacity: 0;
      }
    }

    &:not(:has(input[type='radio']:disabled)) {
      &:has(input[type='radio']:checked) {
        --ds-radio-box-bg: var(
          #{palette.to-color-variable(branding, neutral, 50)}
        );
        --ds-radio-box-border: var(
          #{palette.to-color-variable(branding, neutral, 800)}
        );
        --ds-radio-box-circle: var(
          #{palette.to-color-variable(branding, neutral, 800)}
        );

        &:hover {
          @apply cursor-pointer;
          --ds-radio-box-bg: var(
            #{palette.to-color-variable(branding, primary, 100)}
          );
          --ds-radio-box-border: var(
            #{palette.to-color-variable(branding, primary, 600)}
          );
          --ds-radio-box-circle: var(
            #{palette.to-color-variable(branding, primary, 600)}
          );
        }

        &:active,
        &:focus-within {
          --ds-radio-box-bg: var(
            #{palette.to-color-variable(branding, neutral, 50)}
          );
          --ds-radio-box-border: var(
            #{palette.to-color-variable(branding, neutral, 800)}
          );
          --ds-radio-box-circle: var(
            #{palette.to-color-variable(branding, neutral, 800)}
          );
          --ds-radio-box-shadow: 0px 0px 0px 4px #eef2f6;
        }
      }

      &:not(:has(input[type='radio']:checked)) {
        --ds-radio-box-bg: var(
          #{palette.to-color-variable(branding, base, white)}
        );
        --ds-radio-box-border: var(
          #{palette.to-color-variable(branding, neutral, 300)}
        );
        --ds-radio-box-circle: transparent;
        --ds-radio-circle-opacity: 0;

        &:hover {
          @apply cursor-pointer;
          --ds-radio-box-bg: var(
            #{palette.to-color-variable(branding, primary, 100)}
          );
          --ds-radio-box-border: var(
            #{palette.to-color-variable(branding, primary, 300)}
          );
        }

        &:active,
        &:focus-within {
          --ds-radio-box-bg: var(
            #{palette.to-color-variable(branding, base, white)}
          );
          --ds-radio-box-border: var(
            #{palette.to-color-variable(branding, neutral, 300)}
          );
          --ds-radio-box-shadow: 0px 0px 0px 4px #eef2f6;
        }
      }
    }
  }
}
