@use '../../../palette';
@use '../../../typography';

@mixin tabs-component() {
  .ds-tabs {
    &-horizontal {
      --ds-tabs-gap: 2px;
      --ds-tabs-padding: 0px;
      --ds-tabs-state-padding: 0px;
      --ds-tabs-radius: 0px;
      --ds-tabs-count-size: 22px;
      --ds-tabs-item-size: 36px;
      --ds-tabs-item-padding: 12px;
      --ds-tabs-item-rounded: 9999px;
      @include _setup-state(
        (
          bg: transparent,
          border: none,
          after-bg: transparent,
          item-bg: transparent,
          item-state-bg: transparent,
          item-shadow: none,
          item-state-shadow: none,
          item-border: none,
          item-state-border: none,
          label-color: var(#{palette.to-color-variable(branding, neutral, 500)}),
          label-state-color:
            var(#{palette.to-color-variable(branding, neutral, 500)}),
          count-color: var(#{palette.to-color-variable(branding, neutral, 800)}),
          count-state-color:
            var(#{palette.to-color-variable(branding, neutral, 800)}),
          count-border:
            var(#{palette.to-color-variable(branding, neutral, 200)}),
          count-state-border:
            var(#{palette.to-color-variable(branding, neutral, 200)}),
          count-bg: var(#{palette.to-color-variable(branding, neutral, 50)}),
          count-state-bg:
            var(#{palette.to-color-variable(branding, neutral, 50)}),
        )
      );

      @apply inline-flex list-none m-0 relative;
      gap: var(--ds-tabs-gap);
      padding: var(--ds-tabs-padding);
      background: var(--ds-tabs-bg);
      border: var(--ds-tabs-border);
      border-radius: var(--ds-tabs-radius);

      @apply flex-row w-full;

      &.full-width {
        > li > button {
          @apply flex-1;
        }
      }

      &:not(.full-width) {
        > li > button {
          @apply w-fit;
        }
      }

      &--sm {
        --ds-tabs-item-size: 36px;
        --ds-tabs-count-size: 22px;
        --ds-tabs-item-padding: 12px;
        --ds-tabs-state-padding: 4px;
        > li > button {
          > label {
            @include typography.use-work-sans(sm, 500);
          }

          > span {
            @include typography.use-work-sans(xs, 500);
          }
        }
      }

      &--md {
        --ds-tabs-item-size: 44px;
        --ds-tabs-count-size: 24px;
        --ds-tabs-item-padding: 14px;
        --ds-tabs-state-padding: 6px;
        > li > button {
          > label {
            @include typography.use-work-sans(md, 500);
          }

          > span {
            @include typography.use-work-sans(sm, 500);
          }
        }
      }

      &--button {
        &-primary {
          --ds-tabs-gap: 2px;
          --ds-tabs-item-rounded: 9999px;
          --ds-tabs-radius: 9999px;
          @include _setup-state(
            (
              bg: transparent,
              border: none,
              after-bg: transparent,
              item-bg: transparent,
              item-state-bg:
                var(#{palette.to-color-variable(branding, primary, 50)}),
              item-shadow: none,
              item-state-shadow: 0px 0px 0px 4px #d5e4fb,
              item-border: none,
              item-state-border: none,
              label-color:
                var(#{palette.to-color-variable(branding, neutral, 500)}),
              label-state-color:
                var(#{palette.to-color-variable(branding, primary, 700)}),
              count-color:
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              count-state-color:
                var(#{palette.to-color-variable(branding, primary, 700)}),
              count-border:
                var(#{palette.to-color-variable(branding, neutral, 200)}),
              count-state-border:
                var(#{palette.to-color-variable(branding, primary, 200)}),
              count-bg: var(#{palette.to-color-variable(branding, neutral, 50)}),
              count-state-bg:
                var(#{palette.to-color-variable(branding, primary, 50)}),
            )
          );
        }

        &-gray {
          --ds-tabs-gap: 2px;
          --ds-tabs-item-rounded: 9999px;
          --ds-tabs-radius: 9999px;
          @include _setup-state(
            (
              bg: transparent,
              border: none,
              after-bg: transparent,
              item-bg: transparent,
              item-state-bg:
                var(#{palette.to-color-variable(branding, neutral, 100)}),
              item-shadow: none,
              item-state-shadow: 0px 0px 0px 4px #d5e4fb,
              item-border: none,
              item-state-border: none,
              label-color:
                var(#{palette.to-color-variable(branding, neutral, 500)}),
              label-state-color:
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              count-color:
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              count-state-color:
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              count-border:
                var(#{palette.to-color-variable(branding, neutral, 200)}),
              count-state-border:
                var(#{palette.to-color-variable(branding, neutral, 200)}),
              count-bg: var(#{palette.to-color-variable(branding, neutral, 50)}),
              count-state-bg:
                var(#{palette.to-color-variable(branding, neutral, 50)}),
            )
          );
        }

        &-border {
          --ds-tabs-padding: var(--ds-tabs-state-padding);
          --ds-tabs-gap: 2px;
          --ds-tabs-item-rounded: 9999px;
          --ds-tabs-radius: 9999px;
          @include _setup-state(
            (
              bg: var(#{palette.to-color-variable(branding, neutral, 50)}),
              border: 1px solid
                var(#{palette.to-color-variable(branding, neutral, 200)}),
              after-bg: transparent,
              item-bg: transparent,
              item-state-bg:
                var(#{palette.to-color-variable(branding, base, white)}),
              item-shadow: none,
              item-state-shadow: #{0px 1px 2px 0px rgba(16, 24, 40, 0.06),
              0px 1px 3px 0px rgba(16, 24, 40, 0.1)},
              item-border: none,
              item-state-border: none,
              label-color:
                var(#{palette.to-color-variable(branding, neutral, 500)}),
              label-state-color:
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              count-color:
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              count-state-color:
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              count-border:
                var(#{palette.to-color-variable(branding, neutral, 200)}),
              count-state-border:
                var(#{palette.to-color-variable(branding, neutral, 200)}),
              count-bg: var(#{palette.to-color-variable(branding, neutral, 50)}),
              count-state-bg:
                var(#{palette.to-color-variable(branding, neutral, 50)}),
            )
          );
        }
      }

      &--underline {
        --ds-tabs-gap: 16px;
        --ds-tabs-item-rounded: 0;
        @include _setup-state(
          (
            bg: none,
            border: none,
            after-bg: var(#{palette.to-color-variable(branding, neutral, 200)}),
            item-bg: transparent,
            item-state-bg: transparent,
            item-shadow: none,
            item-state-shadow: none,
            item-border: none,
            item-state-border: 2px solid
              var(#{palette.to-color-variable(branding, neutral, 800)}),
            label-color:
              var(#{palette.to-color-variable(branding, neutral, 500)}),
            label-state-color:
              var(#{palette.to-color-variable(branding, neutral, 800)}),
            count-color:
              var(#{palette.to-color-variable(branding, neutral, 800)}),
            count-state-color:
              var(#{palette.to-color-variable(branding, neutral, 800)}),
            count-border:
              var(#{palette.to-color-variable(branding, neutral, 200)}),
            count-state-border:
              var(#{palette.to-color-variable(branding, neutral, 200)}),
            count-bg: var(#{palette.to-color-variable(branding, neutral, 50)}),
            count-state-bg:
              var(#{palette.to-color-variable(branding, neutral, 50)}),
          )
        );

        &-filled {
          --ds-tabs-gap: 16px;
          --ds-tabs-item-rounded: 0;
          @include _setup-state(
            (
              bg: none,
              border: none,
              after-bg:
                var(#{palette.to-color-variable(branding, neutral, 200)}),
              item-bg: transparent,
              item-state-bg:
                var(#{palette.to-color-variable(branding, neutral, 50)}),
              item-shadow: none,
              item-state-shadow: none,
              item-border: none,
              item-state-border: 2px solid
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              label-color:
                var(#{palette.to-color-variable(branding, neutral, 500)}),
              label-state-color:
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              count-color:
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              count-state-color:
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              count-border:
                var(#{palette.to-color-variable(branding, neutral, 200)}),
              count-state-border:
                var(#{palette.to-color-variable(branding, neutral, 200)}),
              count-bg: var(#{palette.to-color-variable(branding, neutral, 50)}),
              count-state-bg:
                var(#{palette.to-color-variable(branding, neutral, 50)}),
            )
          );
        }
      }

      > li {
        @apply contents;
        > button {
          @apply inline-flex gap-1.5 items-center justify-center outline-none border-none;
          background: var(--ds-tabs-item-bg);
          border-bottom: var(--ds-tabs-item-border);
          box-shadow: var(--ds-tabs-item-shadow);
          padding: 0 var(--ds-tabs-item-padding);
          border-radius: var(--ds-tabs-item-rounded);
          height: var(--ds-tabs-item-size);

          &.active,
          &:active,
          &:hover,
          &:focus,
          &:focus-within,
          &:focus-visible {
            @apply cursor-pointer;
            --ds-tabs-item-bg: var(--ds-tabs-item-state-bg);
            --ds-tabs-item-border: var(--ds-tabs-item-state-border);
            --ds-tabs-label-color: var(--ds-tabs-label-state-color);
            --ds-tabs-count-bg: var(--ds-tabs-count-state-bg);
            --ds-tabs-count-color: var(--ds-tabs-count-state-color);
            --ds-tabs-count-border: var(--ds-tabs-count-state-border);
            > label {
              @apply cursor-pointer;
            }
          }

          &:active,
          &:focus-visible {
            --ds-tabs-item-shadow: var(--ds-tabs-item-state-shadow);
          }

          > label {
            color: var(--ds-tabs-label-color);
          }

          > span {
            @apply inline-flex items-center justify-center rounded-full;
            background: var(--ds-tabs-count-bg);
            color: var(--ds-tabs-count-color);
            width: var(--ds-tabs-count-size);
            height: var(--ds-tabs-count-size);
            border: 1px solid var(--ds-tabs-count-border);
          }
        }
      }

      &::after {
        @apply absolute -z-[1] content-[''] left-0 w-full h-px bottom-0;
        background: var(--ds-tabs-after-bg);
      }
    }

    &-vertical {
      --ds-tabs-gap: 2px;
      --ds-tabs-padding: 0px;
      --ds-tabs-state-padding: 0px;
      --ds-tabs-radius: 0px;
      --ds-tabs-size: 152px;
      --ds-tabs-count-size: 22px;
      --ds-tabs-item-size: 36px;
      --ds-tabs-item-padding: 12px;
      --ds-tabs-item-rounded: 9999px;
      @include _setup-state(
        (
          bg: transparent,
          border: none,
          after-bg: transparent,
          item-bg: transparent,
          item-state-bg: transparent,
          item-shadow: none,
          item-state-shadow: none,
          item-border: none,
          item-state-border: none,
          label-color: var(#{palette.to-color-variable(branding, neutral, 500)}),
          label-state-color:
            var(#{palette.to-color-variable(branding, neutral, 500)}),
          count-color: var(#{palette.to-color-variable(branding, neutral, 800)}),
          count-state-color:
            var(#{palette.to-color-variable(branding, neutral, 800)}),
          count-border:
            var(#{palette.to-color-variable(branding, neutral, 200)}),
          count-state-border:
            var(#{palette.to-color-variable(branding, neutral, 200)}),
          count-bg: var(#{palette.to-color-variable(branding, neutral, 50)}),
          count-state-bg:
            var(#{palette.to-color-variable(branding, neutral, 50)}),
        )
      );

      @apply flex flex-col list-none m-0 relative;
      gap: var(--ds-tabs-gap);
      padding: var(--ds-tabs-padding);
      background: var(--ds-tabs-bg);
      border: var(--ds-tabs-border);
      border-radius: var(--ds-tabs-radius);
      width: var(--ds-tabs-size);

      &--sm {
        --ds-tabs-item-size: 36px;
        --ds-tabs-count-size: 22px;
        --ds-tabs-item-padding: 12px;
        --ds-tabs-state-padding: 4px;
        --ds-tabs-size: 152px;
        > li > button {
          > label {
            @include typography.use-work-sans(sm, 500);
          }

          > span {
            @include typography.use-work-sans(xs, 500);
          }
        }
      }

      &--md {
        --ds-tabs-item-size: 44px;
        --ds-tabs-count-size: 24px;
        --ds-tabs-item-padding: 14px;
        --ds-tabs-state-padding: 6px;
        --ds-tabs-size: 178px;
        > li > button {
          > label {
            @include typography.use-work-sans(md, 500);
          }

          > span {
            @include typography.use-work-sans(sm, 500);
          }
        }
      }

      &--button {
        &-primary {
          --ds-tabs-gap: 2px;
          --ds-tabs-item-rounded: 9999px;
          --ds-tabs-radius: 8px;
          @include _setup-state(
            (
              bg: transparent,
              border: none,
              after-bg: transparent,
              item-bg: transparent,
              item-state-bg:
                var(#{palette.to-color-variable(branding, primary, 50)}),
              item-shadow: none,
              item-state-shadow: 0px 0px 0px 4px #d5e4fb,
              item-border: none,
              item-state-border: none,
              label-color:
                var(#{palette.to-color-variable(branding, neutral, 500)}),
              label-state-color:
                var(#{palette.to-color-variable(branding, primary, 700)}),
              count-color:
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              count-state-color:
                var(#{palette.to-color-variable(branding, primary, 700)}),
              count-border:
                var(#{palette.to-color-variable(branding, neutral, 200)}),
              count-state-border:
                var(#{palette.to-color-variable(branding, primary, 200)}),
              count-bg: var(#{palette.to-color-variable(branding, neutral, 50)}),
              count-state-bg:
                var(#{palette.to-color-variable(branding, primary, 50)}),
            )
          );
        }

        &-gray {
          --ds-tabs-gap: 2px;
          --ds-tabs-item-rounded: 9999px;
          --ds-tabs-radius: 8px;
          @include _setup-state(
            (
              bg: transparent,
              border: none,
              after-bg: transparent,
              item-bg: transparent,
              item-state-bg:
                var(#{palette.to-color-variable(branding, neutral, 100)}),
              item-shadow: none,
              item-state-shadow: 0px 0px 0px 4px #d5e4fb,
              item-border: none,
              item-state-border: none,
              label-color:
                var(#{palette.to-color-variable(branding, neutral, 500)}),
              label-state-color:
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              count-color:
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              count-state-color:
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              count-border:
                var(#{palette.to-color-variable(branding, neutral, 200)}),
              count-state-border:
                var(#{palette.to-color-variable(branding, neutral, 200)}),
              count-bg: var(#{palette.to-color-variable(branding, neutral, 50)}),
              count-state-bg:
                var(#{palette.to-color-variable(branding, neutral, 50)}),
            )
          );
        }

        &-border {
          --ds-tabs-padding: var(--ds-tabs-state-padding);
          --ds-tabs-gap: 2px;
          --ds-tabs-item-rounded: 9999px;
          --ds-tabs-radius: 8px;
          @include _setup-state(
            (
              bg: var(#{palette.to-color-variable(branding, neutral, 50)}),
              border: 1px solid
                var(#{palette.to-color-variable(branding, neutral, 200)}),
              after-bg: transparent,
              item-bg: transparent,
              item-state-bg:
                var(#{palette.to-color-variable(branding, base, white)}),
              item-shadow: none,
              item-state-shadow: #{0px 1px 2px 0px rgba(16, 24, 40, 0.06),
              0px 1px 3px 0px rgba(16, 24, 40, 0.1)},
              item-border: none,
              item-state-border: none,
              label-color:
                var(#{palette.to-color-variable(branding, neutral, 500)}),
              label-state-color:
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              count-color:
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              count-state-color:
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              count-border:
                var(#{palette.to-color-variable(branding, neutral, 200)}),
              count-state-border:
                var(#{palette.to-color-variable(branding, neutral, 200)}),
              count-bg: var(#{palette.to-color-variable(branding, neutral, 50)}),
              count-state-bg:
                var(#{palette.to-color-variable(branding, neutral, 50)}),
            )
          );
        }
      }

      &--underline {
        --ds-tabs-gap: 2px;
        --ds-tabs-item-rounded: 0;
        @include _setup-state(
          (
            bg: none,
            border: none,
            after-bg: var(#{palette.to-color-variable(branding, neutral, 200)}),
            item-bg: transparent,
            item-state-bg: transparent,
            item-shadow: none,
            item-state-shadow: none,
            item-border: none,
            item-state-border: 2px solid
              var(#{palette.to-color-variable(branding, neutral, 800)}),
            label-color:
              var(#{palette.to-color-variable(branding, neutral, 500)}),
            label-state-color:
              var(#{palette.to-color-variable(branding, neutral, 800)}),
            count-color:
              var(#{palette.to-color-variable(branding, neutral, 800)}),
            count-state-color:
              var(#{palette.to-color-variable(branding, neutral, 800)}),
            count-border:
              var(#{palette.to-color-variable(branding, neutral, 200)}),
            count-state-border:
              var(#{palette.to-color-variable(branding, neutral, 200)}),
            count-bg: var(#{palette.to-color-variable(branding, neutral, 50)}),
            count-state-bg:
              var(#{palette.to-color-variable(branding, neutral, 50)}),
          )
        );

        &-filled {
          --ds-tabs-gap: 2px;
          --ds-tabs-item-rounded: 0;
          @include _setup-state(
            (
              bg: none,
              border: none,
              after-bg:
                var(#{palette.to-color-variable(branding, neutral, 200)}),
              item-bg: transparent,
              item-state-bg:
                var(#{palette.to-color-variable(branding, neutral, 50)}),
              item-shadow: none,
              item-state-shadow: none,
              item-border: none,
              item-state-border: 2px solid
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              label-color:
                var(#{palette.to-color-variable(branding, neutral, 500)}),
              label-state-color:
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              count-color:
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              count-state-color:
                var(#{palette.to-color-variable(branding, neutral, 800)}),
              count-border:
                var(#{palette.to-color-variable(branding, neutral, 200)}),
              count-state-border:
                var(#{palette.to-color-variable(branding, neutral, 200)}),
              count-bg: var(#{palette.to-color-variable(branding, neutral, 50)}),
              count-state-bg:
                var(#{palette.to-color-variable(branding, neutral, 50)}),
            )
          );
        }
      }

      > li {
        @apply contents;
        > button {
          @apply inline-flex gap-1.5 items-center outline-none border-none w-full;
          background: var(--ds-tabs-item-bg);
          border-right: var(--ds-tabs-item-border);
          box-shadow: var(--ds-tabs-item-shadow);
          padding: 0 var(--ds-tabs-item-padding);
          border-radius: var(--ds-tabs-item-rounded);
          height: var(--ds-tabs-item-size);

          &.active,
          &:active,
          &:hover,
          &:focus,
          &:focus-within,
          &:focus-visible {
            @apply cursor-pointer;
            --ds-tabs-item-bg: var(--ds-tabs-item-state-bg);
            --ds-tabs-item-border: var(--ds-tabs-item-state-border);
            --ds-tabs-label-color: var(--ds-tabs-label-state-color);
            --ds-tabs-count-bg: var(--ds-tabs-count-state-bg);
            --ds-tabs-count-color: var(--ds-tabs-count-state-color);
            --ds-tabs-count-border: var(--ds-tabs-count-state-border);
            > label {
              @apply cursor-pointer;
            }
          }

          &:active,
          &:focus-visible {
            --ds-tabs-item-shadow: var(--ds-tabs-item-state-shadow);
          }

          > label {
            color: var(--ds-tabs-label-color);
          }

          > span {
            @apply inline-flex items-center justify-center rounded-full;
            background: var(--ds-tabs-count-bg);
            color: var(--ds-tabs-count-color);
            width: var(--ds-tabs-count-size);
            height: var(--ds-tabs-count-size);
            border: 1px solid var(--ds-tabs-count-border);
          }
        }
      }

      &::after {
        @apply absolute -z-[1] content-[''] right-0 w-px h-full top-0;
        background: var(--ds-tabs-after-bg);
      }
    }
  }
}

@mixin _setup-state($color-map) {
  --ds-tabs-bg: #{map-get($color-map, bg)};
  --ds-tabs-border: #{map-get($color-map, border)};
  --ds-tabs-after-bg: #{map-get($color-map, after-bg)};
  --ds-tabs-item-bg: #{map-get($color-map, item-bg)};
  --ds-tabs-item-state-bg: #{map-get($color-map, item-state-bg)};
  --ds-tabs-item-shadow: #{map-get($color-map, item-shadow)};
  --ds-tabs-item-state-shadow: #{map-get($color-map, item-state-shadow)};
  --ds-tabs-item-border: #{map-get($color-map, item-border)};
  --ds-tabs-item-state-border: #{map-get($color-map, item-state-border)};
  --ds-tabs-label-color: #{map-get($color-map, label-color)};
  --ds-tabs-label-state-color: #{map-get($color-map, label-state-color)};
  --ds-tabs-count-color: #{map-get($color-map, count-color)};
  --ds-tabs-count-state-color: #{map-get($color-map, count-state-color)};
  --ds-tabs-count-border: #{map-get($color-map, count-border)};
  --ds-tabs-count-state-border: #{map-get($color-map, count-state-border)};
  --ds-tabs-count-bg: #{map-get($color-map, count-bg)};
  --ds-tabs-count-state-bg: #{map-get($color-map, count-state-bg)};
}
