@use '../../../palette';
@use '../../../typography';

@mixin badge-component() {
  .ds-badge {
    --ds-badge-bg: unset;
    --ds-badge-border-color: unset;
    --ds-badge-border-size: 1px;
    --ds-badge-text-color: unset;
    --ds-badge-radius: unset;
    --ds-badge-padding-x: unset;
    --ds-badge-padding-y: unset;
    --ds-badge-gap: unset;
    --ds-badge-image-size: unset;
    --ds-badge-icon-size: unset;
    --ds-badge-dot-size: 8px;
    --ds-badge-dot-color: unset;

    background: var(--ds-badge-bg);
    border: var(--ds-badge-border-size) solid var(--ds-badge-border-color);
    color: var(--ds-badge-text-color);
    border-radius: var(--ds-badge-radius);
    padding: var(--ds-badge-padding-y) var(--ds-badge-padding-x);
    gap: var(--ds-badge-gap);
    mix-blend-mode: multiply;
    @apply inline-flex items-center;

    &--sm {
      --ds-badge-image-size: 16px;
      --ds-badge-icon-size: 12px;

      .ds-badge__text {
        @include typography.use-work-sans(xs, 500);
      }

      &.ds-badge {
        &--text {
          --ds-badge-gap: 4px;
          --ds-badge-padding-x: 7px;
          --ds-badge-padding-y: 2px;
        }

        &--avatar {
          --ds-badge-gap: 6px;
          --ds-badge-padding-x: 7px;
          --ds-badge-padding-y: 2px;
        }

        &--dot {
          --ds-badge-gap: 4px;
          --ds-badge-padding-x: 7px;
          --ds-badge-padding-y: 2px;
        }

        &--icon {
          --ds-badge-padding-x: 4px;
          --ds-badge-padding-y: 4px;
        }
      }
    }

    &--md {
      --ds-badge-image-size: 16px;
      --ds-badge-icon-size: 12px;
      .ds-badge__text {
        @include typography.use-work-sans(sm, 500);
      }

      &.ds-badge {
        &--text {
          --ds-badge-gap: 4px;
          --ds-badge-padding-x: 9px;
          --ds-badge-padding-y: 2px;
        }

        &--avatar {
          --ds-badge-gap: 6px;
          --ds-badge-padding-x: 9px;
          --ds-badge-padding-y: 2px;
        }

        &--dot {
          --ds-badge-gap: 6px;
          --ds-badge-padding-x: 9px;
          --ds-badge-padding-y: 2px;
        }

        &--icon {
          --ds-badge-padding-x: 5px;
          --ds-badge-padding-y: 5px;
        }
      }
    }

    &--lg {
      --ds-badge-image-size: 16px;
      --ds-badge-icon-size: 12px;

      .ds-badge__text {
        @include typography.use-work-sans(sm, 500);
      }

      &.ds-badge {
        &--text {
          --ds-badge-gap: 4px;
          --ds-badge-padding-x: 11px;
          --ds-badge-padding-y: 3px;
        }

        &--avatar {
          --ds-badge-gap: 6px;
          --ds-badge-padding-x: 11px;
          --ds-badge-padding-y: 3px;
        }

        &--dot {
          --ds-badge-gap: 6px;
          --ds-badge-padding-x: 11px;
          --ds-badge-padding-y: 3px;
        }

        &--icon {
          --ds-badge-padding-x: 7px;
          --ds-badge-padding-y: 7px;
        }
      }
    }

    &--pill {
      &-color {
        &.ds-badge {
          &--text {
            --ds-badge-radius: 16px;
          }

          &--avatar {
            --ds-badge-radius: 16px;
          }

          &--dot {
            --ds-badge-radius: 16px;
          }

          &--icon {
            --ds-badge-radius: 100%;
          }

          &--gray {
            @include _pill-color-render(neutral);
          }

          &--primary {
            @include _pill-color-render(primary);
          }

          &--error {
            @include _pill-color-render(error);
          }

          &--warning {
            @include _pill-color-render(warning);
          }

          &--success {
            @include _pill-color-render(success);
          }

          &--gray-blue {
            @include _pill-color-render(gray-blue);
          }

          &--blue-light {
            @include _pill-color-render(blue-light);
          }

          &--blue {
            @include _pill-color-render(blue);
          }

          &--indigo {
            @include _pill-color-render(indigo);
          }

          &--purple {
            @include _pill-color-render(purple);
          }

          &--pink {
            @include _pill-color-render(pink);
          }

          &--orange {
            @include _pill-color-render(orange);
          }
        }
      }

      &-outline {
        &.ds-badge {
          &--text {
            --ds-badge-radius: 16px;
          }

          &--avatar {
            --ds-badge-radius: 16px;
          }

          &--dot {
            --ds-badge-radius: 16px;
          }

          &--icon {
            --ds-badge-radius: 100%;
          }

          &--gray {
            @include _pill-outline-render(neutral);
          }

          &--primary {
            @include _pill-outline-render(primary);
          }

          &--error {
            @include _pill-outline-render(error);
          }

          &--warning {
            @include _pill-outline-render(warning);
          }

          &--success {
            @include _pill-outline-render(success);
          }

          &--gray-blue {
            @include _pill-outline-render(gray-blue);
          }

          &--blue-light {
            @include _pill-outline-render(blue-light);
          }

          &--blue {
            @include _pill-outline-render(blue);
          }

          &--indigo {
            @include _pill-outline-render(indigo);
          }

          &--purple {
            @include _pill-outline-render(purple);
          }

          &--pink {
            @include _pill-outline-render(pink);
          }

          &--orange {
            @include _pill-outline-render(orange);
          }
        }
      }
    }

    &--color {
      --ds-badge-radius: 8px;

      &.ds-badge {
        &--gray {
          @include _badge-color-render(neutral);
        }

        &--primary {
          @include _badge-color-render(primary);
        }

        &--error {
          @include _badge-color-render(error);
        }

        &--warning {
          @include _badge-color-render(warning);
        }

        &--success {
          @include _badge-color-render(success);
        }

        &--gray-blue {
          @include _badge-color-render(gray-blue);
        }

        &--blue-light {
          @include _badge-color-render(blue-light);
        }

        &--blue {
          @include _badge-color-render(blue);
        }

        &--indigo {
          @include _badge-color-render(indigo);
        }

        &--purple {
          @include _badge-color-render(purple);
        }

        &--pink {
          @include _badge-color-render(pink);
        }

        &--orange {
          @include _badge-color-render(orange);
        }
      }
    }

    &--modern {
      --ds-badge-radius: 8px;

      &.ds-badge {
        &--gray {
          @include _badge-modern-render(neutral);
        }

        &--primary {
          @include _badge-modern-render(primary);
        }

        &--error {
          @include _badge-modern-render(error);
        }

        &--warning {
          @include _badge-modern-render(warning);
        }

        &--success {
          @include _badge-modern-render(success);
        }

        &--gray-blue {
          @include _badge-modern-render(gray-blue);
        }

        &--blue-light {
          @include _badge-modern-render(blue-light);
        }

        &--blue {
          @include _badge-modern-render(blue);
        }

        &--indigo {
          @include _badge-modern-render(indigo);
        }

        &--purple {
          @include _badge-modern-render(purple);
        }

        &--pink {
          @include _badge-modern-render(pink);
        }

        &--orange {
          @include _badge-modern-render(orange);
        }
      }
    }

    &__avatar {
      @apply rounded-full;
      width: var(--ds-badge-image-size);
      height: var(--ds-badge-image-size);
    }

    &__dot {
      @apply rounded-full;
      width: var(--ds-badge-dot-size);
      height: var(--ds-badge-dot-size);
      background: var(--ds-badge-dot-color);
    }

    &__icon {
      color: var(--ds-badge-text-color);
      &.ds-icon {
        --ds-icon-font-size: var(--ds-badge-icon-size);
      }
    }
  }
}

@mixin _pill-color-render($color) {
  --ds-badge-bg: var(#{palette.to-color-variable(branding, $color, 50)});
  --ds-badge-border-color: var(
    #{palette.to-color-variable(branding, $color, 200)}
  );
  --ds-badge-text-color: var(
    #{palette.to-color-variable(branding, $color, 700)}
  );
  --ds-badge-dot-color: var(
    #{palette.to-color-variable(branding, $color, 500)}
  );
}

@mixin _pill-outline-render($color) {
  --ds-badge-border-size: 1.5px;
  --ds-badge-bg: var(#{palette.to-color-variable(branding, base, white)});
  --ds-badge-border-color: var(
    #{palette.to-color-variable(branding, $color, 600)}
  );
  --ds-badge-text-color: var(
    #{palette.to-color-variable(branding, $color, 700)}
  );
  --ds-badge-dot-color: var(
    #{palette.to-color-variable(branding, $color, 600)}
  );
}

@mixin _badge-color-render($color) {
  --ds-badge-bg: var(#{palette.to-color-variable(branding, $color, 50)});
  --ds-badge-border-color: var(
    #{palette.to-color-variable(branding, $color, 200)}
  );
  --ds-badge-text-color: var(
    #{palette.to-color-variable(branding, $color, 700)}
  );
  --ds-badge-dot-color: var(
    #{palette.to-color-variable(branding, $color, 500)}
  );
}

@mixin _badge-modern-render($color) {
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  --ds-badge-bg: var(#{palette.to-color-variable(branding, base, white)});
  --ds-badge-border-color: var(
    #{palette.to-color-variable(branding, neutral, 300)}
  );
  --ds-badge-text-color: var(
    #{palette.to-color-variable(branding, neutral, 700)}
  );
  --ds-badge-dot-color: var(
    #{palette.to-color-variable(branding, $color, 500)}
  );
}
