@use '../../../palette';
@use '../../../typography';
@use '../../../resolution';

@mixin alert-component() {
  .ds-alert {
    @include _setup-color(
      (
        bg: var(#{palette.to-color-variable(branding, base, white)}),
        border: var(#{palette.to-color-variable(branding, neutral, 300)}),
        title-color: var(#{palette.to-color-variable(branding, neutral, 700)}),
        description-color:
          var(#{palette.to-color-variable(branding, neutral, 800)}),
        close-color: var(#{palette.to-color-variable(branding, neutral, 500)}),
        dismiss-color: var(#{palette.to-color-variable(branding, neutral, 700)}),
        change-color: var(#{palette.to-color-variable(branding, primary, 600)}),
      )
    );
    background: var(--ds-alert-bg);
    @apply relative;

    &--floating {
      @apply rounded-2xl p-4;
      border: 1px solid var(--ds-alert-border);

      box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
      @include resolution.use-resolution-max(xs) {
        width: 343px;
        @apply flex gap-2 flex-col;

        .ds-alert__container {
          @apply flex flex-col gap-3;

          > main {
            @apply flex flex-col gap-1;
          }

          > footer {
            @apply flex gap-3;
          }
        }
      }

      @include resolution.use-resolution-min(sm) {
        width: calc(100vh - 224px);
        max-width: 1216px;
        @apply flex gap-2 items-start;

        .ds-alert__container {
          @apply flex flex-col gap-3;

          > main {
            @apply flex flex-col gap-1;
          }

          > footer {
            @apply flex gap-3;
          }
        }
      }

      .ds-alert__close {
        @apply top-2 right-2;
      }
    }

    &--full-width {
      @apply w-screen;

      @include resolution.use-resolution-max(xs) {
        @apply flex flex-col gap-4 p-4;
        border-top: 1px solid var(--ds-alert-border);

        .ds-alert__container {
          @apply flex flex-col gap-4;

          > main {
            @apply flex flex-col gap-0.5;
          }

          > footer {
            @apply flex gap-3;

            &:has(button) {
              @apply flex-col-reverse;

              > button {
                @apply w-full;
              }
            }
          }
        }

        > header {
          @apply hidden;
        }

        .ds-alert__close {
          @apply top-2 right-2;
        }
      }

      @include resolution.use-resolution-min(sm) {
        @apply flex gap-3 py-3 justify-center items-center pl-8 pr-[76px];
        border-bottom: 1px solid var(--ds-alert-border);

        .ds-alert__container {
          @apply flex gap-3 flex-1;

          > main {
            @apply flex gap-1.5 items-center flex-1;
          }

          > footer {
            @apply flex gap-3 items-center;
          }
        }

        .ds-alert__close {
          @apply top-1/2 transform -translate-y-1/2 right-8;
        }
      }
    }

    &--default {
      @include _setup-color(
        (
          bg: var(#{palette.to-color-variable(branding, base, white)}),
          border: var(#{palette.to-color-variable(branding, neutral, 300)}),
          title-color: var(#{palette.to-color-variable(branding, neutral, 700)}),
          description-color:
            var(#{palette.to-color-variable(branding, neutral, 800)}),
          close-color: var(#{palette.to-color-variable(branding, neutral, 500)}),
          dismiss-color:
            var(#{palette.to-color-variable(branding, neutral, 700)}),
          change-color:
            var(#{palette.to-color-variable(branding, primary, 600)}),
        )
      );
    }

    &--primary {
      @include _setup-color(
        (
          bg: var(#{palette.to-color-variable(branding, primary, 25)}),
          border: var(#{palette.to-color-variable(branding, primary, 300)}),
          title-color: var(#{palette.to-color-variable(branding, primary, 700)}),
          description-color:
            var(#{palette.to-color-variable(branding, primary, 700)}),
          close-color: var(#{palette.to-color-variable(branding, primary, 500)}),
          dismiss-color:
            var(#{palette.to-color-variable(branding, neutral, 700)}),
          change-color:
            var(#{palette.to-color-variable(branding, primary, 600)}),
        )
      );
    }

    &--gray {
      @include _setup-color(
        (
          bg: var(#{palette.to-color-variable(branding, neutral, 25)}),
          border: var(#{palette.to-color-variable(branding, neutral, 300)}),
          title-color: var(#{palette.to-color-variable(branding, neutral, 700)}),
          description-color:
            var(#{palette.to-color-variable(branding, neutral, 800)}),
          close-color: var(#{palette.to-color-variable(branding, neutral, 500)}),
          dismiss-color:
            var(#{palette.to-color-variable(branding, neutral, 700)}),
          change-color:
            var(#{palette.to-color-variable(branding, primary, 600)}),
        )
      );
    }

    &--error {
      @include _setup-color(
        (
          bg: var(#{palette.to-color-variable(branding, error, 25)}),
          border: var(#{palette.to-color-variable(branding, error, 300)}),
          title-color: var(#{palette.to-color-variable(branding, error, 700)}),
          description-color:
            var(#{palette.to-color-variable(branding, error, 700)}),
          close-color: var(#{palette.to-color-variable(branding, error, 500)}),
          dismiss-color: var(#{palette.to-color-variable(branding, error, 600)}),
          change-color: var(#{palette.to-color-variable(branding, error, 800)}),
        )
      );
    }

    &--warning {
      @include _setup-color(
        (
          bg: var(#{palette.to-color-variable(branding, warning, 25)}),
          border: var(#{palette.to-color-variable(branding, warning, 300)}),
          title-color: var(#{palette.to-color-variable(branding, warning, 700)}),
          description-color:
            var(#{palette.to-color-variable(branding, warning, 700)}),
          close-color: var(#{palette.to-color-variable(branding, warning, 500)}),
          dismiss-color:
            var(#{palette.to-color-variable(branding, warning, 600)}),
          change-color:
            var(#{palette.to-color-variable(branding, warning, 800)}),
        )
      );
    }

    &--success {
      @include _setup-color(
        (
          bg: var(#{palette.to-color-variable(branding, success, 25)}),
          border: var(#{palette.to-color-variable(branding, success, 300)}),
          title-color: var(#{palette.to-color-variable(branding, success, 700)}),
          description-color:
            var(#{palette.to-color-variable(branding, success, 700)}),
          close-color: var(#{palette.to-color-variable(branding, success, 500)}),
          dismiss-color:
            var(#{palette.to-color-variable(branding, success, 600)}),
          change-color:
            var(#{palette.to-color-variable(branding, success, 800)}),
        )
      );
    }

    &--leave {
      animation: animation-leave 600ms ease-in-out;
    }

    &--enter {
      animation: animation_enter 600ms ease-in-out;
    }

    &__icon {
      @apply contents;
    }

    &__close {
      @apply border-none outline-none shadow-none absolute bg-transparent w-9 h-9 items-center justify-center inline-flex hover:cursor-pointer;

      > i {
        --ds-icon-font-size: 20px;
        color: var(--ds-alert-close-color);
      }
    }

    &__container {
      > main {
        > h5 {
          @include typography.use-work-sans(md, 500);
          color: var(--ds-alert-title-color);
          @apply m-0;
        }

        > p {
          @include typography.use-work-sans(md, 400);
          color: var(--ds-alert-description-color);
          @apply m-0;
        }
      }

      > footer {
        > a {
          @apply no-underline hover:underline hover:cursor-pointer;

          @include typography.use-work-sans(sm, 600);

          &:first-child {
            color: var(--ds-alert-dismiss-color);
          }

          &:last-child {
            color: var(--ds-alert-change-color);
          }
        }
      }
    }
  }
}

@mixin _setup-color($color-map) {
  --ds-alert-bg: #{map-get($color-map, bg)};
  --ds-alert-border: #{map-get($color-map, border)};
  --ds-alert-title-color: #{map-get($color-map, title-color)};
  --ds-alert-description-color: #{map-get($color-map, description-color)};
  --ds-alert-close-color: #{map-get($color-map, close-color)};
  --ds-alert-dismiss-color: #{map-get($color-map, dismiss-color)};
  --ds-alert-change-color: #{map-get($color-map, change-color)};
}

@keyframes animation_enter {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes animation-leave {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}
