@use '../variables';
@use '../functions';

@mixin setup-color() {
  @each $key, $categories in variables.$color-variables {
    @each $category-key, $colors in $categories {
      @each $color-key, $color-value in $colors {
        #{functions.to-color-variable($key, $category-key, $color-key)}: #{$color-value};
      }
    }
  }
}
