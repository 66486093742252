@use '../../../palette';
@use '../../../typography';

@mixin button-component() {
  .ds-button {
    $focus-box-shadow: 0px 0px 0px 4px #d7d1ff,
      0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    $default-box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    @apply inline-flex items-center rounded-full w-fit justify-center;
    @include _button-setup-variables();
    @include _button-setup-state();
    @include _button-setup-size();

    &-icon {
      @apply inline-flex items-center rounded-full w-fit;
      @include _button-setup-variables(true);
      @include _button-setup-state();
      @include _button-setup-size();
    }

    &--primary {
      @include _button-setup-color(
        (
          bg: var(#{palette.to-color-variable(branding, neutral, 900)}),
          shadow: $default-box-shadow,
          border-color:
            var(#{palette.to-color-variable(branding, neutral, 900)}),
          color: var(#{palette.to-color-variable(branding, base, white)}),
        ),
        (
          bg: var(#{palette.to-color-variable(branding, primary, 600)}),
          shadow: $default-box-shadow,
          border-color:
            var(#{palette.to-color-variable(branding, primary, 600)}),
          color: var(#{palette.to-color-variable(branding, base, white)}),
        ),
        (
          bg: var(#{palette.to-color-variable(branding, neutral, 600)}),
          shadow: $focus-box-shadow,
          border-color:
            var(#{palette.to-color-variable(branding, neutral, 600)}),
          color: var(#{palette.to-color-variable(branding, base, white)}),
        ),
        (
          bg: var(#{palette.to-color-variable(branding, neutral, 200)}),
          shadow: $default-box-shadow,
          border-color:
            var(#{palette.to-color-variable(branding, neutral, 200)}),
          color: var(#{palette.to-color-variable(branding, base, white)}),
        )
      );

      &-error {
        @include _button-setup-color(
          (
            bg: var(#{palette.to-color-variable(branding, error, 600)}),
            shadow: $default-box-shadow,
            border-color:
              var(#{palette.to-color-variable(branding, error, 600)}),
            color: var(#{palette.to-color-variable(branding, base, white)}),
          ),
          (
            bg: var(#{palette.to-color-variable(branding, error, 700)}),
            shadow: $default-box-shadow,
            border-color:
              var(#{palette.to-color-variable(branding, error, 700)}),
            color: var(#{palette.to-color-variable(branding, base, white)}),
          ),
          (
            bg: var(#{palette.to-color-variable(branding, error, 600)}),
            shadow: $focus-box-shadow,
            border-color:
              var(#{palette.to-color-variable(branding, error, 600)}),
            color: var(#{palette.to-color-variable(branding, base, white)}),
          ),
          (
            bg: var(#{palette.to-color-variable(branding, error, 200)}),
            shadow: $default-box-shadow,
            border-color:
              var(#{palette.to-color-variable(branding, error, 200)}),
            color: var(#{palette.to-color-variable(branding, base, white)}),
          )
        );
      }
    }

    &--secondary {
      &-gray {
        @include _button-setup-color(
          (
            bg: var(#{palette.to-color-variable(branding, base, white)}),
            shadow: $default-box-shadow,
            border-color:
              var(#{palette.to-color-variable(branding, neutral, 300)}),
            color: var(#{palette.to-color-variable(branding, neutral, 800)}),
          ),
          (
            bg: var(#{palette.to-color-variable(branding, neutral, 50)}),
            shadow: $default-box-shadow,
            border-color:
              var(#{palette.to-color-variable(branding, neutral, 300)}),
            color: var(#{palette.to-color-variable(branding, neutral, 800)}),
          ),
          (
            bg: var(#{palette.to-color-variable(branding, base, white)}),
            shadow: $focus-box-shadow,
            border-color:
              var(#{palette.to-color-variable(branding, neutral, 300)}),
            color: var(#{palette.to-color-variable(branding, neutral, 800)}),
          ),
          (
            bg: var(#{palette.to-color-variable(branding, base, white)}),
            shadow: $default-box-shadow,
            border-color:
              var(#{palette.to-color-variable(branding, neutral, 200)}),
            color: var(#{palette.to-color-variable(branding, neutral, 300)}),
          )
        );
      }
    }

    &--tertiary {
      &-gray {
        @include _button-setup-color(
          (
            bg: transparent,
            shadow: none,
            border-color: transparent,
            color: var(#{palette.to-color-variable(branding, neutral, 800)}),
          ),
          (
            bg: var(#{palette.to-color-variable(branding, neutral, 50)}),
            shadow: none,
            border-color:
              var(#{palette.to-color-variable(branding, neutral, 50)}),
            color: var(#{palette.to-color-variable(branding, neutral, 800)}),
          ),
          (
            bg: transparent,
            shadow: none,
            border-color: transparent,
            color: var(#{palette.to-color-variable(branding, neutral, 800)}),
          ),
          (
            bg: transparent,
            shadow: none,
            border-color: transparent,
            color: var(#{palette.to-color-variable(branding, neutral, 300)}),
          )
        );
      }

      &-color {
        @include _button-setup-color(
          (
            bg: transparent,
            shadow: none,
            border-color: transparent,
            color: var(#{palette.to-color-variable(branding, primary, 700)}),
          ),
          (
            bg: var(#{palette.to-color-variable(branding, primary, 700)}),
            shadow: none,
            border-color:
              var(#{palette.to-color-variable(branding, primary, 50)}),
            color: var(#{palette.to-color-variable(branding, primary, 700)}),
          ),
          (
            bg: transparent,
            shadow: none,
            border-color: transparent,
            color: var(#{palette.to-color-variable(branding, primary, 700)}),
          ),
          (
            bg: transparent,
            shadow: none,
            border-color: transparent,
            color: var(#{palette.to-color-variable(branding, neutral, 300)}),
          )
        );
      }
    }

    &--link {
      &-gray {
        @include _button-setup-size(true);
        @include _button-setup-color(
          (
            bg: transparent,
            shadow: none,
            border-color: transparent,
            color: var(#{palette.to-color-variable(branding, neutral, 700)}),
          ),
          (
            bg: transparent,
            shadow: none,
            border-color: transparent,
            color: var(#{palette.to-color-variable(branding, neutral, 900)}),
          ),
          (
            bg: transparent,
            shadow: none,
            border-color: transparent,
            color: var(#{palette.to-color-variable(branding, neutral, 700)}),
          ),
          (
            bg: transparent,
            shadow: none,
            border-color: transparent,
            color: var(#{palette.to-color-variable(branding, neutral, 300)}),
          )
        );
      }

      &-color {
        @include _button-setup-size(true);
        @include _button-setup-color(
          (
            bg: var(#{palette.to-color-variable(branding, base, white)}),
            shadow: none,
            border-color:
              var(#{palette.to-color-variable(branding, base, white)}),
            color: var(#{palette.to-color-variable(branding, primary, 600)}),
          ),
          (
            bg: var(#{palette.to-color-variable(branding, base, white)}),
            shadow: none,
            border-color:
              var(#{palette.to-color-variable(branding, base, white)}),
            color: var(#{palette.to-color-variable(branding, primary, 800)}),
          ),
          (
            bg: var(#{palette.to-color-variable(branding, base, white)}),
            shadow: none,
            border-color:
              var(#{palette.to-color-variable(branding, base, white)}),
            color: var(#{palette.to-color-variable(branding, primary, 600)}),
          ),
          (
            bg: var(#{palette.to-color-variable(branding, base, white)}),
            shadow: none,
            border-color:
              var(#{palette.to-color-variable(branding, base, white)}),
            color: var(#{palette.to-color-variable(branding, neutral, 300)}),
          )
        );
      }
    }
  }
}

@mixin _button-setup-variables($is-icon: false) {
  --ds-button-bg: unset;
  --ds-button-shadow: none;
  --ds-button-border-color: unset;
  --ds-button-color: unset;
  --ds-button-padding-y: unset;
  --ds-button-padding-x: unset;
  --ds-button-icon-padding: unset;
  --ds-button-gap: unset;
  background: var(--ds-button-bg);
  box-shadow: var(--ds-button-shadow);
  border: 1px solid var(--ds-button-border-color);
  color: var(--ds-button-color);
  gap: var(--ds-button-gap);

  @if ($is-icon == true) {
    padding: var(--ds-button-icon-padding);
  } @else {
    padding: var(--ds-button-padding-y) var(--ds-button-padding-x);
  }
}

@mixin _button-setup-size($is-link: false) {
  @if ($is-link == true) {
    &.ds-button {
      &--sm {
        @include typography.use-work-sans(sm, 600);

        i.ds-icon {
          --ds-icon-font-size: 20px;
        }

        --ds-button-padding-y: 0px;
        --ds-button-padding-x: 0px;
        --ds-button-gap: 2px;
      }

      &--md {
        @include typography.use-work-sans(sm, 600);

        i.ds-icon {
          --ds-icon-font-size: 20px;
        }

        --ds-button-padding-y: 0px;
        --ds-button-padding-x: 0px;
        --ds-button-gap: 2px;
      }

      &--lg {
        @include typography.use-work-sans(md, 600);

        i.ds-icon {
          --ds-icon-font-size: 20px;
        }

        --ds-button-padding-y: 0px;
        --ds-button-padding-x: 0px;
        --ds-button-gap: 2px;
      }

      &--xl {
        @include typography.use-work-sans(md, 600);

        i.ds-icon {
          --ds-icon-font-size: 20px;
        }

        --ds-button-padding-y: 0px;
        --ds-button-padding-x: 0px;
        --ds-button-gap: 2px;
      }

      &--2xl {
        @include typography.use-work-sans(lg, 600);

        i.ds-icon {
          --ds-icon-font-size: 24px;
        }

        --ds-button-padding-y: 0px;
        --ds-button-padding-x: 0px;
        --ds-button-gap: 2px;
      }
    }
  } @else {
    &.ds-button {
      &--sm {
        @include typography.use-work-sans(sm, 600);

        i.ds-icon {
          --ds-icon-font-size: 20px;
        }

        --ds-button-padding-y: 2px;
        --ds-button-padding-x: 14px;
        --ds-button-icon-padding: 7px;
        --ds-button-gap: 8px;
      }

      &--md {
        @include typography.use-work-sans(sm, 600);

        i.ds-icon {
          --ds-icon-font-size: 20px;
        }

        --ds-button-padding-y: 10px;
        --ds-button-padding-x: 16px;
        --ds-button-icon-padding: 9px;
        --ds-button-gap: 8px;
      }

      &--lg {
        @include typography.use-work-sans(md, 600);

        i.ds-icon {
          --ds-icon-font-size: 20px;
        }

        --ds-button-padding-y: 10px;
        --ds-button-padding-x: 18px;
        --ds-button-icon-padding: 11px;
        --ds-button-gap: 8px;
      }

      &--xl {
        @include typography.use-work-sans(md, 600);

        i.ds-icon {
          --ds-icon-font-size: 20px;
        }

        --ds-button-padding-y: 12px;
        --ds-button-padding-x: 20px;
        --ds-button-icon-padding: 13px;
        --ds-button-gap: 8px;
      }

      &--2xl {
        @include typography.use-work-sans(lg, 600);

        i.ds-icon {
          --ds-icon-font-size: 24px;
        }

        --ds-button-padding-y: 16px;
        --ds-button-padding-x: 28px;
        --ds-button-icon-padding: 15px;
        --ds-button-gap: 8px;
      }
    }
  }
}

@mixin _button-setup-state() {
  &:disabled {
    --ds-button-bg: var(--ds-button-bg-disabled);
    --ds-button-shadow: var(--ds-button-shadow-disabled);
    --ds-button-border-color: var(--ds-button-border-color-disabled);
    --ds-button-color: var(--ds-button-color-disabled);
    cursor: not-allowed;
  }

  &:not(:disabled) {
    --ds-button-bg: var(--ds-button-bg-default);
    --ds-button-shadow: var(--ds-button-shadow-default);
    --ds-button-border-color: var(--ds-button-border-color-default);
    --ds-button-color: var(--ds-button-color-default);

    &:hover {
      --ds-button-bg: var(--ds-button-bg-hover);
      --ds-button-shadow: var(--ds-button-shadow-hover);
      --ds-button-border-color: var(--ds-button-border-color-hover);
      --ds-button-color: var(--ds-button-color-hover);
      cursor: pointer;
    }

    &:active,
    &:focus-visible {
      --ds-button-bg: var(--ds-button-bg-focus);
      --ds-button-shadow: var(--ds-button-shadow-focus);
      --ds-button-border-color: var(--ds-button-border-color-focus);
      --ds-button-color: var(--ds-button-color-focus);
      cursor: pointer;
    }
  }
}

@mixin _button-setup-color($default, $hover, $focus, $disabled) {
  &:disabled {
    @include _button-setup-state-color(
      disabled,
      map-get($disabled, bg),
      map-get($disabled, shadow),
      map-get($disabled, border-color),
      map-get($disabled, color)
    );
  }

  &:not(:disabled) {
    @include _button-setup-state-color(
      default,
      map-get($default, bg),
      map-get($default, shadow),
      map-get($default, border-color),
      map-get($default, color)
    );

    &:hover {
      @include _button-setup-state-color(
        hover,
        map-get($hover, bg),
        map-get($hover, shadow),
        map-get($hover, border-color),
        map-get($hover, color)
      );
    }

    &:active,
    &:focus-visible {
      @include _button-setup-state-color(
        focus,
        map-get($focus, bg),
        map-get($focus, shadow),
        map-get($focus, border-color),
        map-get($focus, color)
      );
    }
  }
}

@mixin _button-setup-state-color($state, $bg, $shadow, $border-color, $color) {
  --ds-button-bg-#{$state}: #{$bg};
  --ds-button-shadow-#{$state}: #{$shadow};
  --ds-button-border-color-#{$state}: #{$border-color};
  --ds-button-color-#{$state}: #{$color};
}
