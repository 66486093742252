@use '../../../palette';
@use '../../../typography';

@mixin input-component() {
  .ds-input {
    --ds-input-color: var(#{palette.to-color-variable(branding, neutral, 900)});
    --ds-input-place-holder-color: var(
      #{palette.to-color-variable(branding, neutral, 500)}
    );
    @apply flex-1 outline-none shadow-none border-none bg-transparent;
    @include typography.use-work-sans(md, 400);

    ::placeholder {
      color: var(--ds-input-place-holder-color);
      opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder {
      /* Edge 12-18 */
      color: var(--ds-input-place-holder-color);
    }

    &:disabled {
      --ds-input-color: var(
        #{palette.to-color-variable(branding, neutral, 500)}
      );
    }
  }
}
