@use '../../../palette';
@use '../../../typography';

@mixin toggle-component() {
  .ds-toggle {
    --ds-toggle-box-before-bg: var(
      #{palette.to-color-variable(branding, neutral, 100)}
    );
    --ds-toggle-box-after-bg: var(
      #{palette.to-color-variable(branding, base, white)}
    );
    --ds-toggle-box-shadow: none;
    --ds-toggle-before-x: 0px;
    --ds-toggle-before-y: 0px;
    --ds-toggle-after-x: 2px;
    --ds-toggle-after-x-end: 18px;
    --ds-toggle-after-y: 2px;
    --ds-toggle-before-width: 36px;
    --ds-toggle-before-height: 20px;
    --ds-toggle-after-size: 16px;
    --ds-toggle-padding-left: 44px;
    --ds-toggle-gap: 0px;

    @apply relative;

    &:has(label:empty) {
      --ds-toggle-padding-left: 0px;
      > label {
        width: var(--ds-toggle-before-width);
        height: var(--ds-toggle-before-height);
      }
    }

    > label {
      @apply flex flex-col w-full z-[1] relative;
      padding-left: var(--ds-toggle-padding-left);
      gap: var(--ds-toggle-gap);

      > strong {
        @include typography.use-work-sans(md, 500);
        @apply text-branding-neutral-800;
      }

      > small {
        @include typography.use-work-sans(md, 400);
        @apply text-branding-neutral-600;
      }
    }

    > input[type='checkbox'] {
      @apply opacity-0 w-0 h-0 hidden;
    }

    &--sm {
      --ds-toggle-before-width: 36px;
      --ds-toggle-before-height: 20px;
      --ds-toggle-after-size: 16px;
      --ds-toggle-padding-left: 44px;
      --ds-toggle-gap: 0px;
      --ds-toggle-after-x: 2px;
      --ds-toggle-after-x-end: 18px;
    }

    &--md {
      --ds-toggle-before-width: 44px;
      --ds-toggle-before-height: 24px;
      --ds-toggle-after-size: 20px;
      --ds-toggle-padding-left: 56px;
      --ds-toggle-gap: 2px;
      --ds-toggle-after-x: 2px;
      --ds-toggle-after-x-end: 22px;
    }

    &::before {
      @apply content-[''] absolute rounded-xl duration-150;
      transition-property: background-color;
      background-color: var(--ds-toggle-box-before-bg);
      box-shadow: var(--ds-toggle-box-shadow);
      top: var(--ds-toggle-before-y);
      left: var(--ds-toggle-before-x);
      width: var(--ds-toggle-before-width);
      height: var(--ds-toggle-before-height);
    }

    &::after {
      @apply content-[''] absolute duration-150 rounded-full;
      transition-property: left;
      --ds-icon-font-size: 20px;
      background-color: var(--ds-toggle-box-after-bg);
      top: var(--ds-toggle-after-y);
      left: var(--ds-toggle-after-x);
      width: var(--ds-toggle-after-size);
      height: var(--ds-toggle-after-size);
    }

    &:has(input[type='checkbox']:disabled) {
      @apply cursor-not-allowed;
      --ds-toggle-box-after-bg: var(
        #{palette.to-color-variable(branding, neutral, 50)}
      );
      --ds-toggle-box-before-bg: var(
        #{palette.to-color-variable(branding, neutral, 100)}
      );
    }

    &:has(input[type='checkbox']:checked) {
      --ds-toggle-after-x: var(--ds-toggle-after-x-end);
    }

    &:not(:has(input[type='checkbox']:disabled)) {
      &:has(input[type='checkbox']:checked) {
        --ds-toggle-box-after-bg: var(
          #{palette.to-color-variable(branding, base, white)}
        );
        --ds-toggle-box-before-bg: var(
          #{palette.to-color-variable(branding, neutral, 800)}
        );

        &:has(label:hover) {
          @apply cursor-pointer;
          --ds-toggle-box-before-bg: var(
            #{palette.to-color-variable(branding, primary, 600)}
          );
        }

        &:has(label:active),
        &:has(label:focus-within) {
          --ds-toggle-box-shadow: 0px 0px 0px 4px #eef2f6;
        }
      }

      &:not(:has(input[type='checkbox']:checked)) {
        --ds-toggle-box-before-bg: var(
          #{palette.to-color-variable(branding, neutral, 100)}
        );
        --ds-toggle-box-after-bg: var(
          #{palette.to-color-variable(branding, base, white)}
        );

        &:has(label:hover) {
          @apply cursor-pointer;
          --ds-toggle-box-before-bg: var(
            #{palette.to-color-variable(branding, neutral, 200)}
          );
        }

        &:has(label:active),
        &:has(label:focus-within) {
          --ds-toggle-box-before-bg: var(
            #{palette.to-color-variable(branding, neutral, 50)}
          );
          --ds-toggle-box-shadow: 0px 0px 0px 4px #eef2f6;
        }
      }
    }
  }
}
