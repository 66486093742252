@use '../../../palette';
@use '../../../typography';

@mixin select-component() {
  .ds-select {
    @apply shadow-none border-none bg-transparent outline-none p-0 w-full;
    --ds-select-box-padding: var(--ds-field-control-padding-y)
      var(--ds-field-control-padding-x);
    --ds-select-box-height: var(--ds-field-height);
    --ds-select-box-gap: var(--ds-field-control-gap);
    --ds-select-box-border: 1px solid var(--ds-field-border-color);
    --ds-select-box-shadow: var(--ds-field-box-shadow);
    --ds-select-box-bg: var(--ds-field-bg-color);

    &-box {
      @apply flex items-center justify-between rounded-xl;
      background-color: var(--ds-select-box-bg);
      box-shadow: var(--ds-select-box-shadow);
      border: var(--ds-select-box-border);
      gap: var(--ds-select-box-gap);
      padding: var(--ds-select-box-padding);
      height: var(--ds-select-box-height);

      &__icon {
        --ds-icon-font-size: 18px;
        color: var(palette.to-color-variable(branding, neutral, 800));
        @apply transform rotate-0 duration-150;

        &.animate {
          @apply rotate-180;
        }
      }

      &__label {
        @include typography.use-work-sans(md, 400);
        @apply truncate flex-1 text-left;
        &.is-placeholder {
          @apply text-branding-neutral-400;
        }

        &:not(.is-placeholder) {
          @apply text-branding-neutral-800;
        }
      }
    }

    &-search {
      @apply px-4 py-2;
    }

    &-container {
      @apply w-full flex flex-col gap-2 py-2 overflow-x-hidden;
      border-radius: 28px;
      background-color: var(palette.to-color-variable(branding, base, white));
      box-shadow: 0 8px 36px 0 rgba(45, 30, 82, 0.08);
    }

    &-list {
      --ds-select-list-max-height: auto;

      @apply flex flex-col w-full overflow-y-auto m-0 list-none p-0;
      max-height: var(--ds-select-list-max-height);

      > li {
        @apply w-full;
      }
    }

    &-item {
      --ds-select-template-bg: transparent;
      --ds-select-template-color: var(
        #{palette.to-color-variable(branding, neutral, 700)}
      );
      --ds-select-template-icon-color: var(
        #{palette.to-color-variable(branding, neutral, 700)}
      );
      @apply flex justify-between items-center px-2.5 h-10;
      background-color: var(--ds-select-template-bg);

      > main {
        @apply flex-1;
        > label {
          @include typography.use-work-sans(sm, 500);
          color: var(--ds-select-template-color);
        }
      }

      > i {
        --ds-icon-font-size: 18px;
        color: var(--ds-select-template-icon-color);
      }

      &.disabled {
        @apply cursor-not-allowed;
        --ds-select-template-bg: var(
          #{palette.to-color-variable(branding, neutral, 300)}
        );
        --ds-select-template-color: var(
          #{palette.to-color-variable(branding, neutral, 300)}
        );
        --ds-select-template-icon-color: var(
          #{palette.to-color-variable(branding, neutral, 500)}
        );
      }

      &:not(.disabled) {
        &:hover {
          @apply cursor-pointer;
          --ds-select-template-bg: var(
            #{palette.to-color-variable(branding, neutral, 50)}
          );
        }

        &.active {
          --ds-select-template-bg: var(
            #{palette.to-color-variable(branding, neutral, 50)}
          );
          --ds-select-template-icon-color: var(
            #{palette.to-color-variable(branding, primary, 600)}
          );
        }
      }
    }
  }
}
