@use '../../../palette';
@use '../../../typography';
@use '../../../../assets/fonts/ocloud-icon' as icon;

@mixin checkbox-component() {
  .ds-checkbox {
    --ds-checkbox-box-bg: var(
      #{palette.to-color-variable(branding, base, white)}
    );
    --ds-checkbox-box-border: var(
      #{palette.to-color-variable(branding, neutral, 300)}
    );
    --ds-checkbox-box-shadow: none;
    --ds-checkbox-content: '#{icon.get-icon-content(outline-refresh-square-2)}';
    --ds-checkbox-x: 0px;
    --ds-checkbox-y: 2px;
    --ds-checkbox-size: 16px;
    --ds-checkbox-padding-left: 24px;
    --ds-checkbox-gap: 0px;

    @apply relative;

    &--sm {
      --ds-checkbox-size: 16px;
      --ds-checkbox-padding-left: 24px;
      --ds-checkbox-gap: 0px;
    }

    &--md {
      --ds-checkbox-size: 20px;
      --ds-checkbox-padding-left: 32px;
      --ds-checkbox-gap: 2px;
    }

    &:has(label:empty) {
      --ds-checkbox-x: 0px;
      --ds-checkbox-y: 0px;
      --ds-checkbox-padding-left: 0px;

      > label {
        width: var(--ds-checkbox-size);
        height: var(--ds-checkbox-size);
      }
    }

    > label {
      @apply flex flex-col z-[2] relative;
      padding-left: var(--ds-checkbox-padding-left);
      gap: var(--ds-checkbox-gap);

      > strong {
        @include typography.use-work-sans(md, 500);
        @apply text-branding-neutral-800;
      }

      > small {
        @include typography.use-work-sans(md, 400);
        @apply text-branding-neutral-600;
      }
    }

    > input[type='checkbox'] {
      @apply opacity-0 w-0 h-0 hidden;
    }

    &::before {
      --ds-icon-font-size: var(--ds-checkbox-size) !important;
      @apply content-[''] absolute z-[1];
      @include icon.icon-component-default-config();
      top: var(--ds-checkbox-y);
      left: var(--ds-checkbox-x);
      color: var(--ds-checkbox-box-border);
      content: var(--ds-checkbox-content);
    }

    &::after {
      @apply absolute rounded-[6px] content-[''];
      background-color: var(--ds-checkbox-box-bg);
      box-shadow: var(--ds-checkbox-box-shadow);
      width: calc(var(--ds-checkbox-size) - 2px);
      height: calc(var(--ds-checkbox-size) - 2px);
      top: calc(var(--ds-checkbox-y) + 1px);
      left: calc(var(--ds-checkbox-x) + 1px);
    }

    &:has(input[type='checkbox']:disabled) {
      @apply cursor-not-allowed;
      --ds-checkbox-box-border: var(
        #{palette.to-color-variable(branding, neutral, 200)}
      );

      &:has(input[type='checkbox']:checked) {
        --ds-checkbox-box-bg: transparent;
      }
      &:not(:has(input[type='checkbox']:checked)) {
        --ds-checkbox-box-bg: var(
          #{palette.to-color-variable(branding, neutral, 200)}
        );
      }
    }

    &:has(input[type='checkbox']:checked) {
      --ds-checkbox-content: '#{icon.get-icon-content(bold-tick-square)}';
    }

    &:not(:has(input[type='checkbox']:disabled)) {
      &:has(input[type='checkbox']:checked) {
        --ds-checkbox-box-bg: transparent;
        --ds-checkbox-box-border: var(
          #{palette.to-color-variable(branding, neutral, 800)}
        );

        &:has(label:hover) {
          @apply cursor-pointer;
          --ds-checkbox-box-bg: transparent;
          --ds-checkbox-box-border: var(
            #{palette.to-color-variable(branding, primary, 600)}
          );
        }

        &:has(label:active),
        &:has(label:focus-within) {
          --ds-checkbox-box-bg: transparent;
          --ds-checkbox-box-border: var(
            #{palette.to-color-variable(branding, neutral, 800)}
          );
          --ds-checkbox-box-shadow: 0px 0px 0px 4px #eef2f6;
        }
      }

      &:not(:has(input[type='checkbox']:checked)) {
        --ds-checkbox-box-bg: var(
          #{palette.to-color-variable(branding, base, white)}
        );
        --ds-checkbox-box-border: var(
          #{palette.to-color-variable(branding, neutral, 300)}
        );

        &:has(label:hover) {
          @apply cursor-pointer;
          --ds-checkbox-box-bg: var(
            #{palette.to-color-variable(branding, primary, 100)}
          );
          --ds-checkbox-box-border: var(
            #{palette.to-color-variable(branding, primary, 300)}
          );
        }

        &:has(label:active),
        &:has(label:focus-within) {
          --ds-checkbox-box-bg: var(
            #{palette.to-color-variable(branding, base, white)}
          );
          --ds-checkbox-box-border: var(
            #{palette.to-color-variable(branding, neutral, 300)}
          );
          --ds-checkbox-box-shadow: 0px 0px 0px 4px #eef2f6;
        }
      }
    }
  }
}
