@use '../../../palette';
@use '../../../typography';

@mixin date-picker-component() {
  .ds-date-picker {
    @apply shadow-none border-none bg-transparent outline-none p-0 w-full;
    --ds-date-picker-box-padding: var(--ds-field-control-padding-y)
      var(--ds-field-control-padding-x);
    --ds-date-picker-box-height: var(--ds-field-height);
    --ds-date-picker-box-gap: var(--ds-field-control-gap);
    --ds-date-picker-box-border: 1px solid var(--ds-field-border-color);
    --ds-date-picker-box-shadow: var(--ds-field-box-shadow);
    --ds-date-picker-box-bg: var(--ds-field-bg-color);

    &-box {
      @apply flex items-center justify-between rounded-xl;
      background-color: var(--ds-date-picker-box-bg);
      box-shadow: var(--ds-date-picker-box-shadow);
      border: var(--ds-date-picker-box-border);
      gap: var(--ds-date-picker-box-gap);
      padding: var(--ds-date-picker-box-padding);
      height: var(--ds-date-picker-box-height);

      > i {
        --ds-icon-font-size: 18px;
        color: var(palette.to-color-variable(branding, neutral, 800));
      }

      > span {
        @include typography.use-work-sans(md, 400);
        @apply truncate flex-1 text-left;
        &.is-placeholder {
          @apply text-branding-neutral-400;
        }

        &:not(.is-placeholder) {
          @apply text-branding-neutral-800;
        }
      }
    }

    &-date {
      @apply flex flex-wrap w-[280px] gap-y-1;
    }

    &-item {
      @apply w-10 h-10 inline-flex items-center justify-center;

      &:is(span) {
        @include typography.use-work-sans(sm, 500);
        @apply text-branding-neutral-700;
      }

      &:is(button) {
        --ds-date-picker-item-bg: none;
        --ds-date-picker-item-color: var(
          #{palette.to-color-variable(branding, neutral, 500)}
        );
        --ds-date-picker-item-current-bg: var(
          #{palette.to-color-variable(branding, neutral, 800)}
        );
        @apply outline-none shadow-none border-none rounded-full relative bg-transparent;
        > label {
          color: var(--ds-date-picker-item-color);
          @include typography.use-work-sans(sm, 400);
          @apply z-[1];
        }

        > span {
          @apply rounded-full w-[5px] h-[5px] absolute transform -translate-x-1/2 bottom-1 left-1/2 z-[1];
          background: var(--ds-date-picker-item-current-bg);
        }

        &::after {
          @apply absolute content-[''] top-0 left-0 rounded-full w-full h-full;
          background: var(--ds-date-picker-item-bg);
        }

        &:disabled {
          --ds-date-picker-item-color: var(
            #{palette.to-color-variable(branding, neutral, 500)}
          );
          --ds-date-picker-item-current-bg: var(
            #{palette.to-color-variable(branding, neutral, 400)}
          );

          &.active {
            --ds-date-picker-item-bg: var(
              #{palette.to-color-variable(branding, neutral, 100)}
            );
          }

          &:not(.active) {
            --ds-date-picker-item-bg: none;
          }
        }

        &:not(:disabled) {
          &:active,
          &.active {
            &.range {
              --ds-date-picker-item-bg: var(
                #{palette.to-color-variable(branding, neutral, 800)}
              );
              --ds-date-picker-item-color: var(
                #{palette.to-color-variable(branding, base, white)}
              );
              --ds-date-picker-item-current-bg: var(
                #{palette.to-color-variable(branding, base, white)}
              );
            }

            &:not(.range) {
              --ds-date-picker-item-bg: var(
                #{palette.to-color-variable(branding, neutral, 800)}
              );
              --ds-date-picker-item-color: var(
                #{palette.to-color-variable(branding, base, white)}
              );
              --ds-date-picker-item-current-bg: var(
                #{palette.to-color-variable(branding, base, white)}
              );
            }

            &:hover,
            &:focus-visible {
              --ds-date-picker-item-bg: var(
                #{palette.to-color-variable(branding, primary, 700)}
              );
              --ds-date-picker-item-color: var(
                #{palette.to-color-variable(branding, base, white)}
              );
              --ds-date-picker-item-current-bg: var(
                #{palette.to-color-variable(branding, base, white)}
              );
            }
          }

          &:not(:active):not(.active) {
            --ds-date-picker-item-bg: none;
            --ds-date-picker-item-color: var(
              #{palette.to-color-variable(branding, neutral, 700)}
            );
            --ds-date-picker-item-current-bg: var(
              #{palette.to-color-variable(branding, neutral, 800)}
            );

            &:hover,
            &:focus-visible {
              --ds-date-picker-item-bg: var(
                #{palette.to-color-variable(branding, neutral, 800)}
              );
              --ds-date-picker-item-color: var(
                #{palette.to-color-variable(branding, base, white)}
              );
              --ds-date-picker-item-current-bg: var(
                #{palette.to-color-variable(branding, base, white)}
              );
            }
          }
        }

        &.range {
          &::before {
            @apply content-[''] w-10 bg-branding-neutral-50 h-10 absolute top-0;
          }

          &.first {
            &::before {
              @apply rounded-l-full left-0;
            }
          }

          &.last {
            &::before {
              @apply right-0 rounded-r-full;
            }
          }
        }

        &.not-in-month {
          @apply opacity-50;
        }
      }
    }

    &-single {
      --ds-date-picker-actions-width: 100%;
      @include _default-container-setup(column, 516px);

      > header {
        @apply px-6 pt-5;
      }
      > main {
        @apply px-6 pt-3 pb-5 flex flex-col gap-3 h-[390px];
      }

      > footer {
        @apply p-4 border-0 border-t border-solid border-branding-neutral-200;
      }
    }

    &-range {
      --ds-date-picker-actions-width: fit-content;
      @include _default-container-setup(row, 468px);

      > section {
        @apply flex flex-col;

        > main {
          @apply flex;

          > article {
            @apply border-0 border-l border-solid border-branding-neutral-200 w-[328px] h-[392px];
            @apply py-5 px-6 flex flex-col gap-3;
          }
        }

        > footer {
          @apply flex justify-between items-center p-4 border-0 border-t border-l border-solid border-branding-neutral-200;
        }
      }
    }

    &-actions {
      @apply flex gap-3;
      width: var(--ds-date-picker-actions-width);

      > button {
        @apply flex-1;
      }
    }

    &-navigation {
      @apply w-full flex items-center justify-between;

      > h5 {
        @apply text-branding-neutral-700 m-0;
        @include typography.use-work-sans(md, 600);
      }
    }

    &-value {
      @apply flex items-center gap-3;

      > hr {
        @apply w-2 h-px bg-branding-neutral-500;
      }

      &--range {
        .ds-field {
          --ds-field-width: 136px;
        }
      }

      &--single {
        .ds-field {
          --ds-field-width: 188px;
        }
      }
    }

    &-menu {
      @apply h-full px-4 py-3 list-none m-0;
      @apply flex flex-col gap-1;

      > li {
        @apply flex w-[160px];
        > button {
          @apply px-4 py-2.5 w-full rounded-xl bg-transparent hover:bg-branding-neutral-50 outline-none border-none shadow-none text-left;
          @include typography.use-work-sans(sm, 500);
        }
      }
    }
  }
}

@mixin _default-container-setup($flex-direction, $height) {
  @apply bg-branding-base-white border border-solid border-branding-neutral-100 rounded-lg;
  @apply flex;
  flex-direction: #{$flex-direction};
  height: #{$height};
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
}
