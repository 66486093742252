@use '../../../palette';
@use '../../../typography';

@mixin validation-component() {
  .validation-summary {
    --validation-color: var(#{palette.to-color-variable(branding, error, 500)});
    --validation-padding-left: 0;
    --validation-list-style-type: none;
    --validation-margin: 0;
    --validation-margin-top: -10px;

    list-style-type: var(--validation-list-style-type);
    padding-left: var(--validation-padding-left);
    color: var(--validation-color) !important;
    margin: var(--validation-margin);
    margin-top: var(--validation-margin-top) !important;

    > li {
      @include typography.use-work-sans(sm, 400);
      color: var(--validation-color) !important;
    }
  }
}
