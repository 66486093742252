@font-face {
  font-family: 'ds-icon';
  src: url('./ds-icon.eot?puehr0');
  src: url('./ds-icon.eot?puehr0#iefix') format('embedded-opentype'),
    url('./ds-icon.ttf?puehr0') format('truetype'),
    url('./ds-icon.woff?puehr0') format('woff'),
    url('./ds-icon.svg?puehr0#ds-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

$icon-content-variables: (
  custom-check: '\f4d9',
  custom-x: '\f4da',
  bold-crypto-theta-theta: '\e900',
  bold-crypto-vuesax: '\e901',
  bold-crypto-aave-aave: '\e902',
  bold-crypto-litecoinltc: '\e903',
  bold-crypto-google-1: '\e904',
  bold-crypto-educare-ekt: '\e905',
  bold-crypto-apple: '\e906',
  bold-crypto-icon-1: '\e907',
  bold-crypto-hex-hex-path1: '\e908',
  bold-crypto-hex-hex-path2: '\e909',
  bold-crypto-hex-hex-path3: '\e90a',
  bold-crypto-hex-hex-path4: '\e90b',
  bold-crypto-messenger: '\e90c',
  bold-crypto-slack: '\e90d',
  bold-crypto-xrp-xrp: '\e90e',
  bold-crypto-framer: '\e90f',
  bold-crypto-ethereum-classic-etc: '\e910',
  bold-crypto-monero-xmr: '\e911',
  bold-crypto-nem-xem: '\e912',
  bold-crypto-trello: '\e913',
  bold-crypto-enjin-coin-enj: '\e914',
  bold-crypto-dribbble: '\e915',
  bold-crypto-vibe-vibe: '\e916',
  bold-crypto-illustrator: '\e917',
  bold-crypto-html-3: '\e918',
  bold-crypto-youtube: '\e919',
  bold-crypto-thorchain-rune: '\e91a',
  bold-crypto-polyswarm-nct: '\e91b',
  bold-crypto-windows: '\e91c',
  bold-crypto-twitch: '\e91d',
  bold-crypto-okb-okb: '\e91e',
  bold-crypto-stacks-stx: '\e91f',
  bold-crypto-photoshop: '\e920',
  bold-crypto-tether-usdt: '\e921',
  bold-crypto-celo-celo: '\e922',
  bold-crypto-google: '\e923',
  bold-crypto-whatsapp: '\e924',
  bold-crypto-ontology-ont: '\e925',
  bold-crypto-be: '\e926',
  bold-crypto-bootsrap: '\e927',
  bold-crypto-facebook: '\e928',
  bold-crypto-html-5: '\e929',
  bold-crypto-paypal: '\e92a',
  bold-crypto-velas-vlx: '\e92b',
  bold-crypto-bitcoin-btc: '\e92c',
  bold-crypto-dash-dash: '\e92d',
  bold-crypto-binance-usd-busd: '\e92e',
  bold-crypto-avalanche-avax: '\e92f',
  bold-crypto-ocean-protocol-ocean: '\e930',
  bold-crypto-snapchat: '\e931',
  bold-crypto-maker-mkr: '\e932',
  bold-crypto-kyber-network-knc: '\e933',
  bold-crypto-binance-coin-bnb: '\e934',
  bold-crypto-trontron-trx: '\e935',
  bold-crypto-java-script: '\e936',
  bold-crypto-spotify: '\e937',
  bold-crypto-ethereum-eth: '\e938',
  bold-crypto-xd: '\e939',
  bold-crypto-harmony-one: '\e93a',
  bold-crypto-decred-dcr: '\e93b',
  bold-crypto-polkadot-dot: '\e93c',
  bold-crypto-iost-iost: '\e93d',
  bold-crypto-icon: '\e93e',
  bold-crypto-ankr-ankr: '\e93f',
  bold-crypto-nexo-nexo: '\e940',
  bold-crypto-zel-zel: '\e941',
  bold-crypto-augur-rep: '\e942',
  bold-crypto-blogger: '\e943',
  bold-crypto-python: '\e944',
  bold-crypto-dai-dai: '\e945',
  bold-crypto-autonio-niox: '\e946',
  bold-crypto-chainlink-link: '\e947',
  bold-crypto-zoom: '\e948',
  bold-crypto-emercoin-emc: '\e949',
  bold-crypto-wing-wing: '\e94a',
  bold-crypto-quant-qnt: '\e94b',
  bold-crypto-ftx-token-ftt: '\e94c',
  bold-crypto-icon-icx: '\e94d',
  bold-crypto-usd-coin-usdc: '\e94e',
  bold-crypto-the-graph-grt: '\e94f',
  bold-crypto-dent-dent: '\e950',
  bold-crypto-wanchain-wan: '\e951',
  bold-crypto-hedera-hashgraph-hbar: '\e952',
  bold-crypto-civic-cvc: '\e953',
  bold-crypto-figma-1: '\e954',
  bold-crypto-ui8: '\e955',
  bold-crypto-google-play: '\e956',
  bold-crypto-tenx-pay: '\e957',
  bold-crypto-huobi-token-ht: '\e958',
  bold-crypto-wanchain-wan-1: '\e959',
  bold-crypto-siacoin-sc: '\e95a',
  bold-crypto-js: '\e95b',
  bold-crypto-celsius-cel: '\e95c',
  bold-crypto-polygon-matic: '\e95d',
  bold-crypto-android: '\e95e',
  bold-crypto-cardano-ada: '\e95f',
  bold-crypto-nebulas-nas: '\e960',
  bold-crypto-eos-eos: '\e961',
  bold-crypto-dropbox: '\e962',
  bold-crypto-stellar-xlm: '\e963',
  bold-crypto-xiaomi: '\e964',
  bold-crypto-solana-sol: '\e965',
  bold-crypto-figma: '\e966',
  bold-crypto-triangle: '\e967',
  bold-data-2-path1: '\e968',
  bold-data-2-path2: '\e969',
  bold-data-2-path3: '\e96a',
  bold-data-2-path4: '\e96b',
  bold-data-2-path5: '\e96c',
  bold-heart: '\e96d',
  bold-calendar-remove: '\e96e',
  bold-refresh-2: '\e96f',
  bold-align-left: '\e970',
  bold-clipboard-text: '\e971',
  bold-send-2: '\e972',
  bold-direct-notification: '\e973',
  bold-card-tick: '\e974',
  bold-money-remove: '\e975',
  bold-voice-cricle: '\e976',
  bold-forward-10-seconds: '\e977',
  bold-direct-normal: '\e978',
  bold-weight: '\e979',
  bold-candle-2: '\e97a',
  bold-moon: '\e97b',
  bold-gallery-export: '\e97c',
  bold-music-circle: '\e97d',
  bold-element-plus: '\e97e',
  bold-scanning: '\e97f',
  bold-document-normal: '\e980',
  bold-wallet-minus: '\e981',
  bold-card-add: '\e982',
  bold-3d-cube-scan: '\e983',
  bold-minus: '\e984',
  bold-monitor: '\e985',
  bold-timer: '\e986',
  bold-arrow-down-1: '\e987',
  bold-color-swatch: '\e988',
  bold-archive-1: '\e989',
  bold-copy-success: '\e98a',
  bold-alarm: '\e98b',
  bold-folder: '\e98c',
  bold-cards: '\e98d',
  bold-message-remove: '\e98e',
  bold-search-favorite: '\e98f',
  bold-bezier: '\e990',
  bold-building: '\e991',
  bold-graph: '\e992',
  bold-slider-horizontal: '\e993',
  bold-logout-1: '\e994',
  bold-document-sketch: '\e995',
  bold-menu-board: '\e996',
  bold-mirror: '\e997',
  bold-blend-2: '\e998',
  bold-lamp-slash: '\e999',
  bold-receipt-text: '\e99a',
  bold-man: '\e99b',
  bold-truck-remove: '\e99c',
  bold-layer: '\e99d',
  bold-sms-edit: '\e99e',
  bold-music-square-remove: '\e99f',
  bold-routing: '\e9a0',
  bold-login-1: '\e9a1',
  bold-mouse: '\e9a2',
  bold-3d-square: '\e9a3',
  bold-discover-1: '\e9a4',
  bold-calculator: '\e9a5',
  bold-airplane: '\e9a6',
  bold-happyemoji: '\e9a7',
  bold-shopping-cart: '\e9a8',
  bold-finger-cricle: '\e9a9',
  bold-save-add: '\e9aa',
  bold-chrome: '\e9ab',
  bold-volume-slash: '\e9ac',
  bold-bucket-square: '\e9ad',
  bold-note-21: '\e9ae',
  bold-security-user: '\e9af',
  bold-repeate-one: '\e9b0',
  bold-send-1: '\e9b1',
  bold-money-recive: '\e9b2',
  bold-sms-star: '\e9b3',
  bold-search-zoom-out: '\e9b4',
  bold-building-4: '\e9b5',
  bold-like-shapes: '\e9b6',
  bold-menu: '\e9b7',
  bold-drop: '\e9b8',
  bold-volume-low-1: '\e9b9',
  bold-setting-3: '\e9ba',
  bold-maximize-circle: '\e9bb',
  bold-receipt-discount: '\e9bc',
  bold-strongbox-2: '\e9bd',
  bold-component: '\e9be',
  bold-trend-down: '\e9bf',
  bold-favorite-chart: '\e9c0',
  bold-math: '\e9c1',
  bold-screenmirroring: '\e9c2',
  bold-receipt-minus: '\e9c3',
  bold-lock-circle: '\e9c4',
  bold-level: '\e9c5',
  bold-document-filter: '\e9c6',
  bold-call-add: '\e9c7',
  bold-programming-arrow: '\e9c8',
  bold-rotate-right: '\e9c9',
  bold-note-square: '\e9ca',
  bold-status: '\e9cb',
  bold-shield-slash: '\e9cc',
  bold-arrow-down-2: '\e9cd',
  bold-archive-2: '\e9ce',
  bold-bookmark: '\e9cf',
  bold-blend: '\e9d0',
  bold-forward: '\e9d1',
  bold-call-minus: '\e9d2',
  bold-box-tick: '\e9d3',
  bold-setting-2: '\e9d4',
  bold-pause: '\e9d5',
  bold-cpu-charge: '\e9d6',
  bold-diamonds: '\e9d7',
  bold-mini-music-sqaure-path1: '\e9d8',
  bold-mini-music-sqaure-path2: '\e9d9',
  bold-mini-music-sqaure-path3: '\e9da',
  bold-cup: '\e9db',
  bold-shuffle: '\e9dc',
  bold-lovely: '\e9dd',
  bold-cloud: '\e9de',
  bold-task-square: '\e9df',
  bold-wallet-money: '\e9e0',
  bold-heart-edit: '\e9e1',
  bold-clipboard-import: '\e9e2',
  bold-close-square: '\e9e3',
  bold-receipt-search: '\e9e4',
  bold-omega-circle: '\e9e5',
  bold-profile-circle: '\e9e6',
  bold-scanner: '\e9e7',
  bold-volume-low: '\e9e8',
  bold-message-minus: '\e9e9',
  bold-keyboard-open: '\e9ea',
  bold-microphone-slash: '\e9eb',
  bold-ram: '\e9ec',
  bold-data: '\e9ed',
  bold-buliding: '\e9ee',
  bold-gas-station: '\e9ef',
  bold-arrow-left: '\e9f0',
  bold-lamp-charge: '\e9f1',
  bold-calendar: '\e9f2',
  bold-video-slash-path1: '\e9f3',
  bold-video-slash-path2: '\e9f4',
  bold-video-slash-path3: '\e9f5',
  bold-video-slash-path4: '\e9f6',
  bold-arrow-swap: '\e9f7',
  bold-medal: '\e9f8',
  bold-link-square: '\e9f9',
  bold-pharagraphspacing: '\e9fa',
  bold-money-send: '\e9fb',
  bold-add-circle: '\e9fc',
  bold-filter: '\e9fd',
  bold-box-time: '\e9fe',
  bold-bitcoin-convert: '\e9ff',
  bold-activity: '\ea00',
  bold-tree: '\ea01',
  bold-security-safe: '\ea02',
  bold-filter-square: '\ea03',
  bold-external-drive: '\ea04',
  bold-video-vertical: '\ea05',
  bold-video: '\ea06',
  bold-undo: '\ea07',
  bold-messages-1: '\ea08',
  bold-sort: '\ea09',
  bold-microscope: '\ea0a',
  bold-folder-add: '\ea0b',
  bold-colors-square: '\ea0c',
  bold-money: '\ea0d',
  bold-arrow-up: '\ea0e',
  bold-video-horizontal: '\ea0f',
  bold-document-upload: '\ea10',
  bold-video-square: '\ea11',
  bold-arrow-square-down: '\ea12',
  bold-share: '\ea13',
  bold-tag-right: '\ea14',
  bold-language-square: '\ea15',
  bold-clock-1: '\ea16',
  bold-heart-remove: '\ea17',
  bold-forward-15-seconds: '\ea18',
  bold-messages-3: '\ea19',
  bold-car: '\ea1a',
  bold-setting-5: '\ea1b',
  bold-gift: '\ea1c',
  bold-glass-1: '\ea1d',
  bold-arrange-square-2: '\ea1e',
  bold-search-normal-1: '\ea1f',
  bold-receipt-edit: '\ea20',
  bold-electricity: '\ea21',
  bold-device-message: '\ea22',
  bold-profile-tick: '\ea23',
  bold-location: '\ea24',
  bold-path-square: '\ea25',
  bold-wallet-remove: '\ea26',
  bold-bluetooth-rectangle: '\ea27',
  bold-attach-square: '\ea28',
  bold-headphone: '\ea29',
  bold-personalcard: '\ea2a',
  bold-cloud-connection: '\ea2b',
  bold-gallery-edit: '\ea2c',
  bold-mobile: '\ea2d',
  bold-wallet-add: '\ea2e',
  bold-eye: '\ea2f',
  bold-call: '\ea30',
  bold-direct-send: '\ea31',
  bold-repeat-circle: '\ea32',
  bold-message-programming: '\ea33',
  bold-send-sqaure-2: '\ea34',
  bold-courthouse: '\ea35',
  bold-slider-horizontal-1: '\ea36',
  bold-textalign-justifyright: '\ea37',
  bold-call-incoming: '\ea38',
  bold-wind-2: '\ea39',
  bold-clock: '\ea3a',
  bold-command: '\ea3b',
  bold-setting-4: '\ea3c',
  bold-messages-2: '\ea3d',
  bold-building-3: '\ea3e',
  bold-ticket-discount: '\ea3f',
  bold-forbidden: '\ea40',
  bold-hierarchy: '\ea41',
  bold-play-remove: '\ea42',
  bold-simcard: '\ea43',
  bold-bitcoin-refresh: '\ea44',
  bold-music-library-2: '\ea45',
  bold-search-zoom-out-1: '\ea46',
  bold-3square: '\ea47',
  bold-home-1: '\ea48',
  bold-timer-1: '\ea49',
  bold-call-received: '\ea4a',
  bold-magicpen: '\ea4b',
  bold-global-refresh: '\ea4c',
  bold-receipt-item: '\ea4d',
  bold-arrow-swap-horizontal: '\ea4e',
  bold-video-play: '\ea4f',
  bold-lock-1: '\ea50',
  bold-judge: '\ea51',
  bold-frame: '\ea52',
  bold-bank: '\ea53',
  bold-location-minus: '\ea54',
  bold-woman: '\ea55',
  bold-eraser-1: '\ea56',
  bold-designtools: '\ea57',
  bold-truck-time: '\ea58',
  bold-forward-square: '\ea59',
  bold-card-remove: '\ea5a',
  bold-music-play: '\ea5b',
  bold-star-slash: '\ea5c',
  bold-notification-status: '\ea5d',
  bold-forbidden-2: '\ea5e',
  bold-unlock: '\ea5f',
  bold-send-square: '\ea60',
  bold-flash-slash: '\ea61',
  bold-arrow-left-1: '\ea62',
  bold-export-2: '\ea63',
  bold-folder-cloud: '\ea64',
  bold-export-3: '\ea65',
  bold-pet: '\ea66',
  bold-message-edit: '\ea67',
  bold-colorfilter: '\ea68',
  bold-volume-high: '\ea69',
  bold-lifebuoy: '\ea6a',
  bold-gallery-add: '\ea6b',
  bold-emoji-normal: '\ea6c',
  bold-receipt-2: '\ea6d',
  bold-wallet-add-1: '\ea6e',
  bold-strongbox: '\ea6f',
  bold-bluetooth-2: '\ea70',
  bold-home-wifi: '\ea71',
  bold-maximize-21: '\ea72',
  bold-next: '\ea73',
  bold-percentage-circle: '\ea74',
  bold-edit: '\ea75',
  bold-mouse-circle: '\ea76',
  bold-more-2: '\ea77',
  bold-folder-cross: '\ea78',
  bold-message-square: '\ea79',
  bold-arrow-circle-down: '\ea7a',
  bold-cloud-sunny: '\ea7b',
  bold-danger: '\ea7c',
  bold-bag-cross-1: '\ea7d',
  bold-bag-timer: '\ea7e',
  bold-home-2: '\ea7f',
  bold-sun: '\ea80',
  bold-clipboard-export: '\ea81',
  bold-cd: '\ea82',
  bold-folder-2: '\ea83',
  bold-bitcoin-card: '\ea84',
  bold-star: '\ea85',
  bold-security-time: '\ea86',
  bold-hashtag: '\ea87',
  bold-volume-up: '\ea88',
  bold-weight-1: '\ea89',
  bold-computing: '\ea8a',
  bold-grid-9: '\ea8b',
  bold-element-equal: '\ea8c',
  bold-recovery-convert: '\ea8d',
  bold-scan-barcode: '\ea8e',
  bold-dollar-square: '\ea8f',
  bold-direct-left: '\ea90',
  bold-element-3: '\ea91',
  bold-video-octagon: '\ea92',
  bold-maximize-4: '\ea93',
  bold-record-circle: '\ea94',
  bold-monitor-recorder: '\ea95',
  bold-pen-add: '\ea96',
  bold-refresh-left-square: '\ea97',
  bold-battery-3full: '\ea98',
  bold-trash: '\ea99',
  bold-export-1: '\ea9a',
  bold-arrow-left-2: '\ea9b',
  bold-arrow-left-3: '\ea9c',
  bold-textalign-center: '\ea9d',
  bold-firstline: '\ea9e',
  bold-bus: '\ea9f',
  bold-battery-empty-1: '\eaa0',
  bold-cloud-minus: '\eaa1',
  bold-grid-eraser: '\eaa2',
  bold-glass: '\eaa3',
  bold-element-2: '\eaa4',
  bold-back-square: '\eaa5',
  bold-text-underline: '\eaa6',
  bold-emoji-sad: '\eaa7',
  bold-document-download: '\eaa8',
  bold-receipt-1: '\eaa9',
  bold-bucket: '\eaaa',
  bold-more: '\eaab',
  bold-security: '\eaac',
  bold-arrow-bottom: '\eaad',
  bold-grid-8: '\eaae',
  bold-music-playlist: '\eaaf',
  bold-note-remove: '\eab0',
  bold-pen-tool: '\eab1',
  bold-quote-up-circle: '\eab2',
  bold-discount-circle: '\eab3',
  bold-user-search: '\eab4',
  bold-truck: '\eab5',
  bold-folder-minus: '\eab6',
  bold-cloud-add: '\eab7',
  bold-autobrightness: '\eab8',
  bold-redo: '\eab9',
  bold-hashtag-down: '\eaba',
  bold-more-square: '\eabb',
  bold-printer: '\eabc',
  bold-link-2: '\eabd',
  bold-camera: '\eabe',
  bold-document-text: '\eabf',
  bold-battery-empty: '\eac0',
  bold-brifecase-tick: '\eac1',
  bold-arrow-right-3: '\eac2',
  bold-convert: '\eac3',
  bold-archive-slash: '\eac4',
  bold-speaker: '\eac5',
  bold-play-add: '\eac6',
  bold-simcard-2: '\eac7',
  bold-empty-wallet-add: '\eac8',
  bold-scroll: '\eac9',
  bold-tick-circle: '\eaca',
  bold-tag-cross: '\eacb',
  bold-bill: '\eacc',
  bold-mouse-1: '\eacd',
  bold-user-octagon: '\eace',
  bold-smart-car: '\eacf',
  bold-direct: '\ead0',
  bold-barcode: '\ead1',
  bold-send: '\ead2',
  bold-airplane-square: '\ead3',
  bold-user-edit: '\ead4',
  bold-maximize-1: '\ead5',
  bold-diagram: '\ead6',
  bold-notification-bing: '\ead7',
  bold-like-1: '\ead8',
  bold-verify: '\ead9',
  bold-coin: '\eada',
  bold-driving: '\eadb',
  bold-text-block: '\eadc',
  bold-notification: '\eadd',
  bold-filter-edit: '\eade',
  bold-filter-remove: '\eadf',
  bold-directbox-receive: '\eae0',
  bold-toggle-off-circle: '\eae1',
  bold-backward: '\eae2',
  bold-play: '\eae3',
  bold-eye-slash: '\eae4',
  bold-rulerpen: '\eae5',
  bold-message-add-1: '\eae6',
  bold-broom: '\eae7',
  bold-crown: '\eae8',
  bold-message-2: '\eae9',
  bold-cloud-change: '\eaea',
  bold-mask: '\eaeb',
  bold-messages: '\eaec',
  bold-empty-wallet-remove: '\eaed',
  bold-format-circle: '\eaee',
  bold-location-cross: '\eaef',
  bold-box: '\eaf0',
  bold-box-search: '\eaf1',
  bold-trade: '\eaf2',
  bold-award: '\eaf3',
  bold-sticker: '\eaf4',
  bold-video-remove: '\eaf5',
  bold-airpod: '\eaf6',
  bold-message: '\eaf7',
  bold-document-copy: '\eaf8',
  bold-safe-home: '\eaf9',
  bold-textalign-justifyleft: '\eafa',
  bold-message-favorite: '\eafb',
  bold-story: '\eafc',
  bold-flash-circle: '\eafd',
  bold-paperclip: '\eafe',
  bold-arrow-right-2: '\eaff',
  bold-crown-1: '\eb00',
  bold-previous: '\eb01',
  bold-volume-mute: '\eb02',
  bold-3dcube: '\eb03',
  bold-grid-lock: '\eb04',
  bold-setting: '\eb05',
  bold-message-text: '\eb06',
  bold-heart-add: '\eb07',
  bold-command-square: '\eb08',
  bold-document-cloud: '\eb09',
  bold-link-1: '\eb0a',
  bold-direct-inbox: '\eb0b',
  bold-security-card: '\eb0c',
  bold-cloud-lightning: '\eb0d',
  bold-scan: '\eb0e',
  bold-minus-cirlce: '\eb0f',
  bold-lamp: '\eb10',
  bold-search-favorite-1: '\eb11',
  bold-simcard-1: '\eb12',
  bold-flash-1: '\eb13',
  bold-reserve: '\eb14',
  bold-truck-tick: '\eb15',
  bold-card-slash: '\eb16',
  bold-folder-connection: '\eb17',
  bold-calendar-edit: '\eb18',
  bold-maximize-2: '\eb19',
  bold-image: '\eb1a',
  bold-align-right: '\eb1b',
  bold-text: '\eb1c',
  bold-gallery-import: '\eb1d',
  bold-hashtag-1: '\eb1e',
  bold-chart-square: '\eb1f',
  bold-link-21: '\eb20',
  bold-cloud-cross: '\eb21',
  bold-refresh: '\eb22',
  bold-hierarchy-square: '\eb23',
  bold-box-add: '\eb24',
  bold-hierarchy-square-2: '\eb25',
  bold-align-horizontally: '\eb26',
  bold-hierarchy-square-3: '\eb27',
  bold-arrange-circle: '\eb28',
  bold-sun-fog: '\eb29',
  bold-bag: '\eb2a',
  bold-element-4: '\eb2b',
  bold-maximize-3: '\eb2c',
  bold-call-calling: '\eb2d',
  bold-bag-tick-2: '\eb2e',
  bold-receipt-add: '\eb2f',
  bold-copy: '\eb30',
  bold-watch-status: '\eb31',
  bold-smallcaps: '\eb32',
  bold-arrow-right-1: '\eb33',
  bold-ticket-expired: '\eb34',
  bold-bag-cross: '\eb35',
  bold-cloud-fog: '\eb36',
  bold-info-circle: '\eb37',
  bold-quote-down-square: '\eb38',
  bold-people: '\eb39',
  bold-convert-card: '\eb3a',
  bold-book-1: '\eb3b',
  bold-document-code: '\eb3c',
  bold-speedometer: '\eb3d',
  bold-truck-fast: '\eb3e',
  bold-add: '\eb3f',
  bold-user-add: '\eb40',
  bold-sidebar-bottom: '\eb41',
  bold-key-square: '\eb42',
  bold-code-circle: '\eb43',
  bold-archive-tick: '\eb44',
  bold-grid-3: '\eb45',
  bold-archive-add: '\eb46',
  bold-arrow-circle-right: '\eb47',
  bold-slider: '\eb48',
  bold-wallet-check: '\eb49',
  bold-backward-item: '\eb4a',
  bold-directbox-default: '\eb4b',
  bold-video-add: '\eb4c',
  bold-card: '\eb4d',
  bold-watch: '\eb4e',
  bold-document-code-2: '\eb4f',
  bold-route-square: '\eb50',
  bold-hashtag-up: '\eb51',
  bold-wifi: '\eb52',
  bold-main-component: '\eb53',
  bold-category-2: '\eb54',
  bold-empty-wallet-time: '\eb55',
  bold-frame-4: '\eb56',
  bold-gallery-slash: '\eb57',
  bold-finger-scan: '\eb58',
  bold-additem: '\eb59',
  bold-headphones: '\eb5a',
  bold-routing-2: '\eb5b',
  bold-global: '\eb5c',
  bold-bluetooth: '\eb5d',
  bold-arrange-circle-2: '\eb5e',
  bold-direct-down: '\eb5f',
  bold-archive-book: '\eb60',
  bold-hospital: '\eb61',
  bold-filter-add: '\eb62',
  bold-trend-up: '\eb63',
  bold-mirroring-screen: '\eb64',
  bold-airdrop: '\eb65',
  bold-ranking: '\eb66',
  bold-notification-1: '\eb67',
  bold-empty-wallet-change: '\eb68',
  bold-check: '\eb69',
  bold-chart-success: '\eb6a',
  bold-document: '\eb6b',
  bold-cloud-remove: '\eb6c',
  bold-chart-21: '\eb6d',
  bold-bluetooth-circle: '\eb6e',
  bold-battery-full: '\eb6f',
  bold-category: '\eb70',
  bold-stickynote: '\eb71',
  bold-video-time: '\eb72',
  bold-grid-2: '\eb73',
  bold-ranking-1: '\eb74',
  bold-record: '\eb75',
  bold-music-dashboard: '\eb76',
  ld-scissor-1: '\eb77',
  bold-user-remove: '\eb78',
  bold-receive-square-2: '\eb79',
  bold-call-slash: '\eb7a',
  bold-document-1: '\eb7b',
  bold-cpu: '\eb7c',
  bold-document-favorite: '\eb7d',
  bold-sms-tracking: '\eb7e',
  bold-music-square: '\eb7f',
  bold-ruler: '\eb80',
  bold-buy-crypto: '\eb81',
  bold-gemini-2: '\eb82',
  bold-message-tick: '\eb83',
  bold-emoji-happy: '\eb84',
  bold-save-remove: '\eb85',
  bold-save-2: '\eb86',
  bold-pen-close: '\eb87',
  bold-row-vertical: '\eb88',
  bold-document-previous: '\eb89',
  bold-arrow-circle-left: '\eb8a',
  bold-card-remove-1: '\eb8b',
  bold-house-2: '\eb8c',
  bold-search-normal: '\eb8d',
  bold-stop: '\eb8e',
  bold-flash-circle-1: '\eb8f',
  bold-gameboy: '\eb90',
  bold-like-tag: '\eb91',
  bold-search-status-1: '\eb92',
  bold-gps: '\eb93',
  bold-pen-remove: '\eb94',
  bold-flag: '\eb95',
  bold-teacher: '\eb96',
  bold-convert-3d-cube: '\eb97',
  bold-medal-star: '\eb98',
  bold-user-cirlce-add: '\eb99',
  bold-cake: '\eb9a',
  bold-money-4: '\eb9b',
  bold-code: '\eb9c',
  bold-flash: '\eb9d',
  bold-unlimited: '\eb9e',
  bold-coffee: '\eb9f',
  bold-paperclip-2: '\eba0',
  bold-bucket-circle: '\eba1',
  bold-backward-10-seconds: '\eba2',
  bold-wallet: '\eba3',
  bold-folder-favorite: '\eba4',
  bold-user-minus: '\eba5',
  bold-timer-pause: '\eba6',
  bold-scissor: '\eba7',
  bold-heart-slash: '\eba8',
  bold-grid-1: '\eba9',
  bold-note-add: '\ebaa',
  bold-book: '\ebab',
  bold-grid-edit: '\ebac',
  bold-ticket-2: '\ebad',
  bold-sagittarius: '\ebae',
  bold-devices-1: '\ebaf',
  bold-radio: '\ebb0',
  bold-login: '\ebb1',
  bold-slash: '\ebb2',
  bold-repeate-music: '\ebb3',
  bold-rotate-left-1: '\ebb4',
  bold-shapes: '\ebb5',
  bold-battery-charging: '\ebb6',
  bold-ram-2: '\ebb7',
  bold-message-time: '\ebb8',
  bold-wifi-square: '\ebb9',
  bold-message-question: '\ebba',
  bold-tag-user: '\ebbb',
  bold-card-pos: '\ebbc',
  bold-voice-square: '\ebbd',
  bold-box-1: '\ebbe',
  bold-link-circle: '\ebbf',
  bold-sms: '\ebc0',
  bold-receipt-disscount: '\ebc1',
  bold-discover: '\ebc2',
  bold-add-square: '\ebc3',
  bold-cloud-notif: '\ebc4',
  bold-profile-add: '\ebc5',
  bold-shield-cross: '\ebc6',
  bold-grid-5: '\ebc7',
  bold-note-2: '\ebc8',
  bold-radar-2: '\ebc9',
  bold-global-edit: '\ebca',
  bold-gallery: '\ebcb',
  bold-home-hashtag: '\ebcc',
  bold-direct-up: '\ebcd',
  bold-import: '\ebce',
  bold-size: '\ebcf',
  bold-note: '\ebd0',
  bold-frame-2: '\ebd1',
  bold-align-vertically: '\ebd2',
  bold-kanban: '\ebd3',
  bold-shop-remove: '\ebd4',
  bold-video-circle: '\ebd5',
  bold-code-1: '\ebd6',
  bold-notification-favorite: '\ebd7',
  bold-bookmark-2: '\ebd8',
  bold-backward-5-seconds: '\ebd9',
  bold-textalign-right: '\ebda',
  bold-omega-square: '\ebdb',
  bold-close-circle: '\ebdc',
  bold-frame-3: '\ebdd',
  bold-archive: '\ebde',
  bold-game: '\ebdf',
  bold-note-text: '\ebe0',
  bold-forward-item: '\ebe1',
  bold-global-search: '\ebe2',
  bold-book-saved: '\ebe3',
  bold-volume-cross: '\ebe4',
  bold-status-up: '\ebe5',
  bold-grid-4: '\ebe6',
  bold-mask-1: '\ebe7',
  bold-text-bold: '\ebe8',
  bold-cloud-snow: '\ebe9',
  bold-gallery-favorite: '\ebea',
  bold-row-horizontal: '\ebeb',
  bold-settings: '\ebec',
  bold-minus-square: '\ebed',
  bold-receive-square: '\ebee',
  bold-programming-arrows: '\ebef',
  bold-calendar-tick: '\ebf0',
  bold-backward-15-seconds: '\ebf1',
  bold-driver-2: '\ebf2',
  bold-chart-1: '\ebf3',
  bold-ship: '\ebf4',
  bold-chart-3: '\ebf5',
  bold-3d-rotate: '\ebf6',
  bold-stop-circle: '\ebf7',
  bold-brush: '\ebf8',
  bold-arrow-right: '\ebf9',
  bold-box-2: '\ebfa',
  bold-money-forbidden: '\ebfb',
  bold-grid-6: '\ebfc',
  bold-key: '\ebfd',
  bold-note-1: '\ebfe',
  bold-toggle-off: '\ebff',
  bold-empty-wallet: '\ec00',
  bold-radar-1: '\ec01',
  bold-link: '\ec02',
  bold-receipt-square: '\ec03',
  bold-instagram: '\ec04',
  bold-lamp-on: '\ec05',
  bold-clipboard: '\ec06',
  bold-frame-1: '\ec07',
  bold-coin-1: '\ec08',
  bold-driver-refresh: '\ec09',
  bold-dislike: '\ec0a',
  bold-empty-wallet-tick: '\ec0b',
  bold-money-2: '\ec0c',
  bold-cloud-drizzle: '\ec0d',
  bold-arrow-square-right: '\ec0e',
  bold-money-3: '\ec0f',
  bold-search-zoom-in: '\ec10',
  bold-slider-vertical: '\ec11',
  bold-filter-search: '\ec12',
  bold-bag-2: '\ec13',
  bold-language-circle: '\ec14',
  bold-shopping-bag: '\ec15',
  bold-health: '\ec16',
  bold-buildings: '\ec17',
  bold-call-outgoing: '\ec18',
  bold-box-remove: '\ec19',
  bold-lock: '\ec1a',
  bold-user-tick: '\ec1b',
  bold-mask-2: '\ec1c',
  bold-grid-7: '\ec1d',
  bold-save-minus: '\ec1e',
  bold-profile-2user: '\ec1f',
  bold-video-tick: '\ec20',
  bold-location-tick: '\ec21',
  bold-attach-circle: '\ec22',
  bold-microphone-2: '\ec23',
  bold-filter-tick: '\ec24',
  bold-keyboard: '\ec25',
  bold-path-2: '\ec26',
  bold-chart-2: '\ec27',
  bold-folder-open: '\ec28',
  bold-search-status: '\ec29',
  bold-bubble: '\ec2a',
  bold-sms-search: '\ec2b',
  bold-message-add: '\ec2c',
  bold-warning-2: '\ec2d',
  bold-brush-3: '\ec2e',
  bold-arrow-up-1: '\ec2f',
  bold-lock-slash: '\ec30',
  bold-cpu-setting: '\ec31',
  bold-shield-security: '\ec32',
  bold-calendar-2: '\ec33',
  bold-radar: '\ec34',
  bold-maximize: '\ec35',
  bold-shield-tick: '\ec36',
  bold-magic-star: '\ec37',
  bold-percentage-square: '\ec38',
  bold-mouse-square: '\ec39',
  bold-fatrows: '\ec3a',
  bold-discount-shape: '\ec3b',
  bold-devices: '\ec3c',
  bold-arrow-2: '\ec3d',
  bold-message-circle: '\ec3e',
  bold-pen-tool-2: '\ec3f',
  bold-monitor-mobbile: '\ec40',
  bold-smileys: '\ec41',
  bold-arrow-3: '\ec42',
  bold-ticket-star: '\ec43',
  bold-grammerly: '\ec44',
  bold-user-square: '\ec45',
  bold-airpods: '\ec46',
  bold-transaction-minus: '\ec47',
  bold-mobile-programming: '\ec48',
  bold-book-square: '\ec49',
  bold-music-filter: '\ec4a',
  bold-calendar-circle: '\ec4b',
  bold-home-trend-down: '\ec4c',
  bold-microphone: '\ec4d',
  bold-sms-notification: '\ec4e',
  bold-sidebar-right: '\ec4f',
  bold-map: '\ec50',
  bold-moneys: '\ec51',
  bold-brush-2: '\ec52',
  bold-chart-fail: '\ec53',
  bold-direct-right: '\ec54',
  bold-cloud-plus: '\ec55',
  bold-directbox-send: '\ec56',
  bold-wallet-1: '\ec57',
  bold-hierarchy-3: '\ec58',
  bold-calendar-search: '\ec59',
  bold-wallet-3: '\ec5a',
  bold-music-square-add: '\ec5b',
  bold-arrow-circle-up: '\ec5c',
  bold-calendar-add: '\ec5d',
  bold-align-bottom: '\ec5e',
  bold-arrow-up-2: '\ec5f',
  bold-location-slash: '\ec60',
  bold-briefcase: '\ec61',
  bold-sidebar-top: '\ec62',
  bold-user-tag: '\ec63',
  bold-tag: '\ec64',
  bold-calendar-1: '\ec65',
  bold-text-italic: '\ec66',
  bold-crop: '\ec67',
  bold-call-remove: '\ec68',
  bold-refresh-right-square: '\ec69',
  bold-convertshape-2: '\ec6a',
  bold-blur: '\ec6b',
  bold-export: '\ec6c',
  bold-ticket: '\ec6d',
  bold-brifecase-cross: '\ec6e',
  bold-quote-up-square: '\ec6f',
  bold-candle: '\ec70',
  bold-signpost: '\ec71',
  bold-creative-commons: '\ec72',
  bold-flag-2: '\ec73',
  bold-message-notif: '\ec74',
  bold-camera-slash: '\ec75',
  bold-logout: '\ec76',
  bold-24-support: '\ec77',
  bold-message-search: '\ec78',
  bold-money-tick: '\ec79',
  bold-bag-happy: '\ec7a',
  bold-card-edit: '\ec7b',
  bold-dollar-circle: '\ec7c',
  bold-star-1: '\ec7d',
  bold-arrow-up-3: '\ec7e',
  bold-brush-1: '\ec7f',
  bold-music-square-search: '\ec80',
  bold-music: '\ec81',
  bold-received: '\ec82',
  bold-textalign-justifycenter: '\ec83',
  bold-card-tick-1: '\ec84',
  bold-play-cricle: '\ec85',
  bold-hierarchy-2: '\ec86',
  bold-wallet-2: '\ec87',
  bold-copyright: '\ec88',
  bold-shop: '\ec89',
  bold-home: '\ec8a',
  bold-milk: '\ec8b',
  bold-edit-2: '\ec8c',
  bold-clipboard-tick: '\ec8d',
  bold-forward-5-seconds: '\ec8e',
  bold-arrow-square: '\ec8f',
  bold-like-dislike: '\ec90',
  bold-format-square: '\ec91',
  bold-quote-up: '\ec92',
  bold-sidebar-left: '\ec93',
  bold-heart-search: '\ec94',
  bold-card-receive: '\ec95',
  bold-rotate-right-1: '\ec96',
  bold-home-trend-up: '\ec97',
  bold-message-text-1: '\ec98',
  bold-arrow: '\ec99',
  bold-money-change: '\ec9a',
  bold-user: '\ec9b',
  bold-wallet-search: '\ec9c',
  bold-audio-square: '\ec9d',
  bold-more-circle: '\ec9e',
  bold-translate: '\ec9f',
  bold-document-forward: '\eca0',
  bold-money-time: '\eca1',
  bold-aquarius: '\eca2',
  bold-tag-2: '\eca3',
  bold-map-1: '\eca4',
  bold-document-like: '\eca5',
  bold-chart: '\eca6',
  bold-tick-square: '\eca7',
  bold-password-check: '\eca8',
  bold-presention-chart: '\eca9',
  bold-gps-slash: '\ecaa',
  bold-gallery-tick: '\ecab',
  bold-brush-4: '\ecac',
  bold-path: '\ecad',
  bold-profile-remove: '\ecae',
  bold-import-1: '\ecaf',
  bold-repeat: '\ecb0',
  bold-refresh-square-2: '\ecb1',
  bold-arrange-square: '\ecb2',
  bold-import-3: '\ecb3',
  bold-document-text-1: '\ecb4',
  bold-information: '\ecb5',
  bold-picture-frame: '\ecb6',
  bold-arrow-square-left: '\ecb7',
  bold-play-circle: '\ecb8',
  bold-menu-1: '\ecb9',
  bold-rotate-left: '\ecba',
  bold-receipt-2-1: '\ecbb',
  bold-wind: '\ecbc',
  bold-money-add: '\ecbd',
  bold-note-favorite: '\ecbe',
  bold-trush-square: '\ecbf',
  bold-card-coin: '\ecc0',
  bold-buildings-2: '\ecc1',
  bold-house: '\ecc2',
  bold-card-send: '\ecc3',
  bold-sound: '\ecc4',
  bold-refresh-circle: '\ecc5',
  bold-location-add: '\ecc6',
  bold-gemini: '\ecc7',
  bold-shield-search: '\ecc8',
  bold-archive-minus: '\ecc9',
  bold-timer-start: '\ecca',
  bold-quote-down-circle: '\eccb',
  bold-shapes-1: '\eccc',
  bold-brifecase-timer: '\eccd',
  bold-heart-tick: '\ecce',
  bold-textalign-left: '\eccf',
  bold-ghost: '\ecd0',
  bold-lamp-1: '\ecd1',
  bold-quote-down: '\ecd2',
  bold-battery-disable: '\ecd3',
  bold-arrow-down: '\ecd4',
  bold-search-zoom-in-1: '\ecd5',
  bold-paintbucket: '\ecd6',
  bold-printer-slash: '\ecd7',
  bold-subtitle: '\ecd8',
  bold-pause-circle: '\ecd9',
  bold-receipt: '\ecda',
  bold-like: '\ecdb',
  bold-eraser: '\ecdc',
  bold-musicnote: '\ecdd',
  bold-toggle-on-circle: '\ecde',
  bold-shop-add: '\ecdf',
  bold-clipboard-close: '\ece0',
  bold-task: '\ece1',
  bold-slider-vertical-1: '\ece2',
  bold-driver: '\ece3',
  bold-sun-1: '\ece4',
  bold-toggle-on: '\ece5',
  bold-triangle: '\ece6',
  bold-gallery-remove: '\ece7',
  bold-bag-tick: '\ece8',
  bold-heart-circle: '\ece9',
  bold-directbox-notif: '\ecea',
  bold-arrow-square-up: '\eceb',
  bold-import-2: '\ecec',
  bold-profile-delete: '\eced',
  bold-smart-home: '\ecee',
  bold-microphone-slash-1: '\ecef',
  bold-convertshape: '\ecf0',
  outline-data-2-path1: '\ecf1',
  outline-data-2-path2: '\ecf2',
  outline-data-2-path3: '\ecf3',
  outline-data-2-path4: '\ecf4',
  outline-data-2-path5: '\ecf5',
  outline-data-2-path6: '\ecf6',
  outline-data-2-path7: '\ecf7',
  outline-data-2-path8: '\ecf8',
  outline-heart: '\ecf9',
  outline-calendar-remove: '\ecfa',
  outline-refresh-2: '\ecfb',
  outline-align-left: '\ecfc',
  outline-clipboard-text: '\ecfd',
  outline-send-2: '\ecfe',
  outline-direct-notification: '\ecff',
  outline-card-tick: '\ed00',
  outline-money-remove: '\ed01',
  outline-voice-cricle: '\ed02',
  outline-forward-10-seconds: '\ed03',
  outline-direct-normal: '\ed04',
  outline-weight: '\ed05',
  outline-candle-2: '\ed06',
  outline-moon: '\ed07',
  outline-gallery-export: '\ed08',
  outline-music-circle: '\ed09',
  outline-element-plus: '\ed0a',
  outline-scanning: '\ed0b',
  outline-document-normal: '\ed0c',
  outline-wallet-minus: '\ed0d',
  outline-card-add: '\ed0e',
  outline-3d-cube-scan: '\ed0f',
  outline-minus: '\ed10',
  outline-monitor: '\ed11',
  outline-timer: '\ed12',
  outline-arrow-down-1: '\ed13',
  outline-color-swatch: '\ed14',
  outline-archive-1: '\ed15',
  outline-copy-success: '\ed16',
  outline-alarm: '\ed17',
  outline-folder: '\ed18',
  outline-cards: '\ed19',
  outline-message-remove: '\ed1a',
  outline-search-favorite: '\ed1b',
  outline-bezier: '\ed1c',
  outline-building: '\ed1d',
  outline-graph: '\ed1e',
  outline-slider-horizontal: '\ed1f',
  outline-logout-1: '\ed20',
  outline-document-sketch: '\ed21',
  outline-menu-board: '\ed22',
  outline-mirror: '\ed23',
  outline-blend-2: '\ed24',
  outline-lamp-slash: '\ed25',
  outline-receipt-text: '\ed26',
  outline-man: '\ed27',
  outline-truck-remove: '\ed28',
  outline-layer: '\ed29',
  outline-sms-edit: '\ed2a',
  outline-music-square-remove: '\ed2b',
  outline-routing: '\ed2c',
  outline-login-1: '\ed2d',
  outline-mouse: '\ed2e',
  outline-3d-square: '\ed2f',
  outline-discover-1: '\ed30',
  outline-calculator: '\ed31',
  outline-airplane: '\ed32',
  outline-happyemoji: '\ed33',
  outline-shopping-cart: '\ed34',
  outline-finger-cricle: '\ed35',
  outline-save-add: '\ed36',
  outline-chrome: '\ed37',
  outline-volume-slash: '\ed38',
  outline-bucket-square: '\ed39',
  outline-note-21: '\ed3a',
  outline-security-user: '\ed3b',
  outline-repeate-one: '\ed3c',
  outline-send-1: '\ed3d',
  outline-money-recive: '\ed3e',
  outline-sms-star: '\ed3f',
  outline-search-zoom-out: '\ed40',
  outline-building-4: '\ed41',
  outline-like-shapes: '\ed42',
  outline-menu: '\ed43',
  outline-volume-low-1: '\ed44',
  outline-setting-3: '\ed45',
  outline-maximize-circle: '\ed46',
  outline-receipt-discount: '\ed47',
  outline-strongbox-2: '\ed48',
  outline-component: '\ed49',
  outline-trend-down: '\ed4a',
  outline-favorite-chart: '\ed4b',
  outline-math: '\ed4c',
  outline-screenmirroring: '\ed4d',
  outline-receipt-minus: '\ed4e',
  outline-lock-circle: '\ed4f',
  outline-level: '\ed50',
  outline-document-filter: '\ed51',
  outline-call-add: '\ed52',
  outline-programming-arrow: '\ed53',
  outline-rotate-right: '\ed54',
  outline-note-square: '\ed55',
  outline-status: '\ed56',
  outline-shield-slash: '\ed57',
  outline-arrow-down-2: '\ed58',
  outline-archive-2: '\ed59',
  outline-bookmark: '\ed5a',
  outline-blend: '\ed5b',
  outline-forward: '\ed5c',
  outline-call-minus: '\ed5d',
  outline-box-tick: '\ed5e',
  outline-setting-2: '\ed5f',
  outline-pause: '\ed60',
  outline-cpu-charge: '\ed61',
  outline-diamonds: '\ed62',
  outline-mini-music-sqaure: '\ed63',
  outline-cup: '\ed64',
  outline-shuffle: '\ed65',
  outline-lovely: '\ed66',
  outline-cloud: '\ed67',
  outline-task-square: '\ed68',
  outline-wallet-money: '\ed69',
  outline-heart-edit: '\ed6a',
  outline-clipboard-import: '\ed6b',
  outline-close-square: '\ed6c',
  outline-receipt-search: '\ed6d',
  outline-omega-circle: '\ed6e',
  outline-profile-circle: '\ed6f',
  outline-scanner: '\ed70',
  outline-volume-low: '\ed71',
  outline-message-minus: '\ed72',
  outline-keyboard-open: '\ed73',
  outline-microphone-slash: '\ed74',
  outline-ram: '\ed75',
  outline-data: '\ed76',
  outline-buliding: '\ed77',
  outline-gas-station: '\ed78',
  outline-arrow-left: '\ed79',
  outline-lamp-charge: '\ed7a',
  outline-calendar: '\ed7b',
  outline-video-slash: '\ed7c',
  outline-arrow-swap: '\ed7d',
  outline-medal: '\ed7e',
  outline-link-square: '\ed7f',
  outline-pharagraphspacing: '\ed80',
  outline-money-send: '\ed81',
  outline-add-circle: '\ed82',
  outline-filter: '\ed83',
  outline-box-time: '\ed84',
  outline-bitcoin-convert: '\ed85',
  outline-activity: '\ed86',
  outline-tree: '\ed87',
  outline-security-safe: '\ed88',
  outline-filter-square: '\ed89',
  outline-external-drive: '\ed8a',
  outline-video-vertical: '\ed8b',
  outline-video: '\ed8c',
  outline-undo: '\ed8d',
  outline-messages-1: '\ed8e',
  outline-sort: '\ed8f',
  outline-microscope: '\ed90',
  outline-folder-add: '\ed91',
  outline-colors-square: '\ed92',
  outline-money: '\ed93',
  outline-arrow-up: '\ed94',
  outline-video-horizontal: '\ed95',
  outline-document-upload: '\ed96',
  outline-video-square: '\ed97',
  outline-arrow-square-down: '\ed98',
  outline-share: '\ed99',
  outline-tag-right: '\ed9a',
  outline-language-square: '\ed9b',
  outline-clock-1: '\ed9c',
  outline-heart-remove: '\ed9d',
  outline-forward-15-seconds: '\ed9e',
  outline-messages-3: '\ed9f',
  outline-car: '\eda0',
  outline-setting-5: '\eda1',
  outline-gift: '\eda2',
  outline-glass-1: '\eda3',
  outline-arrange-square-2: '\eda4',
  outline-search-normal-1: '\eda5',
  outline-receipt-edit: '\eda6',
  outline-electricity: '\eda7',
  outline-device-message: '\eda8',
  outline-profile-tick: '\eda9',
  outline-location: '\edaa',
  outline-path-square: '\edab',
  outline-wallet-remove: '\edac',
  outline-bluetooth-rectangle: '\edad',
  outline-attach-square: '\edae',
  outline-headphone: '\edaf',
  outline-personalcard: '\edb0',
  outline-cloud-connection: '\edb1',
  outline-gallery-edit: '\edb2',
  outline-mobile: '\edb3',
  outline-wallet-add: '\edb4',
  outline-eye: '\edb5',
  outline-call: '\edb6',
  outline-direct-send: '\edb7',
  outline-repeat-circle: '\edb8',
  outline-message-programming: '\edb9',
  outline-send-sqaure-2: '\edba',
  outline-courthouse: '\edbb',
  outline-slider-horizontal-1: '\edbc',
  outline-textalign-justifyright: '\edbd',
  outline-call-incoming: '\edbe',
  outline-wind-2: '\edbf',
  outline-clock: '\edc0',
  outline-command: '\edc1',
  outline-setting-4: '\edc2',
  outline-messages-2: '\edc3',
  outline-building-3: '\edc4',
  outline-ticket-discount: '\edc5',
  outline-forbidden: '\edc6',
  outline-hierarchy: '\edc7',
  outline-play-remove: '\edc8',
  outline-simcard: '\edc9',
  outline-bitcoin-refresh: '\edca',
  outline-music-library-2: '\edcb',
  outline-search-zoom-out-1: '\edcc',
  outline-3square: '\edcd',
  outline-home-1: '\edce',
  outline-timer-1: '\edcf',
  outline-call-received: '\edd0',
  outline-magicpen: '\edd1',
  outline-global-refresh: '\edd2',
  outline-receipt-item: '\edd3',
  outline-arrow-swap-horizontal: '\edd4',
  outline-video-play: '\edd5',
  outline-lock-1: '\edd6',
  outline-judge: '\edd7',
  outline-frame: '\edd8',
  outline-bank: '\edd9',
  outline-location-minus: '\edda',
  outline-woman: '\eddb',
  outline-eraser-1: '\eddc',
  outline-designtools: '\eddd',
  outline-truck-time: '\edde',
  outline-forward-square: '\eddf',
  outline-card-remove: '\ede0',
  outline-music-play: '\ede1',
  outline-star-slash: '\ede2',
  outline-notification-status: '\ede3',
  outline-forbidden-2: '\ede4',
  outline-unlock: '\ede5',
  outline-send-square: '\ede6',
  outline-flash-slash: '\ede7',
  outline-arrow-left-1: '\ede8',
  outline-export-2: '\ede9',
  outline-folder-cloud: '\edea',
  outline-export-3: '\edeb',
  outline-pet: '\edec',
  outline-message-edit: '\eded',
  outline-colorfilter: '\edee',
  outline-volume-high: '\edef',
  outline-lifebuoy: '\edf0',
  outline-gallery-add: '\edf1',
  outline-emoji-normal: '\edf2',
  outline-receipt-2: '\edf3',
  outline-wallet-add-1: '\edf4',
  outline-strongbox: '\edf5',
  outline-bluetooth-2: '\edf6',
  outline-home-wifi: '\edf7',
  outline-maximize-21: '\edf8',
  outline-next: '\edf9',
  outline-percentage-circle: '\edfa',
  outline-edit: '\edfb',
  outline-mouse-circle: '\edfc',
  outline-arrow-right-4: '\edfd',
  outline-more-2: '\edfe',
  outline-folder-cross: '\edff',
  outline-message-square: '\ee00',
  outline-arrow-circle-down: '\ee01',
  outline-cloud-sunny: '\ee02',
  outline-danger: '\ee03',
  outline-bag-cross-1: '\ee04',
  outline-bag-timer: '\ee05',
  outline-home-2: '\ee06',
  outline-sun: '\ee07',
  outline-clipboard-export: '\ee08',
  outline-cd: '\ee09',
  outline-folder-2: '\ee0a',
  outline-bitcoin-card: '\ee0b',
  outline-star: '\ee0c',
  outline-security-time: '\ee0d',
  outline-hashtag: '\ee0e',
  outline-volume-up: '\ee0f',
  outline-weight-1: '\ee10',
  outline-computing: '\ee11',
  outline-grid-9: '\ee12',
  outline-element-equal: '\ee13',
  outline-recovery-convert: '\ee14',
  outline-scan-barcode: '\ee15',
  outline-dollar-square: '\ee16',
  outline-direct-left: '\ee17',
  outline-element-3: '\ee18',
  outline-video-octagon: '\ee19',
  outline-maximize-4: '\ee1a',
  outline-record-circle: '\ee1b',
  outline-monitor-recorder: '\ee1c',
  outline-pen-add: '\ee1d',
  outline-refresh-left-square: '\ee1e',
  outline-battery-3full: '\ee1f',
  outline-trash: '\ee20',
  outline-export-1: '\ee21',
  outline-arrow-left-2: '\ee22',
  outline-arrow-left-3: '\ee23',
  outline-textalign-center: '\ee24',
  outline-firstline: '\ee25',
  outline-bus: '\ee26',
  outline-battery-empty-1: '\ee27',
  outline-cloud-minus: '\ee28',
  outline-grid-eraser: '\ee29',
  outline-glass: '\ee2a',
  outline-element-2: '\ee2b',
  outline-back-square: '\ee2c',
  outline-text-underline: '\ee2d',
  outline-emoji-sad: '\ee2e',
  outline-document-download: '\ee2f',
  outline-receipt-1: '\ee30',
  outline-bucket: '\ee31',
  outline-more: '\ee32',
  outline-security: '\ee33',
  outline-arrow-bottom: '\ee34',
  outline-grid-8: '\ee35',
  outline-music-playlist: '\ee36',
  outline-note-remove: '\ee37',
  outline-pen-tool: '\ee38',
  outline-quote-up-circle: '\ee39',
  outline-discount-circle: '\ee3a',
  outline-user-search: '\ee3b',
  outline-truck: '\ee3c',
  outline-folder-minus: '\ee3d',
  outline-cloud-add: '\ee3e',
  outline-autobrightness: '\ee3f',
  outline-redo: '\ee40',
  outline-hashtag-down: '\ee41',
  outline-more-square: '\ee42',
  outline-printer: '\ee43',
  outline-link-2: '\ee44',
  outline-camera: '\ee45',
  outline-document-text: '\ee46',
  outline-battery-empty: '\ee47',
  outline-brifecase-tick: '\ee48',
  outline-arrow-right-3: '\ee49',
  outline-convert: '\ee4a',
  outline-archive-slash: '\ee4b',
  outline-speaker: '\ee4c',
  outline-play-add: '\ee4d',
  outline-simcard-2: '\ee4e',
  outline-empty-wallet-add: '\ee4f',
  outline-scroll: '\ee50',
  outline-tick-circle: '\ee51',
  outline-tag-cross: '\ee52',
  outline-bill: '\ee53',
  outline-mouse-1: '\ee54',
  outline-user-octagon: '\ee55',
  outline-smart-car: '\ee56',
  outline-direct: '\ee57',
  outline-barcode: '\ee58',
  outline-send: '\ee59',
  outline-airplane-square: '\ee5a',
  outline-user-edit: '\ee5b',
  outline-maximize-1: '\ee5c',
  outline-diagram: '\ee5d',
  outline-notification-bing: '\ee5e',
  outline-like-1: '\ee5f',
  outline-verify: '\ee60',
  outline-coin: '\ee61',
  outline-driving: '\ee62',
  outline-text-block: '\ee63',
  outline-notification: '\ee64',
  outline-filter-edit: '\ee65',
  outline-filter-remove: '\ee66',
  outline-directbox-receive: '\ee67',
  outline-toggle-off-circle: '\ee68',
  outline-backward: '\ee69',
  outline-play: '\ee6a',
  outline-eye-slash: '\ee6b',
  outline-rulerpen: '\ee6c',
  outline-message-add-1: '\ee6d',
  outline-broom: '\ee6e',
  outline-crown: '\ee6f',
  outline-message-2: '\ee70',
  outline-cloud-change: '\ee71',
  outline-mask: '\ee72',
  outline-messages: '\ee73',
  outline-empty-wallet-remove: '\ee74',
  outline-format-circle: '\ee75',
  outline-location-cross: '\ee76',
  outline-box: '\ee77',
  outline-box-search: '\ee78',
  outline-trade: '\ee79',
  outline-award: '\ee7a',
  outline-sticker: '\ee7b',
  outline-video-remove: '\ee7c',
  outline-airpod: '\ee7d',
  outline-message: '\ee7e',
  outline-document-copy: '\ee7f',
  outline-safe-home: '\ee80',
  outline-textalign-justifyleft: '\ee81',
  outline-message-favorite: '\ee82',
  outline-story: '\ee83',
  outline-flash-circle: '\ee84',
  outline-paperclip: '\ee85',
  outline-arrow-right-2: '\ee86',
  outline-crown-1: '\ee87',
  outline-previous: '\ee88',
  outline-volume-mute: '\ee89',
  outline-3dcube: '\ee8a',
  outline-grid-lock: '\ee8b',
  outline-setting: '\ee8c',
  outline-message-text: '\ee8d',
  outline-heart-add: '\ee8e',
  outline-command-square: '\ee8f',
  outline-document-cloud: '\ee90',
  outline-link-1: '\ee91',
  outline-direct-inbox: '\ee92',
  outline-security-card: '\ee93',
  outline-cloud-lightning: '\ee94',
  outline-scan: '\ee95',
  outline-minus-cirlce: '\ee96',
  outline-lamp: '\ee97',
  outline-search-favorite-1: '\ee98',
  outline-simcard-1: '\ee99',
  outline-flash-1: '\ee9a',
  outline-reserve: '\ee9b',
  outline-truck-tick: '\ee9c',
  outline-card-slash: '\ee9d',
  outline-folder-connection: '\ee9e',
  outline-calendar-edit: '\ee9f',
  outline-maximize-2: '\eea0',
  outline-image: '\eea1',
  outline-align-right: '\eea2',
  outline-text: '\eea3',
  outline-gallery-import: '\eea4',
  outline-hashtag-1: '\eea5',
  outline-chart-square: '\eea6',
  outline-link-21: '\eea7',
  outline-cloud-cross: '\eea8',
  outline-refresh: '\eea9',
  outline-hierarchy-square: '\eeaa',
  outline-box-add: '\eeab',
  outline-hierarchy-square-2: '\eeac',
  outline-align-horizontally: '\eead',
  outline-hierarchy-square-3: '\eeae',
  outline-arrange-circle: '\eeaf',
  outline-sun-fog: '\eeb0',
  outline-bag: '\eeb1',
  outline-element-4: '\eeb2',
  outline-maximize-3: '\eeb3',
  outline-call-calling: '\eeb4',
  outline-bag-tick-2: '\eeb5',
  outline-receipt-add: '\eeb6',
  outline-copy: '\eeb7',
  outline-watch-status: '\eeb8',
  outline-smallcaps: '\eeb9',
  outline-arrow-right-1: '\eeba',
  outline-ticket-expired: '\eebb',
  outline-bag-cross: '\eebc',
  outline-cloud-fog: '\eebd',
  outline-info-circle: '\eebe',
  outline-quote-down-square: '\eebf',
  outline-people: '\eec0',
  outline-convert-card: '\eec1',
  outline-book-1: '\eec2',
  outline-document-code: '\eec3',
  outline-speedometer: '\eec4',
  outline-truck-fast: '\eec5',
  outline-add: '\eec6',
  outline-user-add: '\eec7',
  outline-sidebar-bottom: '\eec8',
  outline-key-square: '\eec9',
  outline-code-circle: '\eeca',
  outline-archive-tick: '\eecb',
  outline-grid-3: '\eecc',
  outline-archive-add: '\eecd',
  outline-arrow-circle-right: '\eece',
  outline-slider: '\eecf',
  outline-wallet-check: '\eed0',
  outline-backward-item: '\eed1',
  outline-directbox-default: '\eed2',
  outline-video-add: '\eed3',
  outline-card: '\eed4',
  outline-watch: '\eed5',
  outline-document-code-2: '\eed6',
  outline-route-square: '\eed7',
  outline-hashtag-up: '\eed8',
  outline-wifi: '\eed9',
  outline-main-component: '\eeda',
  outline-category-2: '\eedb',
  outline-empty-wallet-time: '\eedc',
  outline-frame-4: '\eedd',
  outline-gallery-slash: '\eede',
  outline-finger-scan: '\eedf',
  outline-additem: '\eee0',
  outline-headphones: '\eee1',
  outline-routing-2: '\eee2',
  outline-global: '\eee3',
  outline-bluetooth: '\eee4',
  outline-arrange-circle-2: '\eee5',
  outline-direct-down: '\eee6',
  outline-archive-book: '\eee7',
  outline-hospital: '\eee8',
  outline-filter-add: '\eee9',
  outline-trend-up: '\eeea',
  outline-mirroring-screen: '\eeeb',
  outline-airdrop: '\eeec',
  outline-ranking: '\eeed',
  outline-notification-1: '\eeee',
  outline-empty-wallet-change: '\eeef',
  outline-check: '\eef0',
  outline-chart-success: '\eef1',
  outline-document: '\eef2',
  outline-cloud-remove: '\eef3',
  outline-chart-21: '\eef4',
  outline-bluetooth-circle: '\eef5',
  outline-battery-full: '\eef6',
  outline-category: '\eef7',
  outline-stickynote: '\eef8',
  outline-video-time: '\eef9',
  outline-grid-2: '\eefa',
  outline-ranking-1: '\eefb',
  outline-record: '\eefc',
  outline-music-dashboard: '\eefd',
  outline-scissor-1: '\eefe',
  outline-user-remove: '\eeff',
  outline-receive-square-2: '\ef00',
  outline-call-slash: '\ef01',
  outline-document-1: '\ef02',
  outline-cpu: '\ef03',
  outline-document-favorite: '\ef04',
  outline-sms-tracking: '\ef05',
  outline-music-square: '\ef06',
  outline-ruler: '\ef07',
  outline-buy-crypto: '\ef08',
  outline-gemini-2: '\ef09',
  outline-message-tick: '\ef0a',
  outline-emoji-happy: '\ef0b',
  outline-save-remove: '\ef0c',
  outline-save-2: '\ef0d',
  outline-pen-close: '\ef0e',
  outline-row-vertical: '\ef0f',
  outline-document-previous: '\ef10',
  outline-arrow-circle-left: '\ef11',
  outline-card-remove-1: '\ef12',
  outline-house-2: '\ef13',
  outline-search-normal: '\ef14',
  outline-stop: '\ef15',
  outline-flash-circle-1: '\ef16',
  outline-gameboy: '\ef17',
  outline-like-tag: '\ef18',
  outline-search-status-1: '\ef19',
  outline-gps: '\ef1a',
  outline-pen-remove: '\ef1b',
  outline-flag: '\ef1c',
  outline-teacher: '\ef1d',
  outline-convert-3d-cube: '\ef1e',
  outline-medal-star: '\ef1f',
  outline-user-cirlce-add: '\ef20',
  outline-icon: '\ef21',
  outline-cake: '\ef22',
  outline-money-4: '\ef23',
  outline-code: '\ef24',
  outline-flash: '\ef25',
  outline-unlimited: '\ef26',
  outline-coffee: '\ef27',
  outline-paperclip-2: '\ef28',
  outline-bucket-circle: '\ef29',
  outline-backward-10-seconds: '\ef2a',
  outline-wallet: '\ef2b',
  outline-folder-favorite: '\ef2c',
  outline-user-minus: '\ef2d',
  outline-timer-pause: '\ef2e',
  outline-scissor: '\ef2f',
  outline-heart-slash: '\ef30',
  outline-grid-1: '\ef31',
  outline-note-add: '\ef32',
  outline-book: '\ef33',
  outline-grid-edit: '\ef34',
  outline-ticket-2: '\ef35',
  outline-sagittarius: '\ef36',
  outline-devices-1: '\ef37',
  outline-radio: '\ef38',
  outline-login: '\ef39',
  outline-slash: '\ef3a',
  outline-repeate-music: '\ef3b',
  outline-rotate-left-1: '\ef3c',
  outline-shapes: '\ef3d',
  outline-battery-charging: '\ef3e',
  outline-ram-2: '\ef3f',
  outline-message-time: '\ef40',
  outline-wifi-square: '\ef41',
  outline-message-question: '\ef42',
  outline-tag-user: '\ef43',
  outline-card-pos: '\ef44',
  outline-voice-square: '\ef45',
  outline-box-1: '\ef46',
  outline-link-circle: '\ef47',
  outline-sms: '\ef48',
  outline-receipt-disscount: '\ef49',
  outline-discover: '\ef4a',
  outline-add-square: '\ef4b',
  outline-cloud-notif: '\ef4c',
  outline-profile-add: '\ef4d',
  outline-shield-cross: '\ef4e',
  outline-grid-5: '\ef4f',
  outline-note-2: '\ef50',
  outline-radar-2: '\ef51',
  outline-global-edit: '\ef52',
  outline-gallery: '\ef53',
  outline-home-hashtag: '\ef54',
  outline-direct-up: '\ef55',
  outline-import: '\ef56',
  outline-size: '\ef57',
  outline-note: '\ef58',
  outline-frame-2: '\ef59',
  outline-align-vertically: '\ef5a',
  outline-kanban: '\ef5b',
  outline-shop-remove: '\ef5c',
  outline-video-circle: '\ef5d',
  outline-shield: '\ef5e',
  outline-code-1: '\ef5f',
  outline-notification-favorite: '\ef60',
  outline-bookmark-2: '\ef61',
  outline-backward-5-seconds: '\ef62',
  outline-textalign-right: '\ef63',
  outline-omega-square: '\ef64',
  outline-close-circle: '\ef65',
  outline-frame-3: '\ef66',
  outline-archive: '\ef67',
  outline-game: '\ef68',
  outline-note-text: '\ef69',
  outline-forward-item: '\ef6a',
  outline-global-search: '\ef6b',
  outline-book-saved: '\ef6c',
  outline-volume-cross: '\ef6d',
  outline-status-up: '\ef6e',
  outline-grid-4: '\ef6f',
  outline-mask-1: '\ef70',
  outline-text-bold: '\ef71',
  outline-cloud-snow: '\ef72',
  outline-gallery-favorite: '\ef73',
  outline-row-horizontal: '\ef74',
  outline-settings: '\ef75',
  outline-minus-square: '\ef76',
  outline-receive-square: '\ef77',
  outline-programming-arrows: '\ef78',
  outline-calendar-tick: '\ef79',
  outline-backward-15-seconds: '\ef7a',
  outline-driver-2: '\ef7b',
  outline-chart-1: '\ef7c',
  outline-ship: '\ef7d',
  outline-chart-3: '\ef7e',
  outline-3d-rotate: '\ef7f',
  outline-stop-circle: '\ef80',
  outline-brush: '\ef81',
  outline-arrow-right: '\ef82',
  outline-box-2: '\ef83',
  outline-money-forbidden: '\ef84',
  outline-grid-6: '\ef85',
  outline-key: '\ef86',
  outline-note-1: '\ef87',
  outline-toggle-off: '\ef88',
  outline-empty-wallet: '\ef89',
  outline-radar-1: '\ef8a',
  outline-link: '\ef8b',
  outline-receipt-square: '\ef8c',
  outline-instagram: '\ef8d',
  outline-lamp-on: '\ef8e',
  outline-clipboard: '\ef8f',
  outline-frame-1: '\ef90',
  outline-coin-1: '\ef91',
  outline-driver-refresh: '\ef92',
  outline-dislike: '\ef93',
  outline-empty-wallet-tick: '\ef94',
  outline-money-2: '\ef95',
  outline-cloud-drizzle: '\ef96',
  outline-money-3: '\ef97',
  outline-search-zoom-in: '\ef98',
  outline-slider-vertical: '\ef99',
  outline-filter-search: '\ef9a',
  outline-bag-2: '\ef9b',
  outline-language-circle: '\ef9c',
  outline-shopping-bag: '\ef9d',
  outline-health: '\ef9e',
  outline-buildings: '\ef9f',
  outline-call-outgoing: '\efa0',
  outline-box-remove: '\efa1',
  outline-lock: '\efa2',
  outline-user-tick: '\efa3',
  outline-mask-2: '\efa4',
  outline-grid-7: '\efa5',
  outline-save-minus: '\efa6',
  outline-profile-2user: '\efa7',
  outline-video-tick: '\efa8',
  outline-location-tick: '\efa9',
  outline-attach-circle: '\efaa',
  outline-microphone-2: '\efab',
  outline-filter-tick: '\efac',
  outline-notification-circle: '\efad',
  outline-keyboard: '\efae',
  outline-path-2: '\efaf',
  outline-chart-2: '\efb0',
  outline-folder-open: '\efb1',
  outline-search-status: '\efb2',
  outline-bubble: '\efb3',
  outline-sms-search: '\efb4',
  outline-message-add: '\efb5',
  outline-warning-2: '\efb6',
  outline-brush-3: '\efb7',
  outline-arrow-up-1: '\efb8',
  outline-lock-slash: '\efb9',
  outline-cpu-setting: '\efba',
  outline-calendar-2: '\efbb',
  outline-radar: '\efbc',
  outline-maximize: '\efbd',
  outline-shield-tick: '\efbe',
  outline-magic-star: '\efbf',
  outline-percentage-square: '\efc0',
  outline-mouse-square: '\efc1',
  outline-fatrows: '\efc2',
  outline-discount-shape: '\efc3',
  outline-devices: '\efc4',
  outline-arrow-2: '\efc5',
  outline-message-circle: '\efc6',
  outline-pen-tool-2: '\efc7',
  outline-monitor-mobbile: '\efc8',
  outline-smileys: '\efc9',
  outline-arrow-3: '\efca',
  outline-ticket-star: '\efcb',
  outline-grammerly: '\efcc',
  outline-user-square: '\efcd',
  outline-airpods: '\efce',
  outline-transaction-minus: '\efcf',
  outline-mobile-programming: '\efd0',
  outline-book-square: '\efd1',
  outline-music-filter: '\efd2',
  outline-calendar-circle: '\efd3',
  outline-home-trend-down: '\efd4',
  outline-microphone: '\efd5',
  outline-sms-notification: '\efd6',
  outline-sidebar-right: '\efd7',
  outline-map: '\efd8',
  outline-moneys: '\efd9',
  outline-brush-2: '\efda',
  outline-chart-fail: '\efdb',
  outline-direct-right: '\efdc',
  outline-cloud-plus: '\efdd',
  outline-directbox-send: '\efde',
  outline-wallet-1: '\efdf',
  outline-hierarchy-3: '\efe0',
  outline-calendar-search: '\efe1',
  outline-wallet-3: '\efe2',
  outline-music-square-add: '\efe3',
  outline-arrow-circle-up: '\efe4',
  outline-calendar-add: '\efe5',
  outline-align-bottom: '\efe6',
  outline-arrow-up-2: '\efe7',
  outline-location-slash: '\efe8',
  outline-briefcase: '\efe9',
  outline-sidebar-top: '\efea',
  outline-user-tag: '\efeb',
  outline-tag: '\efec',
  outline-calendar-1: '\efed',
  outline-text-italic: '\efee',
  outline-crop: '\efef',
  outline-call-remove: '\eff0',
  outline-refresh-right-square: '\eff1',
  outline-convertshape-2: '\eff2',
  outline-blur: '\eff3',
  outline-export: '\eff4',
  outline-ticket: '\eff5',
  outline-brifecase-cross: '\eff6',
  outline-quote-up-square: '\eff7',
  outline-candle: '\eff8',
  outline-signpost: '\eff9',
  outline-creative-commons: '\effa',
  outline-flag-2: '\effb',
  outline-message-notif: '\effc',
  outline-camera-slash: '\effd',
  outline-logout: '\effe',
  outline-24-support: '\efff',
  outline-message-search: '\f000',
  outline-money-tick: '\f001',
  outline-bag-happy: '\f002',
  outline-card-edit: '\f003',
  outline-dollar-circle: '\f004',
  outline-star-1: '\f005',
  outline-arrow-up-3: '\f006',
  outline-brush-1: '\f007',
  outline-music-square-search: '\f008',
  outline-music: '\f009',
  outline-received: '\f00a',
  outline-textalign-justifycenter: '\f00b',
  outline-card-tick-1: '\f00c',
  outline-play-cricle: '\f00d',
  outline-hierarchy-2: '\f00e',
  outline-wallet-2: '\f00f',
  outline-copyright: '\f010',
  outline-shop: '\f011',
  outline-home: '\f012',
  outline-milk: '\f013',
  outline-edit-2: '\f014',
  outline-clipboard-tick: '\f015',
  outline-forward-5-seconds: '\f016',
  outline-arrow-square: '\f017',
  outline-like-dislike: '\f018',
  outline-format-square: '\f019',
  outline-quote-up: '\f01a',
  outline-sidebar-left: '\f01b',
  outline-heart-search: '\f01c',
  outline-card-receive: '\f01d',
  outline-rotate-right-1: '\f01e',
  outline-home-trend-up: '\f01f',
  outline-message-text-1: '\f020',
  outline-arrow: '\f021',
  outline-money-change: '\f022',
  outline-user: '\f023',
  outline-wallet-search: '\f024',
  outline-audio-square: '\f025',
  outline-more-circle: '\f026',
  outline-translate: '\f027',
  outline-document-forward: '\f028',
  outline-money-time: '\f029',
  outline-aquarius: '\f02a',
  outline-tag-2: '\f02b',
  outline-map-1: '\f02c',
  outline-document-like: '\f02d',
  outline-chart: '\f02e',
  outline-tick-square: '\f02f',
  outline-password-check: '\f030',
  outline-presention-chart: '\f031',
  outline-gps-slash: '\f032',
  outline-gallery-tick: '\f033',
  outline-brush-4: '\f034',
  outline-path: '\f035',
  outline-profile-remove: '\f036',
  outline-import-1: '\f037',
  outline-repeat: '\f038',
  outline-refresh-square-2: '\f039',
  outline-arrange-square: '\f03a',
  outline-document-text-1: '\f03b',
  outline-information: '\f03c',
  outline-picture-frame: '\f03d',
  outline-arrow-square-left: '\f03e',
  outline-play-circle: '\f03f',
  outline-menu-1: '\f040',
  outline-rotate-left: '\f041',
  outline-receipt-2-1: '\f042',
  outline-wind: '\f043',
  outline-money-add: '\f044',
  outline-note-favorite: '\f045',
  outline-trush-square: '\f046',
  outline-card-coin: '\f047',
  outline-buildings-2: '\f048',
  outline-house: '\f049',
  outline-card-send: '\f04a',
  outline-sound: '\f04b',
  outline-refresh-circle: '\f04c',
  outline-location-add: '\f04d',
  outline-gemini: '\f04e',
  outline-shield-search: '\f04f',
  outline-archive-minus: '\f050',
  outline-timer-start: '\f051',
  outline-quote-down-circle: '\f052',
  outline-shapes-1: '\f053',
  outline-brifecase-timer: '\f054',
  outline-heart-tick: '\f055',
  outline-textalign-left: '\f056',
  outline-ghost: '\f057',
  outline-lamp-1: '\f058',
  outline-quote-down: '\f059',
  outline-battery-disable: '\f05a',
  outline-arrow-down: '\f05b',
  outline-search-zoom-in-1: '\f05c',
  outline-paintbucket: '\f05d',
  outline-printer-slash: '\f05e',
  outline-subtitle: '\f05f',
  outline-pause-circle: '\f060',
  outline-receipt: '\f061',
  outline-like: '\f062',
  outline-eraser: '\f063',
  outline-musicnote: '\f064',
  outline-toggle-on-circle: '\f065',
  outline-shop-add: '\f066',
  outline-clipboard-close: '\f067',
  outline-task: '\f068',
  outline-slider-vertical-1: '\f069',
  outline-driver: '\f06a',
  outline-sun-1: '\f06b',
  outline-toggle-on: '\f06c',
  outline-triangle: '\f06d',
  outline-gallery-remove: '\f06e',
  outline-bag-tick: '\f06f',
  outline-heart-circle: '\f070',
  outline-directbox-notif: '\f071',
  outline-arrow-square-up: '\f072',
  outline-import-2: '\f073',
  outline-profile-delete: '\f074',
  outline-smart-home: '\f075',
  outline-microphone-slash-1: '\f076',
  outline-convertshape: '\f077',
  outline-crypto-theta-theta: '\f078',
  outline-crypto-vuesax: '\f079',
  outline-crypto-aave-aave: '\f07a',
  outline-crypto-litecoinltc: '\f07b',
  outline-crypto-educare-ekt: '\f07c',
  outline-crypto-apple: '\f07d',
  outline-crypto-icon-1: '\f07e',
  outline-crypto-hex-hex: '\f07f',
  outline-crypto-messenger: '\f080',
  outline-crypto-slack: '\f081',
  outline-crypto-xrp-xrp: '\f082',
  outline-crypto-framer: '\f083',
  outline-crypto-ethereum-classic-etc: '\f084',
  outline-crypto-monero-xmr: '\f085',
  outline-crypto-nem-xem: '\f086',
  outline-crypto-trello: '\f087',
  outline-crypto-enjin-coin-enj: '\f088',
  outline-crypto-dribbble: '\f089',
  outline-crypto-vibe-vibe: '\f08a',
  outline-crypto-illustrator: '\f08b',
  outline-crypto-html-3: '\f08c',
  outline-crypto-youtube: '\f08d',
  outline-crypto-google-paly: '\f08e',
  outline-crypto-thorchain-rune: '\f08f',
  outline-crypto-polyswarm-nct: '\f090',
  outline-crypto-windows: '\f091',
  outline-crypto-twitch: '\f092',
  outline-crypto-okb-okb: '\f093',
  outline-crypto-stacks-stx: '\f094',
  outline-crypto-photoshop: '\f095',
  outline-crypto-tether-usdt: '\f096',
  outline-crypto-celo-celo: '\f097',
  outline-crypto-google: '\f098',
  outline-crypto-whatsapp: '\f099',
  outline-crypto-ontology-ont: '\f09a',
  outline-crypto-be: '\f09b',
  outline-crypto-facebook: '\f09c',
  outline-crypto-html-5: '\f09d',
  outline-crypto-paypal: '\f09e',
  outline-crypto-velas-vlx: '\f09f',
  outline-crypto-bitcoin-btc: '\f0a0',
  outline-crypto-dash-dash: '\f0a1',
  outline-crypto-binance-usd-busd: '\f0a2',
  outline-crypto-avalanche-avax: '\f0a3',
  outline-crypto-ocean-protocol-ocean: '\f0a4',
  outline-crypto-snapchat: '\f0a5',
  outline-crypto-maker-mkr: '\f0a6',
  outline-crypto-kyber-network-knc: '\f0a7',
  outline-crypto-binance-coin-bnb: '\f0a8',
  outline-crypto-trontron-trx: '\f0a9',
  outline-crypto-java-script: '\f0aa',
  outline-crypto-spotify: '\f0ab',
  outline-crypto-ethereum-eth: '\f0ac',
  outline-crypto-xd: '\f0ad',
  outline-crypto-harmony-one: '\f0ae',
  outline-crypto-decred-dcr: '\f0af',
  outline-crypto-polkadot-dot: '\f0b0',
  outline-crypto-iost-iost: '\f0b1',
  outline-crypto-icon: '\f0b2',
  outline-crypto-ankr-ankr: '\f0b3',
  outline-crypto-nexo-nexo: '\f0b4',
  outline-crypto-zel-zel: '\f0b5',
  outline-crypto-augur-rep: '\f0b6',
  outline-crypto-blogger: '\f0b7',
  outline-crypto-python: '\f0b8',
  outline-crypto-dai-dai: '\f0b9',
  outline-crypto-autonio-niox: '\f0ba',
  outline-crypto-chainlink-link: '\f0bb',
  outline-crypto-zoom: '\f0bc',
  outline-crypto-emercoin-emc: '\f0bd',
  outline-crypto-wing-wing: '\f0be',
  outline-crypto-quant-qnt: '\f0bf',
  outline-crypto-ftx-token-ftt: '\f0c0',
  outline-crypto-icon-icx: '\f0c1',
  outline-crypto-usd-coin-usdc: '\f0c2',
  outline-crypto-the-graph-grt: '\f0c3',
  outline-crypto-dent-dent: '\f0c4',
  outline-crypto-wanchain-wan: '\f0c5',
  outline-crypto-hedera-hashgraph-hbar: '\f0c6',
  outline-crypto-civic-cvc: '\f0c7',
  outline-crypto-figma-1: '\f0c8',
  outline-crypto-ui8: '\f0c9',
  outline-crypto-tenx-pay: '\f0ca',
  outline-crypto-huobi-token-ht: '\f0cb',
  outline-crypto-google-drive: '\f0cc',
  outline-crypto-wanchain-wan-1: '\f0cd',
  outline-crypto-siacoin-sc: '\f0ce',
  outline-crypto-js: '\f0cf',
  outline-crypto-celsius-cel: '\f0d0',
  outline-crypto-polygon-matic: '\f0d1',
  outline-crypto-android: '\f0d2',
  outline-crypto-cardano-ada: '\f0d3',
  outline-crypto-nebulas-nas: '\f0d4',
  outline-crypto-eos-eos: '\f0d5',
  outline-crypto-dropbox: '\f0d6',
  outline-crypto-stellar-xlm: '\f0d7',
  outline-crypto-xiaomi: '\f0d8',
  outline-crypto-solana-sol: '\f0d9',
  outline-crypto-figma: '\f0da',
  outline-crypto-triangle: '\f0db',
  outline-crypto-bootstrap: '\f0dc',
  linear-crypto-theta-theta: '\f0dd',
  linear-crypto-vuesax: '\f0de',
  linear-crypto-aave-aave: '\f0df',
  linear-crypto-litecoinltc: '\f0e0',
  linear-crypto-google-1: '\f0e1',
  linear-crypto-educare-ekt: '\f0e2',
  linear-crypto-apple: '\f0e3',
  linear-crypto-hex-hex: '\f0e4',
  linear-crypto-messenger: '\f0e5',
  linear-crypto-slack: '\f0e6',
  linear-crypto-xrp-xrp: '\f0e7',
  linear-crypto-framer: '\f0e8',
  linear-crypto-ethereum-classic-etc: '\f0e9',
  linear-crypto-monero-xmr: '\f0ea',
  linear-crypto-nem-xem: '\f0eb',
  linear-crypto-trello: '\f0ec',
  linear-crypto-enjin-coin-enj: '\f0ed',
  linear-crypto-dribbble: '\f0ee',
  linear-crypto-vibe-vibe: '\f0ef',
  linear-crypto-illustrator: '\f0f0',
  linear-crypto-frame: '\f0f1',
  linear-crypto-html-3: '\f0f2',
  linear-crypto-youtube: '\f0f3',
  linear-crypto-thorchain-rune: '\f0f4',
  linear-crypto-polyswarm-nct: '\f0f5',
  linear-crypto-windows: '\f0f6',
  linear-crypto-twitch: '\f0f7',
  linear-crypto-okb-okb: '\f0f8',
  linear-crypto-stacks-stx: '\f0f9',
  linear-crypto-photoshop: '\f0fa',
  linear-crypto-tether-usdt: '\f0fb',
  linear-crypto-celo-celo: '\f0fc',
  linear-crypto-google: '\f0fd',
  linear-crypto-whatsapp: '\f0fe',
  linear-crypto-ontology-ont: '\f0ff',
  linear-crypto-be: '\f100',
  linear-crypto-bootsrap: '\f101',
  linear-crypto-facebook: '\f102',
  linear-crypto-html-5: '\f103',
  linear-crypto-paypal: '\f104',
  linear-crypto-velas-vlx: '\f105',
  linear-crypto-bitcoin-btc: '\f106',
  linear-crypto-dash-dash: '\f107',
  linear-crypto-binance-usd-busd: '\f108',
  linear-crypto-avalanche-avax: '\f109',
  linear-crypto-ocean-protocol-ocean: '\f10a',
  linear-crypto-snapchat: '\f10b',
  linear-crypto-maker-mkr: '\f10c',
  linear-crypto-kyber-network-knc: '\f10d',
  linear-crypto-binance-coin-bnb: '\f10e',
  linear-crypto-trontron-trx: '\f10f',
  linear-crypto-java-script: '\f110',
  linear-crypto-spotify: '\f111',
  linear-crypto-ethereum-eth: '\f112',
  linear-crypto-xd: '\f113',
  linear-crypto-harmony-one: '\f114',
  linear-crypto-decred-dcr: '\f115',
  linear-crypto-polkadot-dot: '\f116',
  linear-crypto-iost-iost: '\f117',
  linear-crypto-ankr-ankr: '\f118',
  linear-crypto-nexo-nexo: '\f119',
  linear-crypto-zel-zel: '\f11a',
  linear-crypto-augur-rep: '\f11b',
  linear-crypto-blogger: '\f11c',
  linear-crypto-python: '\f11d',
  linear-crypto-dai-dai: '\f11e',
  linear-crypto-autonio-niox: '\f11f',
  linear-crypto-chainlink-link: '\f120',
  linear-crypto-zoom: '\f121',
  linear-crypto-emercoin-emc: '\f122',
  linear-crypto-wing-wing: '\f123',
  linear-crypto-frame-1: '\f124',
  linear-crypto-quant-qnt: '\f125',
  linear-crypto-ftx-token-ftt: '\f126',
  linear-crypto-icon-icx: '\f127',
  linear-crypto-usd-coin-usdc: '\f128',
  linear-crypto-the-graph-grt: '\f129',
  linear-crypto-dent-dent: '\f12a',
  linear-crypto-wanchain-wan: '\f12b',
  linear-crypto-hedera-hashgraph-hbar: '\f12c',
  linear-crypto-civic-cvc: '\f12d',
  linear-crypto-figma-1: '\f12e',
  linear-crypto-ui8: '\f12f',
  linear-crypto-google-play: '\f130',
  linear-crypto-tenx-pay: '\f131',
  linear-crypto-huobi-token-ht: '\f132',
  linear-crypto-wanchain-wan-1: '\f133',
  linear-crypto-siacoin-sc: '\f134',
  linear-crypto-js: '\f135',
  linear-crypto-celsius-cel: '\f136',
  linear-crypto-polygon-matic: '\f137',
  linear-crypto-android: '\f138',
  linear-crypto-cardano-ada: '\f139',
  linear-crypto-nebulas-nas: '\f13a',
  linear-crypto-eos-eos: '\f13b',
  linear-crypto-dropbox: '\f13c',
  linear-crypto-stellar-xlm: '\f13d',
  linear-crypto-xiaomi: '\f13e',
  linear-crypto-solana-sol: '\f13f',
  linear-crypto-figma: '\f140',
  linear-crypto-triangle: '\f141',
  linear-data-2: '\f142',
  linear-heart: '\f143',
  linear-calendar-remove: '\f144',
  linear-refresh-2: '\f145',
  linear-align-left: '\f146',
  linear-clipboard-text: '\f147',
  linear-send-2: '\f148',
  linear-backward-item-1: '\f149',
  linear-direct-notification: '\f14a',
  linear-card-tick: '\f14b',
  linear-money-remove: '\f14c',
  linear-voice-cricle: '\f14d',
  linear-forward-10-seconds: '\f14e',
  linear-direct-normal: '\f14f',
  linear-weight: '\f150',
  linear-candle-2: '\f151',
  linear-moon: '\f152',
  linear-gallery-export: '\f153',
  linear-music-circle: '\f154',
  linear-element-plus: '\f155',
  linear-scanning: '\f156',
  linear-document-normal: '\f157',
  linear-wallet-minus: '\f158',
  linear-card-add: '\f159',
  linear-3d-cube-scan: '\f15a',
  linear-minus: '\f15b',
  linear-monitor: '\f15c',
  linear-timer: '\f15d',
  linear-arrow-down-1: '\f15e',
  linear-color-swatch: '\f15f',
  linear-archive-1: '\f160',
  linear-copy-success: '\f161',
  linear-alarm: '\f162',
  linear-folder: '\f163',
  linear-cards: '\f164',
  linear-message-remove: '\f165',
  linear-search-favorite: '\f166',
  linear-bezier: '\f167',
  linear-building: '\f168',
  linear-graph: '\f169',
  linear-slider-horizontal: '\f16a',
  linear-logout-1: '\f16b',
  linear-document-sketch: '\f16c',
  linear-menu-board: '\f16d',
  linear-mirror: '\f16e',
  linear-blend-2: '\f16f',
  linear-lamp-slash: '\f170',
  linear-receipt-text: '\f171',
  linear-man: '\f172',
  linear-truck-remove: '\f173',
  linear-layer: '\f174',
  linear-sms-edit: '\f175',
  linear-music-square-remove: '\f176',
  linear-routing: '\f177',
  linear-login-1: '\f178',
  linear-mouse: '\f179',
  linear-3d-square: '\f17a',
  linear-discover-1: '\f17b',
  linear-calculator: '\f17c',
  linear-airplane: '\f17d',
  linear-happyemoji: '\f17e',
  linear-shopping-cart: '\f17f',
  linear-finger-cricle: '\f180',
  linear-save-add: '\f181',
  linear-chrome: '\f182',
  linear-volume-slash: '\f183',
  linear-bucket-square: '\f184',
  linear-note-21: '\f185',
  linear-security-user: '\f186',
  linear-repeate-one: '\f187',
  linear-send-1: '\f188',
  linear-money-recive: '\f189',
  linear-sms-star: '\f18a',
  linear-search-zoom-out: '\f18b',
  linear-building-4: '\f18c',
  linear-like-shapes: '\f18d',
  linear-menu: '\f18e',
  linear-drop: '\f18f',
  linear-volume-low-1: '\f190',
  linear-setting-3: '\f191',
  linear-maximize-circle: '\f192',
  linear-receipt-discount: '\f193',
  linear-strongbox-2: '\f194',
  linear-component: '\f195',
  linear-trend-down: '\f196',
  linear-favorite-chart: '\f197',
  linear-math: '\f198',
  linear-screenmirroring: '\f199',
  linear-receipt-minus: '\f19a',
  linear-lock-circle: '\f19b',
  linear-level: '\f19c',
  linear-document-filter: '\f19d',
  linear-pen-tool-2-1: '\f19e',
  linear-call-add: '\f19f',
  linear-programming-arrow: '\f1a0',
  linear-rotate-right: '\f1a1',
  linear-note-square: '\f1a2',
  linear-status: '\f1a3',
  linear-shield-slash: '\f1a4',
  linear-arrow-down-2: '\f1a5',
  linear-bookmark: '\f1a6',
  linear-blend: '\f1a7',
  linear-forward: '\f1a8',
  linear-call-minus: '\f1a9',
  linear-box-tick: '\f1aa',
  linear-setting-2: '\f1ab',
  linear-pause: '\f1ac',
  linear-cpu-charge: '\f1ad',
  linear-colors-square-1: '\f1ae',
  linear-diamonds: '\f1af',
  linear-mini-music-sqaure: '\f1b0',
  linear-cup: '\f1b1',
  linear-lovely: '\f1b2',
  linear-cloud: '\f1b3',
  linear-task-square: '\f1b4',
  linear-wallet-money: '\f1b5',
  linear-heart-edit: '\f1b6',
  linear-clipboard-import: '\f1b7',
  linear-close-square: '\f1b8',
  linear-receipt-search: '\f1b9',
  linear-omega-circle: '\f1ba',
  linear-profile-circle: '\f1bb',
  linear-scanner: '\f1bc',
  linear-volume-low: '\f1bd',
  linear-message-minus: '\f1be',
  linear-keyboard-open: '\f1bf',
  linear-microphone-slash: '\f1c0',
  linear-ram: '\f1c1',
  linear-data: '\f1c2',
  linear-buliding: '\f1c3',
  linear-gas-station: '\f1c4',
  linear-arrow-left: '\f1c5',
  linear-lamp-charge: '\f1c6',
  linear-calendar: '\f1c7',
  linear-video-slash: '\f1c8',
  linear-medal: '\f1c9',
  linear-link-square: '\f1ca',
  linear-pharagraphspacing: '\f1cb',
  linear-money-send: '\f1cc',
  linear-add-circle: '\f1cd',
  linear-filter: '\f1ce',
  linear-box-time: '\f1cf',
  linear-bitcoin-convert: '\f1d0',
  linear-activity: '\f1d1',
  linear-tree: '\f1d2',
  linear-security-safe: '\f1d3',
  linear-filter-square: '\f1d4',
  linear-external-drive: '\f1d5',
  linear-video-vertical: '\f1d6',
  linear-video: '\f1d7',
  linear-undo: '\f1d8',
  linear-messages-1: '\f1d9',
  linear-sort: '\f1da',
  linear-microscope: '\f1db',
  linear-folder-add: '\f1dc',
  linear-colors-square: '\f1dd',
  linear-money: '\f1de',
  linear-arrow-up: '\f1df',
  linear-video-horizontal: '\f1e0',
  linear-document-upload: '\f1e1',
  linear-video-square: '\f1e2',
  linear-arrow-square-down: '\f1e3',
  linear-share: '\f1e4',
  linear-tag-right: '\f1e5',
  linear-profile: '\f1e6',
  linear-language-square: '\f1e7',
  linear-clock-1: '\f1e8',
  linear-heart-remove: '\f1e9',
  linear-forward-15-seconds: '\f1ea',
  linear-messages-3: '\f1eb',
  linear-car: '\f1ec',
  linear-setting-5: '\f1ed',
  linear-gift: '\f1ee',
  linear-glass-1: '\f1ef',
  linear-arrange-square-2: '\f1f0',
  linear-search-normal-1: '\f1f1',
  linear-receipt-edit: '\f1f2',
  linear-electricity: '\f1f3',
  linear-device-message: '\f1f4',
  linear-profile-tick: '\f1f5',
  linear-location: '\f1f6',
  linear-path-square: '\f1f7',
  linear-wallet-remove: '\f1f8',
  linear-bluetooth-rectangle: '\f1f9',
  linear-attach-square: '\f1fa',
  linear-headphone: '\f1fb',
  linear-personalcard: '\f1fc',
  linear-cloud-connection: '\f1fd',
  linear-gallery-edit: '\f1fe',
  linear-mobile: '\f1ff',
  linear-wallet-add: '\f200',
  linear-eye: '\f201',
  linear-call: '\f202',
  linear-direct-send: '\f203',
  linear-repeat-circle: '\f204',
  linear-message-programming: '\f205',
  linear-send-sqaure-2: '\f206',
  linear-courthouse: '\f207',
  linear-slider-horizontal-1: '\f208',
  linear-textalign-justifyright: '\f209',
  linear-call-incoming: '\f20a',
  linear-pen-close-1: '\f20b',
  linear-wind-2: '\f20c',
  linear-clock: '\f20d',
  linear-command: '\f20e',
  linear-setting-4: '\f20f',
  linear-messages-2: '\f210',
  linear-building-3: '\f211',
  linear-ticket-discount: '\f212',
  linear-hierarchy: '\f213',
  linear-play-remove: '\f214',
  linear-simcard: '\f215',
  linear-bitcoin-refresh: '\f216',
  linear-music-library-2: '\f217',
  linear-search-zoom-out-1: '\f218',
  linear-3square: '\f219',
  linear-timer-1: '\f21a',
  linear-call-received: '\f21b',
  linear-magicpen: '\f21c',
  linear-global-refresh: '\f21d',
  linear-receipt-item: '\f21e',
  linear-arrow-swap-horizontal: '\f21f',
  linear-video-play: '\f220',
  linear-lock-1: '\f221',
  linear-group-10: '\f222',
  linear-judge: '\f223',
  linear-frame: '\f224',
  linear-bank: '\f225',
  linear-location-minus: '\f226',
  linear-woman: '\f227',
  linear-eraser-1: '\f228',
  linear-designtools: '\f229',
  linear-truck-time: '\f22a',
  linear-forward-square: '\f22b',
  linear-card-remove: '\f22c',
  linear-music-play: '\f22d',
  linear-star-slash: '\f22e',
  linear-notification-status: '\f22f',
  linear-unlock: '\f230',
  linear-send-square: '\f231',
  linear-flash-slash: '\f232',
  linear-arrow-left-1: '\f233',
  linear-export-2: '\f234',
  linear-folder-cloud: '\f235',
  linear-export-3: '\f236',
  linear-pet: '\f237',
  linear-message-edit: '\f238',
  linear-colorfilter: '\f239',
  linear-volume-high: '\f23a',
  linear-lifebuoy: '\f23b',
  linear-gallery-add: '\f23c',
  linear-element-1: '\f23d',
  linear-emoji-normal: '\f23e',
  linear-receipt-2: '\f23f',
  linear-wallet-add-1: '\f240',
  linear-strongbox: '\f241',
  linear-bluetooth-2: '\f242',
  linear-home-wifi: '\f243',
  linear-group-11: '\f244',
  linear-maximize-21: '\f245',
  linear-next: '\f246',
  linear-forward-item-1: '\f247',
  linear-edit: '\f248',
  linear-mouse-circle: '\f249',
  linear-more-2: '\f24a',
  linear-folder-cross: '\f24b',
  linear-message-square: '\f24c',
  linear-arrow-circle-down: '\f24d',
  linear-cloud-sunny: '\f24e',
  linear-danger: '\f24f',
  linear-bag-cross-1: '\f250',
  linear-bag-timer: '\f251',
  linear-home-2: '\f252',
  linear-sun: '\f253',
  linear-clipboard-export: '\f254',
  linear-cd: '\f255',
  linear-folder-2: '\f256',
  linear-bitcoin-card: '\f257',
  linear-star: '\f258',
  linear-security-time: '\f259',
  linear-hashtag: '\f25a',
  linear-volume-up: '\f25b',
  linear-weight-1: '\f25c',
  linear-computing: '\f25d',
  linear-grid-9: '\f25e',
  linear-element-equal: '\f25f',
  linear-recovery-convert: '\f260',
  linear-eraser-2: '\f261',
  linear-scan-barcode: '\f262',
  linear-dollar-square: '\f263',
  linear-direct-left: '\f264',
  linear-group-8: '\f265',
  linear-element-3: '\f266',
  linear-video-octagon: '\f267',
  linear-maximize-4: '\f268',
  linear-record-circle: '\f269',
  linear-monitor-recorder: '\f26a',
  linear-pen-add: '\f26b',
  linear-refresh-left-square: '\f26c',
  linear-battery-3full: '\f26d',
  linear-trash: '\f26e',
  linear-export-1: '\f26f',
  linear-arrow-left-2: '\f270',
  linear-arrow-left-3: '\f271',
  linear-textalign-center: '\f272',
  linear-firstline: '\f273',
  linear-bus: '\f274',
  linear-battery-empty-1: '\f275',
  linear-cloud-minus: '\f276',
  linear-grid-eraser: '\f277',
  linear-glass: '\f278',
  linear-group-9: '\f279',
  linear-element-2: '\f27a',
  linear-back-square: '\f27b',
  linear-text-underline: '\f27c',
  linear-emoji-sad: '\f27d',
  linear-document-download: '\f27e',
  linear-receipt-1: '\f27f',
  linear-more: '\f280',
  linear-security: '\f281',
  linear-arrow-bottom: '\f282',
  linear-grid-8: '\f283',
  linear-music-playlist: '\f284',
  linear-note-remove: '\f285',
  linear-pen-tool: '\f286',
  linear-quote-up-circle: '\f287',
  linear-discount-circle: '\f288',
  linear-user-search: '\f289',
  linear-folder-minus: '\f28a',
  linear-cloud-add: '\f28b',
  linear-autobrightness: '\f28c',
  linear-redo: '\f28d',
  linear-hashtag-down: '\f28e',
  linear-more-square: '\f28f',
  linear-printer: '\f290',
  linear-link-2: '\f291',
  linear-camera: '\f292',
  linear-document-text: '\f293',
  linear-battery-empty: '\f294',
  linear-brifecase-tick: '\f295',
  linear-arrow-right-3: '\f296',
  linear-omega-circle-1: '\f297',
  linear-convert: '\f298',
  linear-archive-slash: '\f299',
  linear-speaker: '\f29a',
  linear-play-add: '\f29b',
  linear-simcard-2: '\f29c',
  linear-empty-wallet-add: '\f29d',
  linear-scroll: '\f29e',
  linear-tick-circle: '\f29f',
  linear-tag-cross: '\f2a0',
  linear-bill: '\f2a1',
  linear-mouse-1: '\f2a2',
  linear-user-octagon: '\f2a3',
  linear-smart-car: '\f2a4',
  linear-direct: '\f2a5',
  linear-barcode: '\f2a6',
  linear-send: '\f2a7',
  linear-airplane-square: '\f2a8',
  linear-user-edit: '\f2a9',
  linear-maximize-1: '\f2aa',
  linear-diagram: '\f2ab',
  linear-notification-bing: '\f2ac',
  linear-like-1: '\f2ad',
  linear-verify: '\f2ae',
  linear-coin: '\f2af',
  linear-driving: '\f2b0',
  linear-text-block: '\f2b1',
  linear-notification: '\f2b2',
  linear-filter-edit: '\f2b3',
  linear-bucket-circle-1: '\f2b4',
  linear-filter-remove: '\f2b5',
  linear-directbox-receive: '\f2b6',
  linear-toggle-off-circle: '\f2b7',
  linear-backward: '\f2b8',
  linear-play: '\f2b9',
  linear-eye-slash: '\f2ba',
  linear-rulerpen: '\f2bb',
  linear-message-add-1: '\f2bc',
  linear-broom: '\f2bd',
  linear-crown: '\f2be',
  linear-message-2: '\f2bf',
  linear-cloud-change: '\f2c0',
  linear-mask: '\f2c1',
  linear-messages: '\f2c2',
  linear-empty-wallet-remove: '\f2c3',
  linear-format-circle: '\f2c4',
  linear-location-cross: '\f2c5',
  linear-box: '\f2c6',
  linear-box-search: '\f2c7',
  linear-trade: '\f2c8',
  linear-award: '\f2c9',
  linear-sticker: '\f2ca',
  linear-video-remove: '\f2cb',
  linear-airpod: '\f2cc',
  linear-message: '\f2cd',
  linear-document-copy: '\f2ce',
  linear-safe-home: '\f2cf',
  linear-textalign-justifyleft: '\f2d0',
  linear-message-favorite: '\f2d1',
  linear-story: '\f2d2',
  linear-flash-circle: '\f2d3',
  linear-paperclip: '\f2d4',
  linear-arrow-right-2: '\f2d5',
  linear-crown-1: '\f2d6',
  linear-previous: '\f2d7',
  linear-volume-mute: '\f2d8',
  linear-3dcube: '\f2d9',
  linear-grid-lock: '\f2da',
  linear-setting: '\f2db',
  linear-message-text: '\f2dc',
  linear-heart-add: '\f2dd',
  linear-command-square: '\f2de',
  linear-document-cloud: '\f2df',
  linear-link-1: '\f2e0',
  linear-direct-inbox: '\f2e1',
  linear-security-card: '\f2e2',
  linear-pen-remove-1: '\f2e3',
  linear-cloud-lightning: '\f2e4',
  linear-scan: '\f2e5',
  linear-minus-cirlce: '\f2e6',
  linear-lamp: '\f2e7',
  linear-search-favorite-1: '\f2e8',
  linear-simcard-1: '\f2e9',
  linear-flash-1: '\f2ea',
  linear-reserve: '\f2eb',
  linear-truck-tick: '\f2ec',
  linear-card-slash: '\f2ed',
  linear-folder-connection: '\f2ee',
  linear-calendar-edit: '\f2ef',
  linear-maximize-2: '\f2f0',
  linear-image: '\f2f1',
  linear-align-right: '\f2f2',
  linear-text: '\f2f3',
  linear-gallery-import: '\f2f4',
  linear-hashtag-1: '\f2f5',
  linear-chart-square: '\f2f6',
  linear-link-21: '\f2f7',
  linear-cloud-cross: '\f2f8',
  linear-refresh: '\f2f9',
  linear-hierarchy-square: '\f2fa',
  linear-box-add: '\f2fb',
  linear-hierarchy-square-2: '\f2fc',
  linear-align-horizontally: '\f2fd',
  linear-hierarchy-square-3: '\f2fe',
  linear-arrange-circle: '\f2ff',
  linear-sun-fog: '\f300',
  linear-bag: '\f301',
  linear-element-4: '\f302',
  linear-maximize-3: '\f303',
  linear-call-calling: '\f304',
  linear-bag-tick-2: '\f305',
  linear-receipt-add: '\f306',
  linear-copy: '\f307',
  linear-watch-status: '\f308',
  linear-smallcaps: '\f309',
  linear-arrow-right-1: '\f30a',
  linear-ticket-expired: '\f30b',
  linear-bag-cross: '\f30c',
  linear-cloud-fog: '\f30d',
  linear-info-circle: '\f30e',
  linear-quote-down-square: '\f30f',
  linear-people: '\f310',
  linear-book-1: '\f311',
  linear-document-code: '\f312',
  linear-speedometer: '\f313',
  linear-add: '\f314',
  linear-user-add: '\f315',
  linear-sidebar-bottom: '\f316',
  linear-key-square: '\f317',
  linear-code-circle: '\f318',
  linear-archive-tick: '\f319',
  linear-flash-circle-2: '\f31a',
  linear-grid-3: '\f31b',
  linear-archive-add: '\f31c',
  linear-arrow-circle-right: '\f31d',
  linear-slider: '\f31e',
  linear-wallet-check: '\f31f',
  linear-backward-item: '\f320',
  linear-group-2: '\f321',
  linear-directbox-default: '\f322',
  linear-video-add: '\f323',
  linear-card: '\f324',
  linear-watch: '\f325',
  linear-document-code-2: '\f326',
  linear-route-square: '\f327',
  linear-hashtag-up: '\f328',
  linear-wifi: '\f329',
  linear-main-component: '\f32a',
  linear-category-2: '\f32b',
  linear-empty-wallet-time: '\f32c',
  linear-frame-4: '\f32d',
  linear-gallery-slash: '\f32e',
  linear-finger-scan: '\f32f',
  linear-additem: '\f330',
  linear-headphones: '\f331',
  linear-routing-2: '\f332',
  linear-global: '\f333',
  linear-bluetooth: '\f334',
  linear-arrange-circle-2: '\f335',
  linear-direct-down: '\f336',
  linear-archive-book: '\f337',
  linear-hospital: '\f338',
  linear-frame-5: '\f339',
  linear-filter-add: '\f33a',
  linear-trend-up: '\f33b',
  linear-mirroring-screen: '\f33c',
  linear-airdrop: '\f33d',
  linear-ranking: '\f33e',
  linear-group-3: '\f33f',
  linear-notification-1: '\f340',
  linear-empty-wallet-change: '\f341',
  linear-check: '\f342',
  linear-chart-success: '\f343',
  linear-document: '\f344',
  linear-cloud-remove: '\f345',
  linear-chart-21: '\f346',
  linear-bluetooth-circle: '\f347',
  linear-battery-full: '\f348',
  linear-category: '\f349',
  linear-stickynote: '\f34a',
  linear-video-time: '\f34b',
  linear-grid-2: '\f34c',
  linear-ranking-1: '\f34d',
  linear-record: '\f34e',
  linear-music-dashboard: '\f34f',
  linear-scissor-1: '\f350',
  linear-user-remove: '\f351',
  linear-receive-square-2: '\f352',
  linear-call-slash: '\f353',
  linear-document-1: '\f354',
  linear-cpu: '\f355',
  linear-document-favorite: '\f356',
  linear-sms-tracking: '\f357',
  linear-music-square: '\f358',
  linear-ruler: '\f359',
  linear-buy-crypto: '\f35a',
  linear-gemini-2: '\f35b',
  linear-message-tick: '\f35c',
  linear-emoji-happy: '\f35d',
  linear-save-remove: '\f35e',
  linear-save-2: '\f35f',
  linear-pen-close: '\f360',
  linear-row-vertical: '\f361',
  linear-document-previous: '\f362',
  linear-arrow-circle-left: '\f363',
  linear-card-remove-1: '\f364',
  linear-house-2: '\f365',
  linear-search-normal: '\f366',
  linear-stop: '\f367',
  linear-flash-circle-1: '\f368',
  linear-gameboy: '\f369',
  linear-like-tag: '\f36a',
  linear-search-status-1: '\f36b',
  linear-gps: '\f36c',
  linear-pen-remove: '\f36d',
  linear-group-1: '\f36e',
  linear-flag: '\f36f',
  linear-teacher: '\f370',
  linear-convert-3d-cube: '\f371',
  linear-medal-star: '\f372',
  linear-user-cirlce-add: '\f373',
  linear-cake: '\f374',
  linear-money-4: '\f375',
  linear-code: '\f376',
  linear-flash: '\f377',
  linear-component-1: '\f378',
  linear-unlimited: '\f379',
  linear-coffee: '\f37a',
  linear-paperclip-2: '\f37b',
  linear-bucket-circle: '\f37c',
  linear-backward-10-seconds: '\f37d',
  linear-wallet: '\f37e',
  linear-folder-favorite: '\f37f',
  linear-user-minus: '\f380',
  linear-timer-pause: '\f381',
  linear-scissor: '\f382',
  linear-heart-slash: '\f383',
  linear-grid-1: '\f384',
  linear-scissor-2: '\f385',
  linear-note-add: '\f386',
  linear-book: '\f387',
  linear-grid-edit: '\f388',
  linear-ticket-2: '\f389',
  linear-sagittarius: '\f38a',
  linear-devices-1: '\f38b',
  linear-radio: '\f38c',
  linear-login: '\f38d',
  linear-slash: '\f38e',
  linear-repeate-music: '\f38f',
  linear-rotate-left-1: '\f390',
  linear-shapes: '\f391',
  linear-battery-charging: '\f392',
  linear-ram-2: '\f393',
  linear-message-time: '\f394',
  linear-wifi-square: '\f395',
  linear-message-question: '\f396',
  linear-tag-user: '\f397',
  linear-card-pos: '\f398',
  linear-voice-square: '\f399',
  linear-box-1: '\f39a',
  linear-link-circle: '\f39b',
  linear-sms: '\f39c',
  linear-receipt-disscount: '\f39d',
  linear-discover: '\f39e',
  linear-add-square: '\f39f',
  linear-cloud-notif: '\f3a0',
  linear-profile-add: '\f3a1',
  linear-shield-cross: '\f3a2',
  linear-grid-5: '\f3a3',
  linear-note-2: '\f3a4',
  linear-radar-2: '\f3a5',
  linear-global-edit: '\f3a6',
  linear-gallery: '\f3a7',
  linear-home-hashtag: '\f3a8',
  linear-direct-up: '\f3a9',
  linear-import: '\f3aa',
  linear-size: '\f3ab',
  linear-group-4: '\f3ac',
  linear-note: '\f3ad',
  linear-frame-2: '\f3ae',
  linear-align-vertically: '\f3af',
  linear-kanban: '\f3b0',
  linear-shop-remove: '\f3b1',
  linear-video-circle: '\f3b2',
  linear-shield: '\f3b3',
  linear-code-1: '\f3b4',
  linear-notification-favorite: '\f3b5',
  linear-bookmark-2: '\f3b6',
  linear-backward-5-seconds: '\f3b7',
  linear-textalign-right: '\f3b8',
  linear-omega-square: '\f3b9',
  linear-close-circle: '\f3ba',
  linear-frame-3: '\f3bb',
  linear-archive: '\f3bc',
  linear-game: '\f3bd',
  linear-group-5: '\f3be',
  linear-note-text: '\f3bf',
  linear-forward-item: '\f3c0',
  linear-global-search: '\f3c1',
  linear-book-saved: '\f3c2',
  linear-volume-cross: '\f3c3',
  linear-status-up: '\f3c4',
  linear-grid-4: '\f3c5',
  linear-mask-1: '\f3c6',
  linear-text-bold: '\f3c7',
  linear-cloud-snow: '\f3c8',
  linear-gallery-favorite: '\f3c9',
  linear-row-horizontal: '\f3ca',
  linear-settings: '\f3cb',
  linear-minus-square: '\f3cc',
  linear-group: '\f3cd',
  linear-receive-square: '\f3ce',
  linear-programming-arrows: '\f3cf',
  linear-calendar-tick: '\f3d0',
  linear-pen-add-1: '\f3d1',
  linear-backward-15-seconds: '\f3d2',
  linear-driver-2: '\f3d3',
  linear-chart-1: '\f3d4',
  linear-ship: '\f3d5',
  linear-chart-3: '\f3d6',
  linear-3d-rotate: '\f3d7',
  linear-stop-circle: '\f3d8',
  linear-brush: '\f3d9',
  linear-arrow-right: '\f3da',
  linear-box-2: '\f3db',
  linear-money-forbidden: '\f3dc',
  linear-grid-6: '\f3dd',
  linear-mask-3: '\f3de',
  linear-key: '\f3df',
  linear-note-1: '\f3e0',
  linear-toggle-off: '\f3e1',
  linear-empty-wallet: '\f3e2',
  linear-radar-1: '\f3e3',
  linear-link: '\f3e4',
  linear-receipt-square: '\f3e5',
  linear-instagram: '\f3e6',
  linear-group-7: '\f3e7',
  linear-lamp-on: '\f3e8',
  linear-clipboard: '\f3e9',
  linear-frame-1: '\f3ea',
  linear-coin-1: '\f3eb',
  linear-driver-refresh: '\f3ec',
  linear-dislike: '\f3ed',
  linear-empty-wallet-tick: '\f3ee',
  linear-money-2: '\f3ef',
  linear-main-component-1: '\f3f0',
  linear-cloud-drizzle: '\f3f1',
  linear-arrow-square-right: '\f3f2',
  linear-money-3: '\f3f3',
  linear-search-zoom-in: '\f3f4',
  linear-slider-vertical: '\f3f5',
  linear-filter-search: '\f3f6',
  linear-bag-2: '\f3f7',
  linear-language-circle: '\f3f8',
  linear-shopping-bag: '\f3f9',
  linear-health: '\f3fa',
  linear-buildings: '\f3fb',
  linear-group-6: '\f3fc',
  linear-call-outgoing: '\f3fd',
  linear-box-remove: '\f3fe',
  linear-lock: '\f3ff',
  linear-user-tick: '\f400',
  linear-mask-2: '\f401',
  linear-grid-7: '\f402',
  linear-save-minus: '\f403',
  linear-profile-2user: '\f404',
  linear-video-tick: '\f405',
  linear-location-tick: '\f406',
  linear-attach-circle: '\f407',
  linear-microphone-2: '\f408',
  linear-filter-tick: '\f409',
  linear-notification-circle: '\f40a',
  linear-keyboard: '\f40b',
  linear-path-2: '\f40c',
  linear-chart-2: '\f40d',
  linear-folder-open: '\f40e',
  linear-search-status: '\f40f',
  linear-bubble: '\f410',
  linear-sms-search: '\f411',
  linear-message-add: '\f412',
  linear-warning-2: '\f413',
  linear-brush-3: '\f414',
  linear-arrow-up-1: '\f415',
  linear-lock-slash: '\f416',
  linear-cpu-setting: '\f417',
  linear-shield-security: '\f418',
  linear-calendar-2: '\f419',
  linear-radar: '\f41a',
  linear-maximize: '\f41b',
  linear-shield-tick: '\f41c',
  linear-magic-star: '\f41d',
  linear-percentage-square: '\f41e',
  linear-mouse-square: '\f41f',
  linear-fatrows: '\f420',
  linear-discount-shape: '\f421',
  linear-devices: '\f422',
  linear-arrow-2: '\f423',
  linear-message-circle: '\f424',
  linear-pen-tool-2: '\f425',
  linear-monitor-mobbile: '\f426',
  linear-smileys: '\f427',
  linear-arrow-3: '\f428',
  linear-ticket-star: '\f429',
  linear-grammerly: '\f42a',
  linear-user-square: '\f42b',
  linear-airpods: '\f42c',
  linear-transaction-minus: '\f42d',
  linear-mobile-programming: '\f42e',
  linear-book-square: '\f42f',
  linear-music-filter: '\f430',
  linear-calendar-circle: '\f431',
  linear-home-trend-down: '\f432',
  linear-microphone: '\f433',
  linear-sms-notification: '\f434',
  linear-sidebar-right: '\f435',
  linear-map: '\f436',
  linear-moneys: '\f437',
  linear-brush-2: '\f438',
  linear-direct-right: '\f439',
  linear-cloud-plus: '\f43a',
  linear-directbox-send: '\f43b',
  linear-wallet-1: '\f43c',
  linear-hierarchy-3: '\f43d',
  linear-calendar-search: '\f43e',
  linear-wallet-3: '\f43f',
  linear-music-square-add: '\f440',
  linear-calendar-add: '\f441',
  linear-align-bottom: '\f442',
  linear-arrow-up-2: '\f443',
  linear-location-slash: '\f444',
  linear-briefcase: '\f445',
  linear-sidebar-top: '\f446',
  linear-user-tag: '\f447',
  linear-tag: '\f448',
  linear-calendar-1: '\f449',
  linear-text-italic: '\f44a',
  linear-crop: '\f44b',
  linear-call-remove: '\f44c',
  linear-refresh-right-square: '\f44d',
  linear-convertshape-2: '\f44e',
  linear-blur: '\f44f',
  linear-export: '\f450',
  linear-ticket: '\f451',
  linear-brifecase-cross: '\f452',
  linear-quote-up-square: '\f453',
  linear-candle: '\f454',
  linear-pen-tool-1: '\f455',
  linear-signpost: '\f456',
  linear-creative-commons: '\f457',
  linear-flag-2: '\f458',
  linear-message-notif: '\f459',
  linear-camera-slash: '\f45a',
  linear-logout: '\f45b',
  linear-24-support: '\f45c',
  linear-message-search: '\f45d',
  linear-money-tick: '\f45e',
  linear-bucket-square-1: '\f45f',
  linear-bag-happy: '\f460',
  linear-card-edit: '\f461',
  linear-omega-square-1: '\f462',
  linear-dollar-circle: '\f463',
  linear-star-1: '\f464',
  linear-arrow-up-3: '\f465',
  linear-brush-1: '\f466',
  linear-music-square-search: '\f467',
  linear-music: '\f468',
  linear-received: '\f469',
  linear-textalign-justifycenter: '\f46a',
  linear-card-tick-1: '\f46b',
  linear-play-cricle: '\f46c',
  linear-hierarchy-2: '\f46d',
  linear-wallet-2: '\f46e',
  linear-copyright: '\f46f',
  linear-shop: '\f470',
  linear-home: '\f471',
  linear-milk: '\f472',
  linear-edit-2: '\f473',
  linear-clipboard-tick: '\f474',
  linear-forward-5-seconds: '\f475',
  linear-arrow-square: '\f476',
  linear-like-dislike: '\f477',
  linear-format-square: '\f478',
  linear-quote-up: '\f479',
  linear-sidebar-left: '\f47a',
  linear-heart-search: '\f47b',
  linear-card-receive: '\f47c',
  linear-rotate-right-1: '\f47d',
  linear-home-trend-up: '\f47e',
  linear-message-text-1: '\f47f',
  linear-arrow: '\f480',
  linear-money-change: '\f481',
  linear-user: '\f482',
  linear-wallet-search: '\f483',
  linear-audio-square: '\f484',
  linear-more-circle: '\f485',
  linear-shapes-2: '\f486',
  linear-translate: '\f487',
  linear-document-forward: '\f488',
  linear-money-time: '\f489',
  linear-aquarius: '\f48a',
  linear-tag-2: '\f48b',
  linear-map-1: '\f48c',
  linear-document-like: '\f48d',
  linear-chart: '\f48e',
  linear-tick-square: '\f48f',
  linear-password-check: '\f490',
  linear-presention-chart: '\f491',
  linear-gps-slash: '\f492',
  linear-gallery-tick: '\f493',
  linear-path: '\f494',
  linear-profile-remove: '\f495',
  linear-import-1: '\f496',
  linear-bezier-1: '\f497',
  linear-repeat: '\f498',
  linear-refresh-square-2: '\f499',
  linear-arrange-square: '\f49a',
  linear-document-text-1: '\f49b',
  linear-information: '\f49c',
  linear-blur-1: '\f49d',
  linear-picture-frame: '\f49e',
  linear-arrow-square-left: '\f49f',
  linear-play-circle: '\f4a0',
  linear-menu-1: '\f4a1',
  linear-rotate-left: '\f4a2',
  linear-receipt-2-1: '\f4a3',
  linear-wind: '\f4a4',
  linear-money-add: '\f4a5',
  linear-note-favorite: '\f4a6',
  linear-trush-square: '\f4a7',
  linear-card-coin: '\f4a8',
  linear-buildings-2: '\f4a9',
  linear-house: '\f4aa',
  linear-card-send: '\f4ab',
  linear-sound: '\f4ac',
  linear-refresh-circle: '\f4ad',
  linear-location-add: '\f4ae',
  linear-gemini: '\f4af',
  linear-shield-search: '\f4b0',
  linear-archive-minus: '\f4b1',
  linear-timer-start: '\f4b2',
  linear-quote-down-circle: '\f4b3',
  linear-shapes-1: '\f4b4',
  linear-brifecase-timer: '\f4b5',
  linear-heart-tick: '\f4b6',
  linear-textalign-left: '\f4b7',
  linear-ghost: '\f4b8',
  linear-lamp-1: '\f4b9',
  linear-quote-down: '\f4ba',
  linear-battery-disable: '\f4bb',
  linear-arrow-down: '\f4bc',
  linear-search-zoom-in-1: '\f4bd',
  linear-paintbucket: '\f4be',
  linear-printer-slash: '\f4bf',
  linear-subtitle: '\f4c0',
  linear-pause-circle: '\f4c1',
  linear-receipt: '\f4c2',
  linear-like: '\f4c3',
  linear-eraser: '\f4c4',
  linear-musicnote: '\f4c5',
  linear-toggle-on-circle: '\f4c6',
  linear-shop-add: '\f4c7',
  linear-clipboard-close: '\f4c8',
  linear-task: '\f4c9',
  linear-slider-vertical-1: '\f4ca',
  linear-driver: '\f4cb',
  linear-sun-1: '\f4cc',
  linear-toggle-on: '\f4cd',
  linear-triangle: '\f4ce',
  linear-gallery-remove: '\f4cf',
  linear-bag-tick: '\f4d0',
  linear-heart-circle: '\f4d1',
  linear-directbox-notif: '\f4d2',
  linear-arrow-square-up: '\f4d3',
  linear-import-2: '\f4d4',
  linear-profile-delete: '\f4d5',
  linear-smart-home: '\f4d6',
  linear-microphone-slash-1: '\f4d7',
  linear-convertshape: '\f4d8',
);

@function get-icon-content($name) {
  @return #{map-get($icon-content-variables, $name)};
}

@mixin icon-component-default-config() {
  --ds-icon-font-size: 24px;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ds-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: var(--ds-icon-font-size);

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin icon-component() {
  @include icon-component-default-config();

  &.custom-x:before {
    content: '\f4d9';
  }

  &.custom-check:before {
    content: '\f4da';
  }

  &.bold-crypto-theta-theta:before {
    content: '\e900';
  }

  &.bold-crypto-vuesax:before {
    content: '\e901';
  }

  &.bold-crypto-aave-aave:before {
    content: '\e902';
  }

  &.bold-crypto-litecoinltc:before {
    content: '\e903';
  }

  &.bold-crypto-google-1:before {
    content: '\e904';
  }

  &.bold-crypto-educare-ekt:before {
    content: '\e905';
  }

  &.bold-crypto-apple:before {
    content: '\e906';
  }

  &.bold-crypto-icon-1:before {
    content: '\e907';
  }

  &.bold-crypto-hex-hex .path1:before {
    content: '\e908';
    color: rgb(0, 0, 0);
    opacity: 0.13;
  }

  &.bold-crypto-hex-hex .path2:before {
    content: '\e909';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }

  &.bold-crypto-hex-hex .path3:before {
    content: '\e90a';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }

  &.bold-crypto-hex-hex .path4:before {
    content: '\e90b';
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }

  &.bold-crypto-messenger:before {
    content: '\e90c';
  }

  &.bold-crypto-slack:before {
    content: '\e90d';
  }

  &.bold-crypto-xrp-xrp:before {
    content: '\e90e';
  }

  &.bold-crypto-framer:before {
    content: '\e90f';
  }

  &.bold-crypto-ethereum-classic-etc:before {
    content: '\e910';
  }

  &.bold-crypto-monero-xmr:before {
    content: '\e911';
  }

  &.bold-crypto-nem-xem:before {
    content: '\e912';
  }

  &.bold-crypto-trello:before {
    content: '\e913';
  }

  &.bold-crypto-enjin-coin-enj:before {
    content: '\e914';
  }

  &.bold-crypto-dribbble:before {
    content: '\e915';
  }

  &.bold-crypto-vibe-vibe:before {
    content: '\e916';
  }

  &.bold-crypto-illustrator:before {
    content: '\e917';
  }

  &.bold-crypto-html-3:before {
    content: '\e918';
  }

  &.bold-crypto-youtube:before {
    content: '\e919';
  }

  &.bold-crypto-thorchain-rune:before {
    content: '\e91a';
  }

  &.bold-crypto-polyswarm-nct:before {
    content: '\e91b';
  }

  &.bold-crypto-windows:before {
    content: '\e91c';
  }

  &.bold-crypto-twitch:before {
    content: '\e91d';
  }

  &.bold-crypto-okb-okb:before {
    content: '\e91e';
  }

  &.bold-crypto-stacks-stx:before {
    content: '\e91f';
  }

  &.bold-crypto-photoshop:before {
    content: '\e920';
  }

  &.bold-crypto-tether-usdt:before {
    content: '\e921';
  }

  &.bold-crypto-celo-celo:before {
    content: '\e922';
  }

  &.bold-crypto-google:before {
    content: '\e923';
  }

  &.bold-crypto-whatsapp:before {
    content: '\e924';
  }

  &.bold-crypto-ontology-ont:before {
    content: '\e925';
  }

  &.bold-crypto-be:before {
    content: '\e926';
  }

  &.bold-crypto-bootsrap:before {
    content: '\e927';
  }

  &.bold-crypto-facebook:before {
    content: '\e928';
  }

  &.bold-crypto-html-5:before {
    content: '\e929';
  }

  &.bold-crypto-paypal:before {
    content: '\e92a';
  }

  &.bold-crypto-velas-vlx:before {
    content: '\e92b';
  }

  &.bold-crypto-bitcoin-btc:before {
    content: '\e92c';
  }

  &.bold-crypto-dash-dash:before {
    content: '\e92d';
  }

  &.bold-crypto-binance-usd-busd:before {
    content: '\e92e';
  }

  &.bold-crypto-avalanche-avax:before {
    content: '\e92f';
  }

  &.bold-crypto-ocean-protocol-ocean:before {
    content: '\e930';
  }

  &.bold-crypto-snapchat:before {
    content: '\e931';
  }

  &.bold-crypto-maker-mkr:before {
    content: '\e932';
  }

  &.bold-crypto-kyber-network-knc:before {
    content: '\e933';
  }

  &.bold-crypto-binance-coin-bnb:before {
    content: '\e934';
  }

  &.bold-crypto-trontron-trx:before {
    content: '\e935';
  }

  &.bold-crypto-java-script:before {
    content: '\e936';
  }

  &.bold-crypto-spotify:before {
    content: '\e937';
  }

  &.bold-crypto-ethereum-eth:before {
    content: '\e938';
  }

  &.bold-crypto-xd:before {
    content: '\e939';
  }

  &.bold-crypto-harmony-one:before {
    content: '\e93a';
  }

  &.bold-crypto-decred-dcr:before {
    content: '\e93b';
  }

  &.bold-crypto-polkadot-dot:before {
    content: '\e93c';
  }

  &.bold-crypto-iost-iost:before {
    content: '\e93d';
  }

  &.bold-crypto-icon:before {
    content: '\e93e';
  }

  &.bold-crypto-ankr-ankr:before {
    content: '\e93f';
  }

  &.bold-crypto-nexo-nexo:before {
    content: '\e940';
  }

  &.bold-crypto-zel-zel:before {
    content: '\e941';
  }

  &.bold-crypto-augur-rep:before {
    content: '\e942';
  }

  &.bold-crypto-blogger:before {
    content: '\e943';
  }

  &.bold-crypto-python:before {
    content: '\e944';
  }

  &.bold-crypto-dai-dai:before {
    content: '\e945';
  }

  &.bold-crypto-autonio-niox:before {
    content: '\e946';
  }

  &.bold-crypto-chainlink-link:before {
    content: '\e947';
  }

  &.bold-crypto-zoom:before {
    content: '\e948';
  }

  &.bold-crypto-emercoin-emc:before {
    content: '\e949';
  }

  &.bold-crypto-wing-wing:before {
    content: '\e94a';
  }

  &.bold-crypto-quant-qnt:before {
    content: '\e94b';
  }

  &.bold-crypto-ftx-token-ftt:before {
    content: '\e94c';
  }

  &.bold-crypto-icon-icx:before {
    content: '\e94d';
  }

  &.bold-crypto-usd-coin-usdc:before {
    content: '\e94e';
  }

  &.bold-crypto-the-graph-grt:before {
    content: '\e94f';
  }

  &.bold-crypto-dent-dent:before {
    content: '\e950';
  }

  &.bold-crypto-wanchain-wan:before {
    content: '\e951';
  }

  &.bold-crypto-hedera-hashgraph-hbar:before {
    content: '\e952';
  }

  &.bold-crypto-civic-cvc:before {
    content: '\e953';
  }

  &.bold-crypto-figma-1:before {
    content: '\e954';
  }

  &.bold-crypto-ui8:before {
    content: '\e955';
  }

  &.bold-crypto-google-play:before {
    content: '\e956';
  }

  &.bold-crypto-tenx-pay:before {
    content: '\e957';
  }

  &.bold-crypto-huobi-token-ht:before {
    content: '\e958';
  }

  &.bold-crypto-wanchain-wan-1:before {
    content: '\e959';
  }

  &.bold-crypto-siacoin-sc:before {
    content: '\e95a';
  }

  &.bold-crypto-js:before {
    content: '\e95b';
  }

  &.bold-crypto-celsius-cel:before {
    content: '\e95c';
  }

  &.bold-crypto-polygon-matic:before {
    content: '\e95d';
  }

  &.bold-crypto-android:before {
    content: '\e95e';
  }

  &.bold-crypto-cardano-ada:before {
    content: '\e95f';
  }

  &.bold-crypto-nebulas-nas:before {
    content: '\e960';
  }

  &.bold-crypto-eos-eos:before {
    content: '\e961';
  }

  &.bold-crypto-dropbox:before {
    content: '\e962';
  }

  &.bold-crypto-stellar-xlm:before {
    content: '\e963';
  }

  &.bold-crypto-xiaomi:before {
    content: '\e964';
  }

  &.bold-crypto-solana-sol:before {
    content: '\e965';
  }

  &.bold-crypto-figma:before {
    content: '\e966';
  }

  &.bold-crypto-triangle:before {
    content: '\e967';
  }

  &.bold-data-2 .path1:before {
    content: '\e968';
    color: rgb(41, 45, 50);
  }

  &.bold-data-2 .path2:before {
    content: '\e969';
    margin-left: -1em;
    color: rgb(41, 45, 50);
  }

  &.bold-data-2 .path3:before {
    content: '\e96a';
    margin-left: -1em;
    color: rgb(41, 45, 50);
  }

  &.bold-data-2 .path4:before {
    content: '\e96b';
    margin-left: -1em;
    color: rgb(41, 45, 50);
    opacity: 0.96;
  }

  &.bold-data-2 .path5:before {
    content: '\e96c';
    margin-left: -1em;
    color: rgb(41, 45, 50);
  }

  &.bold-heart:before {
    content: '\e96d';
  }

  &.bold-calendar-remove:before {
    content: '\e96e';
  }

  &.bold-refresh-2:before {
    content: '\e96f';
  }

  &.bold-align-left:before {
    content: '\e970';
  }

  &.bold-clipboard-text:before {
    content: '\e971';
  }

  &.bold-send-2:before {
    content: '\e972';
  }

  &.bold-direct-notification:before {
    content: '\e973';
  }

  &.bold-card-tick:before {
    content: '\e974';
  }

  &.bold-money-remove:before {
    content: '\e975';
  }

  &.bold-voice-cricle:before {
    content: '\e976';
  }

  &.bold-forward-10-seconds:before {
    content: '\e977';
  }

  &.bold-direct-normal:before {
    content: '\e978';
  }

  &.bold-weight:before {
    content: '\e979';
  }

  &.bold-candle-2:before {
    content: '\e97a';
  }

  &.bold-moon:before {
    content: '\e97b';
  }

  &.bold-gallery-export:before {
    content: '\e97c';
  }

  &.bold-music-circle:before {
    content: '\e97d';
  }

  &.bold-element-plus:before {
    content: '\e97e';
  }

  &.bold-scanning:before {
    content: '\e97f';
  }

  &.bold-document-normal:before {
    content: '\e980';
  }

  &.bold-wallet-minus:before {
    content: '\e981';
  }

  &.bold-card-add:before {
    content: '\e982';
  }

  &.bold-3d-cube-scan:before {
    content: '\e983';
  }

  &.bold-minus:before {
    content: '\e984';
  }

  &.bold-monitor:before {
    content: '\e985';
  }

  &.bold-timer:before {
    content: '\e986';
  }

  &.bold-arrow-down-1:before {
    content: '\e987';
  }

  &.bold-color-swatch:before {
    content: '\e988';
  }

  &.bold-archive-1:before {
    content: '\e989';
  }

  &.bold-copy-success:before {
    content: '\e98a';
  }

  &.bold-alarm:before {
    content: '\e98b';
  }

  &.bold-folder:before {
    content: '\e98c';
  }

  &.bold-cards:before {
    content: '\e98d';
  }

  &.bold-message-remove:before {
    content: '\e98e';
  }

  &.bold-search-favorite:before {
    content: '\e98f';
  }

  &.bold-bezier:before {
    content: '\e990';
  }

  &.bold-building:before {
    content: '\e991';
  }

  &.bold-graph:before {
    content: '\e992';
  }

  &.bold-slider-horizontal:before {
    content: '\e993';
  }

  &.bold-logout-1:before {
    content: '\e994';
  }

  &.bold-document-sketch:before {
    content: '\e995';
  }

  &.bold-menu-board:before {
    content: '\e996';
  }

  &.bold-mirror:before {
    content: '\e997';
  }

  &.bold-blend-2:before {
    content: '\e998';
  }

  &.bold-lamp-slash:before {
    content: '\e999';
  }

  &.bold-receipt-text:before {
    content: '\e99a';
  }

  &.bold-man:before {
    content: '\e99b';
  }

  &.bold-truck-remove:before {
    content: '\e99c';
  }

  &.bold-layer:before {
    content: '\e99d';
  }

  &.bold-sms-edit:before {
    content: '\e99e';
  }

  &.bold-music-square-remove:before {
    content: '\e99f';
  }

  &.bold-routing:before {
    content: '\e9a0';
  }

  &.bold-login-1:before {
    content: '\e9a1';
  }

  &.bold-mouse:before {
    content: '\e9a2';
  }

  &.bold-3d-square:before {
    content: '\e9a3';
  }

  &.bold-discover-1:before {
    content: '\e9a4';
  }

  &.bold-calculator:before {
    content: '\e9a5';
  }

  &.bold-airplane:before {
    content: '\e9a6';
  }

  &.bold-happyemoji:before {
    content: '\e9a7';
  }

  &.bold-shopping-cart:before {
    content: '\e9a8';
  }

  &.bold-finger-cricle:before {
    content: '\e9a9';
  }

  &.bold-save-add:before {
    content: '\e9aa';
  }

  &.bold-chrome:before {
    content: '\e9ab';
  }

  &.bold-volume-slash:before {
    content: '\e9ac';
  }

  &.bold-bucket-square:before {
    content: '\e9ad';
  }

  &.bold-note-21:before {
    content: '\e9ae';
  }

  &.bold-security-user:before {
    content: '\e9af';
  }

  &.bold-repeate-one:before {
    content: '\e9b0';
  }

  &.bold-send-1:before {
    content: '\e9b1';
  }

  &.bold-money-recive:before {
    content: '\e9b2';
  }

  &.bold-sms-star:before {
    content: '\e9b3';
  }

  &.bold-search-zoom-out:before {
    content: '\e9b4';
  }

  &.bold-building-4:before {
    content: '\e9b5';
  }

  &.bold-like-shapes:before {
    content: '\e9b6';
  }

  &.bold-menu:before {
    content: '\e9b7';
  }

  &.bold-drop:before {
    content: '\e9b8';
  }

  &.bold-volume-low-1:before {
    content: '\e9b9';
  }

  &.bold-setting-3:before {
    content: '\e9ba';
  }

  &.bold-maximize-circle:before {
    content: '\e9bb';
  }

  &.bold-receipt-discount:before {
    content: '\e9bc';
  }

  &.bold-strongbox-2:before {
    content: '\e9bd';
  }

  &.bold-component:before {
    content: '\e9be';
  }

  &.bold-trend-down:before {
    content: '\e9bf';
  }

  &.bold-favorite-chart:before {
    content: '\e9c0';
  }

  &.bold-math:before {
    content: '\e9c1';
  }

  &.bold-screenmirroring:before {
    content: '\e9c2';
  }

  &.bold-receipt-minus:before {
    content: '\e9c3';
  }

  &.bold-lock-circle:before {
    content: '\e9c4';
  }

  &.bold-level:before {
    content: '\e9c5';
  }

  &.bold-document-filter:before {
    content: '\e9c6';
  }

  &.bold-call-add:before {
    content: '\e9c7';
  }

  &.bold-programming-arrow:before {
    content: '\e9c8';
  }

  &.bold-rotate-right:before {
    content: '\e9c9';
  }

  &.bold-note-square:before {
    content: '\e9ca';
  }

  &.bold-status:before {
    content: '\e9cb';
  }

  &.bold-shield-slash:before {
    content: '\e9cc';
  }

  &.bold-arrow-down-2:before {
    content: '\e9cd';
  }

  &.bold-archive-2:before {
    content: '\e9ce';
  }

  &.bold-bookmark:before {
    content: '\e9cf';
  }

  &.bold-blend:before {
    content: '\e9d0';
  }

  &.bold-forward:before {
    content: '\e9d1';
  }

  &.bold-call-minus:before {
    content: '\e9d2';
  }

  &.bold-box-tick:before {
    content: '\e9d3';
  }

  &.bold-setting-2:before {
    content: '\e9d4';
  }

  &.bold-pause:before {
    content: '\e9d5';
  }

  &.bold-cpu-charge:before {
    content: '\e9d6';
  }

  &.bold-diamonds:before {
    content: '\e9d7';
  }

  &.bold-mini-music-sqaure .path1:before {
    content: '\e9d8';
    color: rgb(41, 45, 50);
    opacity: 0.4;
  }

  &.bold-mini-music-sqaure .path2:before {
    content: '\e9d9';
    margin-left: -1em;
    color: rgb(41, 45, 50);
  }

  &.bold-mini-music-sqaure .path3:before {
    content: '\e9da';
    margin-left: -1em;
    color: rgb(41, 45, 50);
  }

  &.bold-cup:before {
    content: '\e9db';
  }

  &.bold-shuffle:before {
    content: '\e9dc';
  }

  &.bold-lovely:before {
    content: '\e9dd';
  }

  &.bold-cloud:before {
    content: '\e9de';
  }

  &.bold-task-square:before {
    content: '\e9df';
  }

  &.bold-wallet-money:before {
    content: '\e9e0';
  }

  &.bold-heart-edit:before {
    content: '\e9e1';
  }

  &.bold-clipboard-import:before {
    content: '\e9e2';
  }

  &.bold-close-square:before {
    content: '\e9e3';
  }

  &.bold-receipt-search:before {
    content: '\e9e4';
  }

  &.bold-omega-circle:before {
    content: '\e9e5';
  }

  &.bold-profile-circle:before {
    content: '\e9e6';
  }

  &.bold-scanner:before {
    content: '\e9e7';
  }

  &.bold-volume-low:before {
    content: '\e9e8';
  }

  &.bold-message-minus:before {
    content: '\e9e9';
  }

  &.bold-keyboard-open:before {
    content: '\e9ea';
  }

  &.bold-microphone-slash:before {
    content: '\e9eb';
  }

  &.bold-ram:before {
    content: '\e9ec';
  }

  &.bold-data:before {
    content: '\e9ed';
  }

  &.bold-buliding:before {
    content: '\e9ee';
  }

  &.bold-gas-station:before {
    content: '\e9ef';
  }

  &.bold-arrow-left:before {
    content: '\e9f0';
  }

  &.bold-lamp-charge:before {
    content: '\e9f1';
  }

  &.bold-calendar:before {
    content: '\e9f2';
  }

  &.bold-video-slash .path1:before {
    content: '\e9f3';
    color: rgb(41, 45, 50);
    opacity: 0.4;
  }

  &.bold-video-slash .path2:before {
    content: '\e9f4';
    margin-left: -1em;
    color: rgb(41, 45, 50);
  }

  &.bold-video-slash .path3:before {
    content: '\e9f5';
    margin-left: -1em;
    color: rgb(41, 45, 50);
  }

  &.bold-video-slash .path4:before {
    content: '\e9f6';
    margin-left: -1em;
    color: rgb(41, 45, 50);
  }

  &.bold-arrow-swap:before {
    content: '\e9f7';
  }

  &.bold-medal:before {
    content: '\e9f8';
  }

  &.bold-link-square:before {
    content: '\e9f9';
  }

  &.bold-pharagraphspacing:before {
    content: '\e9fa';
  }

  &.bold-money-send:before {
    content: '\e9fb';
  }

  &.bold-add-circle:before {
    content: '\e9fc';
  }

  &.bold-filter:before {
    content: '\e9fd';
  }

  &.bold-box-time:before {
    content: '\e9fe';
  }

  &.bold-bitcoin-convert:before {
    content: '\e9ff';
  }

  &.bold-activity:before {
    content: '\ea00';
  }

  &.bold-tree:before {
    content: '\ea01';
  }

  &.bold-security-safe:before {
    content: '\ea02';
  }

  &.bold-filter-square:before {
    content: '\ea03';
  }

  &.bold-external-drive:before {
    content: '\ea04';
  }

  &.bold-video-vertical:before {
    content: '\ea05';
  }

  &.bold-video:before {
    content: '\ea06';
  }

  &.bold-undo:before {
    content: '\ea07';
  }

  &.bold-messages-1:before {
    content: '\ea08';
  }

  &.bold-sort:before {
    content: '\ea09';
  }

  &.bold-microscope:before {
    content: '\ea0a';
  }

  &.bold-folder-add:before {
    content: '\ea0b';
  }

  &.bold-colors-square:before {
    content: '\ea0c';
  }

  &.bold-money:before {
    content: '\ea0d';
  }

  &.bold-arrow-up:before {
    content: '\ea0e';
  }

  &.bold-video-horizontal:before {
    content: '\ea0f';
  }

  &.bold-document-upload:before {
    content: '\ea10';
  }

  &.bold-video-square:before {
    content: '\ea11';
  }

  &.bold-arrow-square-down:before {
    content: '\ea12';
  }

  &.bold-share:before {
    content: '\ea13';
  }

  &.bold-tag-right:before {
    content: '\ea14';
  }

  &.bold-language-square:before {
    content: '\ea15';
  }

  &.bold-clock-1:before {
    content: '\ea16';
  }

  &.bold-heart-remove:before {
    content: '\ea17';
  }

  &.bold-forward-15-seconds:before {
    content: '\ea18';
  }

  &.bold-messages-3:before {
    content: '\ea19';
  }

  &.bold-car:before {
    content: '\ea1a';
  }

  &.bold-setting-5:before {
    content: '\ea1b';
  }

  &.bold-gift:before {
    content: '\ea1c';
  }

  &.bold-glass-1:before {
    content: '\ea1d';
  }

  &.bold-arrange-square-2:before {
    content: '\ea1e';
  }

  &.bold-search-normal-1:before {
    content: '\ea1f';
  }

  &.bold-receipt-edit:before {
    content: '\ea20';
  }

  &.bold-electricity:before {
    content: '\ea21';
  }

  &.bold-device-message:before {
    content: '\ea22';
  }

  &.bold-profile-tick:before {
    content: '\ea23';
  }

  &.bold-location:before {
    content: '\ea24';
  }

  &.bold-path-square:before {
    content: '\ea25';
  }

  &.bold-wallet-remove:before {
    content: '\ea26';
  }

  &.bold-bluetooth-rectangle:before {
    content: '\ea27';
  }

  &.bold-attach-square:before {
    content: '\ea28';
  }

  &.bold-headphone:before {
    content: '\ea29';
  }

  &.bold-personalcard:before {
    content: '\ea2a';
  }

  &.bold-cloud-connection:before {
    content: '\ea2b';
  }

  &.bold-gallery-edit:before {
    content: '\ea2c';
  }

  &.bold-mobile:before {
    content: '\ea2d';
  }

  &.bold-wallet-add:before {
    content: '\ea2e';
  }

  &.bold-eye:before {
    content: '\ea2f';
  }

  &.bold-call:before {
    content: '\ea30';
  }

  &.bold-direct-send:before {
    content: '\ea31';
  }

  &.bold-repeat-circle:before {
    content: '\ea32';
  }

  &.bold-message-programming:before {
    content: '\ea33';
  }

  &.bold-send-sqaure-2:before {
    content: '\ea34';
  }

  &.bold-courthouse:before {
    content: '\ea35';
  }

  &.bold-slider-horizontal-1:before {
    content: '\ea36';
  }

  &.bold-textalign-justifyright:before {
    content: '\ea37';
  }

  &.bold-call-incoming:before {
    content: '\ea38';
  }

  &.bold-wind-2:before {
    content: '\ea39';
  }

  &.bold-clock:before {
    content: '\ea3a';
  }

  &.bold-command:before {
    content: '\ea3b';
  }

  &.bold-setting-4:before {
    content: '\ea3c';
  }

  &.bold-messages-2:before {
    content: '\ea3d';
  }

  &.bold-building-3:before {
    content: '\ea3e';
  }

  &.bold-ticket-discount:before {
    content: '\ea3f';
  }

  &.bold-forbidden:before {
    content: '\ea40';
  }

  &.bold-hierarchy:before {
    content: '\ea41';
  }

  &.bold-play-remove:before {
    content: '\ea42';
  }

  &.bold-simcard:before {
    content: '\ea43';
  }

  &.bold-bitcoin-refresh:before {
    content: '\ea44';
  }

  &.bold-music-library-2:before {
    content: '\ea45';
  }

  &.bold-search-zoom-out-1:before {
    content: '\ea46';
  }

  &.bold-3square:before {
    content: '\ea47';
  }

  &.bold-home-1:before {
    content: '\ea48';
  }

  &.bold-timer-1:before {
    content: '\ea49';
  }

  &.bold-call-received:before {
    content: '\ea4a';
  }

  &.bold-magicpen:before {
    content: '\ea4b';
  }

  &.bold-global-refresh:before {
    content: '\ea4c';
  }

  &.bold-receipt-item:before {
    content: '\ea4d';
  }

  &.bold-arrow-swap-horizontal:before {
    content: '\ea4e';
  }

  &.bold-video-play:before {
    content: '\ea4f';
  }

  &.bold-lock-1:before {
    content: '\ea50';
  }

  &.bold-judge:before {
    content: '\ea51';
  }

  &.bold-frame:before {
    content: '\ea52';
  }

  &.bold-bank:before {
    content: '\ea53';
  }

  &.bold-location-minus:before {
    content: '\ea54';
  }

  &.bold-woman:before {
    content: '\ea55';
  }

  &.bold-eraser-1:before {
    content: '\ea56';
  }

  &.bold-designtools:before {
    content: '\ea57';
  }

  &.bold-truck-time:before {
    content: '\ea58';
  }

  &.bold-forward-square:before {
    content: '\ea59';
  }

  &.bold-card-remove:before {
    content: '\ea5a';
  }

  &.bold-music-play:before {
    content: '\ea5b';
  }

  &.bold-star-slash:before {
    content: '\ea5c';
  }

  &.bold-notification-status:before {
    content: '\ea5d';
  }

  &.bold-forbidden-2:before {
    content: '\ea5e';
  }

  &.bold-unlock:before {
    content: '\ea5f';
  }

  &.bold-send-square:before {
    content: '\ea60';
  }

  &.bold-flash-slash:before {
    content: '\ea61';
  }

  &.bold-arrow-left-1:before {
    content: '\ea62';
  }

  &.bold-export-2:before {
    content: '\ea63';
  }

  &.bold-folder-cloud:before {
    content: '\ea64';
  }

  &.bold-export-3:before {
    content: '\ea65';
  }

  &.bold-pet:before {
    content: '\ea66';
  }

  &.bold-message-edit:before {
    content: '\ea67';
  }

  &.bold-colorfilter:before {
    content: '\ea68';
  }

  &.bold-volume-high:before {
    content: '\ea69';
  }

  &.bold-lifebuoy:before {
    content: '\ea6a';
  }

  &.bold-gallery-add:before {
    content: '\ea6b';
  }

  &.bold-emoji-normal:before {
    content: '\ea6c';
  }

  &.bold-receipt-2:before {
    content: '\ea6d';
  }

  &.bold-wallet-add-1:before {
    content: '\ea6e';
  }

  &.bold-strongbox:before {
    content: '\ea6f';
  }

  &.bold-bluetooth-2:before {
    content: '\ea70';
  }

  &.bold-home-wifi:before {
    content: '\ea71';
  }

  &.bold-maximize-21:before {
    content: '\ea72';
  }

  &.bold-next:before {
    content: '\ea73';
  }

  &.bold-percentage-circle:before {
    content: '\ea74';
  }

  &.bold-edit:before {
    content: '\ea75';
  }

  &.bold-mouse-circle:before {
    content: '\ea76';
  }

  &.bold-more-2:before {
    content: '\ea77';
  }

  &.bold-folder-cross:before {
    content: '\ea78';
  }

  &.bold-message-square:before {
    content: '\ea79';
  }

  &.bold-arrow-circle-down:before {
    content: '\ea7a';
  }

  &.bold-cloud-sunny:before {
    content: '\ea7b';
  }

  &.bold-danger:before {
    content: '\ea7c';
  }

  &.bold-bag-cross-1:before {
    content: '\ea7d';
  }

  &.bold-bag-timer:before {
    content: '\ea7e';
  }

  &.bold-home-2:before {
    content: '\ea7f';
  }

  &.bold-sun:before {
    content: '\ea80';
  }

  &.bold-clipboard-export:before {
    content: '\ea81';
  }

  &.bold-cd:before {
    content: '\ea82';
  }

  &.bold-folder-2:before {
    content: '\ea83';
  }

  &.bold-bitcoin-card:before {
    content: '\ea84';
  }

  &.bold-star:before {
    content: '\ea85';
  }

  &.bold-security-time:before {
    content: '\ea86';
  }

  &.bold-hashtag:before {
    content: '\ea87';
  }

  &.bold-volume-up:before {
    content: '\ea88';
  }

  &.bold-weight-1:before {
    content: '\ea89';
  }

  &.bold-computing:before {
    content: '\ea8a';
  }

  &.bold-grid-9:before {
    content: '\ea8b';
  }

  &.bold-element-equal:before {
    content: '\ea8c';
  }

  &.bold-recovery-convert:before {
    content: '\ea8d';
  }

  &.bold-scan-barcode:before {
    content: '\ea8e';
  }

  &.bold-dollar-square:before {
    content: '\ea8f';
  }

  &.bold-direct-left:before {
    content: '\ea90';
  }

  &.bold-element-3:before {
    content: '\ea91';
  }

  &.bold-video-octagon:before {
    content: '\ea92';
  }

  &.bold-maximize-4:before {
    content: '\ea93';
  }

  &.bold-record-circle:before {
    content: '\ea94';
  }

  &.bold-monitor-recorder:before {
    content: '\ea95';
  }

  &.bold-pen-add:before {
    content: '\ea96';
  }

  &.bold-refresh-left-square:before {
    content: '\ea97';
  }

  &.bold-battery-3full:before {
    content: '\ea98';
  }

  &.bold-trash:before {
    content: '\ea99';
  }

  &.bold-export-1:before {
    content: '\ea9a';
  }

  &.bold-arrow-left-2:before {
    content: '\ea9b';
  }

  &.bold-arrow-left-3:before {
    content: '\ea9c';
  }

  &.bold-textalign-center:before {
    content: '\ea9d';
  }

  &.bold-firstline:before {
    content: '\ea9e';
  }

  &.bold-bus:before {
    content: '\ea9f';
  }

  &.bold-battery-empty-1:before {
    content: '\eaa0';
  }

  &.bold-cloud-minus:before {
    content: '\eaa1';
  }

  &.bold-grid-eraser:before {
    content: '\eaa2';
  }

  &.bold-glass:before {
    content: '\eaa3';
  }

  &.bold-element-2:before {
    content: '\eaa4';
  }

  &.bold-back-square:before {
    content: '\eaa5';
  }

  &.bold-text-underline:before {
    content: '\eaa6';
  }

  &.bold-emoji-sad:before {
    content: '\eaa7';
  }

  &.bold-document-download:before {
    content: '\eaa8';
  }

  &.bold-receipt-1:before {
    content: '\eaa9';
  }

  &.bold-bucket:before {
    content: '\eaaa';
  }

  &.bold-more:before {
    content: '\eaab';
  }

  &.bold-security:before {
    content: '\eaac';
  }

  &.bold-arrow-bottom:before {
    content: '\eaad';
  }

  &.bold-grid-8:before {
    content: '\eaae';
  }

  &.bold-music-playlist:before {
    content: '\eaaf';
  }

  &.bold-note-remove:before {
    content: '\eab0';
  }

  &.bold-pen-tool:before {
    content: '\eab1';
  }

  &.bold-quote-up-circle:before {
    content: '\eab2';
  }

  &.bold-discount-circle:before {
    content: '\eab3';
  }

  &.bold-user-search:before {
    content: '\eab4';
  }

  &.bold-truck:before {
    content: '\eab5';
  }

  &.bold-folder-minus:before {
    content: '\eab6';
  }

  &.bold-cloud-add:before {
    content: '\eab7';
  }

  &.bold-autobrightness:before {
    content: '\eab8';
  }

  &.bold-redo:before {
    content: '\eab9';
  }

  &.bold-hashtag-down:before {
    content: '\eaba';
  }

  &.bold-more-square:before {
    content: '\eabb';
  }

  &.bold-printer:before {
    content: '\eabc';
  }

  &.bold-link-2:before {
    content: '\eabd';
  }

  &.bold-camera:before {
    content: '\eabe';
  }

  &.bold-document-text:before {
    content: '\eabf';
  }

  &.bold-battery-empty:before {
    content: '\eac0';
  }

  &.bold-brifecase-tick:before {
    content: '\eac1';
  }

  &.bold-arrow-right-3:before {
    content: '\eac2';
  }

  &.bold-convert:before {
    content: '\eac3';
  }

  &.bold-archive-slash:before {
    content: '\eac4';
  }

  &.bold-speaker:before {
    content: '\eac5';
  }

  &.bold-play-add:before {
    content: '\eac6';
  }

  &.bold-simcard-2:before {
    content: '\eac7';
  }

  &.bold-empty-wallet-add:before {
    content: '\eac8';
  }

  &.bold-scroll:before {
    content: '\eac9';
  }

  &.bold-tick-circle:before {
    content: '\eaca';
  }

  &.bold-tag-cross:before {
    content: '\eacb';
  }

  &.bold-bill:before {
    content: '\eacc';
  }

  &.bold-mouse-1:before {
    content: '\eacd';
  }

  &.bold-user-octagon:before {
    content: '\eace';
  }

  &.bold-smart-car:before {
    content: '\eacf';
  }

  &.bold-direct:before {
    content: '\ead0';
  }

  &.bold-barcode:before {
    content: '\ead1';
  }

  &.bold-send:before {
    content: '\ead2';
  }

  &.bold-airplane-square:before {
    content: '\ead3';
  }

  &.bold-user-edit:before {
    content: '\ead4';
  }

  &.bold-maximize-1:before {
    content: '\ead5';
  }

  &.bold-diagram:before {
    content: '\ead6';
  }

  &.bold-notification-bing:before {
    content: '\ead7';
  }

  &.bold-like-1:before {
    content: '\ead8';
  }

  &.bold-verify:before {
    content: '\ead9';
  }

  &.bold-coin:before {
    content: '\eada';
  }

  &.bold-driving:before {
    content: '\eadb';
  }

  &.bold-text-block:before {
    content: '\eadc';
  }

  &.bold-notification:before {
    content: '\eadd';
  }

  &.bold-filter-edit:before {
    content: '\eade';
  }

  &.bold-filter-remove:before {
    content: '\eadf';
  }

  &.bold-directbox-receive:before {
    content: '\eae0';
  }

  &.bold-toggle-off-circle:before {
    content: '\eae1';
  }

  &.bold-backward:before {
    content: '\eae2';
  }

  &.bold-play:before {
    content: '\eae3';
  }

  &.bold-eye-slash:before {
    content: '\eae4';
  }

  &.bold-rulerpen:before {
    content: '\eae5';
  }

  &.bold-message-add-1:before {
    content: '\eae6';
  }

  &.bold-broom:before {
    content: '\eae7';
  }

  &.bold-crown:before {
    content: '\eae8';
  }

  &.bold-message-2:before {
    content: '\eae9';
  }

  &.bold-cloud-change:before {
    content: '\eaea';
  }

  &.bold-mask:before {
    content: '\eaeb';
  }

  &.bold-messages:before {
    content: '\eaec';
  }

  &.bold-empty-wallet-remove:before {
    content: '\eaed';
  }

  &.bold-format-circle:before {
    content: '\eaee';
  }

  &.bold-location-cross:before {
    content: '\eaef';
  }

  &.bold-box:before {
    content: '\eaf0';
  }

  &.bold-box-search:before {
    content: '\eaf1';
  }

  &.bold-trade:before {
    content: '\eaf2';
  }

  &.bold-award:before {
    content: '\eaf3';
  }

  &.bold-sticker:before {
    content: '\eaf4';
  }

  &.bold-video-remove:before {
    content: '\eaf5';
  }

  &.bold-airpod:before {
    content: '\eaf6';
  }

  &.bold-message:before {
    content: '\eaf7';
  }

  &.bold-document-copy:before {
    content: '\eaf8';
  }

  &.bold-safe-home:before {
    content: '\eaf9';
  }

  &.bold-textalign-justifyleft:before {
    content: '\eafa';
  }

  &.bold-message-favorite:before {
    content: '\eafb';
  }

  &.bold-story:before {
    content: '\eafc';
  }

  &.bold-flash-circle:before {
    content: '\eafd';
  }

  &.bold-paperclip:before {
    content: '\eafe';
  }

  &.bold-arrow-right-2:before {
    content: '\eaff';
  }

  &.bold-crown-1:before {
    content: '\eb00';
  }

  &.bold-previous:before {
    content: '\eb01';
  }

  &.bold-volume-mute:before {
    content: '\eb02';
  }

  &.bold-3dcube:before {
    content: '\eb03';
  }

  &.bold-grid-lock:before {
    content: '\eb04';
  }

  &.bold-setting:before {
    content: '\eb05';
  }

  &.bold-message-text:before {
    content: '\eb06';
  }

  &.bold-heart-add:before {
    content: '\eb07';
  }

  &.bold-command-square:before {
    content: '\eb08';
  }

  &.bold-document-cloud:before {
    content: '\eb09';
  }

  &.bold-link-1:before {
    content: '\eb0a';
  }

  &.bold-direct-inbox:before {
    content: '\eb0b';
  }

  &.bold-security-card:before {
    content: '\eb0c';
  }

  &.bold-cloud-lightning:before {
    content: '\eb0d';
  }

  &.bold-scan:before {
    content: '\eb0e';
  }

  &.bold-minus-cirlce:before {
    content: '\eb0f';
  }

  &.bold-lamp:before {
    content: '\eb10';
  }

  &.bold-search-favorite-1:before {
    content: '\eb11';
  }

  &.bold-simcard-1:before {
    content: '\eb12';
  }

  &.bold-flash-1:before {
    content: '\eb13';
  }

  &.bold-reserve:before {
    content: '\eb14';
  }

  &.bold-truck-tick:before {
    content: '\eb15';
  }

  &.bold-card-slash:before {
    content: '\eb16';
  }

  &.bold-folder-connection:before {
    content: '\eb17';
  }

  &.bold-calendar-edit:before {
    content: '\eb18';
  }

  &.bold-maximize-2:before {
    content: '\eb19';
  }

  &.bold-image:before {
    content: '\eb1a';
  }

  &.bold-align-right:before {
    content: '\eb1b';
  }

  &.bold-text:before {
    content: '\eb1c';
  }

  &.bold-gallery-import:before {
    content: '\eb1d';
  }

  &.bold-hashtag-1:before {
    content: '\eb1e';
  }

  &.bold-chart-square:before {
    content: '\eb1f';
  }

  &.bold-link-21:before {
    content: '\eb20';
  }

  &.bold-cloud-cross:before {
    content: '\eb21';
  }

  &.bold-refresh:before {
    content: '\eb22';
  }

  &.bold-hierarchy-square:before {
    content: '\eb23';
  }

  &.bold-box-add:before {
    content: '\eb24';
  }

  &.bold-hierarchy-square-2:before {
    content: '\eb25';
  }

  &.bold-align-horizontally:before {
    content: '\eb26';
  }

  &.bold-hierarchy-square-3:before {
    content: '\eb27';
  }

  &.bold-arrange-circle:before {
    content: '\eb28';
  }

  &.bold-sun-fog:before {
    content: '\eb29';
  }

  &.bold-bag:before {
    content: '\eb2a';
  }

  &.bold-element-4:before {
    content: '\eb2b';
  }

  &.bold-maximize-3:before {
    content: '\eb2c';
  }

  &.bold-call-calling:before {
    content: '\eb2d';
  }

  &.bold-bag-tick-2:before {
    content: '\eb2e';
  }

  &.bold-receipt-add:before {
    content: '\eb2f';
  }

  &.bold-copy:before {
    content: '\eb30';
  }

  &.bold-watch-status:before {
    content: '\eb31';
  }

  &.bold-smallcaps:before {
    content: '\eb32';
  }

  &.bold-arrow-right-1:before {
    content: '\eb33';
  }

  &.bold-ticket-expired:before {
    content: '\eb34';
  }

  &.bold-bag-cross:before {
    content: '\eb35';
  }

  &.bold-cloud-fog:before {
    content: '\eb36';
  }

  &.bold-info-circle:before {
    content: '\eb37';
  }

  &.bold-quote-down-square:before {
    content: '\eb38';
  }

  &.bold-people:before {
    content: '\eb39';
  }

  &.bold-convert-card:before {
    content: '\eb3a';
  }

  &.bold-book-1:before {
    content: '\eb3b';
  }

  &.bold-document-code:before {
    content: '\eb3c';
  }

  &.bold-speedometer:before {
    content: '\eb3d';
  }

  &.bold-truck-fast:before {
    content: '\eb3e';
  }

  &.bold-add:before {
    content: '\eb3f';
  }

  &.bold-user-add:before {
    content: '\eb40';
  }

  &.bold-sidebar-bottom:before {
    content: '\eb41';
  }

  &.bold-key-square:before {
    content: '\eb42';
  }

  &.bold-code-circle:before {
    content: '\eb43';
  }

  &.bold-archive-tick:before {
    content: '\eb44';
  }

  &.bold-grid-3:before {
    content: '\eb45';
  }

  &.bold-archive-add:before {
    content: '\eb46';
  }

  &.bold-arrow-circle-right:before {
    content: '\eb47';
  }

  &.bold-slider:before {
    content: '\eb48';
  }

  &.bold-wallet-check:before {
    content: '\eb49';
  }

  &.bold-backward-item:before {
    content: '\eb4a';
  }

  &.bold-directbox-default:before {
    content: '\eb4b';
  }

  &.bold-video-add:before {
    content: '\eb4c';
  }

  &.bold-card:before {
    content: '\eb4d';
  }

  &.bold-watch:before {
    content: '\eb4e';
  }

  &.bold-document-code-2:before {
    content: '\eb4f';
  }

  &.bold-route-square:before {
    content: '\eb50';
  }

  &.bold-hashtag-up:before {
    content: '\eb51';
  }

  &.bold-wifi:before {
    content: '\eb52';
  }

  &.bold-main-component:before {
    content: '\eb53';
  }

  &.bold-category-2:before {
    content: '\eb54';
  }

  &.bold-empty-wallet-time:before {
    content: '\eb55';
  }

  &.bold-frame-4:before {
    content: '\eb56';
  }

  &.bold-gallery-slash:before {
    content: '\eb57';
  }

  &.bold-finger-scan:before {
    content: '\eb58';
  }

  &.bold-additem:before {
    content: '\eb59';
  }

  &.bold-headphones:before {
    content: '\eb5a';
  }

  &.bold-routing-2:before {
    content: '\eb5b';
  }

  &.bold-global:before {
    content: '\eb5c';
  }

  &.bold-bluetooth:before {
    content: '\eb5d';
  }

  &.bold-arrange-circle-2:before {
    content: '\eb5e';
  }

  &.bold-direct-down:before {
    content: '\eb5f';
  }

  &.bold-archive-book:before {
    content: '\eb60';
  }

  &.bold-hospital:before {
    content: '\eb61';
  }

  &.bold-filter-add:before {
    content: '\eb62';
  }

  &.bold-trend-up:before {
    content: '\eb63';
  }

  &.bold-mirroring-screen:before {
    content: '\eb64';
  }

  &.bold-airdrop:before {
    content: '\eb65';
  }

  &.bold-ranking:before {
    content: '\eb66';
  }

  &.bold-notification-1:before {
    content: '\eb67';
  }

  &.bold-empty-wallet-change:before {
    content: '\eb68';
  }

  &.bold-check:before {
    content: '\eb69';
  }

  &.bold-chart-success:before {
    content: '\eb6a';
  }

  &.bold-document:before {
    content: '\eb6b';
  }

  &.bold-cloud-remove:before {
    content: '\eb6c';
  }

  &.bold-chart-21:before {
    content: '\eb6d';
  }

  &.bold-bluetooth-circle:before {
    content: '\eb6e';
  }

  &.bold-battery-full:before {
    content: '\eb6f';
  }

  &.bold-category:before {
    content: '\eb70';
  }

  &.bold-stickynote:before {
    content: '\eb71';
  }

  &.bold-video-time:before {
    content: '\eb72';
  }

  &.bold-grid-2:before {
    content: '\eb73';
  }

  &.bold-ranking-1:before {
    content: '\eb74';
  }

  &.bold-record:before {
    content: '\eb75';
  }

  &.bold-music-dashboard:before {
    content: '\eb76';
  }

  &.bold-scissor-1:before {
    content: '\eb77';
  }

  &.bold-user-remove:before {
    content: '\eb78';
  }

  &.bold-receive-square-2:before {
    content: '\eb79';
  }

  &.bold-call-slash:before {
    content: '\eb7a';
  }

  &.bold-document-1:before {
    content: '\eb7b';
  }

  &.bold-cpu:before {
    content: '\eb7c';
  }

  &.bold-document-favorite:before {
    content: '\eb7d';
  }

  &.bold-sms-tracking:before {
    content: '\eb7e';
  }

  &.bold-music-square:before {
    content: '\eb7f';
  }

  &.bold-ruler:before {
    content: '\eb80';
  }

  &.bold-buy-crypto:before {
    content: '\eb81';
  }

  &.bold-gemini-2:before {
    content: '\eb82';
  }

  &.bold-message-tick:before {
    content: '\eb83';
  }

  &.bold-emoji-happy:before {
    content: '\eb84';
  }

  &.bold-save-remove:before {
    content: '\eb85';
  }

  &.bold-save-2:before {
    content: '\eb86';
  }

  &.bold-pen-close:before {
    content: '\eb87';
  }

  &.bold-row-vertical:before {
    content: '\eb88';
  }

  &.bold-document-previous:before {
    content: '\eb89';
  }

  &.bold-arrow-circle-left:before {
    content: '\eb8a';
  }

  &.bold-card-remove-1:before {
    content: '\eb8b';
  }

  &.bold-house-2:before {
    content: '\eb8c';
  }

  &.bold-search-normal:before {
    content: '\eb8d';
  }

  &.bold-stop:before {
    content: '\eb8e';
  }

  &.bold-flash-circle-1:before {
    content: '\eb8f';
  }

  &.bold-gameboy:before {
    content: '\eb90';
  }

  &.bold-like-tag:before {
    content: '\eb91';
  }

  &.bold-search-status-1:before {
    content: '\eb92';
  }

  &.bold-gps:before {
    content: '\eb93';
  }

  &.bold-pen-remove:before {
    content: '\eb94';
  }

  &.bold-flag:before {
    content: '\eb95';
  }

  &.bold-teacher:before {
    content: '\eb96';
  }

  &.bold-convert-3d-cube:before {
    content: '\eb97';
  }

  &.bold-medal-star:before {
    content: '\eb98';
  }

  &.bold-user-cirlce-add:before {
    content: '\eb99';
  }

  &.bold-cake:before {
    content: '\eb9a';
  }

  &.bold-money-4:before {
    content: '\eb9b';
  }

  &.bold-code:before {
    content: '\eb9c';
  }

  &.bold-flash:before {
    content: '\eb9d';
  }

  &.bold-unlimited:before {
    content: '\eb9e';
  }

  &.bold-coffee:before {
    content: '\eb9f';
  }

  &.bold-paperclip-2:before {
    content: '\eba0';
  }

  &.bold-bucket-circle:before {
    content: '\eba1';
  }

  &.bold-backward-10-seconds:before {
    content: '\eba2';
  }

  &.bold-wallet:before {
    content: '\eba3';
  }

  &.bold-folder-favorite:before {
    content: '\eba4';
  }

  &.bold-user-minus:before {
    content: '\eba5';
  }

  &.bold-timer-pause:before {
    content: '\eba6';
  }

  &.bold-scissor:before {
    content: '\eba7';
  }

  &.bold-heart-slash:before {
    content: '\eba8';
  }

  &.bold-grid-1:before {
    content: '\eba9';
  }

  &.bold-note-add:before {
    content: '\ebaa';
  }

  &.bold-book:before {
    content: '\ebab';
  }

  &.bold-grid-edit:before {
    content: '\ebac';
  }

  &.bold-ticket-2:before {
    content: '\ebad';
  }

  &.bold-sagittarius:before {
    content: '\ebae';
  }

  &.bold-devices-1:before {
    content: '\ebaf';
  }

  &.bold-radio:before {
    content: '\ebb0';
  }

  &.bold-login:before {
    content: '\ebb1';
  }

  &.bold-slash:before {
    content: '\ebb2';
  }

  &.bold-repeate-music:before {
    content: '\ebb3';
  }

  &.bold-rotate-left-1:before {
    content: '\ebb4';
  }

  &.bold-shapes:before {
    content: '\ebb5';
  }

  &.bold-battery-charging:before {
    content: '\ebb6';
  }

  &.bold-ram-2:before {
    content: '\ebb7';
  }

  &.bold-message-time:before {
    content: '\ebb8';
  }

  &.bold-wifi-square:before {
    content: '\ebb9';
  }

  &.bold-message-question:before {
    content: '\ebba';
  }

  &.bold-tag-user:before {
    content: '\ebbb';
  }

  &.bold-card-pos:before {
    content: '\ebbc';
  }

  &.bold-voice-square:before {
    content: '\ebbd';
  }

  &.bold-box-1:before {
    content: '\ebbe';
  }

  &.bold-link-circle:before {
    content: '\ebbf';
  }

  &.bold-sms:before {
    content: '\ebc0';
  }

  &.bold-receipt-disscount:before {
    content: '\ebc1';
  }

  &.bold-discover:before {
    content: '\ebc2';
  }

  &.bold-add-square:before {
    content: '\ebc3';
  }

  &.bold-cloud-notif:before {
    content: '\ebc4';
  }

  &.bold-profile-add:before {
    content: '\ebc5';
  }

  &.bold-shield-cross:before {
    content: '\ebc6';
  }

  &.bold-grid-5:before {
    content: '\ebc7';
  }

  &.bold-note-2:before {
    content: '\ebc8';
  }

  &.bold-radar-2:before {
    content: '\ebc9';
  }

  &.bold-global-edit:before {
    content: '\ebca';
  }

  &.bold-gallery:before {
    content: '\ebcb';
  }

  &.bold-home-hashtag:before {
    content: '\ebcc';
  }

  &.bold-direct-up:before {
    content: '\ebcd';
  }

  &.bold-import:before {
    content: '\ebce';
  }

  &.bold-size:before {
    content: '\ebcf';
  }

  &.bold-note:before {
    content: '\ebd0';
  }

  &.bold-frame-2:before {
    content: '\ebd1';
  }

  &.bold-align-vertically:before {
    content: '\ebd2';
  }

  &.bold-kanban:before {
    content: '\ebd3';
  }

  &.bold-shop-remove:before {
    content: '\ebd4';
  }

  &.bold-video-circle:before {
    content: '\ebd5';
  }

  &.bold-code-1:before {
    content: '\ebd6';
  }

  &.bold-notification-favorite:before {
    content: '\ebd7';
  }

  &.bold-bookmark-2:before {
    content: '\ebd8';
  }

  &.bold-backward-5-seconds:before {
    content: '\ebd9';
  }

  &.bold-textalign-right:before {
    content: '\ebda';
  }

  &.bold-omega-square:before {
    content: '\ebdb';
  }

  &.bold-close-circle:before {
    content: '\ebdc';
  }

  &.bold-frame-3:before {
    content: '\ebdd';
  }

  &.bold-archive:before {
    content: '\ebde';
  }

  &.bold-game:before {
    content: '\ebdf';
  }

  &.bold-note-text:before {
    content: '\ebe0';
  }

  &.bold-forward-item:before {
    content: '\ebe1';
  }

  &.bold-global-search:before {
    content: '\ebe2';
  }

  &.bold-book-saved:before {
    content: '\ebe3';
  }

  &.bold-volume-cross:before {
    content: '\ebe4';
  }

  &.bold-status-up:before {
    content: '\ebe5';
  }

  &.bold-grid-4:before {
    content: '\ebe6';
  }

  &.bold-mask-1:before {
    content: '\ebe7';
  }

  &.bold-text-bold:before {
    content: '\ebe8';
  }

  &.bold-cloud-snow:before {
    content: '\ebe9';
  }

  &.bold-gallery-favorite:before {
    content: '\ebea';
  }

  &.bold-row-horizontal:before {
    content: '\ebeb';
  }

  &.bold-settings:before {
    content: '\ebec';
  }

  &.bold-minus-square:before {
    content: '\ebed';
  }

  &.bold-receive-square:before {
    content: '\ebee';
  }

  &.bold-programming-arrows:before {
    content: '\ebef';
  }

  &.bold-calendar-tick:before {
    content: '\ebf0';
  }

  &.bold-backward-15-seconds:before {
    content: '\ebf1';
  }

  &.bold-driver-2:before {
    content: '\ebf2';
  }

  &.bold-chart-1:before {
    content: '\ebf3';
  }

  &.bold-ship:before {
    content: '\ebf4';
  }

  &.bold-chart-3:before {
    content: '\ebf5';
  }

  &.bold-3d-rotate:before {
    content: '\ebf6';
  }

  &.bold-stop-circle:before {
    content: '\ebf7';
  }

  &.bold-brush:before {
    content: '\ebf8';
  }

  &.bold-arrow-right:before {
    content: '\ebf9';
  }

  &.bold-box-2:before {
    content: '\ebfa';
  }

  &.bold-money-forbidden:before {
    content: '\ebfb';
  }

  &.bold-grid-6:before {
    content: '\ebfc';
  }

  &.bold-key:before {
    content: '\ebfd';
  }

  &.bold-note-1:before {
    content: '\ebfe';
  }

  &.bold-toggle-off:before {
    content: '\ebff';
  }

  &.bold-empty-wallet:before {
    content: '\ec00';
  }

  &.bold-radar-1:before {
    content: '\ec01';
  }

  &.bold-link:before {
    content: '\ec02';
  }

  &.bold-receipt-square:before {
    content: '\ec03';
  }

  &.bold-instagram:before {
    content: '\ec04';
  }

  &.bold-lamp-on:before {
    content: '\ec05';
  }

  &.bold-clipboard:before {
    content: '\ec06';
  }

  &.bold-frame-1:before {
    content: '\ec07';
  }

  &.bold-coin-1:before {
    content: '\ec08';
  }

  &.bold-driver-refresh:before {
    content: '\ec09';
  }

  &.bold-dislike:before {
    content: '\ec0a';
  }

  &.bold-empty-wallet-tick:before {
    content: '\ec0b';
  }

  &.bold-money-2:before {
    content: '\ec0c';
  }

  &.bold-cloud-drizzle:before {
    content: '\ec0d';
  }

  &.bold-arrow-square-right:before {
    content: '\ec0e';
  }

  &.bold-money-3:before {
    content: '\ec0f';
  }

  &.bold-search-zoom-in:before {
    content: '\ec10';
  }

  &.bold-slider-vertical:before {
    content: '\ec11';
  }

  &.bold-filter-search:before {
    content: '\ec12';
  }

  &.bold-bag-2:before {
    content: '\ec13';
  }

  &.bold-language-circle:before {
    content: '\ec14';
  }

  &.bold-shopping-bag:before {
    content: '\ec15';
  }

  &.bold-health:before {
    content: '\ec16';
  }

  &.bold-buildings:before {
    content: '\ec17';
  }

  &.bold-call-outgoing:before {
    content: '\ec18';
  }

  &.bold-box-remove:before {
    content: '\ec19';
  }

  &.bold-lock:before {
    content: '\ec1a';
  }

  &.bold-user-tick:before {
    content: '\ec1b';
  }

  &.bold-mask-2:before {
    content: '\ec1c';
  }

  &.bold-grid-7:before {
    content: '\ec1d';
  }

  &.bold-save-minus:before {
    content: '\ec1e';
  }

  &.bold-profile-2user:before {
    content: '\ec1f';
  }

  &.bold-video-tick:before {
    content: '\ec20';
  }

  &.bold-location-tick:before {
    content: '\ec21';
  }

  &.bold-attach-circle:before {
    content: '\ec22';
  }

  &.bold-microphone-2:before {
    content: '\ec23';
  }

  &.bold-filter-tick:before {
    content: '\ec24';
  }

  &.bold-keyboard:before {
    content: '\ec25';
  }

  &.bold-path-2:before {
    content: '\ec26';
  }

  &.bold-chart-2:before {
    content: '\ec27';
  }

  &.bold-folder-open:before {
    content: '\ec28';
  }

  &.bold-search-status:before {
    content: '\ec29';
  }

  &.bold-bubble:before {
    content: '\ec2a';
  }

  &.bold-sms-search:before {
    content: '\ec2b';
  }

  &.bold-message-add:before {
    content: '\ec2c';
  }

  &.bold-warning-2:before {
    content: '\ec2d';
  }

  &.bold-brush-3:before {
    content: '\ec2e';
  }

  &.bold-arrow-up-1:before {
    content: '\ec2f';
  }

  &.bold-lock-slash:before {
    content: '\ec30';
  }

  &.bold-cpu-setting:before {
    content: '\ec31';
  }

  &.bold-shield-security:before {
    content: '\ec32';
  }

  &.bold-calendar-2:before {
    content: '\ec33';
  }

  &.bold-radar:before {
    content: '\ec34';
  }

  &.bold-maximize:before {
    content: '\ec35';
  }

  &.bold-shield-tick:before {
    content: '\ec36';
  }

  &.bold-magic-star:before {
    content: '\ec37';
  }

  &.bold-percentage-square:before {
    content: '\ec38';
  }

  &.bold-mouse-square:before {
    content: '\ec39';
  }

  &.bold-fatrows:before {
    content: '\ec3a';
  }

  &.bold-discount-shape:before {
    content: '\ec3b';
  }

  &.bold-devices:before {
    content: '\ec3c';
  }

  &.bold-arrow-2:before {
    content: '\ec3d';
  }

  &.bold-message-circle:before {
    content: '\ec3e';
  }

  &.bold-pen-tool-2:before {
    content: '\ec3f';
  }

  &.bold-monitor-mobbile:before {
    content: '\ec40';
  }

  &.bold-smileys:before {
    content: '\ec41';
  }

  &.bold-arrow-3:before {
    content: '\ec42';
  }

  &.bold-ticket-star:before {
    content: '\ec43';
  }

  &.bold-grammerly:before {
    content: '\ec44';
  }

  &.bold-user-square:before {
    content: '\ec45';
  }

  &.bold-airpods:before {
    content: '\ec46';
  }

  &.bold-transaction-minus:before {
    content: '\ec47';
  }

  &.bold-mobile-programming:before {
    content: '\ec48';
  }

  &.bold-book-square:before {
    content: '\ec49';
  }

  &.bold-music-filter:before {
    content: '\ec4a';
  }

  &.bold-calendar-circle:before {
    content: '\ec4b';
  }

  &.bold-home-trend-down:before {
    content: '\ec4c';
  }

  &.bold-microphone:before {
    content: '\ec4d';
  }

  &.bold-sms-notification:before {
    content: '\ec4e';
  }

  &.bold-sidebar-right:before {
    content: '\ec4f';
  }

  &.bold-map:before {
    content: '\ec50';
  }

  &.bold-moneys:before {
    content: '\ec51';
  }

  &.bold-brush-2:before {
    content: '\ec52';
  }

  &.bold-chart-fail:before {
    content: '\ec53';
  }

  &.bold-direct-right:before {
    content: '\ec54';
  }

  &.bold-cloud-plus:before {
    content: '\ec55';
  }

  &.bold-directbox-send:before {
    content: '\ec56';
  }

  &.bold-wallet-1:before {
    content: '\ec57';
  }

  &.bold-hierarchy-3:before {
    content: '\ec58';
  }

  &.bold-calendar-search:before {
    content: '\ec59';
  }

  &.bold-wallet-3:before {
    content: '\ec5a';
  }

  &.bold-music-square-add:before {
    content: '\ec5b';
  }

  &.bold-arrow-circle-up:before {
    content: '\ec5c';
  }

  &.bold-calendar-add:before {
    content: '\ec5d';
  }

  &.bold-align-bottom:before {
    content: '\ec5e';
  }

  &.bold-arrow-up-2:before {
    content: '\ec5f';
  }

  &.bold-location-slash:before {
    content: '\ec60';
  }

  &.bold-briefcase:before {
    content: '\ec61';
  }

  &.bold-sidebar-top:before {
    content: '\ec62';
  }

  &.bold-user-tag:before {
    content: '\ec63';
  }

  &.bold-tag:before {
    content: '\ec64';
  }

  &.bold-calendar-1:before {
    content: '\ec65';
  }

  &.bold-text-italic:before {
    content: '\ec66';
  }

  &.bold-crop:before {
    content: '\ec67';
  }

  &.bold-call-remove:before {
    content: '\ec68';
  }

  &.bold-refresh-right-square:before {
    content: '\ec69';
  }

  &.bold-convertshape-2:before {
    content: '\ec6a';
  }

  &.bold-blur:before {
    content: '\ec6b';
  }

  &.bold-export:before {
    content: '\ec6c';
  }

  &.bold-ticket:before {
    content: '\ec6d';
  }

  &.bold-brifecase-cross:before {
    content: '\ec6e';
  }

  &.bold-quote-up-square:before {
    content: '\ec6f';
  }

  &.bold-candle:before {
    content: '\ec70';
  }

  &.bold-signpost:before {
    content: '\ec71';
  }

  &.bold-creative-commons:before {
    content: '\ec72';
  }

  &.bold-flag-2:before {
    content: '\ec73';
  }

  &.bold-message-notif:before {
    content: '\ec74';
  }

  &.bold-camera-slash:before {
    content: '\ec75';
  }

  &.bold-logout:before {
    content: '\ec76';
  }

  &.bold-24-support:before {
    content: '\ec77';
  }

  &.bold-message-search:before {
    content: '\ec78';
  }

  &.bold-money-tick:before {
    content: '\ec79';
  }

  &.bold-bag-happy:before {
    content: '\ec7a';
  }

  &.bold-card-edit:before {
    content: '\ec7b';
  }

  &.bold-dollar-circle:before {
    content: '\ec7c';
  }

  &.bold-star-1:before {
    content: '\ec7d';
  }

  &.bold-arrow-up-3:before {
    content: '\ec7e';
  }

  &.bold-brush-1:before {
    content: '\ec7f';
  }

  &.bold-music-square-search:before {
    content: '\ec80';
  }

  &.bold-music:before {
    content: '\ec81';
  }

  &.bold-received:before {
    content: '\ec82';
  }

  &.bold-textalign-justifycenter:before {
    content: '\ec83';
  }

  &.bold-card-tick-1:before {
    content: '\ec84';
  }

  &.bold-play-cricle:before {
    content: '\ec85';
  }

  &.bold-hierarchy-2:before {
    content: '\ec86';
  }

  &.bold-wallet-2:before {
    content: '\ec87';
  }

  &.bold-copyright:before {
    content: '\ec88';
  }

  &.bold-shop:before {
    content: '\ec89';
  }

  &.bold-home:before {
    content: '\ec8a';
  }

  &.bold-milk:before {
    content: '\ec8b';
  }

  &.bold-edit-2:before {
    content: '\ec8c';
  }

  &.bold-clipboard-tick:before {
    content: '\ec8d';
  }

  &.bold-forward-5-seconds:before {
    content: '\ec8e';
  }

  &.bold-arrow-square:before {
    content: '\ec8f';
  }

  &.bold-like-dislike:before {
    content: '\ec90';
  }

  &.bold-format-square:before {
    content: '\ec91';
  }

  &.bold-quote-up:before {
    content: '\ec92';
  }

  &.bold-sidebar-left:before {
    content: '\ec93';
  }

  &.bold-heart-search:before {
    content: '\ec94';
  }

  &.bold-card-receive:before {
    content: '\ec95';
  }

  &.bold-rotate-right-1:before {
    content: '\ec96';
  }

  &.bold-home-trend-up:before {
    content: '\ec97';
  }

  &.bold-message-text-1:before {
    content: '\ec98';
  }

  &.bold-arrow:before {
    content: '\ec99';
  }

  &.bold-money-change:before {
    content: '\ec9a';
  }

  &.bold-user:before {
    content: '\ec9b';
  }

  &.bold-wallet-search:before {
    content: '\ec9c';
  }

  &.bold-audio-square:before {
    content: '\ec9d';
  }

  &.bold-more-circle:before {
    content: '\ec9e';
  }

  &.bold-translate:before {
    content: '\ec9f';
  }

  &.bold-document-forward:before {
    content: '\eca0';
  }

  &.bold-money-time:before {
    content: '\eca1';
  }

  &.bold-aquarius:before {
    content: '\eca2';
  }

  &.bold-tag-2:before {
    content: '\eca3';
  }

  &.bold-map-1:before {
    content: '\eca4';
  }

  &.bold-document-like:before {
    content: '\eca5';
  }

  &.bold-chart:before {
    content: '\eca6';
  }

  &.bold-tick-square:before {
    content: '\eca7';
  }

  &.bold-password-check:before {
    content: '\eca8';
  }

  &.bold-presention-chart:before {
    content: '\eca9';
  }

  &.bold-gps-slash:before {
    content: '\ecaa';
  }

  &.bold-gallery-tick:before {
    content: '\ecab';
  }

  &.bold-brush-4:before {
    content: '\ecac';
  }

  &.bold-path:before {
    content: '\ecad';
  }

  &.bold-profile-remove:before {
    content: '\ecae';
  }

  &.bold-import-1:before {
    content: '\ecaf';
  }

  &.bold-repeat:before {
    content: '\ecb0';
  }

  &.bold-refresh-square-2:before {
    content: '\ecb1';
  }

  &.bold-arrange-square:before {
    content: '\ecb2';
  }

  &.bold-import-3:before {
    content: '\ecb3';
  }

  &.bold-document-text-1:before {
    content: '\ecb4';
  }

  &.bold-information:before {
    content: '\ecb5';
  }

  &.bold-picture-frame:before {
    content: '\ecb6';
  }

  &.bold-arrow-square-left:before {
    content: '\ecb7';
  }

  &.bold-play-circle:before {
    content: '\ecb8';
  }

  &.bold-menu-1:before {
    content: '\ecb9';
  }

  &.bold-rotate-left:before {
    content: '\ecba';
  }

  &.bold-receipt-2-1:before {
    content: '\ecbb';
  }

  &.bold-wind:before {
    content: '\ecbc';
  }

  &.bold-money-add:before {
    content: '\ecbd';
  }

  &.bold-note-favorite:before {
    content: '\ecbe';
  }

  &.bold-trush-square:before {
    content: '\ecbf';
  }

  &.bold-card-coin:before {
    content: '\ecc0';
  }

  &.bold-buildings-2:before {
    content: '\ecc1';
  }

  &.bold-house:before {
    content: '\ecc2';
  }

  &.bold-card-send:before {
    content: '\ecc3';
  }

  &.bold-sound:before {
    content: '\ecc4';
  }

  &.bold-refresh-circle:before {
    content: '\ecc5';
  }

  &.bold-location-add:before {
    content: '\ecc6';
  }

  &.bold-gemini:before {
    content: '\ecc7';
  }

  &.bold-shield-search:before {
    content: '\ecc8';
  }

  &.bold-archive-minus:before {
    content: '\ecc9';
  }

  &.bold-timer-start:before {
    content: '\ecca';
  }

  &.bold-quote-down-circle:before {
    content: '\eccb';
  }

  &.bold-shapes-1:before {
    content: '\eccc';
  }

  &.bold-brifecase-timer:before {
    content: '\eccd';
  }

  &.bold-heart-tick:before {
    content: '\ecce';
  }

  &.bold-textalign-left:before {
    content: '\eccf';
  }

  &.bold-ghost:before {
    content: '\ecd0';
  }

  &.bold-lamp-1:before {
    content: '\ecd1';
  }

  &.bold-quote-down:before {
    content: '\ecd2';
  }

  &.bold-battery-disable:before {
    content: '\ecd3';
  }

  &.bold-arrow-down:before {
    content: '\ecd4';
  }

  &.bold-search-zoom-in-1:before {
    content: '\ecd5';
  }

  &.bold-paintbucket:before {
    content: '\ecd6';
  }

  &.bold-printer-slash:before {
    content: '\ecd7';
  }

  &.bold-subtitle:before {
    content: '\ecd8';
  }

  &.bold-pause-circle:before {
    content: '\ecd9';
  }

  &.bold-receipt:before {
    content: '\ecda';
  }

  &.bold-like:before {
    content: '\ecdb';
  }

  &.bold-eraser:before {
    content: '\ecdc';
  }

  &.bold-musicnote:before {
    content: '\ecdd';
  }

  &.bold-toggle-on-circle:before {
    content: '\ecde';
  }

  &.bold-shop-add:before {
    content: '\ecdf';
  }

  &.bold-clipboard-close:before {
    content: '\ece0';
  }

  &.bold-task:before {
    content: '\ece1';
  }

  &.bold-slider-vertical-1:before {
    content: '\ece2';
  }

  &.bold-driver:before {
    content: '\ece3';
  }

  &.bold-sun-1:before {
    content: '\ece4';
  }

  &.bold-toggle-on:before {
    content: '\ece5';
  }

  &.bold-triangle:before {
    content: '\ece6';
  }

  &.bold-gallery-remove:before {
    content: '\ece7';
  }

  &.bold-bag-tick:before {
    content: '\ece8';
  }

  &.bold-heart-circle:before {
    content: '\ece9';
  }

  &.bold-directbox-notif:before {
    content: '\ecea';
  }

  &.bold-arrow-square-up:before {
    content: '\eceb';
  }

  &.bold-import-2:before {
    content: '\ecec';
  }

  &.bold-profile-delete:before {
    content: '\eced';
  }

  &.bold-smart-home:before {
    content: '\ecee';
  }

  &.bold-microphone-slash-1:before {
    content: '\ecef';
  }

  &.bold-convertshape:before {
    content: '\ecf0';
  }

  &.outline-data-2 .path1:before {
    content: '\ecf1';
    color: rgb(41, 45, 50);
  }

  &.outline-data-2 .path2:before {
    content: '\ecf2';
    margin-left: -1em;
    color: rgb(41, 45, 50);
  }

  &.outline-data-2 .path3:before {
    content: '\ecf3';
    margin-left: -1em;
    color: rgb(41, 45, 50);
  }

  &.outline-data-2 .path4:before {
    content: '\ecf4';
    margin-left: -1em;
    color: rgb(41, 45, 50);
  }

  &.outline-data-2 .path5:before {
    content: '\ecf5';
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }

  &.outline-data-2 .path6:before {
    content: '\ecf6';
    margin-left: -1em;
    color: rgb(41, 45, 50);
  }

  &.outline-data-2 .path7:before {
    content: '\ecf7';
    margin-left: -1em;
    color: rgb(41, 45, 50);
  }

  &.outline-data-2 .path8:before {
    content: '\ecf8';
    margin-left: -1em;
    color: rgb(41, 45, 50);
  }

  &.outline-heart:before {
    content: '\ecf9';
  }

  &.outline-calendar-remove:before {
    content: '\ecfa';
  }

  &.outline-refresh-2:before {
    content: '\ecfb';
  }

  &.outline-align-left:before {
    content: '\ecfc';
  }

  &.outline-clipboard-text:before {
    content: '\ecfd';
  }

  &.outline-send-2:before {
    content: '\ecfe';
  }

  &.outline-direct-notification:before {
    content: '\ecff';
  }

  &.outline-card-tick:before {
    content: '\ed00';
  }

  &.outline-money-remove:before {
    content: '\ed01';
  }

  &.outline-voice-cricle:before {
    content: '\ed02';
  }

  &.outline-forward-10-seconds:before {
    content: '\ed03';
  }

  &.outline-direct-normal:before {
    content: '\ed04';
  }

  &.outline-weight:before {
    content: '\ed05';
  }

  &.outline-candle-2:before {
    content: '\ed06';
  }

  &.outline-moon:before {
    content: '\ed07';
  }

  &.outline-gallery-export:before {
    content: '\ed08';
  }

  &.outline-music-circle:before {
    content: '\ed09';
  }

  &.outline-element-plus:before {
    content: '\ed0a';
  }

  &.outline-scanning:before {
    content: '\ed0b';
  }

  &.outline-document-normal:before {
    content: '\ed0c';
  }

  &.outline-wallet-minus:before {
    content: '\ed0d';
  }

  &.outline-card-add:before {
    content: '\ed0e';
  }

  &.outline-3d-cube-scan:before {
    content: '\ed0f';
  }

  &.outline-minus:before {
    content: '\ed10';
  }

  &.outline-monitor:before {
    content: '\ed11';
  }

  &.outline-timer:before {
    content: '\ed12';
  }

  &.outline-arrow-down-1:before {
    content: '\ed13';
  }

  &.outline-color-swatch:before {
    content: '\ed14';
  }

  &.outline-archive-1:before {
    content: '\ed15';
  }

  &.outline-copy-success:before {
    content: '\ed16';
  }

  &.outline-alarm:before {
    content: '\ed17';
  }

  &.outline-folder:before {
    content: '\ed18';
  }

  &.outline-cards:before {
    content: '\ed19';
  }

  &.outline-message-remove:before {
    content: '\ed1a';
  }

  &.outline-search-favorite:before {
    content: '\ed1b';
  }

  &.outline-bezier:before {
    content: '\ed1c';
  }

  &.outline-building:before {
    content: '\ed1d';
  }

  &.outline-graph:before {
    content: '\ed1e';
  }

  &.outline-slider-horizontal:before {
    content: '\ed1f';
  }

  &.outline-logout-1:before {
    content: '\ed20';
  }

  &.outline-document-sketch:before {
    content: '\ed21';
  }

  &.outline-menu-board:before {
    content: '\ed22';
  }

  &.outline-mirror:before {
    content: '\ed23';
  }

  &.outline-blend-2:before {
    content: '\ed24';
  }

  &.outline-lamp-slash:before {
    content: '\ed25';
  }

  &.outline-receipt-text:before {
    content: '\ed26';
  }

  &.outline-man:before {
    content: '\ed27';
  }

  &.outline-truck-remove:before {
    content: '\ed28';
  }

  &.outline-layer:before {
    content: '\ed29';
  }

  &.outline-sms-edit:before {
    content: '\ed2a';
  }

  &.outline-music-square-remove:before {
    content: '\ed2b';
  }

  &.outline-routing:before {
    content: '\ed2c';
  }

  &.outline-login-1:before {
    content: '\ed2d';
  }

  &.outline-mouse:before {
    content: '\ed2e';
  }

  &.outline-3d-square:before {
    content: '\ed2f';
  }

  &.outline-discover-1:before {
    content: '\ed30';
  }

  &.outline-calculator:before {
    content: '\ed31';
  }

  &.outline-airplane:before {
    content: '\ed32';
  }

  &.outline-happyemoji:before {
    content: '\ed33';
  }

  &.outline-shopping-cart:before {
    content: '\ed34';
  }

  &.outline-finger-cricle:before {
    content: '\ed35';
  }

  &.outline-save-add:before {
    content: '\ed36';
  }

  &.outline-chrome:before {
    content: '\ed37';
  }

  &.outline-volume-slash:before {
    content: '\ed38';
  }

  &.outline-bucket-square:before {
    content: '\ed39';
  }

  &.outline-note-21:before {
    content: '\ed3a';
  }

  &.outline-security-user:before {
    content: '\ed3b';
  }

  &.outline-repeate-one:before {
    content: '\ed3c';
  }

  &.outline-send-1:before {
    content: '\ed3d';
  }

  &.outline-money-recive:before {
    content: '\ed3e';
  }

  &.outline-sms-star:before {
    content: '\ed3f';
  }

  &.outline-search-zoom-out:before {
    content: '\ed40';
  }

  &.outline-building-4:before {
    content: '\ed41';
  }

  &.outline-like-shapes:before {
    content: '\ed42';
  }

  &.outline-menu:before {
    content: '\ed43';
  }

  &.outline-volume-low-1:before {
    content: '\ed44';
  }

  &.outline-setting-3:before {
    content: '\ed45';
  }

  &.outline-maximize-circle:before {
    content: '\ed46';
  }

  &.outline-receipt-discount:before {
    content: '\ed47';
  }

  &.outline-strongbox-2:before {
    content: '\ed48';
  }

  &.outline-component:before {
    content: '\ed49';
  }

  &.outline-trend-down:before {
    content: '\ed4a';
  }

  &.outline-favorite-chart:before {
    content: '\ed4b';
  }

  &.outline-math:before {
    content: '\ed4c';
  }

  &.outline-screenmirroring:before {
    content: '\ed4d';
  }

  &.outline-receipt-minus:before {
    content: '\ed4e';
  }

  &.outline-lock-circle:before {
    content: '\ed4f';
  }

  &.outline-level:before {
    content: '\ed50';
  }

  &.outline-document-filter:before {
    content: '\ed51';
  }

  &.outline-call-add:before {
    content: '\ed52';
  }

  &.outline-programming-arrow:before {
    content: '\ed53';
  }

  &.outline-rotate-right:before {
    content: '\ed54';
  }

  &.outline-note-square:before {
    content: '\ed55';
  }

  &.outline-status:before {
    content: '\ed56';
  }

  &.outline-shield-slash:before {
    content: '\ed57';
  }

  &.outline-arrow-down-2:before {
    content: '\ed58';
  }

  &.outline-archive-2:before {
    content: '\ed59';
  }

  &.outline-bookmark:before {
    content: '\ed5a';
  }

  &.outline-blend:before {
    content: '\ed5b';
  }

  &.outline-forward:before {
    content: '\ed5c';
  }

  &.outline-call-minus:before {
    content: '\ed5d';
  }

  &.outline-box-tick:before {
    content: '\ed5e';
  }

  &.outline-setting-2:before {
    content: '\ed5f';
  }

  &.outline-pause:before {
    content: '\ed60';
  }

  &.outline-cpu-charge:before {
    content: '\ed61';
  }

  &.outline-diamonds:before {
    content: '\ed62';
  }

  &.outline-mini-music-sqaure:before {
    content: '\ed63';
  }

  &.outline-cup:before {
    content: '\ed64';
  }

  &.outline-shuffle:before {
    content: '\ed65';
  }

  &.outline-lovely:before {
    content: '\ed66';
  }

  &.outline-cloud:before {
    content: '\ed67';
  }

  &.outline-task-square:before {
    content: '\ed68';
  }

  &.outline-wallet-money:before {
    content: '\ed69';
  }

  &.outline-heart-edit:before {
    content: '\ed6a';
  }

  &.outline-clipboard-import:before {
    content: '\ed6b';
  }

  &.outline-close-square:before {
    content: '\ed6c';
  }

  &.outline-receipt-search:before {
    content: '\ed6d';
  }

  &.outline-omega-circle:before {
    content: '\ed6e';
  }

  &.outline-profile-circle:before {
    content: '\ed6f';
  }

  &.outline-scanner:before {
    content: '\ed70';
  }

  &.outline-volume-low:before {
    content: '\ed71';
  }

  &.outline-message-minus:before {
    content: '\ed72';
  }

  &.outline-keyboard-open:before {
    content: '\ed73';
  }

  &.outline-microphone-slash:before {
    content: '\ed74';
  }

  &.outline-ram:before {
    content: '\ed75';
  }

  &.outline-data:before {
    content: '\ed76';
  }

  &.outline-buliding:before {
    content: '\ed77';
  }

  &.outline-gas-station:before {
    content: '\ed78';
  }

  &.outline-arrow-left:before {
    content: '\ed79';
  }

  &.outline-lamp-charge:before {
    content: '\ed7a';
  }

  &.outline-calendar:before {
    content: '\ed7b';
  }

  &.outline-video-slash:before {
    content: '\ed7c';
  }

  &.outline-arrow-swap:before {
    content: '\ed7d';
  }

  &.outline-medal:before {
    content: '\ed7e';
  }

  &.outline-link-square:before {
    content: '\ed7f';
  }

  &.outline-pharagraphspacing:before {
    content: '\ed80';
  }

  &.outline-money-send:before {
    content: '\ed81';
  }

  &.outline-add-circle:before {
    content: '\ed82';
  }

  &.outline-filter:before {
    content: '\ed83';
  }

  &.outline-box-time:before {
    content: '\ed84';
  }

  &.outline-bitcoin-convert:before {
    content: '\ed85';
  }

  &.outline-activity:before {
    content: '\ed86';
  }

  &.outline-tree:before {
    content: '\ed87';
  }

  &.outline-security-safe:before {
    content: '\ed88';
  }

  &.outline-filter-square:before {
    content: '\ed89';
  }

  &.outline-external-drive:before {
    content: '\ed8a';
  }

  &.outline-video-vertical:before {
    content: '\ed8b';
  }

  &.outline-video:before {
    content: '\ed8c';
  }

  &.outline-undo:before {
    content: '\ed8d';
  }

  &.outline-messages-1:before {
    content: '\ed8e';
  }

  &.outline-sort:before {
    content: '\ed8f';
  }

  &.outline-microscope:before {
    content: '\ed90';
  }

  &.outline-folder-add:before {
    content: '\ed91';
  }

  &.outline-colors-square:before {
    content: '\ed92';
  }

  &.outline-money:before {
    content: '\ed93';
  }

  &.outline-arrow-up:before {
    content: '\ed94';
  }

  &.outline-video-horizontal:before {
    content: '\ed95';
  }

  &.outline-document-upload:before {
    content: '\ed96';
  }

  &.outline-video-square:before {
    content: '\ed97';
  }

  &.outline-arrow-square-down:before {
    content: '\ed98';
  }

  &.outline-share:before {
    content: '\ed99';
  }

  &.outline-tag-right:before {
    content: '\ed9a';
  }

  &.outline-language-square:before {
    content: '\ed9b';
  }

  &.outline-clock-1:before {
    content: '\ed9c';
  }

  &.outline-heart-remove:before {
    content: '\ed9d';
  }

  &.outline-forward-15-seconds:before {
    content: '\ed9e';
  }

  &.outline-messages-3:before {
    content: '\ed9f';
  }

  &.outline-car:before {
    content: '\eda0';
  }

  &.outline-setting-5:before {
    content: '\eda1';
  }

  &.outline-gift:before {
    content: '\eda2';
  }

  &.outline-glass-1:before {
    content: '\eda3';
  }

  &.outline-arrange-square-2:before {
    content: '\eda4';
  }

  &.outline-search-normal-1:before {
    content: '\eda5';
  }

  &.outline-receipt-edit:before {
    content: '\eda6';
  }

  &.outline-electricity:before {
    content: '\eda7';
  }

  &.outline-device-message:before {
    content: '\eda8';
  }

  &.outline-profile-tick:before {
    content: '\eda9';
  }

  &.outline-location:before {
    content: '\edaa';
  }

  &.outline-path-square:before {
    content: '\edab';
  }

  &.outline-wallet-remove:before {
    content: '\edac';
  }

  &.outline-bluetooth-rectangle:before {
    content: '\edad';
  }

  &.outline-attach-square:before {
    content: '\edae';
  }

  &.outline-headphone:before {
    content: '\edaf';
  }

  &.outline-personalcard:before {
    content: '\edb0';
  }

  &.outline-cloud-connection:before {
    content: '\edb1';
  }

  &.outline-gallery-edit:before {
    content: '\edb2';
  }

  &.outline-mobile:before {
    content: '\edb3';
  }

  &.outline-wallet-add:before {
    content: '\edb4';
  }

  &.outline-eye:before {
    content: '\edb5';
  }

  &.outline-call:before {
    content: '\edb6';
  }

  &.outline-direct-send:before {
    content: '\edb7';
  }

  &.outline-repeat-circle:before {
    content: '\edb8';
  }

  &.outline-message-programming:before {
    content: '\edb9';
  }

  &.outline-send-sqaure-2:before {
    content: '\edba';
  }

  &.outline-courthouse:before {
    content: '\edbb';
  }

  &.outline-slider-horizontal-1:before {
    content: '\edbc';
  }

  &.outline-textalign-justifyright:before {
    content: '\edbd';
  }

  &.outline-call-incoming:before {
    content: '\edbe';
  }

  &.outline-wind-2:before {
    content: '\edbf';
  }

  &.outline-clock:before {
    content: '\edc0';
  }

  &.outline-command:before {
    content: '\edc1';
  }

  &.outline-setting-4:before {
    content: '\edc2';
  }

  &.outline-messages-2:before {
    content: '\edc3';
  }

  &.outline-building-3:before {
    content: '\edc4';
  }

  &.outline-ticket-discount:before {
    content: '\edc5';
  }

  &.outline-forbidden:before {
    content: '\edc6';
  }

  &.outline-hierarchy:before {
    content: '\edc7';
  }

  &.outline-play-remove:before {
    content: '\edc8';
  }

  &.outline-simcard:before {
    content: '\edc9';
  }

  &.outline-bitcoin-refresh:before {
    content: '\edca';
  }

  &.outline-music-library-2:before {
    content: '\edcb';
  }

  &.outline-search-zoom-out-1:before {
    content: '\edcc';
  }

  &.outline-3square:before {
    content: '\edcd';
  }

  &.outline-home-1:before {
    content: '\edce';
  }

  &.outline-timer-1:before {
    content: '\edcf';
  }

  &.outline-call-received:before {
    content: '\edd0';
  }

  &.outline-magicpen:before {
    content: '\edd1';
  }

  &.outline-global-refresh:before {
    content: '\edd2';
  }

  &.outline-receipt-item:before {
    content: '\edd3';
  }

  &.outline-arrow-swap-horizontal:before {
    content: '\edd4';
  }

  &.outline-video-play:before {
    content: '\edd5';
  }

  &.outline-lock-1:before {
    content: '\edd6';
  }

  &.outline-judge:before {
    content: '\edd7';
  }

  &.outline-frame:before {
    content: '\edd8';
  }

  &.outline-bank:before {
    content: '\edd9';
  }

  &.outline-location-minus:before {
    content: '\edda';
  }

  &.outline-woman:before {
    content: '\eddb';
  }

  &.outline-eraser-1:before {
    content: '\eddc';
  }

  &.outline-designtools:before {
    content: '\eddd';
  }

  &.outline-truck-time:before {
    content: '\edde';
  }

  &.outline-forward-square:before {
    content: '\eddf';
  }

  &.outline-card-remove:before {
    content: '\ede0';
  }

  &.outline-music-play:before {
    content: '\ede1';
  }

  &.outline-star-slash:before {
    content: '\ede2';
  }

  &.outline-notification-status:before {
    content: '\ede3';
  }

  &.outline-forbidden-2:before {
    content: '\ede4';
  }

  &.outline-unlock:before {
    content: '\ede5';
  }

  &.outline-send-square:before {
    content: '\ede6';
  }

  &.outline-flash-slash:before {
    content: '\ede7';
  }

  &.outline-arrow-left-1:before {
    content: '\ede8';
  }

  &.outline-export-2:before {
    content: '\ede9';
  }

  &.outline-folder-cloud:before {
    content: '\edea';
  }

  &.outline-export-3:before {
    content: '\edeb';
  }

  &.outline-pet:before {
    content: '\edec';
  }

  &.outline-message-edit:before {
    content: '\eded';
  }

  &.outline-colorfilter:before {
    content: '\edee';
  }

  &.outline-volume-high:before {
    content: '\edef';
  }

  &.outline-lifebuoy:before {
    content: '\edf0';
  }

  &.outline-gallery-add:before {
    content: '\edf1';
  }

  &.outline-emoji-normal:before {
    content: '\edf2';
  }

  &.outline-receipt-2:before {
    content: '\edf3';
  }

  &.outline-wallet-add-1:before {
    content: '\edf4';
  }

  &.outline-strongbox:before {
    content: '\edf5';
  }

  &.outline-bluetooth-2:before {
    content: '\edf6';
  }

  &.outline-home-wifi:before {
    content: '\edf7';
  }

  &.outline-maximize-21:before {
    content: '\edf8';
  }

  &.outline-next:before {
    content: '\edf9';
  }

  &.outline-percentage-circle:before {
    content: '\edfa';
  }

  &.outline-edit:before {
    content: '\edfb';
  }

  &.outline-mouse-circle:before {
    content: '\edfc';
  }

  &.outline-arrow-right-4:before {
    content: '\edfd';
  }

  &.outline-more-2:before {
    content: '\edfe';
  }

  &.outline-folder-cross:before {
    content: '\edff';
  }

  &.outline-message-square:before {
    content: '\ee00';
  }

  &.outline-arrow-circle-down:before {
    content: '\ee01';
  }

  &.outline-cloud-sunny:before {
    content: '\ee02';
  }

  &.outline-danger:before {
    content: '\ee03';
  }

  &.outline-bag-cross-1:before {
    content: '\ee04';
  }

  &.outline-bag-timer:before {
    content: '\ee05';
  }

  &.outline-home-2:before {
    content: '\ee06';
  }

  &.outline-sun:before {
    content: '\ee07';
  }

  &.outline-clipboard-export:before {
    content: '\ee08';
  }

  &.outline-cd:before {
    content: '\ee09';
  }

  &.outline-folder-2:before {
    content: '\ee0a';
  }

  &.outline-bitcoin-card:before {
    content: '\ee0b';
  }

  &.outline-star:before {
    content: '\ee0c';
  }

  &.outline-security-time:before {
    content: '\ee0d';
  }

  &.outline-hashtag:before {
    content: '\ee0e';
  }

  &.outline-volume-up:before {
    content: '\ee0f';
  }

  &.outline-weight-1:before {
    content: '\ee10';
  }

  &.outline-computing:before {
    content: '\ee11';
  }

  &.outline-grid-9:before {
    content: '\ee12';
  }

  &.outline-element-equal:before {
    content: '\ee13';
  }

  &.outline-recovery-convert:before {
    content: '\ee14';
  }

  &.outline-scan-barcode:before {
    content: '\ee15';
  }

  &.outline-dollar-square:before {
    content: '\ee16';
  }

  &.outline-direct-left:before {
    content: '\ee17';
  }

  &.outline-element-3:before {
    content: '\ee18';
  }

  &.outline-video-octagon:before {
    content: '\ee19';
  }

  &.outline-maximize-4:before {
    content: '\ee1a';
  }

  &.outline-record-circle:before {
    content: '\ee1b';
  }

  &.outline-monitor-recorder:before {
    content: '\ee1c';
  }

  &.outline-pen-add:before {
    content: '\ee1d';
  }

  &.outline-refresh-left-square:before {
    content: '\ee1e';
  }

  &.outline-battery-3full:before {
    content: '\ee1f';
  }

  &.outline-trash:before {
    content: '\ee20';
  }

  &.outline-export-1:before {
    content: '\ee21';
  }

  &.outline-arrow-left-2:before {
    content: '\ee22';
  }

  &.outline-arrow-left-3:before {
    content: '\ee23';
  }

  &.outline-textalign-center:before {
    content: '\ee24';
  }

  &.outline-firstline:before {
    content: '\ee25';
  }

  &.outline-bus:before {
    content: '\ee26';
  }

  &.outline-battery-empty-1:before {
    content: '\ee27';
  }

  &.outline-cloud-minus:before {
    content: '\ee28';
  }

  &.outline-grid-eraser:before {
    content: '\ee29';
  }

  &.outline-glass:before {
    content: '\ee2a';
  }

  &.outline-element-2:before {
    content: '\ee2b';
  }

  &.outline-back-square:before {
    content: '\ee2c';
  }

  &.outline-text-underline:before {
    content: '\ee2d';
  }

  &.outline-emoji-sad:before {
    content: '\ee2e';
  }

  &.outline-document-download:before {
    content: '\ee2f';
  }

  &.outline-receipt-1:before {
    content: '\ee30';
  }

  &.outline-bucket:before {
    content: '\ee31';
  }

  &.outline-more:before {
    content: '\ee32';
  }

  &.outline-security:before {
    content: '\ee33';
  }

  &.outline-arrow-bottom:before {
    content: '\ee34';
  }

  &.outline-grid-8:before {
    content: '\ee35';
  }

  &.outline-music-playlist:before {
    content: '\ee36';
  }

  &.outline-note-remove:before {
    content: '\ee37';
  }

  &.outline-pen-tool:before {
    content: '\ee38';
  }

  &.outline-quote-up-circle:before {
    content: '\ee39';
  }

  &.outline-discount-circle:before {
    content: '\ee3a';
  }

  &.outline-user-search:before {
    content: '\ee3b';
  }

  &.outline-truck:before {
    content: '\ee3c';
  }

  &.outline-folder-minus:before {
    content: '\ee3d';
  }

  &.outline-cloud-add:before {
    content: '\ee3e';
  }

  &.outline-autobrightness:before {
    content: '\ee3f';
  }

  &.outline-redo:before {
    content: '\ee40';
  }

  &.outline-hashtag-down:before {
    content: '\ee41';
  }

  &.outline-more-square:before {
    content: '\ee42';
  }

  &.outline-printer:before {
    content: '\ee43';
  }

  &.outline-link-2:before {
    content: '\ee44';
  }

  &.outline-camera:before {
    content: '\ee45';
  }

  &.outline-document-text:before {
    content: '\ee46';
  }

  &.outline-battery-empty:before {
    content: '\ee47';
  }

  &.outline-brifecase-tick:before {
    content: '\ee48';
  }

  &.outline-arrow-right-3:before {
    content: '\ee49';
  }

  &.outline-convert:before {
    content: '\ee4a';
  }

  &.outline-archive-slash:before {
    content: '\ee4b';
  }

  &.outline-speaker:before {
    content: '\ee4c';
  }

  &.outline-play-add:before {
    content: '\ee4d';
  }

  &.outline-simcard-2:before {
    content: '\ee4e';
  }

  &.outline-empty-wallet-add:before {
    content: '\ee4f';
  }

  &.outline-scroll:before {
    content: '\ee50';
  }

  &.outline-tick-circle:before {
    content: '\ee51';
  }

  &.outline-tag-cross:before {
    content: '\ee52';
  }

  &.outline-bill:before {
    content: '\ee53';
  }

  &.outline-mouse-1:before {
    content: '\ee54';
  }

  &.outline-user-octagon:before {
    content: '\ee55';
  }

  &.outline-smart-car:before {
    content: '\ee56';
  }

  &.outline-direct:before {
    content: '\ee57';
  }

  &.outline-barcode:before {
    content: '\ee58';
  }

  &.outline-send:before {
    content: '\ee59';
  }

  &.outline-airplane-square:before {
    content: '\ee5a';
  }

  &.outline-user-edit:before {
    content: '\ee5b';
  }

  &.outline-maximize-1:before {
    content: '\ee5c';
  }

  &.outline-diagram:before {
    content: '\ee5d';
  }

  &.outline-notification-bing:before {
    content: '\ee5e';
  }

  &.outline-like-1:before {
    content: '\ee5f';
  }

  &.outline-verify:before {
    content: '\ee60';
  }

  &.outline-coin:before {
    content: '\ee61';
  }

  &.outline-driving:before {
    content: '\ee62';
  }

  &.outline-text-block:before {
    content: '\ee63';
  }

  &.outline-notification:before {
    content: '\ee64';
  }

  &.outline-filter-edit:before {
    content: '\ee65';
  }

  &.outline-filter-remove:before {
    content: '\ee66';
  }

  &.outline-directbox-receive:before {
    content: '\ee67';
  }

  &.outline-toggle-off-circle:before {
    content: '\ee68';
  }

  &.outline-backward:before {
    content: '\ee69';
  }

  &.outline-play:before {
    content: '\ee6a';
  }

  &.outline-eye-slash:before {
    content: '\ee6b';
  }

  &.outline-rulerpen:before {
    content: '\ee6c';
  }

  &.outline-message-add-1:before {
    content: '\ee6d';
  }

  &.outline-broom:before {
    content: '\ee6e';
  }

  &.outline-crown:before {
    content: '\ee6f';
  }

  &.outline-message-2:before {
    content: '\ee70';
  }

  &.outline-cloud-change:before {
    content: '\ee71';
  }

  &.outline-mask:before {
    content: '\ee72';
  }

  &.outline-messages:before {
    content: '\ee73';
  }

  &.outline-empty-wallet-remove:before {
    content: '\ee74';
  }

  &.outline-format-circle:before {
    content: '\ee75';
  }

  &.outline-location-cross:before {
    content: '\ee76';
  }

  &.outline-box:before {
    content: '\ee77';
  }

  &.outline-box-search:before {
    content: '\ee78';
  }

  &.outline-trade:before {
    content: '\ee79';
  }

  &.outline-award:before {
    content: '\ee7a';
  }

  &.outline-sticker:before {
    content: '\ee7b';
  }

  &.outline-video-remove:before {
    content: '\ee7c';
  }

  &.outline-airpod:before {
    content: '\ee7d';
  }

  &.outline-message:before {
    content: '\ee7e';
  }

  &.outline-document-copy:before {
    content: '\ee7f';
  }

  &.outline-safe-home:before {
    content: '\ee80';
  }

  &.outline-textalign-justifyleft:before {
    content: '\ee81';
  }

  &.outline-message-favorite:before {
    content: '\ee82';
  }

  &.outline-story:before {
    content: '\ee83';
  }

  &.outline-flash-circle:before {
    content: '\ee84';
  }

  &.outline-paperclip:before {
    content: '\ee85';
  }

  &.outline-arrow-right-2:before {
    content: '\ee86';
  }

  &.outline-crown-1:before {
    content: '\ee87';
  }

  &.outline-previous:before {
    content: '\ee88';
  }

  &.outline-volume-mute:before {
    content: '\ee89';
  }

  &.outline-3dcube:before {
    content: '\ee8a';
  }

  &.outline-grid-lock:before {
    content: '\ee8b';
  }

  &.outline-setting:before {
    content: '\ee8c';
  }

  &.outline-message-text:before {
    content: '\ee8d';
  }

  &.outline-heart-add:before {
    content: '\ee8e';
  }

  &.outline-command-square:before {
    content: '\ee8f';
  }

  &.outline-document-cloud:before {
    content: '\ee90';
  }

  &.outline-link-1:before {
    content: '\ee91';
  }

  &.outline-direct-inbox:before {
    content: '\ee92';
  }

  &.outline-security-card:before {
    content: '\ee93';
  }

  &.outline-cloud-lightning:before {
    content: '\ee94';
  }

  &.outline-scan:before {
    content: '\ee95';
  }

  &.outline-minus-cirlce:before {
    content: '\ee96';
  }

  &.outline-lamp:before {
    content: '\ee97';
  }

  &.outline-search-favorite-1:before {
    content: '\ee98';
  }

  &.outline-simcard-1:before {
    content: '\ee99';
  }

  &.outline-flash-1:before {
    content: '\ee9a';
  }

  &.outline-reserve:before {
    content: '\ee9b';
  }

  &.outline-truck-tick:before {
    content: '\ee9c';
  }

  &.outline-card-slash:before {
    content: '\ee9d';
  }

  &.outline-folder-connection:before {
    content: '\ee9e';
  }

  &.outline-calendar-edit:before {
    content: '\ee9f';
  }

  &.outline-maximize-2:before {
    content: '\eea0';
  }

  &.outline-image:before {
    content: '\eea1';
  }

  &.outline-align-right:before {
    content: '\eea2';
  }

  &.outline-text:before {
    content: '\eea3';
  }

  &.outline-gallery-import:before {
    content: '\eea4';
  }

  &.outline-hashtag-1:before {
    content: '\eea5';
  }

  &.outline-chart-square:before {
    content: '\eea6';
  }

  &.outline-link-21:before {
    content: '\eea7';
  }

  &.outline-cloud-cross:before {
    content: '\eea8';
  }

  &.outline-refresh:before {
    content: '\eea9';
  }

  &.outline-hierarchy-square:before {
    content: '\eeaa';
  }

  &.outline-box-add:before {
    content: '\eeab';
  }

  &.outline-hierarchy-square-2:before {
    content: '\eeac';
  }

  &.outline-align-horizontally:before {
    content: '\eead';
  }

  &.outline-hierarchy-square-3:before {
    content: '\eeae';
  }

  &.outline-arrange-circle:before {
    content: '\eeaf';
  }

  &.outline-sun-fog:before {
    content: '\eeb0';
  }

  &.outline-bag:before {
    content: '\eeb1';
  }

  &.outline-element-4:before {
    content: '\eeb2';
  }

  &.outline-maximize-3:before {
    content: '\eeb3';
  }

  &.outline-call-calling:before {
    content: '\eeb4';
  }

  &.outline-bag-tick-2:before {
    content: '\eeb5';
  }

  &.outline-receipt-add:before {
    content: '\eeb6';
  }

  &.outline-copy:before {
    content: '\eeb7';
  }

  &.outline-watch-status:before {
    content: '\eeb8';
  }

  &.outline-smallcaps:before {
    content: '\eeb9';
  }

  &.outline-arrow-right-1:before {
    content: '\eeba';
  }

  &.outline-ticket-expired:before {
    content: '\eebb';
  }

  &.outline-bag-cross:before {
    content: '\eebc';
  }

  &.outline-cloud-fog:before {
    content: '\eebd';
  }

  &.outline-info-circle:before {
    content: '\eebe';
  }

  &.outline-quote-down-square:before {
    content: '\eebf';
  }

  &.outline-people:before {
    content: '\eec0';
  }

  &.outline-convert-card:before {
    content: '\eec1';
  }

  &.outline-book-1:before {
    content: '\eec2';
  }

  &.outline-document-code:before {
    content: '\eec3';
  }

  &.outline-speedometer:before {
    content: '\eec4';
  }

  &.outline-truck-fast:before {
    content: '\eec5';
  }

  &.outline-add:before {
    content: '\eec6';
  }

  &.outline-user-add:before {
    content: '\eec7';
  }

  &.outline-sidebar-bottom:before {
    content: '\eec8';
  }

  &.outline-key-square:before {
    content: '\eec9';
  }

  &.outline-code-circle:before {
    content: '\eeca';
  }

  &.outline-archive-tick:before {
    content: '\eecb';
  }

  &.outline-grid-3:before {
    content: '\eecc';
  }

  &.outline-archive-add:before {
    content: '\eecd';
  }

  &.outline-arrow-circle-right:before {
    content: '\eece';
  }

  &.outline-slider:before {
    content: '\eecf';
  }

  &.outline-wallet-check:before {
    content: '\eed0';
  }

  &.outline-backward-item:before {
    content: '\eed1';
  }

  &.outline-directbox-default:before {
    content: '\eed2';
  }

  &.outline-video-add:before {
    content: '\eed3';
  }

  &.outline-card:before {
    content: '\eed4';
  }

  &.outline-watch:before {
    content: '\eed5';
  }

  &.outline-document-code-2:before {
    content: '\eed6';
  }

  &.outline-route-square:before {
    content: '\eed7';
  }

  &.outline-hashtag-up:before {
    content: '\eed8';
  }

  &.outline-wifi:before {
    content: '\eed9';
  }

  &.outline-main-component:before {
    content: '\eeda';
  }

  &.outline-category-2:before {
    content: '\eedb';
  }

  &.outline-empty-wallet-time:before {
    content: '\eedc';
  }

  &.outline-frame-4:before {
    content: '\eedd';
  }

  &.outline-gallery-slash:before {
    content: '\eede';
  }

  &.outline-finger-scan:before {
    content: '\eedf';
  }

  &.outline-additem:before {
    content: '\eee0';
  }

  &.outline-headphones:before {
    content: '\eee1';
  }

  &.outline-routing-2:before {
    content: '\eee2';
  }

  &.outline-global:before {
    content: '\eee3';
  }

  &.outline-bluetooth:before {
    content: '\eee4';
  }

  &.outline-arrange-circle-2:before {
    content: '\eee5';
  }

  &.outline-direct-down:before {
    content: '\eee6';
  }

  &.outline-archive-book:before {
    content: '\eee7';
  }

  &.outline-hospital:before {
    content: '\eee8';
  }

  &.outline-filter-add:before {
    content: '\eee9';
  }

  &.outline-trend-up:before {
    content: '\eeea';
  }

  &.outline-mirroring-screen:before {
    content: '\eeeb';
  }

  &.outline-airdrop:before {
    content: '\eeec';
  }

  &.outline-ranking:before {
    content: '\eeed';
  }

  &.outline-notification-1:before {
    content: '\eeee';
  }

  &.outline-empty-wallet-change:before {
    content: '\eeef';
  }

  &.outline-check:before {
    content: '\eef0';
  }

  &.outline-chart-success:before {
    content: '\eef1';
  }

  &.outline-document:before {
    content: '\eef2';
  }

  &.outline-cloud-remove:before {
    content: '\eef3';
  }

  &.outline-chart-21:before {
    content: '\eef4';
  }

  &.outline-bluetooth-circle:before {
    content: '\eef5';
  }

  &.outline-battery-full:before {
    content: '\eef6';
  }

  &.outline-category:before {
    content: '\eef7';
  }

  &.outline-stickynote:before {
    content: '\eef8';
  }

  &.outline-video-time:before {
    content: '\eef9';
  }

  &.outline-grid-2:before {
    content: '\eefa';
  }

  &.outline-ranking-1:before {
    content: '\eefb';
  }

  &.outline-record:before {
    content: '\eefc';
  }

  &.outline-music-dashboard:before {
    content: '\eefd';
  }

  &.outline-scissor-1:before {
    content: '\eefe';
  }

  &.outline-user-remove:before {
    content: '\eeff';
  }

  &.outline-receive-square-2:before {
    content: '\ef00';
  }

  &.outline-call-slash:before {
    content: '\ef01';
  }

  &.outline-document-1:before {
    content: '\ef02';
  }

  &.outline-cpu:before {
    content: '\ef03';
  }

  &.outline-document-favorite:before {
    content: '\ef04';
  }

  &.outline-sms-tracking:before {
    content: '\ef05';
  }

  &.outline-music-square:before {
    content: '\ef06';
  }

  &.outline-ruler:before {
    content: '\ef07';
  }

  &.outline-buy-crypto:before {
    content: '\ef08';
  }

  &.outline-gemini-2:before {
    content: '\ef09';
  }

  &.outline-message-tick:before {
    content: '\ef0a';
  }

  &.outline-emoji-happy:before {
    content: '\ef0b';
  }

  &.outline-save-remove:before {
    content: '\ef0c';
  }

  &.outline-save-2:before {
    content: '\ef0d';
  }

  &.outline-pen-close:before {
    content: '\ef0e';
  }

  &.outline-row-vertical:before {
    content: '\ef0f';
  }

  &.outline-document-previous:before {
    content: '\ef10';
  }

  &.outline-arrow-circle-left:before {
    content: '\ef11';
  }

  &.outline-card-remove-1:before {
    content: '\ef12';
  }

  &.outline-house-2:before {
    content: '\ef13';
  }

  &.outline-search-normal:before {
    content: '\ef14';
  }

  &.outline-stop:before {
    content: '\ef15';
  }

  &.outline-flash-circle-1:before {
    content: '\ef16';
  }

  &.outline-gameboy:before {
    content: '\ef17';
  }

  &.outline-like-tag:before {
    content: '\ef18';
  }

  &.outline-search-status-1:before {
    content: '\ef19';
  }

  &.outline-gps:before {
    content: '\ef1a';
  }

  &.outline-pen-remove:before {
    content: '\ef1b';
  }

  &.outline-flag:before {
    content: '\ef1c';
  }

  &.outline-teacher:before {
    content: '\ef1d';
  }

  &.outline-convert-3d-cube:before {
    content: '\ef1e';
  }

  &.outline-medal-star:before {
    content: '\ef1f';
  }

  &.outline-user-cirlce-add:before {
    content: '\ef20';
  }

  &.outline-icon:before {
    content: '\ef21';
  }

  &.outline-cake:before {
    content: '\ef22';
  }

  &.outline-money-4:before {
    content: '\ef23';
  }

  &.outline-code:before {
    content: '\ef24';
  }

  &.outline-flash:before {
    content: '\ef25';
  }

  &.outline-unlimited:before {
    content: '\ef26';
  }

  &.outline-coffee:before {
    content: '\ef27';
  }

  &.outline-paperclip-2:before {
    content: '\ef28';
  }

  &.outline-bucket-circle:before {
    content: '\ef29';
  }

  &.outline-backward-10-seconds:before {
    content: '\ef2a';
  }

  &.outline-wallet:before {
    content: '\ef2b';
  }

  &.outline-folder-favorite:before {
    content: '\ef2c';
  }

  &.outline-user-minus:before {
    content: '\ef2d';
  }

  &.outline-timer-pause:before {
    content: '\ef2e';
  }

  &.outline-scissor:before {
    content: '\ef2f';
  }

  &.outline-heart-slash:before {
    content: '\ef30';
  }

  &.outline-grid-1:before {
    content: '\ef31';
  }

  &.outline-note-add:before {
    content: '\ef32';
  }

  &.outline-book:before {
    content: '\ef33';
  }

  &.outline-grid-edit:before {
    content: '\ef34';
  }

  &.outline-ticket-2:before {
    content: '\ef35';
  }

  &.outline-sagittarius:before {
    content: '\ef36';
  }

  &.outline-devices-1:before {
    content: '\ef37';
  }

  &.outline-radio:before {
    content: '\ef38';
  }

  &.outline-login:before {
    content: '\ef39';
  }

  &.outline-slash:before {
    content: '\ef3a';
  }

  &.outline-repeate-music:before {
    content: '\ef3b';
  }

  &.outline-rotate-left-1:before {
    content: '\ef3c';
  }

  &.outline-shapes:before {
    content: '\ef3d';
  }

  &.outline-battery-charging:before {
    content: '\ef3e';
  }

  &.outline-ram-2:before {
    content: '\ef3f';
  }

  &.outline-message-time:before {
    content: '\ef40';
  }

  &.outline-wifi-square:before {
    content: '\ef41';
  }

  &.outline-message-question:before {
    content: '\ef42';
  }

  &.outline-tag-user:before {
    content: '\ef43';
  }

  &.outline-card-pos:before {
    content: '\ef44';
  }

  &.outline-voice-square:before {
    content: '\ef45';
  }

  &.outline-box-1:before {
    content: '\ef46';
  }

  &.outline-link-circle:before {
    content: '\ef47';
  }

  &.outline-sms:before {
    content: '\ef48';
  }

  &.outline-receipt-disscount:before {
    content: '\ef49';
  }

  &.outline-discover:before {
    content: '\ef4a';
  }

  &.outline-add-square:before {
    content: '\ef4b';
  }

  &.outline-cloud-notif:before {
    content: '\ef4c';
  }

  &.outline-profile-add:before {
    content: '\ef4d';
  }

  &.outline-shield-cross:before {
    content: '\ef4e';
  }

  &.outline-grid-5:before {
    content: '\ef4f';
  }

  &.outline-note-2:before {
    content: '\ef50';
  }

  &.outline-radar-2:before {
    content: '\ef51';
  }

  &.outline-global-edit:before {
    content: '\ef52';
  }

  &.outline-gallery:before {
    content: '\ef53';
  }

  &.outline-home-hashtag:before {
    content: '\ef54';
  }

  &.outline-direct-up:before {
    content: '\ef55';
  }

  &.outline-import:before {
    content: '\ef56';
  }

  &.outline-size:before {
    content: '\ef57';
  }

  &.outline-note:before {
    content: '\ef58';
  }

  &.outline-frame-2:before {
    content: '\ef59';
  }

  &.outline-align-vertically:before {
    content: '\ef5a';
  }

  &.outline-kanban:before {
    content: '\ef5b';
  }

  &.outline-shop-remove:before {
    content: '\ef5c';
  }

  &.outline-video-circle:before {
    content: '\ef5d';
  }

  &.outline-shield:before {
    content: '\ef5e';
  }

  &.outline-code-1:before {
    content: '\ef5f';
  }

  &.outline-notification-favorite:before {
    content: '\ef60';
  }

  &.outline-bookmark-2:before {
    content: '\ef61';
  }

  &.outline-backward-5-seconds:before {
    content: '\ef62';
  }

  &.outline-textalign-right:before {
    content: '\ef63';
  }

  &.outline-omega-square:before {
    content: '\ef64';
  }

  &.outline-close-circle:before {
    content: '\ef65';
  }

  &.outline-frame-3:before {
    content: '\ef66';
  }

  &.outline-archive:before {
    content: '\ef67';
  }

  &.outline-game:before {
    content: '\ef68';
  }

  &.outline-note-text:before {
    content: '\ef69';
  }

  &.outline-forward-item:before {
    content: '\ef6a';
  }

  &.outline-global-search:before {
    content: '\ef6b';
  }

  &.outline-book-saved:before {
    content: '\ef6c';
  }

  &.outline-volume-cross:before {
    content: '\ef6d';
  }

  &.outline-status-up:before {
    content: '\ef6e';
  }

  &.outline-grid-4:before {
    content: '\ef6f';
  }

  &.outline-mask-1:before {
    content: '\ef70';
  }

  &.outline-text-bold:before {
    content: '\ef71';
  }

  &.outline-cloud-snow:before {
    content: '\ef72';
  }

  &.outline-gallery-favorite:before {
    content: '\ef73';
  }

  &.outline-row-horizontal:before {
    content: '\ef74';
  }

  &.outline-settings:before {
    content: '\ef75';
  }

  &.outline-minus-square:before {
    content: '\ef76';
  }

  &.outline-receive-square:before {
    content: '\ef77';
  }

  &.outline-programming-arrows:before {
    content: '\ef78';
  }

  &.outline-calendar-tick:before {
    content: '\ef79';
  }

  &.outline-backward-15-seconds:before {
    content: '\ef7a';
  }

  &.outline-driver-2:before {
    content: '\ef7b';
  }

  &.outline-chart-1:before {
    content: '\ef7c';
  }

  &.outline-ship:before {
    content: '\ef7d';
  }

  &.outline-chart-3:before {
    content: '\ef7e';
  }

  &.outline-3d-rotate:before {
    content: '\ef7f';
  }

  &.outline-stop-circle:before {
    content: '\ef80';
  }

  &.outline-brush:before {
    content: '\ef81';
  }

  &.outline-arrow-right:before {
    content: '\ef82';
  }

  &.outline-box-2:before {
    content: '\ef83';
  }

  &.outline-money-forbidden:before {
    content: '\ef84';
  }

  &.outline-grid-6:before {
    content: '\ef85';
  }

  &.outline-key:before {
    content: '\ef86';
  }

  &.outline-note-1:before {
    content: '\ef87';
  }

  &.outline-toggle-off:before {
    content: '\ef88';
  }

  &.outline-empty-wallet:before {
    content: '\ef89';
  }

  &.outline-radar-1:before {
    content: '\ef8a';
  }

  &.outline-link:before {
    content: '\ef8b';
  }

  &.outline-receipt-square:before {
    content: '\ef8c';
  }

  &.outline-instagram:before {
    content: '\ef8d';
  }

  &.outline-lamp-on:before {
    content: '\ef8e';
  }

  &.outline-clipboard:before {
    content: '\ef8f';
  }

  &.outline-frame-1:before {
    content: '\ef90';
  }

  &.outline-coin-1:before {
    content: '\ef91';
  }

  &.outline-driver-refresh:before {
    content: '\ef92';
  }

  &.outline-dislike:before {
    content: '\ef93';
  }

  &.outline-empty-wallet-tick:before {
    content: '\ef94';
  }

  &.outline-money-2:before {
    content: '\ef95';
  }

  &.outline-cloud-drizzle:before {
    content: '\ef96';
  }

  &.outline-money-3:before {
    content: '\ef97';
  }

  &.outline-search-zoom-in:before {
    content: '\ef98';
  }

  &.outline-slider-vertical:before {
    content: '\ef99';
  }

  &.outline-filter-search:before {
    content: '\ef9a';
  }

  &.outline-bag-2:before {
    content: '\ef9b';
  }

  &.outline-language-circle:before {
    content: '\ef9c';
  }

  &.outline-shopping-bag:before {
    content: '\ef9d';
  }

  &.outline-health:before {
    content: '\ef9e';
  }

  &.outline-buildings:before {
    content: '\ef9f';
  }

  &.outline-call-outgoing:before {
    content: '\efa0';
  }

  &.outline-box-remove:before {
    content: '\efa1';
  }

  &.outline-lock:before {
    content: '\efa2';
  }

  &.outline-user-tick:before {
    content: '\efa3';
  }

  &.outline-mask-2:before {
    content: '\efa4';
  }

  &.outline-grid-7:before {
    content: '\efa5';
  }

  &.outline-save-minus:before {
    content: '\efa6';
  }

  &.outline-profile-2user:before {
    content: '\efa7';
  }

  &.outline-video-tick:before {
    content: '\efa8';
  }

  &.outline-location-tick:before {
    content: '\efa9';
  }

  &.outline-attach-circle:before {
    content: '\efaa';
  }

  &.outline-microphone-2:before {
    content: '\efab';
  }

  &.outline-filter-tick:before {
    content: '\efac';
  }

  &.outline-notification-circle:before {
    content: '\efad';
  }

  &.outline-keyboard:before {
    content: '\efae';
  }

  &.outline-path-2:before {
    content: '\efaf';
  }

  &.outline-chart-2:before {
    content: '\efb0';
  }

  &.outline-folder-open:before {
    content: '\efb1';
  }

  &.outline-search-status:before {
    content: '\efb2';
  }

  &.outline-bubble:before {
    content: '\efb3';
  }

  &.outline-sms-search:before {
    content: '\efb4';
  }

  &.outline-message-add:before {
    content: '\efb5';
  }

  &.outline-warning-2:before {
    content: '\efb6';
  }

  &.outline-brush-3:before {
    content: '\efb7';
  }

  &.outline-arrow-up-1:before {
    content: '\efb8';
  }

  &.outline-lock-slash:before {
    content: '\efb9';
  }

  &.outline-cpu-setting:before {
    content: '\efba';
  }

  &.outline-calendar-2:before {
    content: '\efbb';
  }

  &.outline-radar:before {
    content: '\efbc';
  }

  &.outline-maximize:before {
    content: '\efbd';
  }

  &.outline-shield-tick:before {
    content: '\efbe';
  }

  &.outline-magic-star:before {
    content: '\efbf';
  }

  &.outline-percentage-square:before {
    content: '\efc0';
  }

  &.outline-mouse-square:before {
    content: '\efc1';
  }

  &.outline-fatrows:before {
    content: '\efc2';
  }

  &.outline-discount-shape:before {
    content: '\efc3';
  }

  &.outline-devices:before {
    content: '\efc4';
  }

  &.outline-arrow-2:before {
    content: '\efc5';
  }

  &.outline-message-circle:before {
    content: '\efc6';
  }

  &.outline-pen-tool-2:before {
    content: '\efc7';
  }

  &.outline-monitor-mobbile:before {
    content: '\efc8';
  }

  &.outline-smileys:before {
    content: '\efc9';
  }

  &.outline-arrow-3:before {
    content: '\efca';
  }

  &.outline-ticket-star:before {
    content: '\efcb';
  }

  &.outline-grammerly:before {
    content: '\efcc';
  }

  &.outline-user-square:before {
    content: '\efcd';
  }

  &.outline-airpods:before {
    content: '\efce';
  }

  &.outline-transaction-minus:before {
    content: '\efcf';
  }

  &.outline-mobile-programming:before {
    content: '\efd0';
  }

  &.outline-book-square:before {
    content: '\efd1';
  }

  &.outline-music-filter:before {
    content: '\efd2';
  }

  &.outline-calendar-circle:before {
    content: '\efd3';
  }

  &.outline-home-trend-down:before {
    content: '\efd4';
  }

  &.outline-microphone:before {
    content: '\efd5';
  }

  &.outline-sms-notification:before {
    content: '\efd6';
  }

  &.outline-sidebar-right:before {
    content: '\efd7';
  }

  &.outline-map:before {
    content: '\efd8';
  }

  &.outline-moneys:before {
    content: '\efd9';
  }

  &.outline-brush-2:before {
    content: '\efda';
  }

  &.outline-chart-fail:before {
    content: '\efdb';
  }

  &.outline-direct-right:before {
    content: '\efdc';
  }

  &.outline-cloud-plus:before {
    content: '\efdd';
  }

  &.outline-directbox-send:before {
    content: '\efde';
  }

  &.outline-wallet-1:before {
    content: '\efdf';
  }

  &.outline-hierarchy-3:before {
    content: '\efe0';
  }

  &.outline-calendar-search:before {
    content: '\efe1';
  }

  &.outline-wallet-3:before {
    content: '\efe2';
  }

  &.outline-music-square-add:before {
    content: '\efe3';
  }

  &.outline-arrow-circle-up:before {
    content: '\efe4';
  }

  &.outline-calendar-add:before {
    content: '\efe5';
  }

  &.outline-align-bottom:before {
    content: '\efe6';
  }

  &.outline-arrow-up-2:before {
    content: '\efe7';
  }

  &.outline-location-slash:before {
    content: '\efe8';
  }

  &.outline-briefcase:before {
    content: '\efe9';
  }

  &.outline-sidebar-top:before {
    content: '\efea';
  }

  &.outline-user-tag:before {
    content: '\efeb';
  }

  &.outline-tag:before {
    content: '\efec';
  }

  &.outline-calendar-1:before {
    content: '\efed';
  }

  &.outline-text-italic:before {
    content: '\efee';
  }

  &.outline-crop:before {
    content: '\efef';
  }

  &.outline-call-remove:before {
    content: '\eff0';
  }

  &.outline-refresh-right-square:before {
    content: '\eff1';
  }

  &.outline-convertshape-2:before {
    content: '\eff2';
  }

  &.outline-blur:before {
    content: '\eff3';
  }

  &.outline-export:before {
    content: '\eff4';
  }

  &.outline-ticket:before {
    content: '\eff5';
  }

  &.outline-brifecase-cross:before {
    content: '\eff6';
  }

  &.outline-quote-up-square:before {
    content: '\eff7';
  }

  &.outline-candle:before {
    content: '\eff8';
  }

  &.outline-signpost:before {
    content: '\eff9';
  }

  &.outline-creative-commons:before {
    content: '\effa';
  }

  &.outline-flag-2:before {
    content: '\effb';
  }

  &.outline-message-notif:before {
    content: '\effc';
  }

  &.outline-camera-slash:before {
    content: '\effd';
  }

  &.outline-logout:before {
    content: '\effe';
  }

  &.outline-24-support:before {
    content: '\efff';
  }

  &.outline-message-search:before {
    content: '\f000';
  }

  &.outline-money-tick:before {
    content: '\f001';
  }

  &.outline-bag-happy:before {
    content: '\f002';
  }

  &.outline-card-edit:before {
    content: '\f003';
  }

  &.outline-dollar-circle:before {
    content: '\f004';
  }

  &.outline-star-1:before {
    content: '\f005';
  }

  &.outline-arrow-up-3:before {
    content: '\f006';
  }

  &.outline-brush-1:before {
    content: '\f007';
  }

  &.outline-music-square-search:before {
    content: '\f008';
  }

  &.outline-music:before {
    content: '\f009';
  }

  &.outline-received:before {
    content: '\f00a';
  }

  &.outline-textalign-justifycenter:before {
    content: '\f00b';
  }

  &.outline-card-tick-1:before {
    content: '\f00c';
  }

  &.outline-play-cricle:before {
    content: '\f00d';
  }

  &.outline-hierarchy-2:before {
    content: '\f00e';
  }

  &.outline-wallet-2:before {
    content: '\f00f';
  }

  &.outline-copyright:before {
    content: '\f010';
  }

  &.outline-shop:before {
    content: '\f011';
  }

  &.outline-home:before {
    content: '\f012';
  }

  &.outline-milk:before {
    content: '\f013';
  }

  &.outline-edit-2:before {
    content: '\f014';
  }

  &.outline-clipboard-tick:before {
    content: '\f015';
  }

  &.outline-forward-5-seconds:before {
    content: '\f016';
  }

  &.outline-arrow-square:before {
    content: '\f017';
  }

  &.outline-like-dislike:before {
    content: '\f018';
  }

  &.outline-format-square:before {
    content: '\f019';
  }

  &.outline-quote-up:before {
    content: '\f01a';
  }

  &.outline-sidebar-left:before {
    content: '\f01b';
  }

  &.outline-heart-search:before {
    content: '\f01c';
  }

  &.outline-card-receive:before {
    content: '\f01d';
  }

  &.outline-rotate-right-1:before {
    content: '\f01e';
  }

  &.outline-home-trend-up:before {
    content: '\f01f';
  }

  &.outline-message-text-1:before {
    content: '\f020';
  }

  &.outline-arrow:before {
    content: '\f021';
  }

  &.outline-money-change:before {
    content: '\f022';
  }

  &.outline-user:before {
    content: '\f023';
  }

  &.outline-wallet-search:before {
    content: '\f024';
  }

  &.outline-audio-square:before {
    content: '\f025';
  }

  &.outline-more-circle:before {
    content: '\f026';
  }

  &.outline-translate:before {
    content: '\f027';
  }

  &.outline-document-forward:before {
    content: '\f028';
  }

  &.outline-money-time:before {
    content: '\f029';
  }

  &.outline-aquarius:before {
    content: '\f02a';
  }

  &.outline-tag-2:before {
    content: '\f02b';
  }

  &.outline-map-1:before {
    content: '\f02c';
  }

  &.outline-document-like:before {
    content: '\f02d';
  }

  &.outline-chart:before {
    content: '\f02e';
  }

  &.outline-tick-square:before {
    content: '\f02f';
  }

  &.outline-password-check:before {
    content: '\f030';
  }

  &.outline-presention-chart:before {
    content: '\f031';
  }

  &.outline-gps-slash:before {
    content: '\f032';
  }

  &.outline-gallery-tick:before {
    content: '\f033';
  }

  &.outline-brush-4:before {
    content: '\f034';
  }

  &.outline-path:before {
    content: '\f035';
  }

  &.outline-profile-remove:before {
    content: '\f036';
  }

  &.outline-import-1:before {
    content: '\f037';
  }

  &.outline-repeat:before {
    content: '\f038';
  }

  &.outline-refresh-square-2:before {
    content: '\f039';
  }

  &.outline-arrange-square:before {
    content: '\f03a';
  }

  &.outline-document-text-1:before {
    content: '\f03b';
  }

  &.outline-information:before {
    content: '\f03c';
  }

  &.outline-picture-frame:before {
    content: '\f03d';
  }

  &.outline-arrow-square-left:before {
    content: '\f03e';
  }

  &.outline-play-circle:before {
    content: '\f03f';
  }

  &.outline-menu-1:before {
    content: '\f040';
  }

  &.outline-rotate-left:before {
    content: '\f041';
  }

  &.outline-receipt-2-1:before {
    content: '\f042';
  }

  &.outline-wind:before {
    content: '\f043';
  }

  &.outline-money-add:before {
    content: '\f044';
  }

  &.outline-note-favorite:before {
    content: '\f045';
  }

  &.outline-trush-square:before {
    content: '\f046';
  }

  &.outline-card-coin:before {
    content: '\f047';
  }

  &.outline-buildings-2:before {
    content: '\f048';
  }

  &.outline-house:before {
    content: '\f049';
  }

  &.outline-card-send:before {
    content: '\f04a';
  }

  &.outline-sound:before {
    content: '\f04b';
  }

  &.outline-refresh-circle:before {
    content: '\f04c';
  }

  &.outline-location-add:before {
    content: '\f04d';
  }

  &.outline-gemini:before {
    content: '\f04e';
  }

  &.outline-shield-search:before {
    content: '\f04f';
  }

  &.outline-archive-minus:before {
    content: '\f050';
  }

  &.outline-timer-start:before {
    content: '\f051';
  }

  &.outline-quote-down-circle:before {
    content: '\f052';
  }

  &.outline-shapes-1:before {
    content: '\f053';
  }

  &.outline-brifecase-timer:before {
    content: '\f054';
  }

  &.outline-heart-tick:before {
    content: '\f055';
  }

  &.outline-textalign-left:before {
    content: '\f056';
  }

  &.outline-ghost:before {
    content: '\f057';
  }

  &.outline-lamp-1:before {
    content: '\f058';
  }

  &.outline-quote-down:before {
    content: '\f059';
  }

  &.outline-battery-disable:before {
    content: '\f05a';
  }

  &.outline-arrow-down:before {
    content: '\f05b';
  }

  &.outline-search-zoom-in-1:before {
    content: '\f05c';
  }

  &.outline-paintbucket:before {
    content: '\f05d';
  }

  &.outline-printer-slash:before {
    content: '\f05e';
  }

  &.outline-subtitle:before {
    content: '\f05f';
  }

  &.outline-pause-circle:before {
    content: '\f060';
  }

  &.outline-receipt:before {
    content: '\f061';
  }

  &.outline-like:before {
    content: '\f062';
  }

  &.outline-eraser:before {
    content: '\f063';
  }

  &.outline-musicnote:before {
    content: '\f064';
  }

  &.outline-toggle-on-circle:before {
    content: '\f065';
  }

  &.outline-shop-add:before {
    content: '\f066';
  }

  &.outline-clipboard-close:before {
    content: '\f067';
  }

  &.outline-task:before {
    content: '\f068';
  }

  &.outline-slider-vertical-1:before {
    content: '\f069';
  }

  &.outline-driver:before {
    content: '\f06a';
  }

  &.outline-sun-1:before {
    content: '\f06b';
  }

  &.outline-toggle-on:before {
    content: '\f06c';
  }

  &.outline-triangle:before {
    content: '\f06d';
  }

  &.outline-gallery-remove:before {
    content: '\f06e';
  }

  &.outline-bag-tick:before {
    content: '\f06f';
  }

  &.outline-heart-circle:before {
    content: '\f070';
  }

  &.outline-directbox-notif:before {
    content: '\f071';
  }

  &.outline-arrow-square-up:before {
    content: '\f072';
  }

  &.outline-import-2:before {
    content: '\f073';
  }

  &.outline-profile-delete:before {
    content: '\f074';
  }

  &.outline-smart-home:before {
    content: '\f075';
  }

  &.outline-microphone-slash-1:before {
    content: '\f076';
  }

  &.outline-convertshape:before {
    content: '\f077';
  }

  &.outline-crypto-theta-theta:before {
    content: '\f078';
  }

  &.outline-crypto-vuesax:before {
    content: '\f079';
  }

  &.outline-crypto-aave-aave:before {
    content: '\f07a';
  }

  &.outline-crypto-litecoinltc:before {
    content: '\f07b';
  }

  &.outline-crypto-educare-ekt:before {
    content: '\f07c';
  }

  &.outline-crypto-apple:before {
    content: '\f07d';
  }

  &.outline-crypto-icon-1:before {
    content: '\f07e';
  }

  &.outline-crypto-hex-hex:before {
    content: '\f07f';
  }

  &.outline-crypto-messenger:before {
    content: '\f080';
  }

  &.outline-crypto-slack:before {
    content: '\f081';
  }

  &.outline-crypto-xrp-xrp:before {
    content: '\f082';
  }

  &.outline-crypto-framer:before {
    content: '\f083';
  }

  &.outline-crypto-ethereum-classic-etc:before {
    content: '\f084';
  }

  &.outline-crypto-monero-xmr:before {
    content: '\f085';
  }

  &.outline-crypto-nem-xem:before {
    content: '\f086';
  }

  &.outline-crypto-trello:before {
    content: '\f087';
  }

  &.outline-crypto-enjin-coin-enj:before {
    content: '\f088';
  }

  &.outline-crypto-dribbble:before {
    content: '\f089';
  }

  &.outline-crypto-vibe-vibe:before {
    content: '\f08a';
  }

  &.outline-crypto-illustrator:before {
    content: '\f08b';
  }

  &.outline-crypto-html-3:before {
    content: '\f08c';
  }

  &.outline-crypto-youtube:before {
    content: '\f08d';
  }

  &.outline-crypto-google-paly:before {
    content: '\f08e';
  }

  &.outline-crypto-thorchain-rune:before {
    content: '\f08f';
  }

  &.outline-crypto-polyswarm-nct:before {
    content: '\f090';
  }

  &.outline-crypto-windows:before {
    content: '\f091';
  }

  &.outline-crypto-twitch:before {
    content: '\f092';
  }

  &.outline-crypto-okb-okb:before {
    content: '\f093';
  }

  &.outline-crypto-stacks-stx:before {
    content: '\f094';
  }

  &.outline-crypto-photoshop:before {
    content: '\f095';
  }

  &.outline-crypto-tether-usdt:before {
    content: '\f096';
  }

  &.outline-crypto-celo-celo:before {
    content: '\f097';
  }

  &.outline-crypto-google:before {
    content: '\f098';
  }

  &.outline-crypto-whatsapp:before {
    content: '\f099';
  }

  &.outline-crypto-ontology-ont:before {
    content: '\f09a';
  }

  &.outline-crypto-be:before {
    content: '\f09b';
  }

  &.outline-crypto-facebook:before {
    content: '\f09c';
  }

  &.outline-crypto-html-5:before {
    content: '\f09d';
  }

  &.outline-crypto-paypal:before {
    content: '\f09e';
  }

  &.outline-crypto-velas-vlx:before {
    content: '\f09f';
  }

  &.outline-crypto-bitcoin-btc:before {
    content: '\f0a0';
  }

  &.outline-crypto-dash-dash:before {
    content: '\f0a1';
  }

  &.outline-crypto-binance-usd-busd:before {
    content: '\f0a2';
  }

  &.outline-crypto-avalanche-avax:before {
    content: '\f0a3';
  }

  &.outline-crypto-ocean-protocol-ocean:before {
    content: '\f0a4';
  }

  &.outline-crypto-snapchat:before {
    content: '\f0a5';
  }

  &.outline-crypto-maker-mkr:before {
    content: '\f0a6';
  }

  &.outline-crypto-kyber-network-knc:before {
    content: '\f0a7';
  }

  &.outline-crypto-binance-coin-bnb:before {
    content: '\f0a8';
  }

  &.outline-crypto-trontron-trx:before {
    content: '\f0a9';
  }

  &.outline-crypto-java-script:before {
    content: '\f0aa';
  }

  &.outline-crypto-spotify:before {
    content: '\f0ab';
  }

  &.outline-crypto-ethereum-eth:before {
    content: '\f0ac';
  }

  &.outline-crypto-xd:before {
    content: '\f0ad';
  }

  &.outline-crypto-harmony-one:before {
    content: '\f0ae';
  }

  &.outline-crypto-decred-dcr:before {
    content: '\f0af';
  }

  &.outline-crypto-polkadot-dot:before {
    content: '\f0b0';
  }

  &.outline-crypto-iost-iost:before {
    content: '\f0b1';
  }

  &.outline-crypto-icon:before {
    content: '\f0b2';
  }

  &.outline-crypto-ankr-ankr:before {
    content: '\f0b3';
  }

  &.outline-crypto-nexo-nexo:before {
    content: '\f0b4';
  }

  &.outline-crypto-zel-zel:before {
    content: '\f0b5';
  }

  &.outline-crypto-augur-rep:before {
    content: '\f0b6';
  }

  &.outline-crypto-blogger:before {
    content: '\f0b7';
  }

  &.outline-crypto-python:before {
    content: '\f0b8';
  }

  &.outline-crypto-dai-dai:before {
    content: '\f0b9';
  }

  &.outline-crypto-autonio-niox:before {
    content: '\f0ba';
  }

  &.outline-crypto-chainlink-link:before {
    content: '\f0bb';
  }

  &.outline-crypto-zoom:before {
    content: '\f0bc';
  }

  &.outline-crypto-emercoin-emc:before {
    content: '\f0bd';
  }

  &.outline-crypto-wing-wing:before {
    content: '\f0be';
  }

  &.outline-crypto-quant-qnt:before {
    content: '\f0bf';
  }

  &.outline-crypto-ftx-token-ftt:before {
    content: '\f0c0';
  }

  &.outline-crypto-icon-icx:before {
    content: '\f0c1';
  }

  &.outline-crypto-usd-coin-usdc:before {
    content: '\f0c2';
  }

  &.outline-crypto-the-graph-grt:before {
    content: '\f0c3';
  }

  &.outline-crypto-dent-dent:before {
    content: '\f0c4';
  }

  &.outline-crypto-wanchain-wan:before {
    content: '\f0c5';
  }

  &.outline-crypto-hedera-hashgraph-hbar:before {
    content: '\f0c6';
  }

  &.outline-crypto-civic-cvc:before {
    content: '\f0c7';
  }

  &.outline-crypto-figma-1:before {
    content: '\f0c8';
  }

  &.outline-crypto-ui8:before {
    content: '\f0c9';
  }

  &.outline-crypto-tenx-pay:before {
    content: '\f0ca';
  }

  &.outline-crypto-huobi-token-ht:before {
    content: '\f0cb';
  }

  &.outline-crypto-google-drive:before {
    content: '\f0cc';
  }

  &.outline-crypto-wanchain-wan-1:before {
    content: '\f0cd';
  }

  &.outline-crypto-siacoin-sc:before {
    content: '\f0ce';
  }

  &.outline-crypto-js:before {
    content: '\f0cf';
  }

  &.outline-crypto-celsius-cel:before {
    content: '\f0d0';
  }

  &.outline-crypto-polygon-matic:before {
    content: '\f0d1';
  }

  &.outline-crypto-android:before {
    content: '\f0d2';
  }

  &.outline-crypto-cardano-ada:before {
    content: '\f0d3';
  }

  &.outline-crypto-nebulas-nas:before {
    content: '\f0d4';
  }

  &.outline-crypto-eos-eos:before {
    content: '\f0d5';
  }

  &.outline-crypto-dropbox:before {
    content: '\f0d6';
  }

  &.outline-crypto-stellar-xlm:before {
    content: '\f0d7';
  }

  &.outline-crypto-xiaomi:before {
    content: '\f0d8';
  }

  &.outline-crypto-solana-sol:before {
    content: '\f0d9';
  }

  &.outline-crypto-figma:before {
    content: '\f0da';
  }

  &.outline-crypto-triangle:before {
    content: '\f0db';
  }

  &.outline-crypto-bootstrap:before {
    content: '\f0dc';
  }

  &.linear-crypto-theta-theta:before {
    content: '\f0dd';
  }

  &.linear-crypto-vuesax:before {
    content: '\f0de';
  }

  &.linear-crypto-aave-aave:before {
    content: '\f0df';
  }

  &.linear-crypto-litecoinltc:before {
    content: '\f0e0';
  }

  &.linear-crypto-google-1:before {
    content: '\f0e1';
  }

  &.linear-crypto-educare-ekt:before {
    content: '\f0e2';
  }

  &.linear-crypto-apple:before {
    content: '\f0e3';
  }

  &.linear-crypto-hex-hex:before {
    content: '\f0e4';
  }

  &.linear-crypto-messenger:before {
    content: '\f0e5';
  }

  &.linear-crypto-slack:before {
    content: '\f0e6';
  }

  &.linear-crypto-xrp-xrp:before {
    content: '\f0e7';
  }

  &.linear-crypto-framer:before {
    content: '\f0e8';
  }

  &.linear-crypto-ethereum-classic-etc:before {
    content: '\f0e9';
  }

  &.linear-crypto-monero-xmr:before {
    content: '\f0ea';
  }

  &.linear-crypto-nem-xem:before {
    content: '\f0eb';
  }

  &.linear-crypto-trello:before {
    content: '\f0ec';
  }

  &.linear-crypto-enjin-coin-enj:before {
    content: '\f0ed';
  }

  &.linear-crypto-dribbble:before {
    content: '\f0ee';
  }

  &.linear-crypto-vibe-vibe:before {
    content: '\f0ef';
  }

  &.linear-crypto-illustrator:before {
    content: '\f0f0';
  }

  &.linear-crypto-frame:before {
    content: '\f0f1';
  }

  &.linear-crypto-html-3:before {
    content: '\f0f2';
  }

  &.linear-crypto-youtube:before {
    content: '\f0f3';
  }

  &.linear-crypto-thorchain-rune:before {
    content: '\f0f4';
  }

  &.linear-crypto-polyswarm-nct:before {
    content: '\f0f5';
  }

  &.linear-crypto-windows:before {
    content: '\f0f6';
  }

  &.linear-crypto-twitch:before {
    content: '\f0f7';
  }

  &.linear-crypto-okb-okb:before {
    content: '\f0f8';
  }

  &.linear-crypto-stacks-stx:before {
    content: '\f0f9';
  }

  &.linear-crypto-photoshop:before {
    content: '\f0fa';
  }

  &.linear-crypto-tether-usdt:before {
    content: '\f0fb';
  }

  &.linear-crypto-celo-celo:before {
    content: '\f0fc';
  }

  &.linear-crypto-google:before {
    content: '\f0fd';
  }

  &.linear-crypto-whatsapp:before {
    content: '\f0fe';
  }

  &.linear-crypto-ontology-ont:before {
    content: '\f0ff';
  }

  &.linear-crypto-be:before {
    content: '\f100';
  }

  &.linear-crypto-bootsrap:before {
    content: '\f101';
  }

  &.linear-crypto-facebook:before {
    content: '\f102';
  }

  &.linear-crypto-html-5:before {
    content: '\f103';
  }

  &.linear-crypto-paypal:before {
    content: '\f104';
  }

  &.linear-crypto-velas-vlx:before {
    content: '\f105';
  }

  &.linear-crypto-bitcoin-btc:before {
    content: '\f106';
  }

  &.linear-crypto-dash-dash:before {
    content: '\f107';
  }

  &.linear-crypto-binance-usd-busd:before {
    content: '\f108';
  }

  &.linear-crypto-avalanche-avax:before {
    content: '\f109';
  }

  &.linear-crypto-ocean-protocol-ocean:before {
    content: '\f10a';
  }

  &.linear-crypto-snapchat:before {
    content: '\f10b';
  }

  &.linear-crypto-maker-mkr:before {
    content: '\f10c';
  }

  &.linear-crypto-kyber-network-knc:before {
    content: '\f10d';
  }

  &.linear-crypto-binance-coin-bnb:before {
    content: '\f10e';
  }

  &.linear-crypto-trontron-trx:before {
    content: '\f10f';
  }

  &.linear-crypto-java-script:before {
    content: '\f110';
  }

  &.linear-crypto-spotify:before {
    content: '\f111';
  }

  &.linear-crypto-ethereum-eth:before {
    content: '\f112';
  }

  &.linear-crypto-xd:before {
    content: '\f113';
  }

  &.linear-crypto-harmony-one:before {
    content: '\f114';
  }

  &.linear-crypto-decred-dcr:before {
    content: '\f115';
  }

  &.linear-crypto-polkadot-dot:before {
    content: '\f116';
  }

  &.linear-crypto-iost-iost:before {
    content: '\f117';
  }

  &.linear-crypto-ankr-ankr:before {
    content: '\f118';
  }

  &.linear-crypto-nexo-nexo:before {
    content: '\f119';
  }

  &.linear-crypto-zel-zel:before {
    content: '\f11a';
  }

  &.linear-crypto-augur-rep:before {
    content: '\f11b';
  }

  &.linear-crypto-blogger:before {
    content: '\f11c';
  }

  &.linear-crypto-python:before {
    content: '\f11d';
  }

  &.linear-crypto-dai-dai:before {
    content: '\f11e';
  }

  &.linear-crypto-autonio-niox:before {
    content: '\f11f';
  }

  &.linear-crypto-chainlink-link:before {
    content: '\f120';
  }

  &.linear-crypto-zoom:before {
    content: '\f121';
  }

  &.linear-crypto-emercoin-emc:before {
    content: '\f122';
  }

  &.linear-crypto-wing-wing:before {
    content: '\f123';
  }

  &.linear-crypto-frame-1:before {
    content: '\f124';
  }

  &.linear-crypto-quant-qnt:before {
    content: '\f125';
  }

  &.linear-crypto-ftx-token-ftt:before {
    content: '\f126';
  }

  &.linear-crypto-icon-icx:before {
    content: '\f127';
  }

  &.linear-crypto-usd-coin-usdc:before {
    content: '\f128';
  }

  &.linear-crypto-the-graph-grt:before {
    content: '\f129';
  }

  &.linear-crypto-dent-dent:before {
    content: '\f12a';
  }

  &.linear-crypto-wanchain-wan:before {
    content: '\f12b';
  }

  &.linear-crypto-hedera-hashgraph-hbar:before {
    content: '\f12c';
  }

  &.linear-crypto-civic-cvc:before {
    content: '\f12d';
  }

  &.linear-crypto-figma-1:before {
    content: '\f12e';
  }

  &.linear-crypto-ui8:before {
    content: '\f12f';
  }

  &.linear-crypto-google-play:before {
    content: '\f130';
  }

  &.linear-crypto-tenx-pay:before {
    content: '\f131';
  }

  &.linear-crypto-huobi-token-ht:before {
    content: '\f132';
  }

  &.linear-crypto-wanchain-wan-1:before {
    content: '\f133';
  }

  &.linear-crypto-siacoin-sc:before {
    content: '\f134';
  }

  &.linear-crypto-js:before {
    content: '\f135';
  }

  &.linear-crypto-celsius-cel:before {
    content: '\f136';
  }

  &.linear-crypto-polygon-matic:before {
    content: '\f137';
  }

  &.linear-crypto-android:before {
    content: '\f138';
  }

  &.linear-crypto-cardano-ada:before {
    content: '\f139';
  }

  &.linear-crypto-nebulas-nas:before {
    content: '\f13a';
  }

  &.linear-crypto-eos-eos:before {
    content: '\f13b';
  }

  &.linear-crypto-dropbox:before {
    content: '\f13c';
  }

  &.linear-crypto-stellar-xlm:before {
    content: '\f13d';
  }

  &.linear-crypto-xiaomi:before {
    content: '\f13e';
  }

  &.linear-crypto-solana-sol:before {
    content: '\f13f';
  }

  &.linear-crypto-figma:before {
    content: '\f140';
  }

  &.linear-crypto-triangle:before {
    content: '\f141';
  }

  &.linear-data-2:before {
    content: '\f142';
  }

  &.linear-heart:before {
    content: '\f143';
  }

  &.linear-calendar-remove:before {
    content: '\f144';
  }

  &.linear-refresh-2:before {
    content: '\f145';
  }

  &.linear-align-left:before {
    content: '\f146';
  }

  &.linear-clipboard-text:before {
    content: '\f147';
  }

  &.linear-send-2:before {
    content: '\f148';
  }

  &.linear-backward-item-1:before {
    content: '\f149';
  }

  &.linear-direct-notification:before {
    content: '\f14a';
  }

  &.linear-card-tick:before {
    content: '\f14b';
  }

  &.linear-money-remove:before {
    content: '\f14c';
  }

  &.linear-voice-cricle:before {
    content: '\f14d';
  }

  &.linear-forward-10-seconds:before {
    content: '\f14e';
  }

  &.linear-direct-normal:before {
    content: '\f14f';
  }

  &.linear-weight:before {
    content: '\f150';
  }

  &.linear-candle-2:before {
    content: '\f151';
  }

  &.linear-moon:before {
    content: '\f152';
  }

  &.linear-gallery-export:before {
    content: '\f153';
  }

  &.linear-music-circle:before {
    content: '\f154';
  }

  &.linear-element-plus:before {
    content: '\f155';
  }

  &.linear-scanning:before {
    content: '\f156';
  }

  &.linear-document-normal:before {
    content: '\f157';
  }

  &.linear-wallet-minus:before {
    content: '\f158';
  }

  &.linear-card-add:before {
    content: '\f159';
  }

  &.linear-3d-cube-scan:before {
    content: '\f15a';
  }

  &.linear-minus:before {
    content: '\f15b';
  }

  &.linear-monitor:before {
    content: '\f15c';
  }

  &.linear-timer:before {
    content: '\f15d';
  }

  &.linear-arrow-down-1:before {
    content: '\f15e';
  }

  &.linear-color-swatch:before {
    content: '\f15f';
  }

  &.linear-archive-1:before {
    content: '\f160';
  }

  &.linear-copy-success:before {
    content: '\f161';
  }

  &.linear-alarm:before {
    content: '\f162';
  }

  &.linear-folder:before {
    content: '\f163';
  }

  &.linear-cards:before {
    content: '\f164';
  }

  &.linear-message-remove:before {
    content: '\f165';
  }

  &.linear-search-favorite:before {
    content: '\f166';
  }

  &.linear-bezier:before {
    content: '\f167';
  }

  &.linear-building:before {
    content: '\f168';
  }

  &.linear-graph:before {
    content: '\f169';
  }

  &.linear-slider-horizontal:before {
    content: '\f16a';
  }

  &.linear-logout-1:before {
    content: '\f16b';
  }

  &.linear-document-sketch:before {
    content: '\f16c';
  }

  &.linear-menu-board:before {
    content: '\f16d';
  }

  &.linear-mirror:before {
    content: '\f16e';
  }

  &.linear-blend-2:before {
    content: '\f16f';
  }

  &.linear-lamp-slash:before {
    content: '\f170';
  }

  &.linear-receipt-text:before {
    content: '\f171';
  }

  &.linear-man:before {
    content: '\f172';
  }

  &.linear-truck-remove:before {
    content: '\f173';
  }

  &.linear-layer:before {
    content: '\f174';
  }

  &.linear-sms-edit:before {
    content: '\f175';
  }

  &.linear-music-square-remove:before {
    content: '\f176';
  }

  &.linear-routing:before {
    content: '\f177';
  }

  &.linear-login-1:before {
    content: '\f178';
  }

  &.linear-mouse:before {
    content: '\f179';
  }

  &.linear-3d-square:before {
    content: '\f17a';
  }

  &.linear-discover-1:before {
    content: '\f17b';
  }

  &.linear-calculator:before {
    content: '\f17c';
  }

  &.linear-airplane:before {
    content: '\f17d';
  }

  &.linear-happyemoji:before {
    content: '\f17e';
  }

  &.linear-shopping-cart:before {
    content: '\f17f';
  }

  &.linear-finger-cricle:before {
    content: '\f180';
  }

  &.linear-save-add:before {
    content: '\f181';
  }

  &.linear-chrome:before {
    content: '\f182';
  }

  &.linear-volume-slash:before {
    content: '\f183';
  }

  &.linear-bucket-square:before {
    content: '\f184';
  }

  &.linear-note-21:before {
    content: '\f185';
  }

  &.linear-security-user:before {
    content: '\f186';
  }

  &.linear-repeate-one:before {
    content: '\f187';
  }

  &.linear-send-1:before {
    content: '\f188';
  }

  &.linear-money-recive:before {
    content: '\f189';
  }

  &.linear-sms-star:before {
    content: '\f18a';
  }

  &.linear-search-zoom-out:before {
    content: '\f18b';
  }

  &.linear-building-4:before {
    content: '\f18c';
  }

  &.linear-like-shapes:before {
    content: '\f18d';
  }

  &.linear-menu:before {
    content: '\f18e';
  }

  &.linear-drop:before {
    content: '\f18f';
  }

  &.linear-volume-low-1:before {
    content: '\f190';
  }

  &.linear-setting-3:before {
    content: '\f191';
  }

  &.linear-maximize-circle:before {
    content: '\f192';
  }

  &.linear-receipt-discount:before {
    content: '\f193';
  }

  &.linear-strongbox-2:before {
    content: '\f194';
  }

  &.linear-component:before {
    content: '\f195';
  }

  &.linear-trend-down:before {
    content: '\f196';
  }

  &.linear-favorite-chart:before {
    content: '\f197';
  }

  &.linear-math:before {
    content: '\f198';
  }

  &.linear-screenmirroring:before {
    content: '\f199';
  }

  &.linear-receipt-minus:before {
    content: '\f19a';
  }

  &.linear-lock-circle:before {
    content: '\f19b';
  }

  &.linear-level:before {
    content: '\f19c';
  }

  &.linear-document-filter:before {
    content: '\f19d';
  }

  &.linear-pen-tool-2-1:before {
    content: '\f19e';
  }

  &.linear-call-add:before {
    content: '\f19f';
  }

  &.linear-programming-arrow:before {
    content: '\f1a0';
  }

  &.linear-rotate-right:before {
    content: '\f1a1';
  }

  &.linear-note-square:before {
    content: '\f1a2';
  }

  &.linear-status:before {
    content: '\f1a3';
  }

  &.linear-shield-slash:before {
    content: '\f1a4';
  }

  &.linear-arrow-down-2:before {
    content: '\f1a5';
  }

  &.linear-bookmark:before {
    content: '\f1a6';
  }

  &.linear-blend:before {
    content: '\f1a7';
  }

  &.linear-forward:before {
    content: '\f1a8';
  }

  &.linear-call-minus:before {
    content: '\f1a9';
  }

  &.linear-box-tick:before {
    content: '\f1aa';
  }

  &.linear-setting-2:before {
    content: '\f1ab';
  }

  &.linear-pause:before {
    content: '\f1ac';
  }

  &.linear-cpu-charge:before {
    content: '\f1ad';
  }

  &.linear-colors-square-1:before {
    content: '\f1ae';
  }

  &.linear-diamonds:before {
    content: '\f1af';
  }

  &.linear-mini-music-sqaure:before {
    content: '\f1b0';
  }

  &.linear-cup:before {
    content: '\f1b1';
  }

  &.linear-lovely:before {
    content: '\f1b2';
  }

  &.linear-cloud:before {
    content: '\f1b3';
  }

  &.linear-task-square:before {
    content: '\f1b4';
  }

  &.linear-wallet-money:before {
    content: '\f1b5';
  }

  &.linear-heart-edit:before {
    content: '\f1b6';
  }

  &.linear-clipboard-import:before {
    content: '\f1b7';
  }

  &.linear-close-square:before {
    content: '\f1b8';
  }

  &.linear-receipt-search:before {
    content: '\f1b9';
  }

  &.linear-omega-circle:before {
    content: '\f1ba';
  }

  &.linear-profile-circle:before {
    content: '\f1bb';
  }

  &.linear-scanner:before {
    content: '\f1bc';
  }

  &.linear-volume-low:before {
    content: '\f1bd';
  }

  &.linear-message-minus:before {
    content: '\f1be';
  }

  &.linear-keyboard-open:before {
    content: '\f1bf';
  }

  &.linear-microphone-slash:before {
    content: '\f1c0';
  }

  &.linear-ram:before {
    content: '\f1c1';
  }

  &.linear-data:before {
    content: '\f1c2';
  }

  &.linear-buliding:before {
    content: '\f1c3';
  }

  &.linear-gas-station:before {
    content: '\f1c4';
  }

  &.linear-arrow-left:before {
    content: '\f1c5';
  }

  &.linear-lamp-charge:before {
    content: '\f1c6';
  }

  &.linear-calendar:before {
    content: '\f1c7';
  }

  &.linear-video-slash:before {
    content: '\f1c8';
  }

  &.linear-medal:before {
    content: '\f1c9';
  }

  &.linear-link-square:before {
    content: '\f1ca';
  }

  &.linear-pharagraphspacing:before {
    content: '\f1cb';
  }

  &.linear-money-send:before {
    content: '\f1cc';
  }

  &.linear-add-circle:before {
    content: '\f1cd';
  }

  &.linear-filter:before {
    content: '\f1ce';
  }

  &.linear-box-time:before {
    content: '\f1cf';
  }

  &.linear-bitcoin-convert:before {
    content: '\f1d0';
  }

  &.linear-activity:before {
    content: '\f1d1';
  }

  &.linear-tree:before {
    content: '\f1d2';
  }

  &.linear-security-safe:before {
    content: '\f1d3';
  }

  &.linear-filter-square:before {
    content: '\f1d4';
  }

  &.linear-external-drive:before {
    content: '\f1d5';
  }

  &.linear-video-vertical:before {
    content: '\f1d6';
  }

  &.linear-video:before {
    content: '\f1d7';
  }

  &.linear-undo:before {
    content: '\f1d8';
  }

  &.linear-messages-1:before {
    content: '\f1d9';
  }

  &.linear-sort:before {
    content: '\f1da';
  }

  &.linear-microscope:before {
    content: '\f1db';
  }

  &.linear-folder-add:before {
    content: '\f1dc';
  }

  &.linear-colors-square:before {
    content: '\f1dd';
  }

  &.linear-money:before {
    content: '\f1de';
  }

  &.linear-arrow-up:before {
    content: '\f1df';
  }

  &.linear-video-horizontal:before {
    content: '\f1e0';
  }

  &.linear-document-upload:before {
    content: '\f1e1';
  }

  &.linear-video-square:before {
    content: '\f1e2';
  }

  &.linear-arrow-square-down:before {
    content: '\f1e3';
  }

  &.linear-share:before {
    content: '\f1e4';
  }

  &.linear-tag-right:before {
    content: '\f1e5';
  }

  &.linear-profile:before {
    content: '\f1e6';
  }

  &.linear-language-square:before {
    content: '\f1e7';
  }

  &.linear-clock-1:before {
    content: '\f1e8';
  }

  &.linear-heart-remove:before {
    content: '\f1e9';
  }

  &.linear-forward-15-seconds:before {
    content: '\f1ea';
  }

  &.linear-messages-3:before {
    content: '\f1eb';
  }

  &.linear-car:before {
    content: '\f1ec';
  }

  &.linear-setting-5:before {
    content: '\f1ed';
  }

  &.linear-gift:before {
    content: '\f1ee';
  }

  &.linear-glass-1:before {
    content: '\f1ef';
  }

  &.linear-arrange-square-2:before {
    content: '\f1f0';
  }

  &.linear-search-normal-1:before {
    content: '\f1f1';
  }

  &.linear-receipt-edit:before {
    content: '\f1f2';
  }

  &.linear-electricity:before {
    content: '\f1f3';
  }

  &.linear-device-message:before {
    content: '\f1f4';
  }

  &.linear-profile-tick:before {
    content: '\f1f5';
  }

  &.linear-location:before {
    content: '\f1f6';
  }

  &.linear-path-square:before {
    content: '\f1f7';
  }

  &.linear-wallet-remove:before {
    content: '\f1f8';
  }

  &.linear-bluetooth-rectangle:before {
    content: '\f1f9';
  }

  &.linear-attach-square:before {
    content: '\f1fa';
  }

  &.linear-headphone:before {
    content: '\f1fb';
  }

  &.linear-personalcard:before {
    content: '\f1fc';
  }

  &.linear-cloud-connection:before {
    content: '\f1fd';
  }

  &.linear-gallery-edit:before {
    content: '\f1fe';
  }

  &.linear-mobile:before {
    content: '\f1ff';
  }

  &.linear-wallet-add:before {
    content: '\f200';
  }

  &.linear-eye:before {
    content: '\f201';
  }

  &.linear-call:before {
    content: '\f202';
  }

  &.linear-direct-send:before {
    content: '\f203';
  }

  &.linear-repeat-circle:before {
    content: '\f204';
  }

  &.linear-message-programming:before {
    content: '\f205';
  }

  &.linear-send-sqaure-2:before {
    content: '\f206';
  }

  &.linear-courthouse:before {
    content: '\f207';
  }

  &.linear-slider-horizontal-1:before {
    content: '\f208';
  }

  &.linear-textalign-justifyright:before {
    content: '\f209';
  }

  &.linear-call-incoming:before {
    content: '\f20a';
  }

  &.linear-pen-close-1:before {
    content: '\f20b';
  }

  &.linear-wind-2:before {
    content: '\f20c';
  }

  &.linear-clock:before {
    content: '\f20d';
  }

  &.linear-command:before {
    content: '\f20e';
  }

  &.linear-setting-4:before {
    content: '\f20f';
  }

  &.linear-messages-2:before {
    content: '\f210';
  }

  &.linear-building-3:before {
    content: '\f211';
  }

  &.linear-ticket-discount:before {
    content: '\f212';
  }

  &.linear-hierarchy:before {
    content: '\f213';
  }

  &.linear-play-remove:before {
    content: '\f214';
  }

  &.linear-simcard:before {
    content: '\f215';
  }

  &.linear-bitcoin-refresh:before {
    content: '\f216';
  }

  &.linear-music-library-2:before {
    content: '\f217';
  }

  &.linear-search-zoom-out-1:before {
    content: '\f218';
  }

  &.linear-3square:before {
    content: '\f219';
  }

  &.linear-timer-1:before {
    content: '\f21a';
  }

  &.linear-call-received:before {
    content: '\f21b';
  }

  &.linear-magicpen:before {
    content: '\f21c';
  }

  &.linear-global-refresh:before {
    content: '\f21d';
  }

  &.linear-receipt-item:before {
    content: '\f21e';
  }

  &.linear-arrow-swap-horizontal:before {
    content: '\f21f';
  }

  &.linear-video-play:before {
    content: '\f220';
  }

  &.linear-lock-1:before {
    content: '\f221';
  }

  &.linear-group-10:before {
    content: '\f222';
  }

  &.linear-judge:before {
    content: '\f223';
  }

  &.linear-frame:before {
    content: '\f224';
  }

  &.linear-bank:before {
    content: '\f225';
  }

  &.linear-location-minus:before {
    content: '\f226';
  }

  &.linear-woman:before {
    content: '\f227';
  }

  &.linear-eraser-1:before {
    content: '\f228';
  }

  &.linear-designtools:before {
    content: '\f229';
  }

  &.linear-truck-time:before {
    content: '\f22a';
  }

  &.linear-forward-square:before {
    content: '\f22b';
  }

  &.linear-card-remove:before {
    content: '\f22c';
  }

  &.linear-music-play:before {
    content: '\f22d';
  }

  &.linear-star-slash:before {
    content: '\f22e';
  }

  &.linear-notification-status:before {
    content: '\f22f';
  }

  &.linear-unlock:before {
    content: '\f230';
  }

  &.linear-send-square:before {
    content: '\f231';
  }

  &.linear-flash-slash:before {
    content: '\f232';
  }

  &.linear-arrow-left-1:before {
    content: '\f233';
  }

  &.linear-export-2:before {
    content: '\f234';
  }

  &.linear-folder-cloud:before {
    content: '\f235';
  }

  &.linear-export-3:before {
    content: '\f236';
  }

  &.linear-pet:before {
    content: '\f237';
  }

  &.linear-message-edit:before {
    content: '\f238';
  }

  &.linear-colorfilter:before {
    content: '\f239';
  }

  &.linear-volume-high:before {
    content: '\f23a';
  }

  &.linear-lifebuoy:before {
    content: '\f23b';
  }

  &.linear-gallery-add:before {
    content: '\f23c';
  }

  &.linear-element-1:before {
    content: '\f23d';
  }

  &.linear-emoji-normal:before {
    content: '\f23e';
  }

  &.linear-receipt-2:before {
    content: '\f23f';
  }

  &.linear-wallet-add-1:before {
    content: '\f240';
  }

  &.linear-strongbox:before {
    content: '\f241';
  }

  &.linear-bluetooth-2:before {
    content: '\f242';
  }

  &.linear-home-wifi:before {
    content: '\f243';
  }

  &.linear-group-11:before {
    content: '\f244';
  }

  &.linear-maximize-21:before {
    content: '\f245';
  }

  &.linear-next:before {
    content: '\f246';
  }

  &.linear-forward-item-1:before {
    content: '\f247';
  }

  &.linear-edit:before {
    content: '\f248';
  }

  &.linear-mouse-circle:before {
    content: '\f249';
  }

  &.linear-more-2:before {
    content: '\f24a';
  }

  &.linear-folder-cross:before {
    content: '\f24b';
  }

  &.linear-message-square:before {
    content: '\f24c';
  }

  &.linear-arrow-circle-down:before {
    content: '\f24d';
  }

  &.linear-cloud-sunny:before {
    content: '\f24e';
  }

  &.linear-danger:before {
    content: '\f24f';
  }

  &.linear-bag-cross-1:before {
    content: '\f250';
  }

  &.linear-bag-timer:before {
    content: '\f251';
  }

  &.linear-home-2:before {
    content: '\f252';
  }

  &.linear-sun:before {
    content: '\f253';
  }

  &.linear-clipboard-export:before {
    content: '\f254';
  }

  &.linear-cd:before {
    content: '\f255';
  }

  &.linear-folder-2:before {
    content: '\f256';
  }

  &.linear-bitcoin-card:before {
    content: '\f257';
  }

  &.linear-star:before {
    content: '\f258';
  }

  &.linear-security-time:before {
    content: '\f259';
  }

  &.linear-hashtag:before {
    content: '\f25a';
  }

  &.linear-volume-up:before {
    content: '\f25b';
  }

  &.linear-weight-1:before {
    content: '\f25c';
  }

  &.linear-computing:before {
    content: '\f25d';
  }

  &.linear-grid-9:before {
    content: '\f25e';
  }

  &.linear-element-equal:before {
    content: '\f25f';
  }

  &.linear-recovery-convert:before {
    content: '\f260';
  }

  &.linear-eraser-2:before {
    content: '\f261';
  }

  &.linear-scan-barcode:before {
    content: '\f262';
  }

  &.linear-dollar-square:before {
    content: '\f263';
  }

  &.linear-direct-left:before {
    content: '\f264';
  }

  &.linear-group-8:before {
    content: '\f265';
  }

  &.linear-element-3:before {
    content: '\f266';
  }

  &.linear-video-octagon:before {
    content: '\f267';
  }

  &.linear-maximize-4:before {
    content: '\f268';
  }

  &.linear-record-circle:before {
    content: '\f269';
  }

  &.linear-monitor-recorder:before {
    content: '\f26a';
  }

  &.linear-pen-add:before {
    content: '\f26b';
  }

  &.linear-refresh-left-square:before {
    content: '\f26c';
  }

  &.linear-battery-3full:before {
    content: '\f26d';
  }

  &.linear-trash:before {
    content: '\f26e';
  }

  &.linear-export-1:before {
    content: '\f26f';
  }

  &.linear-arrow-left-2:before {
    content: '\f270';
  }

  &.linear-arrow-left-3:before {
    content: '\f271';
  }

  &.linear-textalign-center:before {
    content: '\f272';
  }

  &.linear-firstline:before {
    content: '\f273';
  }

  &.linear-bus:before {
    content: '\f274';
  }

  &.linear-battery-empty-1:before {
    content: '\f275';
  }

  &.linear-cloud-minus:before {
    content: '\f276';
  }

  &.linear-grid-eraser:before {
    content: '\f277';
  }

  &.linear-glass:before {
    content: '\f278';
  }

  &.linear-group-9:before {
    content: '\f279';
  }

  &.linear-element-2:before {
    content: '\f27a';
  }

  &.linear-back-square:before {
    content: '\f27b';
  }

  &.linear-text-underline:before {
    content: '\f27c';
  }

  &.linear-emoji-sad:before {
    content: '\f27d';
  }

  &.linear-document-download:before {
    content: '\f27e';
  }

  &.linear-receipt-1:before {
    content: '\f27f';
  }

  &.linear-more:before {
    content: '\f280';
  }

  &.linear-security:before {
    content: '\f281';
  }

  &.linear-arrow-bottom:before {
    content: '\f282';
  }

  &.linear-grid-8:before {
    content: '\f283';
  }

  &.linear-music-playlist:before {
    content: '\f284';
  }

  &.linear-note-remove:before {
    content: '\f285';
  }

  &.linear-pen-tool:before {
    content: '\f286';
  }

  &.linear-quote-up-circle:before {
    content: '\f287';
  }

  &.linear-discount-circle:before {
    content: '\f288';
  }

  &.linear-user-search:before {
    content: '\f289';
  }

  &.linear-folder-minus:before {
    content: '\f28a';
  }

  &.linear-cloud-add:before {
    content: '\f28b';
  }

  &.linear-autobrightness:before {
    content: '\f28c';
  }

  &.linear-redo:before {
    content: '\f28d';
  }

  &.linear-hashtag-down:before {
    content: '\f28e';
  }

  &.linear-more-square:before {
    content: '\f28f';
  }

  &.linear-printer:before {
    content: '\f290';
  }

  &.linear-link-2:before {
    content: '\f291';
  }

  &.linear-camera:before {
    content: '\f292';
  }

  &.linear-document-text:before {
    content: '\f293';
  }

  &.linear-battery-empty:before {
    content: '\f294';
  }

  &.linear-brifecase-tick:before {
    content: '\f295';
  }

  &.linear-arrow-right-3:before {
    content: '\f296';
  }

  &.linear-omega-circle-1:before {
    content: '\f297';
  }

  &.linear-convert:before {
    content: '\f298';
  }

  &.linear-archive-slash:before {
    content: '\f299';
  }

  &.linear-speaker:before {
    content: '\f29a';
  }

  &.linear-play-add:before {
    content: '\f29b';
  }

  &.linear-simcard-2:before {
    content: '\f29c';
  }

  &.linear-empty-wallet-add:before {
    content: '\f29d';
  }

  &.linear-scroll:before {
    content: '\f29e';
  }

  &.linear-tick-circle:before {
    content: '\f29f';
  }

  &.linear-tag-cross:before {
    content: '\f2a0';
  }

  &.linear-bill:before {
    content: '\f2a1';
  }

  &.linear-mouse-1:before {
    content: '\f2a2';
  }

  &.linear-user-octagon:before {
    content: '\f2a3';
  }

  &.linear-smart-car:before {
    content: '\f2a4';
  }

  &.linear-direct:before {
    content: '\f2a5';
  }

  &.linear-barcode:before {
    content: '\f2a6';
  }

  &.linear-send:before {
    content: '\f2a7';
  }

  &.linear-airplane-square:before {
    content: '\f2a8';
  }

  &.linear-user-edit:before {
    content: '\f2a9';
  }

  &.linear-maximize-1:before {
    content: '\f2aa';
  }

  &.linear-diagram:before {
    content: '\f2ab';
  }

  &.linear-notification-bing:before {
    content: '\f2ac';
  }

  &.linear-like-1:before {
    content: '\f2ad';
  }

  &.linear-verify:before {
    content: '\f2ae';
  }

  &.linear-coin:before {
    content: '\f2af';
  }

  &.linear-driving:before {
    content: '\f2b0';
  }

  &.linear-text-block:before {
    content: '\f2b1';
  }

  &.linear-notification:before {
    content: '\f2b2';
  }

  &.linear-filter-edit:before {
    content: '\f2b3';
  }

  &.linear-bucket-circle-1:before {
    content: '\f2b4';
  }

  &.linear-filter-remove:before {
    content: '\f2b5';
  }

  &.linear-directbox-receive:before {
    content: '\f2b6';
  }

  &.linear-toggle-off-circle:before {
    content: '\f2b7';
  }

  &.linear-backward:before {
    content: '\f2b8';
  }

  &.linear-play:before {
    content: '\f2b9';
  }

  &.linear-eye-slash:before {
    content: '\f2ba';
  }

  &.linear-rulerpen:before {
    content: '\f2bb';
  }

  &.linear-message-add-1:before {
    content: '\f2bc';
  }

  &.linear-broom:before {
    content: '\f2bd';
  }

  &.linear-crown:before {
    content: '\f2be';
  }

  &.linear-message-2:before {
    content: '\f2bf';
  }

  &.linear-cloud-change:before {
    content: '\f2c0';
  }

  &.linear-mask:before {
    content: '\f2c1';
  }

  &.linear-messages:before {
    content: '\f2c2';
  }

  &.linear-empty-wallet-remove:before {
    content: '\f2c3';
  }

  &.linear-format-circle:before {
    content: '\f2c4';
  }

  &.linear-location-cross:before {
    content: '\f2c5';
  }

  &.linear-box:before {
    content: '\f2c6';
  }

  &.linear-box-search:before {
    content: '\f2c7';
  }

  &.linear-trade:before {
    content: '\f2c8';
  }

  &.linear-award:before {
    content: '\f2c9';
  }

  &.linear-sticker:before {
    content: '\f2ca';
  }

  &.linear-video-remove:before {
    content: '\f2cb';
  }

  &.linear-airpod:before {
    content: '\f2cc';
  }

  &.linear-message:before {
    content: '\f2cd';
  }

  &.linear-document-copy:before {
    content: '\f2ce';
  }

  &.linear-safe-home:before {
    content: '\f2cf';
  }

  &.linear-textalign-justifyleft:before {
    content: '\f2d0';
  }

  &.linear-message-favorite:before {
    content: '\f2d1';
  }

  &.linear-story:before {
    content: '\f2d2';
  }

  &.linear-flash-circle:before {
    content: '\f2d3';
  }

  &.linear-paperclip:before {
    content: '\f2d4';
  }

  &.linear-arrow-right-2:before {
    content: '\f2d5';
  }

  &.linear-crown-1:before {
    content: '\f2d6';
  }

  &.linear-previous:before {
    content: '\f2d7';
  }

  &.linear-volume-mute:before {
    content: '\f2d8';
  }

  &.linear-3dcube:before {
    content: '\f2d9';
  }

  &.linear-grid-lock:before {
    content: '\f2da';
  }

  &.linear-setting:before {
    content: '\f2db';
  }

  &.linear-message-text:before {
    content: '\f2dc';
  }

  &.linear-heart-add:before {
    content: '\f2dd';
  }

  &.linear-command-square:before {
    content: '\f2de';
  }

  &.linear-document-cloud:before {
    content: '\f2df';
  }

  &.linear-link-1:before {
    content: '\f2e0';
  }

  &.linear-direct-inbox:before {
    content: '\f2e1';
  }

  &.linear-security-card:before {
    content: '\f2e2';
  }

  &.linear-pen-remove-1:before {
    content: '\f2e3';
  }

  &.linear-cloud-lightning:before {
    content: '\f2e4';
  }

  &.linear-scan:before {
    content: '\f2e5';
  }

  &.linear-minus-cirlce:before {
    content: '\f2e6';
  }

  &.linear-lamp:before {
    content: '\f2e7';
  }

  &.linear-search-favorite-1:before {
    content: '\f2e8';
  }

  &.linear-simcard-1:before {
    content: '\f2e9';
  }

  &.linear-flash-1:before {
    content: '\f2ea';
  }

  &.linear-reserve:before {
    content: '\f2eb';
  }

  &.linear-truck-tick:before {
    content: '\f2ec';
  }

  &.linear-card-slash:before {
    content: '\f2ed';
  }

  &.linear-folder-connection:before {
    content: '\f2ee';
  }

  &.linear-calendar-edit:before {
    content: '\f2ef';
  }

  &.linear-maximize-2:before {
    content: '\f2f0';
  }

  &.linear-image:before {
    content: '\f2f1';
  }

  &.linear-align-right:before {
    content: '\f2f2';
  }

  &.linear-text:before {
    content: '\f2f3';
  }

  &.linear-gallery-import:before {
    content: '\f2f4';
  }

  &.linear-hashtag-1:before {
    content: '\f2f5';
  }

  &.linear-chart-square:before {
    content: '\f2f6';
  }

  &.linear-link-21:before {
    content: '\f2f7';
  }

  &.linear-cloud-cross:before {
    content: '\f2f8';
  }

  &.linear-refresh:before {
    content: '\f2f9';
  }

  &.linear-hierarchy-square:before {
    content: '\f2fa';
  }

  &.linear-box-add:before {
    content: '\f2fb';
  }

  &.linear-hierarchy-square-2:before {
    content: '\f2fc';
  }

  &.linear-align-horizontally:before {
    content: '\f2fd';
  }

  &.linear-hierarchy-square-3:before {
    content: '\f2fe';
  }

  &.linear-arrange-circle:before {
    content: '\f2ff';
  }

  &.linear-sun-fog:before {
    content: '\f300';
  }

  &.linear-bag:before {
    content: '\f301';
  }

  &.linear-element-4:before {
    content: '\f302';
  }

  &.linear-maximize-3:before {
    content: '\f303';
  }

  &.linear-call-calling:before {
    content: '\f304';
  }

  &.linear-bag-tick-2:before {
    content: '\f305';
  }

  &.linear-receipt-add:before {
    content: '\f306';
  }

  &.linear-copy:before {
    content: '\f307';
  }

  &.linear-watch-status:before {
    content: '\f308';
  }

  &.linear-smallcaps:before {
    content: '\f309';
  }

  &.linear-arrow-right-1:before {
    content: '\f30a';
  }

  &.linear-ticket-expired:before {
    content: '\f30b';
  }

  &.linear-bag-cross:before {
    content: '\f30c';
  }

  &.linear-cloud-fog:before {
    content: '\f30d';
  }

  &.linear-info-circle:before {
    content: '\f30e';
  }

  &.linear-quote-down-square:before {
    content: '\f30f';
  }

  &.linear-people:before {
    content: '\f310';
  }

  &.linear-book-1:before {
    content: '\f311';
  }

  &.linear-document-code:before {
    content: '\f312';
  }

  &.linear-speedometer:before {
    content: '\f313';
  }

  &.linear-add:before {
    content: '\f314';
  }

  &.linear-user-add:before {
    content: '\f315';
  }

  &.linear-sidebar-bottom:before {
    content: '\f316';
  }

  &.linear-key-square:before {
    content: '\f317';
  }

  &.linear-code-circle:before {
    content: '\f318';
  }

  &.linear-archive-tick:before {
    content: '\f319';
  }

  &.linear-flash-circle-2:before {
    content: '\f31a';
  }

  &.linear-grid-3:before {
    content: '\f31b';
  }

  &.linear-archive-add:before {
    content: '\f31c';
  }

  &.linear-arrow-circle-right:before {
    content: '\f31d';
  }

  &.linear-slider:before {
    content: '\f31e';
  }

  &.linear-wallet-check:before {
    content: '\f31f';
  }

  &.linear-backward-item:before {
    content: '\f320';
  }

  &.linear-group-2:before {
    content: '\f321';
  }

  &.linear-directbox-default:before {
    content: '\f322';
  }

  &.linear-video-add:before {
    content: '\f323';
  }

  &.linear-card:before {
    content: '\f324';
  }

  &.linear-watch:before {
    content: '\f325';
  }

  &.linear-document-code-2:before {
    content: '\f326';
  }

  &.linear-route-square:before {
    content: '\f327';
  }

  &.linear-hashtag-up:before {
    content: '\f328';
  }

  &.linear-wifi:before {
    content: '\f329';
  }

  &.linear-main-component:before {
    content: '\f32a';
  }

  &.linear-category-2:before {
    content: '\f32b';
  }

  &.linear-empty-wallet-time:before {
    content: '\f32c';
  }

  &.linear-frame-4:before {
    content: '\f32d';
  }

  &.linear-gallery-slash:before {
    content: '\f32e';
  }

  &.linear-finger-scan:before {
    content: '\f32f';
  }

  &.linear-additem:before {
    content: '\f330';
  }

  &.linear-headphones:before {
    content: '\f331';
  }

  &.linear-routing-2:before {
    content: '\f332';
  }

  &.linear-global:before {
    content: '\f333';
  }

  &.linear-bluetooth:before {
    content: '\f334';
  }

  &.linear-arrange-circle-2:before {
    content: '\f335';
  }

  &.linear-direct-down:before {
    content: '\f336';
  }

  &.linear-archive-book:before {
    content: '\f337';
  }

  &.linear-hospital:before {
    content: '\f338';
  }

  &.linear-frame-5:before {
    content: '\f339';
  }

  &.linear-filter-add:before {
    content: '\f33a';
  }

  &.linear-trend-up:before {
    content: '\f33b';
  }

  &.linear-mirroring-screen:before {
    content: '\f33c';
  }

  &.linear-airdrop:before {
    content: '\f33d';
  }

  &.linear-ranking:before {
    content: '\f33e';
  }

  &.linear-group-3:before {
    content: '\f33f';
  }

  &.linear-notification-1:before {
    content: '\f340';
  }

  &.linear-empty-wallet-change:before {
    content: '\f341';
  }

  &.linear-check:before {
    content: '\f342';
  }

  &.linear-chart-success:before {
    content: '\f343';
  }

  &.linear-document:before {
    content: '\f344';
  }

  &.linear-cloud-remove:before {
    content: '\f345';
  }

  &.linear-chart-21:before {
    content: '\f346';
  }

  &.linear-bluetooth-circle:before {
    content: '\f347';
  }

  &.linear-battery-full:before {
    content: '\f348';
  }

  &.linear-category:before {
    content: '\f349';
  }

  &.linear-stickynote:before {
    content: '\f34a';
  }

  &.linear-video-time:before {
    content: '\f34b';
  }

  &.linear-grid-2:before {
    content: '\f34c';
  }

  &.linear-ranking-1:before {
    content: '\f34d';
  }

  &.linear-record:before {
    content: '\f34e';
  }

  &.linear-music-dashboard:before {
    content: '\f34f';
  }

  &.linear-scissor-1:before {
    content: '\f350';
  }

  &.linear-user-remove:before {
    content: '\f351';
  }

  &.linear-receive-square-2:before {
    content: '\f352';
  }

  &.linear-call-slash:before {
    content: '\f353';
  }

  &.linear-document-1:before {
    content: '\f354';
  }

  &.linear-cpu:before {
    content: '\f355';
  }

  &.linear-document-favorite:before {
    content: '\f356';
  }

  &.linear-sms-tracking:before {
    content: '\f357';
  }

  &.linear-music-square:before {
    content: '\f358';
  }

  &.linear-ruler:before {
    content: '\f359';
  }

  &.linear-buy-crypto:before {
    content: '\f35a';
  }

  &.linear-gemini-2:before {
    content: '\f35b';
  }

  &.linear-message-tick:before {
    content: '\f35c';
  }

  &.linear-emoji-happy:before {
    content: '\f35d';
  }

  &.linear-save-remove:before {
    content: '\f35e';
  }

  &.linear-save-2:before {
    content: '\f35f';
  }

  &.linear-pen-close:before {
    content: '\f360';
  }

  &.linear-row-vertical:before {
    content: '\f361';
  }

  &.linear-document-previous:before {
    content: '\f362';
  }

  &.linear-arrow-circle-left:before {
    content: '\f363';
  }

  &.linear-card-remove-1:before {
    content: '\f364';
  }

  &.linear-house-2:before {
    content: '\f365';
  }

  &.linear-search-normal:before {
    content: '\f366';
  }

  &.linear-stop:before {
    content: '\f367';
  }

  &.linear-flash-circle-1:before {
    content: '\f368';
  }

  &.linear-gameboy:before {
    content: '\f369';
  }

  &.linear-like-tag:before {
    content: '\f36a';
  }

  &.linear-search-status-1:before {
    content: '\f36b';
  }

  &.linear-gps:before {
    content: '\f36c';
  }

  &.linear-pen-remove:before {
    content: '\f36d';
  }

  &.linear-group-1:before {
    content: '\f36e';
  }

  &.linear-flag:before {
    content: '\f36f';
  }

  &.linear-teacher:before {
    content: '\f370';
  }

  &.linear-convert-3d-cube:before {
    content: '\f371';
  }

  &.linear-medal-star:before {
    content: '\f372';
  }

  &.linear-user-cirlce-add:before {
    content: '\f373';
  }

  &.linear-cake:before {
    content: '\f374';
  }

  &.linear-money-4:before {
    content: '\f375';
  }

  &.linear-code:before {
    content: '\f376';
  }

  &.linear-flash:before {
    content: '\f377';
  }

  &.linear-component-1:before {
    content: '\f378';
  }

  &.linear-unlimited:before {
    content: '\f379';
  }

  &.linear-coffee:before {
    content: '\f37a';
  }

  &.linear-paperclip-2:before {
    content: '\f37b';
  }

  &.linear-bucket-circle:before {
    content: '\f37c';
  }

  &.linear-backward-10-seconds:before {
    content: '\f37d';
  }

  &.linear-wallet:before {
    content: '\f37e';
  }

  &.linear-folder-favorite:before {
    content: '\f37f';
  }

  &.linear-user-minus:before {
    content: '\f380';
  }

  &.linear-timer-pause:before {
    content: '\f381';
  }

  &.linear-scissor:before {
    content: '\f382';
  }

  &.linear-heart-slash:before {
    content: '\f383';
  }

  &.linear-grid-1:before {
    content: '\f384';
  }

  &.linear-scissor-2:before {
    content: '\f385';
  }

  &.linear-note-add:before {
    content: '\f386';
  }

  &.linear-book:before {
    content: '\f387';
  }

  &.linear-grid-edit:before {
    content: '\f388';
  }

  &.linear-ticket-2:before {
    content: '\f389';
  }

  &.linear-sagittarius:before {
    content: '\f38a';
  }

  &.linear-devices-1:before {
    content: '\f38b';
  }

  &.linear-radio:before {
    content: '\f38c';
  }

  &.linear-login:before {
    content: '\f38d';
  }

  &.linear-slash:before {
    content: '\f38e';
  }

  &.linear-repeate-music:before {
    content: '\f38f';
  }

  &.linear-rotate-left-1:before {
    content: '\f390';
  }

  &.linear-shapes:before {
    content: '\f391';
  }

  &.linear-battery-charging:before {
    content: '\f392';
  }

  &.linear-ram-2:before {
    content: '\f393';
  }

  &.linear-message-time:before {
    content: '\f394';
  }

  &.linear-wifi-square:before {
    content: '\f395';
  }

  &.linear-message-question:before {
    content: '\f396';
  }

  &.linear-tag-user:before {
    content: '\f397';
  }

  &.linear-card-pos:before {
    content: '\f398';
  }

  &.linear-voice-square:before {
    content: '\f399';
  }

  &.linear-box-1:before {
    content: '\f39a';
  }

  &.linear-link-circle:before {
    content: '\f39b';
  }

  &.linear-sms:before {
    content: '\f39c';
  }

  &.linear-receipt-disscount:before {
    content: '\f39d';
  }

  &.linear-discover:before {
    content: '\f39e';
  }

  &.linear-add-square:before {
    content: '\f39f';
  }

  &.linear-cloud-notif:before {
    content: '\f3a0';
  }

  &.linear-profile-add:before {
    content: '\f3a1';
  }

  &.linear-shield-cross:before {
    content: '\f3a2';
  }

  &.linear-grid-5:before {
    content: '\f3a3';
  }

  &.linear-note-2:before {
    content: '\f3a4';
  }

  &.linear-radar-2:before {
    content: '\f3a5';
  }

  &.linear-global-edit:before {
    content: '\f3a6';
  }

  &.linear-gallery:before {
    content: '\f3a7';
  }

  &.linear-home-hashtag:before {
    content: '\f3a8';
  }

  &.linear-direct-up:before {
    content: '\f3a9';
  }

  &.linear-import:before {
    content: '\f3aa';
  }

  &.linear-size:before {
    content: '\f3ab';
  }

  &.linear-group-4:before {
    content: '\f3ac';
  }

  &.linear-note:before {
    content: '\f3ad';
  }

  &.linear-frame-2:before {
    content: '\f3ae';
  }

  &.linear-align-vertically:before {
    content: '\f3af';
  }

  &.linear-kanban:before {
    content: '\f3b0';
  }

  &.linear-shop-remove:before {
    content: '\f3b1';
  }

  &.linear-video-circle:before {
    content: '\f3b2';
  }

  &.linear-shield:before {
    content: '\f3b3';
  }

  &.linear-code-1:before {
    content: '\f3b4';
  }

  &.linear-notification-favorite:before {
    content: '\f3b5';
  }

  &.linear-bookmark-2:before {
    content: '\f3b6';
  }

  &.linear-backward-5-seconds:before {
    content: '\f3b7';
  }

  &.linear-textalign-right:before {
    content: '\f3b8';
  }

  &.linear-omega-square:before {
    content: '\f3b9';
  }

  &.linear-close-circle:before {
    content: '\f3ba';
  }

  &.linear-frame-3:before {
    content: '\f3bb';
  }

  &.linear-archive:before {
    content: '\f3bc';
  }

  &.linear-game:before {
    content: '\f3bd';
  }

  &.linear-group-5:before {
    content: '\f3be';
  }

  &.linear-note-text:before {
    content: '\f3bf';
  }

  &.linear-forward-item:before {
    content: '\f3c0';
  }

  &.linear-global-search:before {
    content: '\f3c1';
  }

  &.linear-book-saved:before {
    content: '\f3c2';
  }

  &.linear-volume-cross:before {
    content: '\f3c3';
  }

  &.linear-status-up:before {
    content: '\f3c4';
  }

  &.linear-grid-4:before {
    content: '\f3c5';
  }

  &.linear-mask-1:before {
    content: '\f3c6';
  }

  &.linear-text-bold:before {
    content: '\f3c7';
  }

  &.linear-cloud-snow:before {
    content: '\f3c8';
  }

  &.linear-gallery-favorite:before {
    content: '\f3c9';
  }

  &.linear-row-horizontal:before {
    content: '\f3ca';
  }

  &.linear-settings:before {
    content: '\f3cb';
  }

  &.linear-minus-square:before {
    content: '\f3cc';
  }

  &.linear-group:before {
    content: '\f3cd';
  }

  &.linear-receive-square:before {
    content: '\f3ce';
  }

  &.linear-programming-arrows:before {
    content: '\f3cf';
  }

  &.linear-calendar-tick:before {
    content: '\f3d0';
  }

  &.linear-pen-add-1:before {
    content: '\f3d1';
  }

  &.linear-backward-15-seconds:before {
    content: '\f3d2';
  }

  &.linear-driver-2:before {
    content: '\f3d3';
  }

  &.linear-chart-1:before {
    content: '\f3d4';
  }

  &.linear-ship:before {
    content: '\f3d5';
  }

  &.linear-chart-3:before {
    content: '\f3d6';
  }

  &.linear-3d-rotate:before {
    content: '\f3d7';
  }

  &.linear-stop-circle:before {
    content: '\f3d8';
  }

  &.linear-brush:before {
    content: '\f3d9';
  }

  &.linear-arrow-right:before {
    content: '\f3da';
  }

  &.linear-box-2:before {
    content: '\f3db';
  }

  &.linear-money-forbidden:before {
    content: '\f3dc';
  }

  &.linear-grid-6:before {
    content: '\f3dd';
  }

  &.linear-mask-3:before {
    content: '\f3de';
  }

  &.linear-key:before {
    content: '\f3df';
  }

  &.linear-note-1:before {
    content: '\f3e0';
  }

  &.linear-toggle-off:before {
    content: '\f3e1';
  }

  &.linear-empty-wallet:before {
    content: '\f3e2';
  }

  &.linear-radar-1:before {
    content: '\f3e3';
  }

  &.linear-link:before {
    content: '\f3e4';
  }

  &.linear-receipt-square:before {
    content: '\f3e5';
  }

  &.linear-instagram:before {
    content: '\f3e6';
  }

  &.linear-group-7:before {
    content: '\f3e7';
  }

  &.linear-lamp-on:before {
    content: '\f3e8';
  }

  &.linear-clipboard:before {
    content: '\f3e9';
  }

  &.linear-frame-1:before {
    content: '\f3ea';
  }

  &.linear-coin-1:before {
    content: '\f3eb';
  }

  &.linear-driver-refresh:before {
    content: '\f3ec';
  }

  &.linear-dislike:before {
    content: '\f3ed';
  }

  &.linear-empty-wallet-tick:before {
    content: '\f3ee';
  }

  &.linear-money-2:before {
    content: '\f3ef';
  }

  &.linear-main-component-1:before {
    content: '\f3f0';
  }

  &.linear-cloud-drizzle:before {
    content: '\f3f1';
  }

  &.linear-arrow-square-right:before {
    content: '\f3f2';
  }

  &.linear-money-3:before {
    content: '\f3f3';
  }

  &.linear-search-zoom-in:before {
    content: '\f3f4';
  }

  &.linear-slider-vertical:before {
    content: '\f3f5';
  }

  &.linear-filter-search:before {
    content: '\f3f6';
  }

  &.linear-bag-2:before {
    content: '\f3f7';
  }

  &.linear-language-circle:before {
    content: '\f3f8';
  }

  &.linear-shopping-bag:before {
    content: '\f3f9';
  }

  &.linear-health:before {
    content: '\f3fa';
  }

  &.linear-buildings:before {
    content: '\f3fb';
  }

  &.linear-group-6:before {
    content: '\f3fc';
  }

  &.linear-call-outgoing:before {
    content: '\f3fd';
  }

  &.linear-box-remove:before {
    content: '\f3fe';
  }

  &.linear-lock:before {
    content: '\f3ff';
  }

  &.linear-user-tick:before {
    content: '\f400';
  }

  &.linear-mask-2:before {
    content: '\f401';
  }

  &.linear-grid-7:before {
    content: '\f402';
  }

  &.linear-save-minus:before {
    content: '\f403';
  }

  &.linear-profile-2user:before {
    content: '\f404';
  }

  &.linear-video-tick:before {
    content: '\f405';
  }

  &.linear-location-tick:before {
    content: '\f406';
  }

  &.linear-attach-circle:before {
    content: '\f407';
  }

  &.linear-microphone-2:before {
    content: '\f408';
  }

  &.linear-filter-tick:before {
    content: '\f409';
  }

  &.linear-notification-circle:before {
    content: '\f40a';
  }

  &.linear-keyboard:before {
    content: '\f40b';
  }

  &.linear-path-2:before {
    content: '\f40c';
  }

  &.linear-chart-2:before {
    content: '\f40d';
  }

  &.linear-folder-open:before {
    content: '\f40e';
  }

  &.linear-search-status:before {
    content: '\f40f';
  }

  &.linear-bubble:before {
    content: '\f410';
  }

  &.linear-sms-search:before {
    content: '\f411';
  }

  &.linear-message-add:before {
    content: '\f412';
  }

  &.linear-warning-2:before {
    content: '\f413';
  }

  &.linear-brush-3:before {
    content: '\f414';
  }

  &.linear-arrow-up-1:before {
    content: '\f415';
  }

  &.linear-lock-slash:before {
    content: '\f416';
  }

  &.linear-cpu-setting:before {
    content: '\f417';
  }

  &.linear-shield-security:before {
    content: '\f418';
  }

  &.linear-calendar-2:before {
    content: '\f419';
  }

  &.linear-radar:before {
    content: '\f41a';
  }

  &.linear-maximize:before {
    content: '\f41b';
  }

  &.linear-shield-tick:before {
    content: '\f41c';
  }

  &.linear-magic-star:before {
    content: '\f41d';
  }

  &.linear-percentage-square:before {
    content: '\f41e';
  }

  &.linear-mouse-square:before {
    content: '\f41f';
  }

  &.linear-fatrows:before {
    content: '\f420';
  }

  &.linear-discount-shape:before {
    content: '\f421';
  }

  &.linear-devices:before {
    content: '\f422';
  }

  &.linear-arrow-2:before {
    content: '\f423';
  }

  &.linear-message-circle:before {
    content: '\f424';
  }

  &.linear-pen-tool-2:before {
    content: '\f425';
  }

  &.linear-monitor-mobbile:before {
    content: '\f426';
  }

  &.linear-smileys:before {
    content: '\f427';
  }

  &.linear-arrow-3:before {
    content: '\f428';
  }

  &.linear-ticket-star:before {
    content: '\f429';
  }

  &.linear-grammerly:before {
    content: '\f42a';
  }

  &.linear-user-square:before {
    content: '\f42b';
  }

  &.linear-airpods:before {
    content: '\f42c';
  }

  &.linear-transaction-minus:before {
    content: '\f42d';
  }

  &.linear-mobile-programming:before {
    content: '\f42e';
  }

  &.linear-book-square:before {
    content: '\f42f';
  }

  &.linear-music-filter:before {
    content: '\f430';
  }

  &.linear-calendar-circle:before {
    content: '\f431';
  }

  &.linear-home-trend-down:before {
    content: '\f432';
  }

  &.linear-microphone:before {
    content: '\f433';
  }

  &.linear-sms-notification:before {
    content: '\f434';
  }

  &.linear-sidebar-right:before {
    content: '\f435';
  }

  &.linear-map:before {
    content: '\f436';
  }

  &.linear-moneys:before {
    content: '\f437';
  }

  &.linear-brush-2:before {
    content: '\f438';
  }

  &.linear-direct-right:before {
    content: '\f439';
  }

  &.linear-cloud-plus:before {
    content: '\f43a';
  }

  &.linear-directbox-send:before {
    content: '\f43b';
  }

  &.linear-wallet-1:before {
    content: '\f43c';
  }

  &.linear-hierarchy-3:before {
    content: '\f43d';
  }

  &.linear-calendar-search:before {
    content: '\f43e';
  }

  &.linear-wallet-3:before {
    content: '\f43f';
  }

  &.linear-music-square-add:before {
    content: '\f440';
  }

  &.linear-calendar-add:before {
    content: '\f441';
  }

  &.linear-align-bottom:before {
    content: '\f442';
  }

  &.linear-arrow-up-2:before {
    content: '\f443';
  }

  &.linear-location-slash:before {
    content: '\f444';
  }

  &.linear-briefcase:before {
    content: '\f445';
  }

  &.linear-sidebar-top:before {
    content: '\f446';
  }

  &.linear-user-tag:before {
    content: '\f447';
  }

  &.linear-tag:before {
    content: '\f448';
  }

  &.linear-calendar-1:before {
    content: '\f449';
  }

  &.linear-text-italic:before {
    content: '\f44a';
  }

  &.linear-crop:before {
    content: '\f44b';
  }

  &.linear-call-remove:before {
    content: '\f44c';
  }

  &.linear-refresh-right-square:before {
    content: '\f44d';
  }

  &.linear-convertshape-2:before {
    content: '\f44e';
  }

  &.linear-blur:before {
    content: '\f44f';
  }

  &.linear-export:before {
    content: '\f450';
  }

  &.linear-ticket:before {
    content: '\f451';
  }

  &.linear-brifecase-cross:before {
    content: '\f452';
  }

  &.linear-quote-up-square:before {
    content: '\f453';
  }

  &.linear-candle:before {
    content: '\f454';
  }

  &.linear-pen-tool-1:before {
    content: '\f455';
  }

  &.linear-signpost:before {
    content: '\f456';
  }

  &.linear-creative-commons:before {
    content: '\f457';
  }

  &.linear-flag-2:before {
    content: '\f458';
  }

  &.linear-message-notif:before {
    content: '\f459';
  }

  &.linear-camera-slash:before {
    content: '\f45a';
  }

  &.linear-logout:before {
    content: '\f45b';
  }

  &.linear-24-support:before {
    content: '\f45c';
  }

  &.linear-message-search:before {
    content: '\f45d';
  }

  &.linear-money-tick:before {
    content: '\f45e';
  }

  &.linear-bucket-square-1:before {
    content: '\f45f';
  }

  &.linear-bag-happy:before {
    content: '\f460';
  }

  &.linear-card-edit:before {
    content: '\f461';
  }

  &.linear-omega-square-1:before {
    content: '\f462';
  }

  &.linear-dollar-circle:before {
    content: '\f463';
  }

  &.linear-star-1:before {
    content: '\f464';
  }

  &.linear-arrow-up-3:before {
    content: '\f465';
  }

  &.linear-brush-1:before {
    content: '\f466';
  }

  &.linear-music-square-search:before {
    content: '\f467';
  }

  &.linear-music:before {
    content: '\f468';
  }

  &.linear-received:before {
    content: '\f469';
  }

  &.linear-textalign-justifycenter:before {
    content: '\f46a';
  }

  &.linear-card-tick-1:before {
    content: '\f46b';
  }

  &.linear-play-cricle:before {
    content: '\f46c';
  }

  &.linear-hierarchy-2:before {
    content: '\f46d';
  }

  &.linear-wallet-2:before {
    content: '\f46e';
  }

  &.linear-copyright:before {
    content: '\f46f';
  }

  &.linear-shop:before {
    content: '\f470';
  }

  &.linear-home:before {
    content: '\f471';
  }

  &.linear-milk:before {
    content: '\f472';
  }

  &.linear-edit-2:before {
    content: '\f473';
  }

  &.linear-clipboard-tick:before {
    content: '\f474';
  }

  &.linear-forward-5-seconds:before {
    content: '\f475';
  }

  &.linear-arrow-square:before {
    content: '\f476';
  }

  &.linear-like-dislike:before {
    content: '\f477';
  }

  &.linear-format-square:before {
    content: '\f478';
  }

  &.linear-quote-up:before {
    content: '\f479';
  }

  &.linear-sidebar-left:before {
    content: '\f47a';
  }

  &.linear-heart-search:before {
    content: '\f47b';
  }

  &.linear-card-receive:before {
    content: '\f47c';
  }

  &.linear-rotate-right-1:before {
    content: '\f47d';
  }

  &.linear-home-trend-up:before {
    content: '\f47e';
  }

  &.linear-message-text-1:before {
    content: '\f47f';
  }

  &.linear-arrow:before {
    content: '\f480';
  }

  &.linear-money-change:before {
    content: '\f481';
  }

  &.linear-user:before {
    content: '\f482';
  }

  &.linear-wallet-search:before {
    content: '\f483';
  }

  &.linear-audio-square:before {
    content: '\f484';
  }

  &.linear-more-circle:before {
    content: '\f485';
  }

  &.linear-shapes-2:before {
    content: '\f486';
  }

  &.linear-translate:before {
    content: '\f487';
  }

  &.linear-document-forward:before {
    content: '\f488';
  }

  &.linear-money-time:before {
    content: '\f489';
  }

  &.linear-aquarius:before {
    content: '\f48a';
  }

  &.linear-tag-2:before {
    content: '\f48b';
  }

  &.linear-map-1:before {
    content: '\f48c';
  }

  &.linear-document-like:before {
    content: '\f48d';
  }

  &.linear-chart:before {
    content: '\f48e';
  }

  &.linear-tick-square:before {
    content: '\f48f';
  }

  &.linear-password-check:before {
    content: '\f490';
  }

  &.linear-presention-chart:before {
    content: '\f491';
  }

  &.linear-gps-slash:before {
    content: '\f492';
  }

  &.linear-gallery-tick:before {
    content: '\f493';
  }

  &.linear-path:before {
    content: '\f494';
  }

  &.linear-profile-remove:before {
    content: '\f495';
  }

  &.linear-import-1:before {
    content: '\f496';
  }

  &.linear-bezier-1:before {
    content: '\f497';
  }

  &.linear-repeat:before {
    content: '\f498';
  }

  &.linear-refresh-square-2:before {
    content: '\f499';
  }

  &.linear-arrange-square:before {
    content: '\f49a';
  }

  &.linear-document-text-1:before {
    content: '\f49b';
  }

  &.linear-information:before {
    content: '\f49c';
  }

  &.linear-blur-1:before {
    content: '\f49d';
  }

  &.linear-picture-frame:before {
    content: '\f49e';
  }

  &.linear-arrow-square-left:before {
    content: '\f49f';
  }

  &.linear-play-circle:before {
    content: '\f4a0';
  }

  &.linear-menu-1:before {
    content: '\f4a1';
  }

  &.linear-rotate-left:before {
    content: '\f4a2';
  }

  &.linear-receipt-2-1:before {
    content: '\f4a3';
  }

  &.linear-wind:before {
    content: '\f4a4';
  }

  &.linear-money-add:before {
    content: '\f4a5';
  }

  &.linear-note-favorite:before {
    content: '\f4a6';
  }

  &.linear-trush-square:before {
    content: '\f4a7';
  }

  &.linear-card-coin:before {
    content: '\f4a8';
  }

  &.linear-buildings-2:before {
    content: '\f4a9';
  }

  &.linear-house:before {
    content: '\f4aa';
  }

  &.linear-card-send:before {
    content: '\f4ab';
  }

  &.linear-sound:before {
    content: '\f4ac';
  }

  &.linear-refresh-circle:before {
    content: '\f4ad';
  }

  &.linear-location-add:before {
    content: '\f4ae';
  }

  &.linear-gemini:before {
    content: '\f4af';
  }

  &.linear-shield-search:before {
    content: '\f4b0';
  }

  &.linear-archive-minus:before {
    content: '\f4b1';
  }

  &.linear-timer-start:before {
    content: '\f4b2';
  }

  &.linear-quote-down-circle:before {
    content: '\f4b3';
  }

  &.linear-shapes-1:before {
    content: '\f4b4';
  }

  &.linear-brifecase-timer:before {
    content: '\f4b5';
  }

  &.linear-heart-tick:before {
    content: '\f4b6';
  }

  &.linear-textalign-left:before {
    content: '\f4b7';
  }

  &.linear-ghost:before {
    content: '\f4b8';
  }

  &.linear-lamp-1:before {
    content: '\f4b9';
  }

  &.linear-quote-down:before {
    content: '\f4ba';
  }

  &.linear-battery-disable:before {
    content: '\f4bb';
  }

  &.linear-arrow-down:before {
    content: '\f4bc';
  }

  &.linear-search-zoom-in-1:before {
    content: '\f4bd';
  }

  &.linear-paintbucket:before {
    content: '\f4be';
  }

  &.linear-printer-slash:before {
    content: '\f4bf';
  }

  &.linear-subtitle:before {
    content: '\f4c0';
  }

  &.linear-pause-circle:before {
    content: '\f4c1';
  }

  &.linear-receipt:before {
    content: '\f4c2';
  }

  &.linear-like:before {
    content: '\f4c3';
  }

  &.linear-eraser:before {
    content: '\f4c4';
  }

  &.linear-musicnote:before {
    content: '\f4c5';
  }

  &.linear-toggle-on-circle:before {
    content: '\f4c6';
  }

  &.linear-shop-add:before {
    content: '\f4c7';
  }

  &.linear-clipboard-close:before {
    content: '\f4c8';
  }

  &.linear-task:before {
    content: '\f4c9';
  }

  &.linear-slider-vertical-1:before {
    content: '\f4ca';
  }

  &.linear-driver:before {
    content: '\f4cb';
  }

  &.linear-sun-1:before {
    content: '\f4cc';
  }

  &.linear-toggle-on:before {
    content: '\f4cd';
  }

  &.linear-triangle:before {
    content: '\f4ce';
  }

  &.linear-gallery-remove:before {
    content: '\f4cf';
  }

  &.linear-bag-tick:before {
    content: '\f4d0';
  }

  &.linear-heart-circle:before {
    content: '\f4d1';
  }

  &.linear-directbox-notif:before {
    content: '\f4d2';
  }

  &.linear-arrow-square-up:before {
    content: '\f4d3';
  }

  &.linear-import-2:before {
    content: '\f4d4';
  }

  &.linear-profile-delete:before {
    content: '\f4d5';
  }

  &.linear-smart-home:before {
    content: '\f4d6';
  }

  &.linear-microphone-slash-1:before {
    content: '\f4d7';
  }

  &.linear-convertshape:before {
    content: '\f4d8';
  }
}

.ds-icon {
  @include icon-component();
}
