@use '../../../typography';
@use '../../../palette';

@mixin pagination-component() {
  .ds-pagination {
    &-full-page {
      @apply flex items-center justify-between px-0 py-4;

      > main {
        @apply flex gap-2 items-center;
      }

      &__input {
        @apply font-work-sans text-work-sans-sm font-semibold w-8 text-center border-none shadow-none outline-none text-branding-neutral-700;

        &.has-error {
          @apply text-branding-error-700;
        }

        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        &[type='number'] {
          -moz-appearance: textfield;
        }
      }
    }
    &-primary {
      @apply flex items-center justify-between px-4 py-3 max-w-[375px];
    }
  }
}
