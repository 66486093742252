@use '../../../palette';
@use '../../../typography';

@mixin tags-component() {
  .ds-tags {
    --ds-tags-gap: 4px;
    --ds-tags-height: 24px;
    --ds-tags-close: 10px;
    --ds-tags-count: 16px;
    @apply inline-flex items-center justify-center border border-solid border-branding-neutral-300 rounded-full w-fit;
    gap: var(--ds-tags-gap);
    height: var(--ds-tags-height);
    padding: 0 var(--ds-tags-gap);

    &--sm {
      --ds-tags-gap: 4px;
      --ds-tags-height: 24px;
      --ds-tags-close: 10px;
      --ds-tags-count: 16px;
      .ds-tags {
        &__label {
          @include typography.use-work-sans(xs, 500);
        }
        &__count {
          @include typography.use-work-sans(xs, 500);
        }
      }
    }

    &--md {
      --ds-tags-gap: 5px;
      --ds-tags-height: 28px;
      --ds-tags-close: 12px;
      --ds-tags-count: 18px;
      .ds-tags {
        &__label {
          @include typography.use-work-sans(sm, 500);
        }
        &__count {
          @include typography.use-work-sans(xs, 500);
        }
      }
    }

    &--lg {
      --ds-tags-gap: 6px;
      --ds-tags-height: 32px;
      --ds-tags-close: 14px;
      --ds-tags-count: 20px;
      .ds-tags {
        &__label {
          @include typography.use-work-sans(sm, 500);
        }
        &__count {
          @include typography.use-work-sans(sm, 500);
        }
      }
    }

    &__country {
      --ds-icon-font-size: 16px;
    }
    &__avatar {
      @apply w-4 h-4 rounded-full;
    }
    &__dot {
      @apply w-1.5 h-1.5 rounded-full bg-branding-success-500;
    }
    &__label {
      @apply text-branding-neutral-700;
    }
    &__count {
      @apply rounded-full bg-branding-neutral-100 text-branding-neutral-700 inline-flex items-center justify-center;
      height: var(--ds-tags-count);
      width: var(--ds-tags-count);
    }
    &__close {
      --ds-icon-font-size: var(--ds-tags-close);
      @apply text-branding-neutral-400 hover:cursor-pointer hover:text-branding-error-400;
    }
  }
}
