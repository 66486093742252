@use '../../../palette';
@use '../../../typography';

@mixin avatar-component() {
  .ds-avatar {
    --ds-avatar-size: 40px;
    --ds-avatar-online-size: 10px;
    --ds-avatar-icon-font-size: 24px;
    @apply inline-flex items-center justify-center relative rounded-full;
    @apply bg-branding-neutral-100 border-[0.75px] border-solid border-branding-primary-100;
    width: var(--ds-avatar-size);
    height: var(--ds-avatar-size);

    &--is-online {
      &::after {
        @apply content-[''] right-0 bottom-0 rounded-full bg-branding-success-500 absolute;
        box-shadow: 0 0 0 1.5px
          var(#{palette.to-color-variable(branding, base, white)});
        width: var(--ds-avatar-online-size);
        height: var(--ds-avatar-online-size);
      }
    }

    &--have-box {
      box-shadow: 0 0 0 4px
        var(#{palette.to-color-variable(branding, neutral, 100)});
    }

    &--xs {
      --ds-avatar-size: 24px;
      --ds-avatar-online-size: 6px;
      --ds-avatar-icon-font-size: 16px;

      > span {
        @include typography.use-work-sans(xs, 600);
      }
    }

    &--sm {
      --ds-avatar-size: 32px;
      --ds-avatar-online-size: 8px;
      --ds-avatar-icon-font-size: 20px;

      > span {
        @include typography.use-work-sans(sm, 600);
      }
    }

    &--md {
      --ds-avatar-size: 40px;
      --ds-avatar-online-size: 10px;
      --ds-avatar-icon-font-size: 24px;

      > span {
        @include typography.use-work-sans(md, 600);
      }
    }

    &--lg {
      --ds-avatar-size: 48px;
      --ds-avatar-online-size: 12px;
      --ds-avatar-icon-font-size: 28px;

      > span {
        @include typography.use-work-sans(lg, 600);
      }
    }

    &--xl {
      --ds-avatar-size: 56px;
      --ds-avatar-online-size: 14px;
      --ds-avatar-icon-font-size: 32px;

      > span {
        @include typography.use-work-sans(xl, 600);
      }
    }

    &--2xl {
      --ds-avatar-size: 64px;
      --ds-avatar-online-size: 16px;
      --ds-avatar-icon-font-size: 32px;

      > span {
        @include typography.use-work-sans(display-xs, 600);
      }
    }

    > img {
      @apply rounded-full w-full h-full;
    }

    > span {
      @apply text-branding-neutral-600;
    }

    > i {
      @apply text-branding-neutral-600;
      --ds-icon-font-size: var(--ds-avatar-icon-font-size);
    }
  }
}
