@use '../../../typography';
@use '../../../palette';

@mixin button-group-component() {
  .ds-button-group {
    @apply inline-flex rounded-full overflow-hidden border border-solid border-branding-neutral-300 bg-branding-base-white;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

    &:not(.ignore-item-border) {
      > button {
        &:not(:last-child) {
          @apply border-r;
        }
      }
    }

    > button {
      @apply inline-flex gap-0.5 outline-none items-center shadow-none border-0 border-solid;
      --ds-button-group-item-padding-y: 10px;
      --ds-button-group-item-padding-x: 16px;
      padding: var(--ds-button-group-item-padding-y)
        var(--ds-button-group-item-padding-x);
      &.ds-button-group--is-icon {
        --ds-button-group-item-padding-y: 10px;
        --ds-button-group-item-padding-x: 12px;

        &-large {
          --ds-button-group-item-padding-y: 10px;
          --ds-button-group-item-padding-x: 10px;
        }
      }

      > i {
        --ds-icon-font-size: 20px;
      }

      > span {
        @include typography.use-work-sans(sm, 600);
      }

      &.ds-button-group--is-selected {
        @apply bg-branding-neutral-100 border-branding-neutral-300;

        &:not(:disabled) {
          > span {
            @apply text-branding-neutral-800;
          }

          > i {
            @apply text-branding-neutral-800;
          }
        }

        &:disabled {
          > span {
            @apply text-branding-neutral-300;
          }

          > i {
            @apply text-branding-neutral-300;
          }
        }
      }

      &:not(.ds-button-group--is-selected) {
        @apply border-branding-neutral-300 bg-branding-base-white;

        &:not(:disabled) {
          > span {
            @apply text-branding-neutral-700;
          }

          > i {
            @apply text-branding-neutral-500;
          }

          &:not(.disabled-state) {
            &:hover,
            &:active,
            &:focus-visible {
              @apply bg-branding-neutral-50;
            }
          }
        }

        &:disabled {
          > span {
            @apply text-branding-neutral-300;
          }

          > i {
            @apply text-branding-neutral-300;
          }
        }
      }

      &:not(:disabled):not(.disabled-state) {
        &:hover,
        &:active,
        &:focus-visible {
          @apply cursor-pointer;
        }
      }

      &:disabled {
        @apply cursor-not-allowed;
      }
    }
  }
}
