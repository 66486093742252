@use '../functions';

@mixin use-resolution($resolution) {
  $min: map-get(functions.get-resolution($resolution), min);
  $max: map-get(functions.get-resolution($resolution), max);

  @include _render($min, $max) {
    @content;
  }
}

@mixin use-resolution-min($resolution) {
  $min: map-get(functions.get-resolution($resolution), min);
  @include _render($min, null) {
    @content;
  }
}

@mixin use-resolution-max($resolution) {
  $max: map-get(functions.get-resolution($resolution), max);
  @include _render(null, $max) {
    @content;
  }
}

@mixin _render($min, $max) {
  @if ($min != null and $max != null) {
    @media (min-width: #{$min}) and (max-width: #{$max}) {
      @content;
    }
  } @else {
    @if ($min != null or $max != null) {
      @if ($min != null) {
        @media (min-width: #{$min}) {
          @content;
        }
      } @else {
        @media (max-width: #{$max}) {
          @content;
        }
      }
    }
  }
}
