@use '../../../palette';
@use '../../../typography';

@mixin modal-component() {
  .ds-modal {
    --ds-modal-width: 400px;
    --ds-modal-gap: 24px;
    --ds-modal-footer-align: center;
    --ds-modal-footer-direction: row;
    --ds-modal-footer-gap: 12px;
    --ds-modal-bg: var(#{palette.to-color-variable(branding, success, 600)});
    --ds-modal-main-padding: 0;
    --ds-modal-footer-padding: 0;
    box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03),
      0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    @apply relative flex flex-col p-6 rounded-2xl;
    width: var(--ds-modal-width);
    gap: var(--ds-modal-gap);

    &::before {
      @apply content-[''] top-0 left-0 w-full h-full absolute z-[-1] rounded-2xl;
      background: linear-gradient(180deg, var(--ds-modal-bg) 0%, #fff 100%);
    }

    &--column {
      --ds-modal-footer-direction: column;

      > footer {
        > button {
          @apply w-full;
        }
      }
    }
    &--column-reverse {
      --ds-modal-footer-direction: column-reverse;
      > footer {
        > button {
          @apply w-full;
        }
      }
    }
    &--row {
      --ds-modal-footer-direction: row;
      > footer {
        > button {
          @apply flex-1;
        }
      }
    }
    &--row-reverse {
      --ds-modal-footer-direction: row-reverse;
      > footer {
        > button {
          @apply flex-1;
        }
      }
    }

    &--success {
      --ds-modal-bg: var(#{palette.to-color-variable(branding, success, 50)});
      --ds-modal-main-padding: 0 0 20px 0;
      --ds-modal-footer-padding: 8px 0 0 0;
      --ds-modal-footer-align: center;
    }
    &--warning {
      --ds-modal-bg: var(#{palette.to-color-variable(branding, warning, 50)});
      --ds-modal-main-padding: 0 0 20px 0;
      --ds-modal-footer-padding: 8px 0 0 0;
      --ds-modal-footer-align: center;
    }
    &--info {
      --ds-modal-bg: var(#{palette.to-color-variable(branding, neutral, 50)});
      --ds-modal-main-padding: 0 0 20px 0;
      --ds-modal-footer-padding: 8px 0 0 0;
      --ds-modal-footer-align: center;
    }
    &--error {
      --ds-modal-bg: var(#{palette.to-color-variable(branding, error, 50)});
      --ds-modal-main-padding: 0 0 20px 0;
      --ds-modal-footer-padding: 8px 0 0 0;
      --ds-modal-footer-align: center;
    }

    &--form {
      --ds-modal-bg: var(#{palette.to-color-variable(branding, base, white)});
      --ds-modal-main-padding: 0;
      --ds-modal-footer-padding: 16px 0 0 0;
      --ds-modal-footer-align: start;
    }

    &--xs {
      --ds-modal-width: 400px;
    }

    &--sm {
      --ds-modal-width: 480px;
    }

    &--md {
      --ds-modal-width: 544px;
    }

    &--lg {
      --ds-modal-width: 688px;
    }

    &--xl {
      --ds-modal-width: 848px;
    }

    &__close {
      @apply absolute top-4 right-4;
    }

    > header {
      @apply w-full flex items-center;
      justify-content: var(--ds-modal-footer-align);

      > h4 {
        @include typography.use-work-sans(xl, 500);
        @apply m-0 text-branding-neutral-900;
      }
      > span {
        @apply w-12 h-12;
      }
    }

    > main {
      @apply w-full flex flex-col gap-px items-center;
      padding: var(--ds-modal-main-padding);

      > h4 {
        @apply text-center text-branding-neutral-900 m-0;
        @include typography.use-work-sans(lg, 500);
      }

      > p {
        @apply text-center text-branding-neutral-600 m-0;
        @include typography.use-work-sans(sm, 400);
      }
    }

    > footer {
      @apply flex;
      padding: var(--ds-modal-footer-padding);
      flex-direction: var(--ds-modal-footer-direction);
      gap: var(--ds-modal-footer-gap);
    }
  }
}
