@use '../../../typography';
@use '../../../palette';

@mixin table-component() {
  .ds-table {
    @apply border-spacing-0 rounded-t-2xl bg-branding-base-white;

    &--striped {
      tbody {
        tr:nth-child(even) > td {
          @apply bg-branding-neutral-50;
        }
      }
    }

    &--sticky {
      &-left {
        @include _sticky(left);

        &-shadow {
          &::after {
            @apply absolute content-[''] right-0 top-0 h-full w-full;
            box-shadow: 10px 0 16px -5px rgba(16, 24, 40, 0.08);
          }
        }
      }

      &-right {
        @include _sticky(right);
        &-shadow {
          &::before {
            @apply absolute content-[''] left-0 top-0 h-full w-full;
            box-shadow: -10px 0 16px -5px rgba(16, 24, 40, 0.08);
          }
        }
      }

      &-top {
        @include _sticky(top, 2);

        &-left {
          @include _sticky-full(top, left);

          &-shadow {
            &::after {
              @apply absolute content-[''] right-0 top-0 h-full w-full;
              box-shadow: 10px 0 16px -5px rgba(16, 24, 40, 0.08);
            }
          }
        }

        &-right {
          @include _sticky-full(top, right);

          &-shadow {
            &::before {
              @apply absolute content-[''] left-0 top-0 h-full w-full;
              box-shadow: -10px 0 16px -5px rgba(16, 24, 40, 0.08);
            }
          }
        }
      }

      &-bottom {
        @include _sticky(bottom, 2);

        &-left {
          @include _sticky-full(bottom, left);

          &-shadow {
            &::after {
              @apply absolute content-[''] right-0 top-0 h-full w-full;
              box-shadow: 10px 0 16px -5px rgba(16, 24, 40, 0.08);
            }
          }
        }

        &-right {
          @include _sticky-full(bottom, right);

          &-shadow {
            &::before {
              @apply absolute content-[''] left-0 top-0 h-full w-full;
              box-shadow: -10px 0 16px -5px rgba(16, 24, 40, 0.08);
            }
          }
        }
      }
    }

    &-sort {
      --ds-table-sort-text-color: var(
        #{palette.to-color-variable('branding', 'neutral', '600')}
      );
      --ds-table-sort-icon-size: 16px;
      --ds-table-sort-icon-up-color: var(
        #{palette.to-color-variable('branding', 'neutral', '400')}
      );
      --ds-table-sort-icon-down-color: var(
        #{palette.to-color-variable('branding', 'neutral', '400')}
      );

      @apply inline-flex items-center gap-2;

      &--desc {
        --ds-table-sort-icon-up-color: var(
          #{palette.to-color-variable('branding', 'neutral', '300')}
        );
        --ds-table-sort-icon-down-color: var(
          #{palette.to-color-variable('branding', 'primary', '600')}
        );
      }

      &--asc {
        --ds-table-sort-icon-up-color: var(
          #{palette.to-color-variable('branding', 'primary', '600')}
        );
        --ds-table-sort-icon-down-color: var(
          #{palette.to-color-variable('branding', 'neutral', '300')}
        );
      }

      > span {
        &:first-child {
          @include typography.use-work-sans(sm, 500);
          color: var(--ds-table-sort-text-color);
        }

        &:last-child {
          @apply inline-flex flex-col gap-0.5 relative w-4 h-4 hover:cursor-pointer;

          > i {
            font-size: var(--ds-table-sort-icon-size);

            &:first-child {
              color: var(--ds-table-sort-icon-up-color);
              @apply absolute -top-1;
            }

            &:last-child {
              color: var(--ds-table-sort-icon-down-color);
              @apply absolute -bottom-1;
            }
          }
        }
      }
    }

    > thead {
      > tr {
        > th {
          @apply px-4 py-3 bg-branding-neutral-50 text-branding-neutral-600;
          @include _fixed-width();
          @include _alignment();
          @include _cell_border_bottom();
          @include typography.use-work-sans(sm, 500);
          &:first-child {
            @apply rounded-tl-2xl;
          }

          &:last-child {
            @apply rounded-tr-2xl;
          }
        }
      }
    }

    > tbody {
      > tr {
        > td {
          @apply p-4 bg-branding-base-white text-branding-neutral-900;
          @include typography.use-work-sans(md, 400);
          @include _fixed-width();
          @include _alignment();
          @include _cell_border_bottom();
          &:first-child {
            @apply border-l;
          }

          &:last-child {
            @apply border-r;
          }
        }
      }
    }

    > tfoot {
      > tr {
        > td {
          @apply p-4 bg-branding-neutral-50;
          @include typography.use-work-sans(sm, 400);
          @include _fixed-width();
          @include _alignment();
        }
      }
    }

    &:has(thead) {
      > thead {
        > tr > th,
        td {
          &:first-child {
            @apply border-t border-l border-b border-r-0 border-solid border-branding-neutral-200;
          }

          &:last-child {
            @apply border-t border-r border-b border-l-0 border-solid border-branding-neutral-200;
          }

          &:not(:first-child):not(:last-child) {
            @apply border-t border-r-0 border-b border-l-0 border-solid border-branding-neutral-200;
          }
        }
      }
    }

    &:not(:has(thead)) {
      > tbody {
        > tr > th,
        td {
          &:first-child {
            @apply border-t border-l border-b-0 border-r-0 border-solid border-branding-neutral-200;
          }

          &:last-child {
            @apply border-t border-r border-b-0 border-l-0 border-solid border-branding-neutral-200;
          }

          &:not(:first-child):not(:last-child) {
            @apply border-t border-r-0 border-b-0 border-l-0 border-solid border-branding-neutral-200;
          }
        }
      }
    }

    &:has(tfoot) {
      > tfoot {
        > tr > th,
        td {
          &:first-child {
            @apply border-b border-l border-t-0 border-r-0 border-solid border-branding-neutral-200;
          }

          &:last-child {
            @apply border-b border-r border-t-0 border-l-0 border-solid border-branding-neutral-200;
          }

          &:not(:first-child):not(:last-child) {
            @apply border-b border-r-0 border-t-0 border-l-0 border-solid border-branding-neutral-200;
          }
        }
      }
    }

    &:not(:has(tfoot)) {
      > tbody {
        > tr > th,
        td {
          &:first-child {
            @apply border-b border-l border-t-0 border-r-0 border-solid border-branding-neutral-200;
          }

          &:last-child {
            @apply border-b border-r border-t-0 border-l-0 border-solid border-branding-neutral-200;
          }

          &:not(:first-child):not(:last-child) {
            @apply border-b border-r-0 border-t-0 border-l-0 border-solid border-branding-neutral-200;
          }
        }
      }
    }
  }
}

@mixin _fixed-width() {
  --fixed-width: 'auto';
  min-width: var(--fixed-width);
}

@mixin _alignment() {
  --alignment: 'left';
  text-align: var(--alignment);
}

@mixin _cell_border_bottom() {
  @apply border-0 border-b border-solid border-branding-neutral-200;
}

@mixin _sticky($align: left, $z-index: 1) {
  --#{$align}: 0;
  @apply sticky;
  z-index: #{$z-index};
  #{$align}: var(--#{$align});
}

@mixin _sticky-full($align-y: top, $align-x: left) {
  --#{$align-x}: 0;
  --#{$align-y}: 0;
  @apply sticky z-[3];
  #{$align-x}: var(--#{$align-x});
  #{$align-y}: var(--#{$align-y});
}
